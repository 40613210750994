import React, { useEffect } from 'react'
import { 
  Card, CardBody, Button,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PromptModal from 'components/Indicator/Prompt'
import Panel from './form'

import WithPanels from './actions'

const Panels = props => {
  useEffect(() => {
    props.getPanels()
    props.reloadNewPanel()
  }, [])

  const renderCreateModal = () => (
    <Modal size='md' isOpen={ props.showCreatePanel }>
      <ModalHeader 
        toggle={ () => props.reloadNewPanel() }>
        Create Panel</ModalHeader>
      <ModalBody>
        <Panel
          data={ props.data }
          panelType='newPanel'
          currentPanel={ props.newPanel }
          onChangePanelsHOC={ props.onChangePanelsHOC } />
        { props.onLoadPanelsHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color='primary'
          onClick={ () => props.createPanel( props.newPanel ) } >
          Submit</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateModal = () => (
    <Modal size='md' isOpen={ props.showUpdatePanel }>
      <ModalHeader 
        toggle={ () => props.reloadSelectedPanel() }>
        Update Panel</ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedPanel ) && <Panel
            data={ props.data }
            panelType='selectedPanel'
            currentPanel={ props.selectedPanel }
            onChangePanelsHOC={ props.onChangePanelsHOC } />
        }
        { props.onLoadPanelsHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color='primary'
          onClick={ () => props.updatePanel( props.selectedPanel ) } >
          Submit</Button>
      </ModalFooter>
    </Modal>
  )
  
  return(
    <>
      <PageTitle
        heading="Panels"
        subheading="Listings of all the panels."
        icon="pe-7s-clock icon-gradient bg-happy-itmeo"
        buttons={[
          {  
            color: 'primary',
            onClick: () => props.onChangePanelsHOC( 'showCreatePanel', true ),
            content: 'Create panel'
          }
        ]} />
      <Card className="main-card mb-3">
        <CardBody>
          <ReactTable
            showPagination={ true }
            pageSize={ 20 }
            data={ props.panels }
            columns={[
              {
                Header: 'Panel',
                accessor: 'panel_name',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Code',
                accessor: 'code',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Panel\'s contact number',
                accessor: 'contact_number',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Person-in-charge (PIC)',
                accessor: 'person_in_charge',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'PIC\'s contact number',
                accessor: 'person_in_charge_contact_number',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Action',
                style:{ 
                  "justify-content": "center",
                  "overflow": "scroll" 
                },
                Cell: row => <>
                  <Button 
                    color='primary'
                    className='mr-2'
                    onClick={ () => props.getSelectedPanel( row.original.id ) }>
                    <i className='pe-7s-pen'/></Button>
                  <Button 
                    color='danger'
                    className='ml-2'
                    onClick={ () => props.onChangePanelsHOC( 'selectedPanelToDelete', row.original.id ) }>
                    <i className='pe-7s-trash'/></Button>
                </>
              }
            ]}/>
        </CardBody>
      </Card>
      <PromptModal
        showPromptModal={ props.selectedPanelToDelete > 0 }
        onClickYes={ () => props.removePanel( props.selectedPanelToDelete ) }
        onClickNo={ () => props.onChangePanelsHOC( 'selectedPanelToDelete', 0 ) }
        content={ 'Are you sure you want to delete the record?' } />
      { renderCreateModal() }
      { renderUpdateModal() }
      { props.onLoadPanelsHOC && <LoadingOverlay/> }
    </>
  )
}

export default WithPanels( Panels )

