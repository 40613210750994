import React, { Component } from 'react'
import {
  Card,
  CardHeader,
  CardBody
} from 'reactstrap'
import ReactTable from 'react-table'
import { compose } from 'redux'
import Moment from 'moment'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SearchForm from './SearchForm'

import WithMedicineCollections from './actions'
import WithCompanies from 'actions/dictionary/companies'
import CurrencySettings from 'utils/currencies'

class MedicineCollections extends Component {

  componentDidMount = async() => {
    if( this.props.data.profileReducer.profile.current_user.role_id === 1 ){
      this.props.getCompanies()
    }
    this.props.getMedicineCollections( this.props.searchParams[ 2 ][ 'value' ], Moment( this.props.searchParams[ 0 ][ 'value' ] ).format( 'YYYY-MM-DD' ), Moment( this.props.searchParams[ 1 ][ 'value' ] ).format( 'YYYY-MM-DD' ) )
  }

  getMedicineCollectionsTotal = () => {
    let tmpTotal = 0
    this.props.medicineCollections.map( collection => {
      collection.medicines.map( item => { tmpTotal = tmpTotal + ( parseFloat( item.price_per_unit ) * item.quantity ) } )
    })
    return parseFloat( tmpTotal ).toFixed( 2 )
  }

  getMedicineListing = () => {
    let tmpList = []
    this.props.medicineCollections.map( collection => {
      collection.medicines.map( medicine => {
        tmpList.push({
          created_at: collection.created_at,
          doctor: collection.doctor,
          medicine: medicine.name,
          patient: collection.patient,
          patient_address: collection.patient_address
        })
      })
    })

    return tmpList
  }

  render = () => {
    return(
      <>
        <PageTitle
          heading="Medicine Collections"
          subheading="Listings of all the medicine collections."
          icon="pe-7s-mail-open-file icon-gradient bg-happy-itmeo"
          buttons={[]} />
          <SearchForm { ...this.props } />
          <Card className="main-card mb-3">
            <CardHeader>
              { `Search results from ${ Moment( this.props.searchParams[ 0 ][ 'value' ] ).format( 'DD MMM YYYY' ) } to ${ Moment( this.props.searchParams[ 1 ][ 'value' ] ).format( 'DD MMM YYYY' ) }` }
            </CardHeader>
            <CardBody>
              <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                <div className="widget-chart-content">
                  <div className="widget-subheading d-flex">
                    Total collection for selected date
                  </div>
                  <div className="widget-numbers">
                    { `${ CurrencySettings() } ${ this.getMedicineCollectionsTotal() }` }
                  </div>
                </div>
              </div>
              <ReactTable
                defaultPageSize={ 10 }
                data={ this.getMedicineListing() }
                columns={[
                  {
                    Header: 'Date',
                    accessor: 'created_at',
                    Cell: row => Moment( row.original.created_at ).format( 'DD-MM-YYYY' )
                  },
                  {
                    Header: 'Doctor Name',
                    accessor: 'doctor',
                  },
                  {
                    Header: 'Medicine Name',
                    accessor: 'medicine'
                  },
                  {
                    Header: 'Patient Name',
                    accessor: 'patient'
                  },
                  {
                    Header: 'Patient Address',
                    accessor: 'patient_address'
                  }
                ]}
              />
            </CardBody>
          </Card>
          { this.props.onLoadMedicineCollections && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose (
  WithMedicineCollections,
  WithCompanies
)( MedicineCollections )