import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'
import { 
  Row, Col,
  Card, CardBody, CardHeader,
  CardDeck,
  Label, Button
} from 'reactstrap'
import ReactTable from 'react-table'
import Moment from 'moment'
import Tabs from 'react-responsive-tabs'

import MainFrame from 'components/Frame'
import TemplateHeader from 'components/Header'
import TemplateSidemenu from 'components/Menu/Sidemenu'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Users from './Users'
import Treatments from './Treatments'
import Patients from './Patients'
import TreatedPatients from './TreatedPatients'
import MedicalCerts from './MedicalCerts'
import MedicalCertsReports from './MedicalCerts/Reports'
import Roles from './Roles'
import Pharmaceuticals from './Pharmaceuticals'
import Companies from './Companies'
import Appointments from './Appointments'
import Products from './Products'
import Invoices from './Invoices'
import Refunds from './Refunds'
import CollectionsSuperAdmin from './CollectionsSuperAdmin'
import Collections from './Collections'
import CollectionsDr from './CollectionsDr'
import CreditAdvanceCollections from './CreditAdvanceCollections'
import MedicineCollections from './MedicineCollections'
import TimeSlip from './TimeSlip'
import DataMigrations from './DataMigrations'
import ConsentForms from './ConsentForms/Listing'
import ConsentTemplates from './ConsentForms/Templates'
import UserGuide from './UserGuide'
import UpdatePatient from './Patients/Update'
import TreatmentRecordsModal from './Patients/TreatmentRecords'
import ViewPatient from './Patients/View'
import ReleaseNotes from './ReleaseNotes'
import ReferralForms from './ReferralForms'
import PatientBirthdays from './Patients/Birthdays'
import PanelListing from './Panels/Listing'
import PanelCollections from './Panels/Collections'

import Statistics from './components/Statistics'
import SearchForm from './components/SearchForm'
import ReactivateSubscription from './components/ReactivateSubscription'
import SubscriptionForm from './components/SubscriptionForm'

import WithDashboard from './actions'
import WithPaypal from './actions/Paypal'
import WithCompanies from './actions/Companies'
import WithPatients from './Patients/actions'
import WithMedicalRecords from './Patients/actions/MedicalRecords'
import WithCreditAdvance from './Patients/actions/CreditAdvance'
import WithPanels from 'actions/dictionary/panels'
import { getProfile } from 'actions/profile'
import WithOutstandingInvoices from './actions/OutstandingInvoices'
import firebase from '../../firebase'

const TestMobileTablet = () => window.innerWidth <= 800

const TabsConfig = {
  activeTab: 0,
  showMore: true,
  transform: true,
  showInkBar: true,
  items: [
    { title: 'General' },
    { title: 'Statistics' }
  ],
  selectedTabKey: 0,
  transformWidth: 400,
}

class Dashboard extends Component {
  state = {
    showProfileModal : false,
    user: {},
    searchPatientName: '',
    currentTab: 0
  }

  componentDidMount = () => {
    this.props.getProfile()
    this.props.getPanels()
  }

  componentDidUpdate = async pp => {
    if( this.props.data.ajaxReducer.ajaxCallProgress !== pp.data.ajaxReducer.ajaxCallProgress ) {
      if( this.props.data.ajaxReducer.ajaxCallProgress === 0 ) {
        if( !TestMobileTablet() ) {
          try {
            const messaging = firebase.messaging()
            let uid = this.props.data.profileReducer.profile.current_user.id
            await Notification.requestPermission()
              .then( () => messaging.getToken() )
              .then( token => {
                let tmpRef = window.location.host.indexOf( 'app.primeview.site' ) === -1 
                  ? firebase.database().ref( `stagingUsers/${ uid }` ) 
                  : firebase.database().ref( `productionUsers/${ uid }` ) 
                tmpRef.once( 'value', snapshot => {
                  if( snapshot.exists() ){
                    let tmp = snapshot.val()
                    tmp.token = token
                    tmpRef.set( tmp, () => {} )
                  }
                  else{
                    let tmp = { token: token }
                    tmpRef.set( tmp, () => {} )
                  }
                })
              })
          } catch( err ) {
            
          }
        }
        if( this.props.data.profileReducer.profile.companies.length > 0 ) {
          Promise.all([
            this.props.onChangeDashboardHOC( 'selectedCompanyId', this.props.data.profileReducer.profile.companies[ 0 ].id ),
            this.props.getDashboardInfoToday( this.props.data.profileReducer.profile.companies[ 0 ].id ),
            this.props.getDashboardOccupiedSize( this.props.data.profileReducer.profile.companies[ 0 ].id ),
            this.props.getCompanyOutstandingInvoices( this.props.data.profileReducer.profile.companies[ 0 ].id )
          ])
        }
        if( this.props.data.profileReducer.profile.current_user.role.name !== 'Super Admin' ){
          this.checkSubscription()
        }
        this.setState({ user: this.props.data.profileReducer.profile.current_user })
      }
    }
    if( pp.location.pathname !== this.props.location.pathname && this.props.location.pathname === '/dashboard' ){
      Promise.all([
        this.props.onChangeDashboardHOC( 'selectedCompanyId', this.props.data.profileReducer.profile.companies[ 0 ].id ),
        this.props.getDashboardInfoToday( this.props.data.profileReducer.profile.companies[ 0 ].id ),
        this.props.getDashboardOccupiedSize( this.props.data.profileReducer.profile.companies[ 0 ].id ),
        this.props.getCompanyOutstandingInvoices( this.props.data.profileReducer.profile.companies[ 0 ].id )
      ])
    }
  }

  checkSubscription = async() => {
    // await this.props.getPaypalToken()
    if( 
      this.props.data.profileReducer.profile.companies.length > 0 && (
        _.filter( this.props.data.profileReducer.profile.companies.length, { payment_status_id: 0 } ).length
        !== this.props.data.profileReducer.profile.companies.length
      )
    ){
      // let tmpSubscribingCompanies = []
      let tmpNonSubscribingCompanies = []
      let outstandingSubscriptions = []
      Promise.all(
        _.filter( this.props.data.profileReducer.profile.companies, item => item.payment_status_id !== 0 ).map( async( company ) => {
          await this.props.getCompanySubscriptionPayments( company.id )
          let tmp = _.cloneDeep( company )
          tmp.company_payment_histories = this.props.selectedCompanySubscriptionPayments
          return tmp 
        })
      ).then( async( companyList ) => {
        companyList.map( async( currentCompany ) => {
          if( currentCompany.company_payment_histories.length === 0 || _.isEmpty( currentCompany.paypal_subscription_id ) ){
            tmpNonSubscribingCompanies.push( currentCompany )
          } else {
            if( currentCompany.paypal_subscription_id.indexOf( '-SUSPENDED' ) > -1 ){
              outstandingSubscriptions.push( currentCompany )
            } else {
              if( _.isEmpty( this.props.selectedPaypalPlan ) || currentCompany.paypal_subscription_id !== this.props.selectedPaypalPlan.id ){
                await this.props.getSelectedPaypalPlan( currentCompany.paypal_subscription_id )
              }
              if( 
                this.isSubscriptionExpired( 
                  currentCompany.company_payment_histories, 
                  this.props.selectedPaypalPlan.billing_cycles[ 0 ].frequency.interval_unit.toLowerCase(),
                  this.props.selectedPaypalPlan.billing_cycles[ 0 ].frequency.interval_count
                )
              ){
                outstandingSubscriptions.push({ 
                  ...currentCompany,
                  paypal_plan: this.props.selectedPaypalPlan,
                  cycle: 1
                })
              }
            }
          }

          if( _.filter( outstandingSubscriptions, item => item.paypal_subscription_id.indexOf( '-SUSPENDED' ) > -1 ).length > 0 ){
            this.props.onChangePaypalHOC( 'outstandingPaypalSubscriptions', outstandingSubscriptions )
          } else {
            await this.props.getPaypalPlans()
            Promise.all( 
              this.props.paypalPlans.map( async( item ) => {
                await this.props.getSelectedPaypalPlan( item.id )
                return this.props.selectedPaypalPlan
              }) 
            ).then( detailedPlanList => {
              this.props.onChangePaypalHOC( 'paypalPlans', detailedPlanList, tmpNonSubscribingCompanies.length > 0 )
              tmpNonSubscribingCompanies.length > 0 
                ? this.props.onChangePaypalHOC( 'nonSubscribingCompanies', tmpNonSubscribingCompanies )
                : this.props.onChangePaypalHOC( 'outstandingPaypalSubscriptions', outstandingSubscriptions )
            }) 
          }
        })
      })

      // this.props.data.profileReducer.profile.companies.map( company => {
      //   if( !_.isEmpty( company.paypal_subscription_id ) || company.payment_status_id === 0 ){
      //     tmpSubscribingCompanies.push( company )
      //   } else {
      //     tmpNonSubscribingCompanies.push( company )
      //   }
      // })

      // if ( tmpSubscribingCompanies.length > 0 && tmpNonSubscribingCompanies.length < 1 ){
      //   Promise.all( 
      //     tmpSubscribingCompanies.map( async( company ) => {
      //       await this.props.getSelectedPaypalSubscription( company.paypal_subscription_id )
      //       let tmp = _.cloneDeep( this.props.selectedPaypalSubscription )
      //       tmp.company_id = company.id
      //       tmp.company_name = company.name
      //       tmp.payment_status_id = company.payment_status_id
      //       return tmp
      //     })
      //   ).then( async( subscriptionList ) => {
      //     _.filter( subscriptionList, item => item.payment_status_id !== 0  ).map( subscription => {
      //       if( subscription.status !== 'ACTIVE' && subscription.status !== 'SUSPENDED' ){
      //         tmpNonSubscribingCompanies.push( _.find( this.props.data.profileReducer.profile.companies, { id: subscription.company_id } ) )
      //       } else if( subscription.status === 'SUSPENDED' ){
      //         outstandingSubscriptions.push( subscription )
      //       }
      //     })
      //     if( tmpNonSubscribingCompanies.length < 1 ){
      //       this.props.onChangePaypalHOC( 'outstandingPaypalSubscriptions', outstandingSubscriptions )
      //     } else {
      //       await this.props.getPaypalPlans()
      //       Promise.all( this.props.paypalPlans.map( async( plan ) => {
      //           await this.props.getSelectedPaypalPlan( plan.id )
      //           return this.props.selectedPaypalPlan
      //         }) 
      //       ).then( detailedPlanList => {
      //         this.props.onChangePaypalHOC( 'paypalPlans', detailedPlanList )
      //         this.props.onChangePaypalHOC( 'nonSubscribingCompanies', tmpNonSubscribingCompanies )
      //       })
      //     }
      //   })
      // } else if( tmpNonSubscribingCompanies.length > 0 ){
      //   await this.props.getPaypalPlans()
      //   Promise.all( this.props.paypalPlans.map( async( plan ) => {
      //       await this.props.getSelectedPaypalPlan( plan.id )
      //       return this.props.selectedPaypalPlan
      //     }) 
      //   ).then( detailedPlanList => {
      //     this.props.onChangePaypalHOC( 'paypalPlans', detailedPlanList )
      //     this.props.onChangePaypalHOC( 'nonSubscribingCompanies', tmpNonSubscribingCompanies )
      //   })
      // } 
    }
    return true
  }

  isSubscriptionExpired = ( data, timeUnit, subscriptionTimeUnit ) => {
    let nearestPayment = _.cloneDeep( data[ 0 ] )
    let diff = Moment().diff( Moment( data[ 0 ].created_at ), timeUnit, true )
    data.map( item => {
      if( item !== nearestPayment && Moment().diff( Moment( item.created_at ), timeUnit, true ) < diff ){
        nearestPayment = item
        diff = Moment().diff( Moment( item.created_at ), timeUnit, true )
      }
    })
    return diff > ( subscriptionTimeUnit * nearestPayment.cycle )
  }

  onClickToggleProfileModal = () => this.setState({ showProfileModal: !this.state.showProfileModal })

  render = () => {
    return (
      <MainFrame>
        {
          (
            this.props.data.profileReducer.profile.current_user.role_id === 1 || 
            ( this.props.outstandingPaypalSubscriptions.length < 1 && this.props.nonSubscribingCompanies.length < 1 )
          ) && (
            <>
              {
                !_.isEmpty( this.state.user ) && <TemplateHeader
                  onClickToggleProfileModal= {() => this.onClickToggleProfileModal()}
                  history={ this.props.history }
                  user={ this.state.user } />
              }
              <div className="app-main">
                {
                  !_.isEmpty( this.state.user ) && <TemplateSidemenu />
                }
                <div className="app-main__outer">
                  <div className="app-main__inner">
                    {
                      window.location.hash === '#/dashboard' && <>
                        {/* <PageTitle
                          heading="Dashboard"
                          subheading="Listings of system overview."
                          icon="pe-7s-note2 icon-gradient bg-happy-itmeo" /> */}
                        <div>
                          <Tabs
                            tabsWrapperClass="body-tabs"
                            { ...TabsConfig }
                            selectedTabKey={ this.state.currentTab }
                            onChange={ val => this.setState({ currentTab: val }) } />
                          {
                            this.state.currentTab === 0 && (
                              <>
                                <Row>
                                  <Col md={ 12 }>
                                    <CardDeck>
                                      <SearchForm { ...this.props } />
                                      <Card 
                                        className="main-card mb-3 card-shadow-primary card-btm-border border-primary"
                                        style={{ maxWidth: "35%" }} >
                                        <CardBody className="d-flex flex-column justify-content-center" >
                                          <Label
                                            className='w-100 mb-0'
                                            style={{
                                              fontSize: "50px",
                                              fontWeight: "500" }}>
                                            { this.props.dashboardInfoToday.total_appointments }</Label>
                                          <Label
                                            className='w-100'
                                            style={{
                                              color: "#495057",
                                              fontSize: "1rem",
                                              opacity: ".5" }}>
                                            appointments today</Label>
                                        </CardBody>
                                      </Card>
                                      <Card 
                                        className="main-card mb-3 card-shadow-primary card-btm-border border-primary"
                                        style={{ maxWidth: "35%" }} >
                                        <CardBody className="d-flex flex-column justify-content-center" >
                                          <Label
                                            className='w-100 mb-0'
                                            style={{
                                              fontSize: "50px",
                                              fontWeight: "500" }}>
                                            { 
                                              this.props.dashboardOccupiedSize.total_size > 0
                                                ? parseFloat( this.props.dashboardOccupiedSize.total_size/1000000 ).toFixed( 2 )
                                                : 0
                                            }
                                          </Label>
                                          <Label
                                            className='w-100'
                                            style={{
                                              color: "#495057",
                                              fontSize: "1rem",
                                              opacity: ".5" }}>
                                            MB of file size occupied</Label>
                                        </CardBody>
                                      </Card>
                                    </CardDeck>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={ 12 } >
                                    <Card className="main-card mb-3" >
                                      <CardHeader>{ `Appointments today ( ${ Moment().format( 'DD MMM YYYY' ) } )` }</CardHeader>
                                      <CardBody>
                                        <ReactTable
                                          showPagination={ true }
                                          defaultPageSize={ 10 }
                                          data={ this.props.dashboardInfoToday.appointments }
                                          columns={[
                                            {
                                              Header: 'Patient',
                                              accessor: 'patient_name'
                                            },
                                            {
                                              Header: 'Doctor',
                                              accessor: 'user_name'
                                            },
                                            {
                                              Header: 'Time',
                                              Cell: row => `${ Moment( row.original.start_datetime ).format( 'h:mm a' ) } - ${ Moment( row.original.end_datetime ).format( 'h:mm a' ) }`
                                            },
                                            {
                                              Header: 'Details',
                                              accessor: 'details'
                                            },
                                            {
                                              Header: 'Status',
                                              accessor: 'status'
                                            },
                                            {
                                              Header: 'Actions',
                                              Cell: ( row ) => {
                                                return (
                                                  <>
                                                    <Button
                                                      className="mr-2 btn-icon btn-icon-only"
                                                      color="primary"
                                                      onClick={() => {
                                                        this.props.getSelectedPatient( row.original.patient_id, 'edit' )
                                                      }}>
                                                      <i className="pe-7s-pen btn-icon-wrapper"> </i>
                                                    </Button>
                                                    <Button
                                                      className="btn-icon btn-icon-only"
                                                      color="success"
                                                      onClick={() => {
                                                        this.props.getSelectedPatient( row.original.patient_id, 'view' )
                                                        this.props.getMedicalRecords( row.original.patient_id )
                                                      }}>
                                                      <i className="pe-7s-eyedropper btn-icon-wrapper"> </i>
                                                    </Button>
                                                  </>
                                                )
                                              }
                                            }
                                          ]} />
                                      </CardBody>
                                    </Card>
                                    <UpdatePatient { ...this.props } />
                                    <TreatmentRecordsModal { ...this.props } />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={ 12 } >
                                    <Card className="main-card mb-3">
                                      <CardHeader>Outstanding invoices</CardHeader>
                                      <CardBody>
                                        {
                                          !this.props.onLoadOutstandingInvoices
                                            ? (
                                              <ReactTable
                                                showPagination={ true }
                                                defaultPageSize={ 10 }
                                                data={ _.filter( this.props.selectedCompanyOutstandingInvoices, item => parseFloat( item.formated_outstanding_payment ) > 0 ).length > 0 
                                                  ? _.filter( this.props.selectedCompanyOutstandingInvoices, item => parseFloat( item.formated_outstanding_payment ) > 0 )
                                                  : [] }
                                                columns={[
                                                  {
                                                    Header: 'Created at',
                                                    Cell: row => Moment( row.original.created_at ).format( 'DD MMM YYYY' )
                                                  },
                                                  {
                                                    Header: 'Patient name',
                                                    accessor: 'patient_name'
                                                  },
                                                  {
                                                    Header: 'Invoice number',
                                                    accessor: 'invoice_number'
                                                  },
                                                  {
                                                    Header: 'Total amount',
                                                    Cell: row => parseFloat( row.original.total_amount ).toFixed( 2 )
                                                  },
                                                  {
                                                    Header: 'Outstanding amount',
                                                    Cell: row => parseFloat( row.original.formated_outstanding_payment ).toFixed( 2 )
                                                  },
                                                ]} />
                                            ) : (
                                              <h4>Loading the outstanding invoices data, this might take a while...</h4>
                                            )
                                        }
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                              </>
                            )
                          }
                          {
                            this.state.currentTab === 1 && (
                              <Statistics { ...this.props } />
                            )
                          }
                        </div>
                      </>
                    }
                    <Route path={ '/dashboard/users' } component={ Users } />
                    <Route path={ '/dashboard/treatments' } component={ Treatments } />
                    <Route exact path={ '/dashboard/patients' } component={ Patients } />
                    <Route exact path={ '/dashboard/treated-patients' } component={ TreatedPatients } />
                    <Route exact path={ '/dashboard/patients/:patient_id' } component={ Patients } />
                    <Route exact path={ '/dashboard/patients/:patient_id/medical_record/:medical_record_id' } component={ Patients } />
                    <Route exact path={ '/dashboard/patients/:patient_id/medical_record/:medical_record_id/invoices/:invoice_id' } component={ Patients } />
                    <Route exact path={ '/dashboard/patients/:patient_id/medical_record/:medical_record_id/invoices/:invoice_id/payments/:payment_id' } component={ Patients } />
                    <Route exact path={ '/dashboard/patients-birthday' } component={ PatientBirthdays } />
                    <Route exact path={ '/dashboard/panels' } component={ PanelListing } />
                    <Route exact path={ '/dashboard/panel-collections' } component={ PanelCollections } />
                    <Route path={ '/dashboard/medical-certs' } component={ MedicalCerts } />
                    <Route path={ '/dashboard/medical-certs-reports' } component={ MedicalCertsReports } />
                    <Route path={ '/dashboard/roles' } component={ Roles } />
                    <Route path={ '/dashboard/pharmaceuticals' } component={ Pharmaceuticals } />
                    <Route path={ '/dashboard/companies' } component={ Companies } />
                    <Route path={ '/dashboard/appointments' } component={ Appointments } /> 
                    <Route path={ '/dashboard/products' } component={ Products } />
                    <Route path={ '/dashboard/invoices' } component={ Invoices } />
                    <Route path={ '/dashboard/refunds' } component={ Refunds } />
                    <Route path={ '/dashboard/collections-super-admin' } component={ CollectionsSuperAdmin } />
                    <Route path={ '/dashboard/collections' } component={ Collections } />
                    <Route path={ '/dashboard/collections-dr' } component={ CollectionsDr } />
                    <Route path={ '/dashboard/credit-advance-collections' } component={ CreditAdvanceCollections } />
                    <Route path={ '/dashboard/medicine-collections' } component={ MedicineCollections } />
                    <Route path={ '/dashboard/time-slip' } component={ TimeSlip } />
                    <Route path={ '/dashboard/data-migrations' } component={ DataMigrations } />
                    <Route path={ '/dashboard/consent-forms' } component={ ConsentForms } />
                    <Route path={ '/dashboard/consent-templates' } component={ ConsentTemplates } />
                    <Route path={ '/dashboard/referral-forms' } component={ ReferralForms } />
                    <Route path={ '/dashboard/release-notes' } component={ ReleaseNotes } />
                    <Route path={ '/dashboard/user-guide' } component={ UserGuide } />
                  </div>
                </div>
                <ToastContainer position={ toast.POSITION.BOTTOM_RIGHT } style={{ zIndex: 9999 }} />
              </div>
            </>
          )
        }
        <ReactivateSubscription
          history={ this.props.history }
          paypalError={ this.props.paypalError }
          showReactivateSubscriptionModal={ this.props.showReactivateSubscriptionModal }
          outstandingPaypalSubscriptions={ this.props.outstandingPaypalSubscriptions }
          selectedPaypalSubscription={ this.props.selectedPaypalSubscription }
          onChangePaypalHOC={ this.props.onChangePaypalHOC }
          activatePaypalSubscription={ this.props.activatePaypalSubscription }
          createCompanyReactivationPayment={ this.props.createCompanyReactivationPayment }
          paypalPlans={ this.props.paypalPlans }
          updateCompanySubscription={ this.props.updateCompanySubscription }
          createCompanySubscriptionPayment={ this.props.createCompanySubscriptionPayment } />
        <SubscriptionForm
          history={ this.props.history }
          showSubscriptionModal={ this.props.showSubscriptionModal }
          onChangePaypalHOC={ this.props.onChangePaypalHOC }
          paypalPlans={ this.props.paypalPlans }
          selectedPaypalPlan={ this.props.selectedPaypalPlan }
          nonSubscribingCompanies={ this.props.nonSubscribingCompanies }
          selectedNonSubscribingCompany={ this.props.selectedNonSubscribingCompany }
          paypalError={ this.props.paypalError }
          updateCompanySubscription={ this.props.updateCompanySubscription }
          createCompanySubscriptionPayment={ this.props.createCompanySubscriptionPayment } />
        <ViewPatient { ...this.props } />
        { ( 
          this.props.onLoadCompaniesHOC || 
          this.props.onLoadPaypalHOC || 
          this.props.onLoadDashboardHOC || 
          this.props.data.ajaxReducer.ajaxCallProgress > 0 
        ) && <LoadingOverlay /> }
      </MainFrame>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  getProfile
})( 
  compose ( 
    WithDashboard,
    WithPatients,
    WithMedicalRecords,
    WithCreditAdvance,
    WithPaypal,
    WithCompanies,
    WithPanels,
    WithOutstandingInvoices
  )( Dashboard )
)