import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      products: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getProducts = () => Get(
      `/products`,
      this.getProductsSuccess,
      this.getProductsError,
      this.load
    )
    getProductsSuccess = payload => this.setState({ products: payload })
    getProductsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadProducts={ this.state.loading }
          products={ this.state.products }
          getProducts={ this.getProducts } />
      )
    }
  }
  return WithHOC
}

export default HOC