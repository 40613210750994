import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'
import ReactHTMLParser from 'react-html-parser'
import Moment from 'moment'

import { Get } from 'utils/axios'
import getDomainURL from 'utils/api'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      mcReports: [],
      searchParams: {
        company_id: 0,
        branch_id: 0,
        start_date: new Date(),
        end_date: new Date()
      }
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( ({ closeToast }) => ReactHTMLParser( error.message ) )

    onChangeMcReportsHOC = ( key, val ) => this.setState({ [ key ]: val })

    getMcReports = ( company_id, branch_id, start_date, end_date ) => Get(
      `/medical_certs/mc_report?company_id=${ company_id }&branch_id=${ branch_id }&start_date=${ start_date }&end_date=${ end_date }`,
      this.getMcReportsSuccess,
      this.getMcReportsError,
      this.load
    )
    getMcReportsSuccess = payload => this.setState({ mcReports: payload })
    getMcReportsError = error => this.requestError( error )

    downloadMcReportsPDF = ( company_id, branch_id, start_date, end_date ) => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/medical_certs/download_mc_report?company_id=${ company_id }&branch_id=${ branch_id }&start_date=${ start_date }&end_date=${ end_date }`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( blobby => {
        this.load( false )
        return FileSaver.saveAs( blobby, `${ Moment().format( 'YYYYMMDDHH:mm' ) }_mc_reports.pdf` )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save medical certification reports. Please try again.' )
      })
    }

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadMcReportsHOC = { this.state.loading }
          mcReports = { this.state.mcReports }
          searchParams={ this.state.searchParams }
          onChangeMcReportsHOC={ this.onChangeMcReportsHOC }
          getMcReports={ this.getMcReports }
          downloadMcReportsPDF={ this.downloadMcReportsPDF } />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC