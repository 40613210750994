import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Collapse,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'

import SmileDatepicker from 'components/Datepicker'

const SearchForm = ({
  searchParams,
  onChangeCollectionsHOC,
  getCollections,
  data,
  exportReport,
  getInvoices,
  selectedBranchID,
  selectedCompany,
  getSelectedCompany
}) => {
  const [ isCardOpen, updateIsCardOpen ] = useState( false )
  return (
    <Card className="main-card mb-3">
      <CardHeader 
        id='search-bar-toggle'
        className='d-flex justify-content-between' 
        onClick={ () => updateIsCardOpen( !isCardOpen ) } >
        <Label className='m-0' >Search</Label>
        <i 
          className={ isCardOpen ? 'pe-7s-angle-up' : 'pe-7s-angle-down' }
          style={{ fontSize: "3rem", color: "#000" }} />
        <UncontrolledTooltip target='search-bar-toggle' placement="top-end">
          { isCardOpen ? 'Collapse' : 'Expand' }</UncontrolledTooltip>
      </CardHeader>
      <Collapse isOpen={ isCardOpen } >
        <CardBody>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <Label>{ 'Select a company' }</Label>
              <Input
                type="select"
                value={ searchParams[ 0 ][ 'value' ] }
                onChange={ e => {
                  getSelectedCompany( e.target.value )
                  let tmp = _.cloneDeep( searchParams )
                  tmp[ 0 ][ 'value' ] = e.target.value
                  onChangeCollectionsHOC( 'searchParams', tmp )
                }}>
                <option></option>
                {
                  data.profileReducer.profile.companies.map( item => {
                    return <option key={ item.id } value={ item.id }>{ item.name }</option>
                  })
                }
              </Input>
            </FormGroup>
            {
              ( data.profileReducer.profile.current_user.role.name === 'Super Admin' ) && (
                <FormGroup>
                  <Label>
                    Select branch
                  </Label>
                  <Input
                    type="select"
                    value={ selectedBranchID }
                    onChange={ e => {
                      onChangeCollectionsHOC( 'selectedBranchID', e.target.value )
                    }}>
                    <option></option>
                    {
                      selectedCompany.branches.map( item => {
                        return <option key={ item.id } value={ item.id }>{ item.name }</option>
                      })
                    }
                  </Input>
                </FormGroup>
              )
            }
            {
              ( data.profileReducer.profile.current_user.role.name === 'Admin' || data.profileReducer.profile.current_user.role.name === 'Doctor' ) && (
                <FormGroup>
                  <Label>Select branch</Label>
                  <Input
                    type="select"
                    value={ selectedBranchID }
                    onChange={ e => {
                      onChangeCollectionsHOC( 'selectedBranchID', e.target.value )
                    }}>
                    <option></option>
                    {
                      data.profileReducer.profile.branches.map( item => {
                        return <option key={ item.id } value={ item.id }>{ item.name }</option>
                      })
                    }
                  </Input>
                </FormGroup>
              )
            }
            <FormGroup>
              <Label>Start date</Label>
              <SmileDatepicker
                showTimeSelect={ false }
                selectedDate={ searchParams[ 1 ][ 'value' ] }
                onChange={ val => {
                  let tmp = _.cloneDeep( searchParams )
                  tmp[ 1 ][ 'value' ] = val
                  onChangeCollectionsHOC( 'searchParams', tmp )
                }}
                dateFormat="MMMM d, yyyy"
                disabled={ false } 
              />
            </FormGroup>
            <FormGroup>
              <Label>End date</Label>
              <SmileDatepicker
                showTimeSelect={ false }
                selectedDate={ searchParams[ 2 ][ 'value' ] }
                onChange={ val => {
                  let tmp = _.cloneDeep( searchParams )
                  tmp[ 2 ][ 'value' ] = val
                  onChangeCollectionsHOC( 'searchParams', tmp )
                }}
                dateFormat="MMMM d, yyyy"
                disabled={ false } 
              />
            </FormGroup>
            <div className="d-flex">
              <Button 
                color="primary"
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  getCollections( searchParams[ 0 ][ 'value' ], Moment( searchParams[ 1 ][ 'value' ] ).format( 'YYYY-MM-DD' ), Moment( searchParams[ 2 ][ 'value' ] ).format( 'YYYY-MM-DD' ) )
                }}>{ 'Search' }</Button>
              <Button 
                color="danger"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  let tmp = _.cloneDeep( searchParams )
                  tmp[ 0 ][ 'value' ] = 0
                  tmp[ 1 ][ 'value' ] = ''
                  tmp[ 2 ][ 'value' ] = ''
                  getCollections( tmp[ 0 ][ 'value' ], Moment( tmp[ 1 ][ 'value' ] ).format( 'YYYY-MM-DD' ), Moment( tmp[ 2 ][ 'value' ] ).format( 'YYYY-MM-DD' ) )
                }}>{ 'Reset' }</Button>
              <Button
                color="primary"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  exportReport({
                    company_id: searchParams[ 0 ][ 'value' ],
                    start_date: Moment( searchParams[ 1 ][ 'value' ] ).format( 'YYYY-MM-DD' ),
                    end_date: Moment( searchParams[ 2 ][ 'value' ] ).format( 'YYYY-MM-DD' )
                  })
                }}>
                Export report
              </Button>
              <Button
                color="primary"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  let tmp = _.cloneDeep( searchParams )
                  getInvoices( tmp[ 0 ][ 'value' ], Moment( tmp[ 1 ][ 'value' ] ).format( 'YYYY-MM-DD' ), Moment( tmp[ 2 ][ 'value' ] ).format( 'YYYY-MM-DD' ) )
                }}>
                Show invoices
              </Button>
            </div>
          </Form>
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default SearchForm