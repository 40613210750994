import React, { Component } from 'react'
import { 
  Row, 
  Col,
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'
import { compose } from 'redux'
import Moment from 'moment'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SearchForm from './SearchForm'
import InvoiceListings from './Invoices'

import WithCollections from './actions'
import WithCompanies from 'actions/dictionary/companies'
import WithInvoices from './actions/Invoices'

import CurrencySettings from 'utils/currencies'

class CreditAdvanceCollections extends Component {
  render = () => {
    return (
      <>
        <PageTitle
          heading="Credit Advance Collections"
          subheading="Listings of all the credit advance collections based on selected company."
          icon="pe-7s-mail-open-file icon-gradient bg-happy-itmeo"
          buttons={[]} />
        <SearchForm { ...this.props } />
        <Card className="main-card mb-3">
          <CardHeader>
            { `Search results from ${ Moment( this.props.searchParams[ 1 ][ 'value' ] ).format( 'DD MMM YYYY' ) } to ${ Moment( this.props.searchParams[ 2 ][ 'value' ] ).format( 'DD MMM YYYY' ) }` }
          </CardHeader>
          <CardBody>
            <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
              <div className="widget-chart-content">
                <div className="widget-subheading d-flex">
                  Total credit advance collected for selected date
                </div>
                <div className="widget-numbers">
                  { `${ CurrencySettings() } ${ this.props.collections.total }` }
                </div>
              </div>
            </div>
            <ReactTable
              data={
                // [
                //   {
                //     salutation: 'Dr',
                //     name: 'Kenneth Wong',
                //     total_collected_amount: 500,
                //     invoices: [
                //       {
                //         payments: [
                //           {
                //             change: 0.0,
                //             current_cost: 100.0,
                //             current_paid_amount: 100.0,
                //             pay_with: 'Credit Card'
                //           },
                //           {
                //             change: 0.0,
                //             current_cost: 100.0,
                //             current_paid_amount: 100.0,
                //             pay_with: 'Credit Card'
                //           }
                //         ]
                //       }
                //     ]
                //   },
                //   {
                //     salutation: 'Dr',
                //     name: 'Loo Chien Won',
                //     total_collected_amount: 500
                //   }
                // ]
                this.props.collections.by_users
              }
              columns={[
                {
                  Header: 'Patient name',
                  accessor: 'patient_name'
                },
                {
                  Header: 'Payments',
                  Cell: ( row ) => {
                    return (
                      <div>
                        {
                          Object.keys( row.original.payments ).map( key => {
                            return (
                              <>
                                <p style={{ marginBottom: 0 }}>{ `${ key }: ${ row.original.payments[ key ] }` }</p>
                              </>
                            )
                          })
                        }
                      </div>
                    )
                  }
                },
                {
                  Header: 'Total collected amount',
                  accessor: 'total'
                }
              ]}
              defaultPageSize={ 10 } />
          </CardBody>
        </Card>
        <InvoiceListings { ...this.props } />
        { ( this.props.onLoadCollections || this.props.onLoadCompanies || this.props.onLoadInvoices ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithCollections,
  WithCompanies,
  WithInvoices
)( CreditAdvanceCollections )