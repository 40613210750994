import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      offDays: [],
      offDates: [],
      offDatesObjects: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getOffDays = ( doctor_id ) => Get(
      `/off_days?user_ids=${ doctor_id }`,
      this.getOffDaysSuccess,
      this.getOffDaysError,
      this.load
    )
    getOffDaysSuccess = payload => {
      let tmpOffDates = []
      let tmpOffDatesObjects = []
      if( payload && payload.length > 0 ) {
        payload.map( item => {
          tmpOffDates.push( new Date( item.date ) )
          tmpOffDatesObjects.push({
            id: `OffDays-${ item.id }`,
            title: `${ item.user.salutation } ${ item.user.name } - ${ 'Off for this period' }`,
            start_datetime: new Date( `${ item.start_datetime }` ),
            end_datetime: new Date( `${ item.end_datetime }` ),
            status: 'Off day',
            resourceId: item.user_id
          })
        })
      }
      this.setState({ offDays: payload, offDates: tmpOffDates, offDatesObjects: tmpOffDatesObjects })
    }
    getOffDaysError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          offDays={ this.state.offDays }
          offDates={ this.state.offDates }
          offDatesObjects={ this.state.offDatesObjects }
          onLoadOffDays={ this.state.loading }
          getOffDays={ this.getOffDays } />
      )
    }
  }
  return WithHOC
}

export default HOC