import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'
import Moment from 'moment'

import PromptModal from 'components/Indicator/Prompt'

import { Get } from 'utils/axios'
import getDomainURL from 'utils/api'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      collections: {
        by_users: [],
        dr_breakdown: [],
        payment_types: [],
        total: 0
      },
      loading: false,
      searchParams: [
        {
          key: 'company_id',
          value: this.props.data.profileReducer.profile.current_user.company_ids.length > 0 ? this.props.data.profileReducer.profile.current_user.company_ids[0] : 0
        },
        {
          key: 'start_date',
          value: new Date()
        },
        {
          key: 'end_date',
          value: new Date()
        },
        {
          key: 'dr_id',
          value: []
        }
      ],
      selectedBranchID: this.props.data.profileReducer.profile.current_user.branch_ids.length > 0 ? this.props.data.profileReducer.profile.current_user.branch_ids[0] : ''
    }

    componentDidMount = () => {
      if( this.props.data.profileReducer.profile.current_user.role.name === 'Doctor' ) {
        let tmp = _.cloneDeep( this.state.searchParams )
        tmp[ 3 ][ 'value' ] = [ this.props.data.profileReducer.profile.current_user.id ]
        this.setState({
          searchParams: tmp
        }, () => {
          this.getCollectionsByDr( 
            this.state.searchParams[0].value,  
            this.state.searchParams[1].value,
            this.state.searchParams[2].value,
            this.state.searchParams[3].value
          )
        })
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeCollectionsHOC = ( key, val ) => this.setState({ [key]: val })
    
    getCollections = ( company_id, start_date, end_date, user_ids ) => {
      let tmp = ''
      // tmp = `/collections?company_id=${ company_id }&start_date=${ start_date }&end_date=${ end_date }${ this.state.selectedBranchID !== '' ? `&branch_id=${ this.state.selectedBranchID }` : '' }${ user_ids.length > 0 ? `&user_ids=${ user_ids }` : '' }&type=1`
      tmp = `/collections?q[company_id_eq]=${ company_id }${ this.state.selectedBranchID !== '' ? `&q[branch_id_eq]=${ this.state.selectedBranchID }` : '' }${ user_ids.length > 0 ? `&q[user_id_eq]=${ user_ids[0] }` : '' }&q[created_at_gteq]=${ start_date }&q[created_at_lteq]=${ end_date }`
      Get(
        tmp,
        this.getCollectionsSuccess,
        this.getCollectionsError,
        this.load
      )
    }
    getCollectionsSuccess = payload => {
      this.setState({ collections: payload })
    }
    getCollectionsError = error => this.requestError( 'Failed to get collections.' )

    getCollectionsByDr = ( company_id, start_date, end_date, user_ids ) => Get(
      `/collections/by_doctor?user_ids=${ user_ids }&company_id=${ company_id }&start_date=${ start_date }&end_date=${ end_date }`,
      this.getCollectionsByDrSuccess,
      this.getCollectionsByDrError,
      this.load
    )
    getCollectionsByDrSuccess = payload => this.setState({ collections: payload })
    getCollectionsByDrError = error => this.requestError( 'Failed to get collections.' )

    exportReport = data => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/collections/download?company_id=${ data.company_id }&start_date=${ data.start_date }&end_date=${ data.end_date }&user_ids=${ data.user_ids.length > 0 ? JSON.stringify( data.user_ids ) : '' }${ this.state.selectedBranchID !== '' ? `&branch_id=${ this.state.selectedBranchID }` : '' }&type_id=1`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( blobby => {
        this.load( false )
        return FileSaver.saveAs( blobby, `${ Moment().format( 'YYYYMMDDHH:mm' ) }_collections.pdf` )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save receipt. Please try again.' )
      })
    }

    exportLabCostsReport = data => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/collections/net_profit?company_id=${ data.company_id }&start_date=${ data.start_date }&end_date=${ data.end_date }&user_ids=${ data.user_ids.length > 0 ? JSON.stringify( data.user_ids ) : '' }${ this.state.selectedBranchID !== '' ? `&branch_id=${ this.state.selectedBranchID }` : '' }&type_id=1`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( blobby => {
        this.load( false )
        return FileSaver.saveAs( blobby, `${ Moment().format( 'YYYYMMDDHH:mm' ) }_collections.pdf` )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save receipt. Please try again.' )
      })
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          collections={ this.state.collections }
          onLoadCollections={ this.state.loading }
          searchParams={ this.state.searchParams }
          selectedBranchID={ this.state.selectedBranchID }
          onChangeCollectionsHOC={ this.onChangeCollectionsHOC }
          getCollections={ this.getCollections }
          getCollectionsByDr={ this.getCollectionsByDr }
          exportReport={ this.exportReport }
          exportLabCostsReport={ this.exportLabCostsReport } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC