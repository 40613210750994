import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      branches: [],
      loading: false
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getBranches = () => Get(
      `/branches`,
      this.getBranchesSuccess,
      this.getBranchesError,
      this.load
    )
    getBranchesSuccess = payload => this.setState({ branches: payload })
    getBranchesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          getBranches={ this.getBranches }
          onLoadBranches={ this.state.loading }
          branches={ this.state.branches } />
      )
    }
  }
  return WithHOC
}

export default HOC