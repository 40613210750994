import React from 'react'
import { Route } from 'react-router-dom'

import Appointments from './Appointments'

const DataMigrations = props => {
  return (
    <>
      <Route path={ '/dashboard/data-migrations/appointments' } component={ Appointments } />
    </>
  )
}

export default DataMigrations