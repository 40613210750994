import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'
import Moment from 'moment'

import PromptModal from 'components/Indicator/Prompt'

import { Get } from 'utils/axios'
import getDomainURL from 'utils/api'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      collections: {
        by_users: [],
        total: 0
      },
      loading: false,
      searchParams: [
        {
          key: 'company_id',
          value: this.props.data.profileReducer.profile.current_user.company_ids.length > 0 ? this.props.data.profileReducer.profile.current_user.company_ids[0] : 0
        },
        {
          key: 'start_date',
          value: new Date()
        },
        {
          key: 'end_date',
          value: new Date()
        }
      ],
      selectedBranchID: this.props.data.profileReducer.profile.current_user.branch_ids.length > 0 ? this.props.data.profileReducer.profile.current_user.branch_ids[0] : 0
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeCollectionsHOC = ( key, val ) => this.setState({ [key]: val })

    getCollections = ( company_id, start_date, end_date ) => Get(
      `/collections/credit_advance_collection?company_id=${company_id}&start_date=${start_date}&end_date=${end_date}${ this.state.selectedBranchID !== 0 ? `&branch_id=${ this.state.selectedBranchID }` : '' }`,
      this.getCollectionsSuccess,
      this.getCollectionsError,
      this.load
    )
    getCollectionsSuccess = payload => {
      this.setState({ collections: payload })
    }
    getCollectionsError = error => this.requestError( 'Failed to get collections.' )

    exportReport = data => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/collections/download?company_id=${ data.company_id }&start_date=${ data.start_date }&end_date=${ data.end_date }&is_reload_credit_advance=true${ this.state.selectedBranchID !== 0 ? `&branch_id=${ this.state.selectedBranchID }` : '' }`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( blobby => {
        this.load( false )
        return FileSaver.saveAs( blobby, `${ Moment().format( 'YYYYMMDDHH:mm' ) }_collections.pdf` )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save receipt. Please try again.' )
      })
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          collections={ this.state.collections }
          onLoadCollections={ this.state.loading }
          searchParams={ this.state.searchParams }
          selectedBranchID={ this.state.selectedBranchID }
          onChangeCollectionsHOC={ this.onChangeCollectionsHOC }
          getCollections={ this.getCollections }
          exportReport={ this.exportReport } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC