import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { Multiselect } from 'react-widgets'
import _ from 'lodash'
import Moment from 'moment'

import Typeahead from 'components/Typeahead'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

const ListItem = ({ item }) => (
  <span>
    { `${ item.name } - ${ item.nric_no } - ${ item.contact_number }` }
  </span>
)

const Create = ({
  showCreateMedicalCert,
  newMedicalCert,
  createMedicalCert,
  onChangeMedicalCertHOC,
  doctors,
  data,
  getPatients,
  onChangePatientsHOC,
  onLoadPatientsHOC,
  patients,
  searchPatientParam,
  onLoadMedicalCerts
}) => {
  const onChangeMCData = ( key, val ) => {
    let tmp = _.cloneDeep( newMedicalCert )
    tmp[ key ] = val
    return onChangeMedicalCertHOC( 'newMedicalCert', tmp )
  }

  return (
    <Modal
      isOpen={ showCreateMedicalCert }
      size={ 'lg' }
      toggle={() => {
        onChangeMedicalCertHOC( 'showCreateMedicalCert', false )
      }}>
      <ModalHeader toggle={() => onChangeMedicalCertHOC( 'showCreateMedicalCert', false )}>Create medical cert</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>Select company</Label>
            <Input
              type="select"
              value={ newMedicalCert.company_id }
              onChange={ e => {
                onChangeMCData( 'company_id', e.target.value )
              }}>
              <option></option>
              {
                data.profileReducer && data.profileReducer.profile.companies.map( item => {
                  return (
                    <option key={ item.id } value={ item.id }>{ item.name }</option>
                  )
                })
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Select branch</Label>
            <Input
              type="select"
              value={ newMedicalCert.branch_id }
              onChange={ e => {
                onChangeMCData( 'branch_id', e.target.value )
              }}>
              <option></option>
              {
                data.profileReducer && data.profileReducer.profile.branches.map( item => {
                  return (
                    <option key={ item.id } value={ item.id }>{ item.name }</option>
                  )
                })
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Select patient</Label>
            <div className="d-flex mb-2">
              <Input 
                type="text" 
                value={ searchPatientParam }
                className='mr-2'
                onChange={ e => onChangePatientsHOC( 'searchPatientParam', e.target.value ) }
                placeholder="Fill in patient NRIC or name here and click search button" />
              <Button 
                color="primary" 
                onClick={() => {
                  getPatients( searchPatientParam )
                  onChangeMCData( 'patient_id', 0 ) }}>
                Search</Button>
            </div>
            {
              onLoadPatientsHOC 
                ? (
                  <p>Loading patients......</p>
                ) : <>
                  <Multiselect
                    data={ patients }
                    textField={ 'name' }
                    itemComponent={ ListItem }
                    value={ newMedicalCert.patient_id > 0 
                      ? _.find( patients, { id: parseInt( newMedicalCert.patient_id ) } ) 
                        ? [ _.find( patients, { id: parseInt( newMedicalCert.patient_id ) } ) ] 
                        : [ {} ]
                      : [] }
                    onChange={ val => val.length === 1 
                      ? onChangeMCData( 'patient_id', val[ 0 ].id )
                      : onChangeMCData( 'patient_id', 0 )
                    } />
                </>
            }
          </FormGroup>
          <FormGroup>
            <Label>Examine date</Label>
            <input
              type="date"
              className="form-control"
              value={ newMedicalCert.examine_date ? Moment( newMedicalCert.examine_date ).format( 'YYYY-MM-DD' ) : '' }
              onChange={ e => {
                onChangeMCData( 'examine_date', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>Start date</Label>
            <input
              type="date"
              className="form-control"
              value={ newMedicalCert.start_date ? Moment( newMedicalCert.start_date ).format( 'YYYY-MM-DD' ) : '' }
              onChange={ e => {
                onChangeMCData( 'start_date', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>End date</Label>
            <input
              type="date"
              className="form-control"
              value={ newMedicalCert.end_date ? Moment( newMedicalCert.end_date ).format( 'YYYY-MM-DD' ) : '' }
              onChange={ e => {
                onChangeMCData( 'end_date', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>remark</Label>
            <Input
              type="text"
              value={ newMedicalCert.remark }
              onChange={ e => {
                onChangeMCData( 'remark', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>Select doctor</Label>
            <Typeahead
              id={ 'doctors' }
              multiple={ false }
              options={ doctors }
              onSelect={ val => {
                if( val && val.length > 0 ) {
                  onChangeMCData( 'user_id', val[ 0 ].id )
                } else {
                  onChangeMCData( 'user_id', '' )
                }
              }}
              selectedValue={ _.find( doctors, { id: newMedicalCert.user_id }) ? [ _.find( doctors, { id: newMedicalCert.user_id }) ] : [] } />
          </FormGroup>
        </Form>
        { onLoadMedicalCerts && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={() => {
            createMedicalCert( newMedicalCert )
          }}>Submit</Button>
      </ModalFooter>
    </Modal>
  )
}

export default Create