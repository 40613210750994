import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'
import Moment from 'moment'

const InvoiceListings = ({
  showInvoices,
  onChangeInvoicesHOC,
  invoices
}) => {
  return (
    <Modal
      isOpen={ showInvoices }
      size={ 'lg' }
      toggle={() => onChangeInvoicesHOC( 'showInvoices', false )}>
      <ModalHeader toggle={() => onChangeInvoicesHOC( 'showInvoices', false )}>View invoices</ModalHeader>
      <ModalBody>
        {
          invoices.length > 0 
            ? (
              <ReactTable
                data={ invoices }
                columns={[
                  {
                    Header: 'Invoice no.',
                    accessor: 'invoice_number'
                  },
                  {
                    Header: 'Assigned doctor',
                    accessor: 'invoice_user_name'
                  },
                  {
                    Header: 'Created at',
                    Cell: ( row ) => (
                      <span>{ row.original.created_at ? Moment( row.original.created_at ).format( 'DD MMM YYYY HH:mm' ) : '' }</span>
                    )
                  },
                  {
                    Header: 'Action',
                    Cell: ( row ) => (
                      <Button 
                        color="primary"
                        onClick={() => {
                          let link = document.createElement( 'a' )
                          link.href = row.original.document_link
                          link.target = '_blank'
                          link.click()
                        }}>Download</Button>
                    )
                  }
                ]} />
            ) : (
              <p>No invoices are found.</p>
            )
        }
      </ModalBody>
    </Modal>
  )
}

export default InvoiceListings