import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Put, Post, Get } from 'utils/axios'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      selectedCompanySubscriptionPayments: []
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    updateCompanySubscription = data => Put(
      `/companies/${ data.id }/update_payment_detail`,
      data,
      this.updateCompanySubscriptionSuccess,
      this.updateCompanySubscriptionError,
      this.load
    )
    updateCompanySubscriptionSuccess = () =>  this.requestSuccess( 'Subscription is successfully created' )
    updateCompanySubscriptionError = error => this.requestError( error )

    createCompanySubscriptionPayment = data => Post(
      `/companies/${ data.company_id }/company_payment_histories`,
      data,
      this.createCompanySubscriptionPaymentSuccess,
      this.createCompanySubscriptionPaymentError,
      this.load
    )
    createCompanySubscriptionPaymentSuccess = () => this.requestSuccess( 'Subscription payment is done' )
    createCompanySubscriptionPaymentError = error => this.requestError( error )

    getCompanySubscriptionPayments = company_id => Get(
      `/companies/${ company_id }/company_payment_histories`,
      this.getCompanySubscriptionPaymentsSuccess,
      this.getCompanySubscriptionPaymentsError,
      this.load
    )
    getCompanySubscriptionPaymentsSuccess = payload => this.setState({ selectedCompanySubscriptionPayments: payload })
    getCompanySubscriptionPaymentsError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadCompaniesHOC={ this.state.loading }
          updateCompanySubscription={ this.updateCompanySubscription }
          createCompanySubscriptionPayment={ this.createCompanySubscriptionPayment } 
          selectedCompanySubscriptionPayments={ this.state.selectedCompanySubscriptionPayments }
          getCompanySubscriptionPayments={ this.getCompanySubscriptionPayments }/>
      )
    }
  }

  return WithHOC
}

export default HOC