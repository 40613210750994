import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'
import ReactHTMLParser from 'react-html-parser'
import Moment from 'moment'

import { Post, Get, Put } from 'utils/axios'
import getDomainURL from 'utils/api'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      refunds: [],
      newRefund: {},
      selectedRefund: {},
      showCreateRefund: false,
      showUpdateRefund: false,
      searchParams: {
        company_id: this.props.data.profileReducer.profile.companies[ 0 ].id,
        start_date: new Date(),
        end_date: new Date()
      }
    }

    componentDidMount = () => {
      let date = new Date()
      this.setState({
        searchParams: {
          company_id: this.props.data.profileReducer.profile.companies[ 0 ].id,
          start_date: new Date( date.getFullYear(), date.getMonth(), 1 ),
          end_date: new Date()
        }
      }, () => this.getCompanyRefunds())
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( ({ closeToast }) => ReactHTMLParser( error.message ) )

    onChangeRefundsHOC = ( key, val ) => this.setState({ [ key ]: val })

    createRefund = data => Post(
      `/invoices/${ data.invoice_id }/refunds`,
      data,
      this.createRefundSuccess,
      this.createRefundError,
      this.load
    )
    createRefundSuccess = () => {
      this.requestSuccess( 'Refund is successfully created' )
      if( this.state.searchParams.company_id > 0 ){
        this.getCompanyRefunds()
      }
      this.reloadNewRefund()
    }
    createRefundError = error => this.requestError( error )

    getCompanyRefunds = () => Get(
      `/collections/refund_records?company_id=${ this.state.searchParams.company_id }&start_date=${ Moment( this.state.searchParams.start_date ).format( 'YYYY-MM-DD' ) }&end_date=${ Moment( this.state.searchParams.end_date ).format( 'YYYY-MM-DD' ) }`,
      this.getCompanyRefundsSuccess,
      this.getCompanyRefundsError,
      this.load
    )
    getCompanyRefundsSuccess = payload => this.setState({ refunds: payload })
    getCompanyRefundsError = error => this.setState({ refunds: [] })

    getSelectedRefund = ( invoice_id, refund_id ) => Get(
      `/invoices/${ invoice_id }/refunds/${ refund_id }`,
      this.getSelectedRefundSuccess,
      this.getSelectedRefundError,
      this.load
    )
    getSelectedRefundSuccess = payload => this.setState({ selectedRefund: payload, showUpdateRefund: true })
    getSelectedRefundError = error => this.requestError( error )

    updateRefund = data => Put(
      `/invoices/${ data.invoice_id }/refunds/${ data.id }`,
      data,
      this.updateRefundSuccess,
      this.updateRefundError,
      this.load
    )
    updateRefundSuccess = () => {
      this.requestSuccess( 'Refund is successfully updated' )
      if( this.state.searchParams.company_id > 0 ){
        this.getCompanyRefunds()
      }
      this.reloadSelectedRefund()
    }
    updateRefundError = error => this.requestError( error )

    downloadRefundPDF = () => {
      if( this.state.searchParams.company_id > 0 ){
        let headers = new Headers()
        headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
        this.load( true )
        fetch(
          `${ getDomainURL() }/collections/download_refund_records?company_id=${ this.state.searchParams.company_id }&start_date=${ Moment( this.state.searchParams.start_date ).format( 'YYYY-MM-DD' ) }&end_date=${ Moment( this.state.searchParams.end_date ).format( 'YYYY-MM-DD' ) }`,
          {
            headers: headers
          }
        ).then( res => res.blob()).then( blobby => {
          this.load( false )
          return FileSaver.saveAs( blobby, `${ _.find( this.props.data.profileReducer.profile.companies, { id: this.state.searchParams.company_id } ).name.replaceAll( ' ', '-' ) }_Refunds_${ Moment( this.state.searchParams.start_date ).format( 'YYYY-MM-DD' ) }_${ Moment( this.state.searchParams.end_date ).format( 'YYYY-MM-DD' ) }.pdf` )
        }).catch( error => {
          this.load( false )
          this.requestError( 'Failed to save refund record. Please try again.' )
        })
      } else {
        this.requestError( { message: 'Please select a company' } )
      }
    }

    reloadNewRefund = () => this.setState({
      showCreateRefund: false,
      newRefund: {
        patient_id: 0,
        invoice_id: 0,
        payment_id: 0,
        type_id: 1,
        amount: 0,
        reason: ''
      }
    })

    reloadSelectedRefund = () => this.setState({
      showDownloadRefund: false,
      showUpdateRefund: false,
      selectedRefund: {}
    })

    resetSearchParams = () => this.setState({
      searchParams: {
        company_id: 0,
        start_date: new Date(),
        end_date: new Date()
      }
    }, () => this.getCompanyRefunds())

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadRefundsHOC = { this.state.loading }
          refunds = { this.state.refunds }
          newRefund = { this.state.newRefund }
          selectedRefund = { this.state.selectedRefund }
          showCreateRefund = { this.state.showCreateRefund }
          showUpdateRefund = { this.state.showUpdateRefund }
          showDownloadRefund={ this.state.showDownloadRefund }
          selectedRefundToDelete = { this.state.selectedRefundToDelete }
          onChangeRefundsHOC={ this.onChangeRefundsHOC }
          createRefund={ this.createRefund }
          getCompanyRefunds={ this.getCompanyRefunds }
          getSelectedRefund={ this.getSelectedRefund }
          downloadRefundPDF={ this.downloadRefundPDF }
          updateRefund={ this.updateRefund }
          removeRefund={ this.removeRefund }
          reloadNewRefund={ this.reloadNewRefund }
          reloadSelectedRefund={ this.reloadSelectedRefund }
          searchParams={ this.state.searchParams }
          resetSearchParams={ this.resetSearchParams }
          requestError={ this.requestError } />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC