import React, { useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import _ from 'lodash'
import { Multiselect } from 'react-widgets'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithCompanies from 'actions/dictionary/companies'

const ListItem = ({ item }) => (
  <span>
    { `${ item.name }` }
  </span>
)

const CreatePatient = ({
  showCreatePatient,
  onChangePatientsHOC,
  newPatient,
  onLoadPatients,
  createPatient,
  onLoadCompanies,
  companies,
  getCompanies
}) => {

  useEffect(() => {
    getCompanies()
  }, [])

  const onChangePatientData = ( key, val ) => {
    let tmp = _.cloneDeep( newPatient )
    tmp[ key ] = val
    return onChangePatientsHOC( 'newPatient', tmp )
  }

  return (
    <Modal
      isOpen={ showCreatePatient }
      size={ 'md' }
      toggle={() => onChangePatientsHOC( 'showCreatePatient', false )}>
      <ModalHeader toggle={() => onChangePatientsHOC( 'showCreatePatient', false )}>Create patient</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>Name</Label>
            <Input 
              type="text" 
              value={ newPatient.name }
              onChange={ e => {
                onChangePatientData( 'name', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>Contact number(Please put country code, eg for Malaysia +60123456789 in front of contact number for whatsapp use - appointment notification)</Label>
            <Input 
              type="text" 
              value={ newPatient.contact_number }
              onChange={ e => {
                onChangePatientData( 'contact_number', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            {/* company - default to current user belong company  */}
            <Label>Select company/clinic</Label>
            <Multiselect
              data={ companies }
              textField={ 'name' }
              itemComponent={ ListItem }
              onChange={ val => {
                let tmp = []
                val.map( item => {
                  tmp.push( item.id )
                })
                onChangePatientData( 'company_ids', tmp )
              }} />
          </FormGroup>
        </Form>
        { ( onLoadPatients || onLoadCompanies ) && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={() => {
            createPatient( newPatient )
          }}>Submit</Button>
      </ModalFooter>
    </Modal>
  )
}

export default WithCompanies( CreatePatient )