import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Collapse,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'

import SmileDatepicker from 'components/Datepicker'

const SearchForm = ({
  data,
  searchParams,
  onChangeRefundsHOC,
  resetSearchParams,
  getCompanyRefunds,
  downloadRefundPDF
}) => {
  const [ isCardOpen, updateIsCardOpen ] = useState( false )

  const updateSearchParams = ( key, value ) => {
    let tmp = _.cloneDeep( searchParams )
    tmp[ key ] = value
    onChangeRefundsHOC( 'searchParams', tmp )
  }

  return (
    <Card className="main-card mb-3">
      <CardHeader 
        id='search-bar-toggle'
        className='d-flex justify-content-between' 
        onClick={ () => updateIsCardOpen( !isCardOpen ) } >
        <Label className='m-0' >Search</Label>
        <i 
          className={ isCardOpen ? 'pe-7s-angle-up' : 'pe-7s-angle-down' }
          style={{ fontSize: "3rem", color: "#000" }} />
        <UncontrolledTooltip target='search-bar-toggle' placement="top-end">
          { isCardOpen ? 'Collapse' : 'Expand' }</UncontrolledTooltip>
      </CardHeader>
      <Collapse isOpen={ isCardOpen } >
        <CardBody>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <Label>Select a company</Label>
              <Input
                type="select"
                value={ searchParams[ 'company_id' ] }
                onChange={ e => updateSearchParams( 'company_id', parseInt( e.target.value ) ) }>
                <option key={ 0 } value={ 0 }></option>
                {
                  data.profileReducer.profile.companies.map( item => <option key={ item.id } value={ item.id }>{ item.name }</option> )
                }
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Start date</Label>
              <SmileDatepicker
                showTimeSelect={ false }
                selectedDate={ searchParams[ 'start_date' ] }
                onChange={ val => updateSearchParams( 'start_date', val ) }
                dateFormat="MMMM d, yyyy"
                disabled={ false } 
              />
            </FormGroup>
            <FormGroup>
              <Label>End date</Label>
              <SmileDatepicker
                showTimeSelect={ false }
                selectedDate={ searchParams[ 'end_date' ] }
                onChange={ val => updateSearchParams( 'end_date', val ) }
                dateFormat="MMMM d, yyyy"
                disabled={ false } 
              />
            </FormGroup>
            <div className="d-flex">
              <Button 
                color="primary"
                style={{ marginLeft: 'auto' }}
                onClick={ () => getCompanyRefunds() }>
                { 'Search' }</Button>
              <Button 
                color="danger"
                style={{ marginLeft: '10px' }}
                onClick={ () => resetSearchParams() }>
                { 'Reset' }</Button>
              <Button 
                color="primary"
                style={{ marginLeft: '10px' }}
                onClick={ () => downloadRefundPDF() }>
                { 'Export report' }</Button>
            </div>
          </Form>
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default SearchForm