import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get, Post, Delete, Put } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newPayment: {
        customer_name: '',
        customer_address: '',
        customer_ic: '',
        current_paid_amount: 0,
        current_cost: 0,
        change: 0,
        pay_with: '',
        remark: '',
        id: 0, // invoice_id
        discount_type_id: 0,
        discount: 0,
        final_price: 0
      },
      payments: [],
      showNewPayment: false,
      showUpdatePayment: false,
      showDeletePaymentModal: false,
      showViewPayment: false,
      selectedPayment: {
        customer_name: '',
        customer_address: '',
        customer_ic: '',
        current_paid_amount: 0,
        current_cost: 0,
        change: 0,
        pay_with: '',
        remark: '',
        id: 0, // invoice_id
        discount_type_id: 0,
        discount: 0,
        final_price: 0
      },
      caPatients: [],
      caPatientQuery: '',
      selectedCaUser: {}
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error.message ? error.message : error )
    requestSuccess = success => toast.success( success )

    onChangePaymentHOC = ( key, val ) => this.setState({ [ key ]: val }, () => {
      if( key === 'caPatients' && _.isEmpty( val ) ){
        this.setState({ caPatientQuery: '' })
      }
    })

    getPayments = ( invoice_id ) => Get(
      `/invoices/${ invoice_id }/payments`,
      this.getPaymentsSuccess,
      this.getPaymentsError,
      this.load
    )
    getPaymentsSuccess = payload => this.setState({ payments: payload })
    getPaymentsError = error => this.requestError( error )

    getSelectedPayment = ( invoice_id, id, isUpdate ) => Get(
      `/invoices/${ invoice_id }/payments/${ id }`,
      payload => this.getSelectedPaymentSuccess( payload, isUpdate ),
      this.getSelectedPaymentError,
      this.load
    )
    getSelectedPaymentSuccess = ( payload, isUpdate ) => {
      if ( isUpdate ){
        if( payload.credit_advance_payment_logs.length > 0 ){  
          this.getSelectedCaUser( payload.credit_advance_payment_logs[ payload.credit_advance_payment_logs.length - 1 ].patient_id ) 
        }
        this.setState({ 
          selectedPayment: {
            ...payload,
            ...( payload.pay_with === 'Panel' ? { panel_id: payload.panel.id } : {} ),
            final_price: payload[ 'discount' ] > 0
              ? payload[ 'discount_type_id' ] === 0
                ? parseFloat( payload[ 'current_cost' ] * ( 1 - payload[ 'discount' ]/100 ) )
                : parseFloat( payload[ 'current_cost' ] - payload[ 'discount' ] )
              : parseFloat( payload[ 'current_cost' ] )
          }, 
          showUpdatePayment: true 
        })
      } else {
        this.setState({ 
          selectedPayment: {
            ...payload,
            final_price: payload[ 'discount' ] > 0
              ? payload[ 'discount_type_id' ] === 0
                ? parseFloat( payload[ 'current_cost' ] * ( 1 - payload[ 'discount' ]/100 ) )
                : parseFloat( payload[ 'current_cost' ] - payload[ 'discount' ] )
              : parseFloat( payload[ 'current_cost' ] )
          }, 
          showDeletePaymentModal: true
        })
      }
    }
    getSelectedPaymentError = error => this.requestError( 'Failed to get selected payment' )

    getSelectedPaymentView = payload => Get(
      `/invoices/${ payload.invoice_id }/payments/${ payload.id }`,
      this.getSelectedPaymentViewSuccess,
      this.getSelectedPaymentViewError,
      this.load
    )
    getSelectedPaymentViewSuccess = payload => this.setState({ 
      showViewPayment: true, 
      selectedPayment: {
        ...payload,
        final_price: payload[ 'discount' ] > 0
          ? payload[ 'discount_type_id' ] === 0
            ? parseFloat( payload[ 'current_cost' ] * ( 1 - payload[ 'discount' ]/100 ) )
            : parseFloat( payload[ 'current_cost' ] - payload[ 'discount' ] )
          : parseFloat( payload[ 'current_cost' ] )
      } 
    })
    getSelectedPaymentViewError = error => this.requestError( 'Failed to get selected payment' )

    createPayment = data => Post(
      `/invoices/${ data.id }/payments`,
      data,
      payload => this.createPaymentSuccess( payload, data.final_price ),
      this.createPaymentError,
      this.load
    )
    createPaymentSuccess = async( payload, final_price ) => {
      this.requestSuccess( 'Payment is created successfully.' )
      this.getPayments( this.props.selectedInvoice.id )
      if( payload.pay_with === 'Credit advance' ) {
        await this.updateCreditAdvance({
          ...this.state.selectedCaUser.credit_advance,
          payment_id: payload.id,
          amount: this.state.selectedCaUser.credit_advance.amount - final_price
        })
      }
      this.setState({
        newPayment: {
          current_paid_amount: 0,
          current_cost: 0,
          change: 0,
          pay_with: '',
          id: 0, // invoice_id
          discount_type_id: 0,
          discount: 0,
          final_price: 0
        },
        showNewPayment: false
      })
    }
    createPaymentError = error => this.requestError( error )

    updatePayment = ( data, isAdmin ) => Put(
      `/invoices/${ data.invoice_id }/payments/${ data.id }${ isAdmin ? '/master_update' : '' }`,
      data,
      this.updatePaymentSuccess,
      this.updatePaymentError,
      this.load
    )
    updatePaymentSuccess = payload => {
      this.requestSuccess( 'Payment is updated successfully.' )
      this.setState({ showUpdatePayment: false })
      this.getPayments( this.props.selectedInvoice.id )
    }
    updatePaymentError = error => this.requestError( error )

    removePayment = ( invoice_id, payment_id ) => Delete(
      `/invoices/${ invoice_id }/payments/${ payment_id }`,
      this.removePaymentSuccess,
      this.removePaymentError,
      this.load
    )
    removePaymentSuccess = payload => {
      this.getPayments( this.props.selectedInvoice.id )
      this.requestSuccess( 'Payment is successfully removed' )
      this.setState({ showDeletePaymentModal: false })
    }
    removePaymentError = error => this.requestError( error )

    updateCreditAdvance = data => Put(
      `/credit_advances/${ data.id }`,
      data,
      this.updateCreditAdvanceSuccess,
      this.updateCreditAdvanceError,
      this.load
    )
    updateCreditAdvanceSuccess = payload => {
      this.getSelectedCaUser( payload.patient_id )
      this.requestSuccess( 'Credit advance is updated successfully.' ) }
    updateCreditAdvanceError = () => this.requestError( 'Failed to update credit advance.' )

    getCaPatients = searchParams => Get(
      `/patients?page=1&search=${ searchParams }`,
      this.getCaPatientsSuccess,
      this.getCaPatientsError,
      this.load
    )
    getCaPatientsSuccess = payload => this.setState({ caPatients: payload.data, selectedCaUser: {} })
    getCaPatientsError = error => this.requestError( error )

    getSelectedCaUser = id => Get(
      `/patients/${ id }`,
      this.getSelectedCaUserSuccess,
      this.getSelectedCaUserError,
      this.load
    )
    getSelectedCaUserSuccess = payload => !_.isEmpty( this.state.caPatients )
      ? this.setState({ selectedCaUser: payload })
      : this.setState({ selectedCaUser: payload, caPatients: [ payload ] })
    getSelectedCaUserError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          onLoadPayments={ this.state.loading }
          newPayment={ this.state.newPayment }
          selectedPayment={ this.state.selectedPayment }
          payments={ this.state.payments }
          showNewPayment={ this.state.showNewPayment }
          showUpdatePayment={ this.state.showUpdatePayment }
          showViewPayment={ this.state.showViewPayment }
          showDeletePaymentModal={ this.state.showDeletePaymentModal } 
          onChangePaymentHOC={ this.onChangePaymentHOC }
          getPayments={ this.getPayments }
          getSelectedPayment={ this.getSelectedPayment }
          getSelectedPaymentView={ this.getSelectedPaymentView }
          createPayment={ this.createPayment }
          updatePayment={ this.updatePayment }
          removePayment={ this.removePayment }
          updateCreditAdvance={ this.updateCreditAdvance }
          caPatients={ this.state.caPatients }
          caPatientQuery={ this.state.caPatientQuery }
          getCaPatients={ this.getCaPatients }
          selectedCaUser={ this.state.selectedCaUser }
          getSelectedCaUser={ this.getSelectedCaUser } />
      )
    }
  }
  return WithHOC
}

export default HOC