import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'
import ReactHTMLParser from 'react-html-parser'
import Moment from 'moment'

import { Post, Get, Put, Delete } from 'utils/axios'
import getDomainURL from 'utils/api'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      referralForms: [],
      newReferralForm: {},
      selectedReferralForm: {},
      showCreateReferralForm: false,
      showDownloadReferralForm: false,
      showUpdateReferralForm: false,
      selectedReferralFormToDelete: 0,
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( ({ closeToast }) => ReactHTMLParser( error.message ) )

    onChangeReferralFormsHOC = ( key, val ) => this.setState({ [ key ]: val }, () => {
      if( key === 'showDownloadReferralForm' ){
        this.setState({ 
          showDownloadReferralForm: true, 
          selectedReferralForm: { 
            id: val, 
            type_id: 1 ,
            label: 'Cementation Approval'
          } 
      })
      }
    })

    createReferralForm = data => Post(
      `/referrers`,
      data,
      this.createReferralFormSuccess,
      this.createReferralFormError,
      this.load
    )
    createReferralFormSuccess = () => {
      this.requestSuccess( 'Referral form is successfully created' )
      this.getReferralForms()
      this.reloadNewReferralForm()
    }
    createReferralFormError = error => this.requestError( error )

    getReferralForms = pg => Get(
      `/referrers${ pg ? `?page=${ pg }` : '' }`,
      this.getReferralFormsSuccess,
      this.getReferralFormsError,
      this.load
    )
    getReferralFormsSuccess = payload => this.setState({ referralForms: payload })
    getReferralFormsError = error => this.requestError( error )

    getSelectedReferralForm = form_id => Get(
      `/referrers/${ form_id }`,
      this.getSelectedReferralFormSuccess,
      this.getSelectedReferralFormError,
      this.load
    )
    getSelectedReferralFormSuccess = payload => this.setState({ selectedReferralForm: payload, showUpdateReferralForm: true })
    getSelectedReferralFormError = error => this.requestError( error )

    downloadReferralFormPDF = form_id => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/referrers/${ form_id }/download`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( blobby => {
        this.load( false )
        return FileSaver.saveAs( blobby, `referral_form_${ Moment().format( 'YYYYMMDDHHmm' ) }.pdf` )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save referral form. Please try again.' )
      })
    }

    updateReferralForm = data => Put(
      `/referrers/${ data.id }`,
      data,
      this.updateReferralFormSuccess,
      this.updateReferralFormError,
      this.load
    )
    updateReferralFormSuccess = () => {
      this.requestSuccess( 'Referral form is successfully updated' )
      this.getReferralForms()
      this.reloadSelectedReferralForm()
    }
    updateReferralFormError = error => this.requestError( error )

    removeReferralForm = form_id => Delete(
      `/referrers/${ form_id }`,
      this.removeReferralFormSuccess,
      this.removeReferralFormError,
      this.load
    )
    removeReferralFormSuccess = () => {
      this.requestSuccess( 'Referral form is successfully removed' )
      this.getReferralForms()
      this.setState({ selectedReferralFormToDelete: 0 })
    }
    removeReferralFormError = error => this.requestError( error )

    reloadNewReferralForm = () => this.setState({
      showCreateReferralForm: false,
      newReferralForm: {
        company_id: 0,
        branch_id: 0,
        user_id: 0,
        patient_id: 0,
        referred_clinic_name: '',
        referred_dentist: '',
        referred_dentist_contact: '',
        referred_dentist_address: '',
        referred_dentist_email: '',
        reason: ''
      }
    })

    reloadSelectedReferralForm = () => this.setState({
      showDownloadReferralForm: false,
      showUpdateReferralForm: false,
      selectedReferralForm: {}
    })

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadReferralFormsHOC = { this.state.loading }
          referralForms = { this.state.referralForms }
          newReferralForm = { this.state.newReferralForm }
          selectedReferralForm = { this.state.selectedReferralForm }
          showCreateReferralForm = { this.state.showCreateReferralForm }
          showUpdateReferralForm = { this.state.showUpdateReferralForm }
          showDownloadReferralForm={ this.state.showDownloadReferralForm }
          selectedReferralFormToDelete = { this.state.selectedReferralFormToDelete }
          onChangeReferralFormsHOC={ this.onChangeReferralFormsHOC }
          createReferralForm={ this.createReferralForm }
          getReferralForms={ this.getReferralForms }
          getSelectedReferralForm={ this.getSelectedReferralForm }
          downloadReferralFormPDF={ this.downloadReferralFormPDF }
          updateReferralForm={ this.updateReferralForm }
          removeReferralForm={ this.removeReferralForm }
          reloadNewReferralForm={ this.reloadNewReferralForm }
          reloadSelectedReferralForm={ this.reloadSelectedReferralForm } />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC