import React, { useEffect, useState } from 'react'
import {
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Card, CardHeader,
  CardBody
} from 'reactstrap'
import { Multiselect } from 'react-widgets'
import _ from 'lodash'
import { compose } from 'redux'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithCompanies from 'actions/dictionary/companies'
import WithBranches from './actions'

const DummyCompanies = [
  {
    id: 1,
    name: 'Wong and Sim'
  },
  {
    id: 2,
    name: 'Prime Care'
  }
]

const ListItem = ({ item }) => (
  <span>
    { `${ item.name }` }
  </span>
)

const CompanyTab = ({
  onChangeUserData,
  assignedCompanies,
  onLoadCompanies,
  companies,
  getCompanies,
  onLoadBranches,
  assignBranch,
  unassignBranch,
  selectedUser,
  getSelectedUser,
  data
}) => {
  const [ selectedCompanies, updateSelectedCompanies ] = useState( [] )

  useEffect(() => {
    getCompanies()
  }, [])

  useEffect(() => {
    if( companies.length > 0 ) {
      let tmp = []
      assignedCompanies.map( item => {
        let tmpCompany = _.find( companies, { id: item } )
        tmp.push( tmpCompany )
      })
      updateSelectedCompanies( tmp )
    }
  }, [ companies ])

  return (
    <Form onSubmit={ e => e.preventDefault()}>
      <FormGroup>
        <Label>Assign companies</Label>
        <Multiselect
          disabled={ data.profileReducer.profile.current_user.role.name !== 'Super Admin' }
          data={ companies }
          textField={ 'name' }
          itemComponent={ ListItem }
          onChange={ val => {
            let tmp = []
            let tmpSelectedCompanies = []
            val.map( item => {
              let tmpCompany = _.find( companies, { id: item.id } )
              tmp.push( item.id )
              tmpSelectedCompanies.push( tmpCompany )
            })
            updateSelectedCompanies( tmpSelectedCompanies )
            onChangeUserData( 'company_ids', tmp )
          }}
          value={ selectedCompanies } />
      </FormGroup>
      <FormGroup>
        <Label>Assign Branches</Label>
        {
          selectedCompanies.map( item => {
            return (
              <Card key={ item.id }>
                <CardHeader>{ `${ item.name }(${ item.registration_number })` }</CardHeader>
                <CardBody>
                  {
                    item.branches.map( branch => {
                      return (
                        <Card key={ `branch-${ branch.id }` }>
                          <CardBody>
                            <h4>{ branch.name }</h4>
                            <span className="mr-2">{ `Address: ${ branch.address } ${ branch.postcode } ${ branch.city } ${ branch.country }` }</span>
                            <Button
                              disabled={ data.profileReducer.profile.current_user.role.name !== 'Super Admin' }
                              color={ selectedUser.branch_ids.indexOf( branch.id ) > -1 ? 'danger' : 'primary' }
                              onClick={
                                selectedUser.branch_ids.indexOf( branch.id ) > -1 
                                  ? () => {
                                    unassignBranch( branch.id )
                                  } : () => {
                                    assignBranch( branch.id )
                                  }
                              }>
                              {
                                selectedUser.branch_ids.indexOf( branch.id ) > -1 ? 'Remove branch' : 'Assign branch'
                              }
                            </Button>
                          </CardBody>
                        </Card>
                      )
                    })
                  }
                </CardBody>
              </Card>
            )
          })
        }
      </FormGroup>
      { onLoadBranches && <LoadingOverlay /> }
    </Form>
  )
}

export default compose(
  WithCompanies,
  WithBranches
)( CompanyTab )