import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newOffDay: {
        start_datetime: '',
        end_datetime: '',
        user_id: ''
      },
      selectedDoctorId: '',
      selectedOffDays: [],
      selectedOffDates: [],
      showPromptModal: false,
      toRemoveID: ''
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeSetOffDaysHOC = ( key, val ) => this.setState({ [ key ]: val }, () => {
      if( key === 'selectedDoctorId' && val > 0 ){
        this.getOffDays( this.state.selectedDoctorId )
      }
    })

    getOffDays = ( user_id ) => Get(
      `/off_days?user_ids=${ user_id }`,
      this.getOffDaysSuccess,
      this.getOffDaysError,
      this.load
    )
    getOffDaysSuccess = payload => {
      let tmp = []
      if( payload && payload.length > 0 ) {
        payload.map( item => {
          tmp.push( new Date( item.date ) )
        })
      }
      this.setState({ selectedOffDays: payload, selectedOffDates: tmp })
    }
    getOffDaysError = error => this.requestError( error )

    createOffDay = ( user_id, data ) => Post(
      `/off_days`,
      data,
      () => this.createOffDaySuccess( user_id ),
      this.createOffDayError,
      this.load
    )
    createOffDaySuccess = user_id => {
      this.requestSuccess( 'Off day is created successfully.' )
      this.setState({ newOffDay: {
        start_datetime: '',
        end_datetime: '',
        user_id: ''
      }})
      this.getOffDays( JSON.stringify( [ user_id ] ) )
    }
    createOffDayError = error => this.requestError( error )

    removeOffDay = ( user_id, id ) => Delete(
      `/off_days/${ id }`,
      () => this.removeOffDaySuccess( user_id ),
      this.removeOffDayError,
      this.load
    )
    removeOffDaySuccess = user_id => {
      this.requestSuccess( 'Off day is removed successfully.' )
      this.getOffDays( JSON.stringify( [ user_id ] ) )
      this.setState({ showPromptModal: false })
    }
    removeOffDayError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent 
            { ...this.props }
            selectedDoctorId={ this.state.selectedDoctorId } 
            selectedOffDays={ this.state.selectedOffDays }
            selectedOffDates={ this.state.selectedOffDates }
            newOffDay={ this.state.newOffDay }
            onLoadSetOffDays={ this.state.loading }
            onChangeSetOffDaysHOC={ this.onChangeSetOffDaysHOC }
            getOffDays={ this.getOffDays }
            createOffDay={ this.createOffDay }
            removeOffDay={ this.removeOffDay } />
          <PromptModal
            showPromptModal={ this.state.showPromptModal }
            onClickYes={() => this.removeOffDay( 
              this.props.data.profileReducer.profile.current_user.role.name !== 'Doctor'
                ? this.state.selectedDoctorId
                : this.props.data.profileReducer.profile.current_user.id,
              this.state.toRemoveID )}
            onClickNo={() => this.setState({ showPromptModal: false })}
            content={ 'Are you sure you want to delete the record?' } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC