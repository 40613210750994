import React, { Component } from 'react'
import { toast } from 'react-toastify'
import Moment from 'moment'
import _ from 'lodash'

import { Get, Post, Delete, Put } from 'utils/axios'
import getDomainURL from 'utils/api'
import firebase from '../../../../../../firebase'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newPayment: {
        customer_name: '',
        customer_address: '',
        customer_ic: '',
        current_paid_amount: 0,
        current_cost: 0,
        change: 0,
        pay_with: '',
        remark: '',
        id: 0, // invoice_id
        discount_type_id: 0,
        discount: 0,
        final_price: 0
      },
      payments: [],
      showNewPayment: false,
      showUpdatePayment: false,
      showDeletePaymentModal: false,
      showViewPayment: false,
      selectedPayment: {
        customer_name: '',
        customer_address: '',
        customer_ic: '',
        current_paid_amount: 0,
        current_cost: 0,
        change: 0,
        pay_with: '',
        remark: '',
        id: 0, // invoice_id
        discount_type_id: 0,
        discount: 0,
        final_price: 0
      },
      caPatients: [],
      caPatientQuery: '',
      selectedCaUser: {},
      totalPayments: 0
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error.message ? error.message : error )
    requestSuccess = success => toast.success( success )

    onChangePaymentHOC = ( key, val ) => this.setState({ [ key ]: val }, () => {
      if( key === 'caPatients' && _.isEmpty( val ) ){
        this.setState({ caPatientQuery: '' })
      }
    })

    getPayments = ( invoice_id ) => Get(
      `/invoices/${ invoice_id }/payments`,
      this.getPaymentsSuccess,
      this.getPaymentsError,
      this.load
    )
    getPaymentsSuccess = payload => {
      let tmpTotal = 0
      payload.map( item => {
        tmpTotal = tmpTotal + parseFloat( item.current_cost )
      })
      this.setState({ payments: payload, totalPayments: tmpTotal })
    }
    getPaymentsError = error => this.requestError( 'Failed to get payments.' )

    getSelectedPayment = ( invoice_id, id, isUpdate ) => Get(
      `/invoices/${ invoice_id }/payments/${ id }`,
      payload => this.getSelectedPaymentSuccess( payload, isUpdate ),
      this.getSelectedPaymentError,
      this.load
    )
    getSelectedPaymentSuccess = ( payload, isUpdate ) => {
      if ( isUpdate ){
        if( payload.credit_advance_payment_logs.length > 0 ){  
          this.getSelectedCaUser( payload.credit_advance_payment_logs[ payload.credit_advance_payment_logs.length - 1 ].patient_id ) 
        }
        this.setState({ 
          selectedPayment: {
            ...payload,
            ...( payload.pay_with === 'Panel' ? { panel_id: payload.panel.id } : {} ),
            final_price: payload[ 'discount' ] > 0
              ? payload[ 'discount_type_id' ] === 0
                ? parseFloat( payload[ 'current_cost' ] * ( 1 - payload[ 'discount' ]/100 ) )
                : parseFloat( payload[ 'current_cost' ] - payload[ 'discount' ] )
              : parseFloat( payload[ 'current_cost' ] )
          }, 
          showUpdatePayment: true 
        })
      } else {
        this.setState({ 
          selectedPayment: {
            ...payload,
            final_price: payload[ 'discount' ] > 0
              ? payload[ 'discount_type_id' ] === 0
                ? parseFloat( payload[ 'current_cost' ] * ( 1 - payload[ 'discount' ]/100 ) )
                : parseFloat( payload[ 'current_cost' ] - payload[ 'discount' ] )
              : parseFloat( payload[ 'current_cost' ] )
          }, 
          showDeletePaymentModal: true 
        })
      }
    }
    getSelectedPaymentError = error => this.requestError( 'Failed to get selected payment' )

    getSelectedPaymentView = payload => Get(
      `/invoices/${ payload.invoice_id }/payments/${ payload.id }`,
      this.getSelectedPaymentViewSuccess,
      this.getSelectedPaymentViewError,
      this.load
    )
    getSelectedPaymentViewSuccess = payload => this.setState({ 
      showViewPayment: true, 
      selectedPayment: {
        ...payload,
        final_price: payload[ 'discount' ] > 0
          ? payload[ 'discount_type_id' ] === 0
            ? parseFloat( payload[ 'current_cost' ] * ( 1 - payload[ 'discount' ]/100 ) )
            : parseFloat( payload[ 'current_cost' ] - payload[ 'discount' ] )
          : parseFloat( payload[ 'current_cost' ] )
      } 
    })
    getSelectedPaymentViewError = error => this.requestError( 'Failed to get selected payment' )

    createPayment = ( data, patient, medicalRecordId, uid ) => Post(
      `/invoices/${ data.id }/payments`,
      data,
      payload => this.createPaymentSuccess( payload, patient, medicalRecordId, uid, data.final_price ),
      this.createPaymentError,
      this.load
    )
    createPaymentSuccess = async( payload, patient, medicalRecordId, uid, final_price ) => {
      this.notifyFrontdeskOnPayment( payload, patient, medicalRecordId, uid, false )
      this.getPayments( this.props.selectedInvoice.id )
      if( payload.pay_with === 'Credit advance' ) {
        await this.updateCreditAdvance({
          ...this.state.selectedCaUser.credit_advance,
          payment_id: payload.id,
          amount: this.state.selectedCaUser.credit_advance.amount - final_price
        })
      }
      this.setState({
        newPayment: {
          customer_name: '',
          customer_address: '',
          current_paid_amount: 0,
          current_cost: 0,
          change: 0,
          pay_with: '',
          id: 0, // invoice_id
          discount_type_id: 0,
          discount: 0,
          final_price: 0
        },
        showNewPayment: false
      })
      this.requestSuccess( 'Payment is created successfully.' )
    }
    createPaymentError = error => this.requestError( error )

    updatePayment = ( data, type, isAdmin ) => Put(
      `/invoices/${ data.invoice_id }/payments/${ data.id }${ isAdmin ? '/master_update' : '' }`,
      data,
      payload => this.updatePaymentSuccess( payload, type, data.final_price ),
      this.updatePaymentError,
      this.load
    )
    updatePaymentSuccess = async( payload, type, final_price ) => {
      if( payload.pay_with === 'Credit advance' && type === 'update credit advance' ) {
        await this.updateCreditAdvance({
          ...this.state.selectedCaUser.credit_advance,
          payment_id: payload.id,
          amount: this.state.selectedCaUser.credit_advance.amount - final_price
        })
      }
      this.requestSuccess( 'Payment is updated successfully.' )
      this.setState({ showUpdatePayment: false })
      this.getPayments( this.props.selectedInvoice.id )
      this.props.getSelectedPatient( this.props.selectedPatient.id, 'view' )
      if( payload && payload.appointment_id > 0 ){
        this.updateAppointment( payload.appointment_id )
      }
    }
    updatePaymentError = error => this.requestError( error )

    updateAppointment = appointment_id => Put(
      `/appointments/${ appointment_id }`,
      { checkout_datetime: Moment().utc().format() },
      this.updateAppointmentSuccess,
      this.updateAppointmentError,
      this.load
    )
    updateAppointmentSuccess = () => {}
    updateAppointmentError = error => this.requestError( error )

    removePayment = ( invoice_id, payment_id ) => Delete(
      `/invoices/${ invoice_id }/payments/${ payment_id }`,
      this.removePaymentSuccess,
      this.removePaymentError,
      this.load
    )
    removePaymentSuccess = payload => {
      this.getPayments( this.props.selectedInvoice.id )
      this.requestSuccess( 'Payment is successfully removed' )
      this.setState({ selectedPayment: {}, showDeletePaymentModal: false })
    }
    removePaymentError = error => this.requestError( error )

    updateCreditAdvance = data => Put(
      `/credit_advances/${ data.id }`,
      data,
      this.updateCreditAdvanceSuccess,
      this.updateCreditAdvanceError,
      this.load
    )
    updateCreditAdvanceSuccess = () => this.requestSuccess( 'Credit advance is updated successfully.' )
    updateCreditAdvanceError = () => this.requestError( 'Failed to update credit advance.' )

    getCaPatients = searchParams => Get(
      `/patients?page=1&search=${ searchParams }`,
      this.getCaPatientsSuccess,
      this.getCaPatientsError,
      this.load
    )
    getCaPatientsSuccess = payload => this.setState({ caPatients: payload.data, selectedCaUser: {} })
    getCaPatientsError = error => this.requestError( error )

    getSelectedCaUser = id => Get(
      `/patients/${ id }`,
      this.getSelectedCaUserSuccess,
      this.getSelectedCaUserError,
      this.load
    )
    getSelectedCaUserSuccess = payload => !_.isEmpty( this.state.caPatients )
      ? this.setState({ selectedCaUser: payload })
      : this.setState({ selectedCaUser: payload, caPatients: [ payload ] })
    getSelectedCaUserError = error => this.requestError( error )

    notifyFrontdeskOnPayment = ( payload, patient, medicalRecordId, uid, priorPayment ) => {
      const userRef = getDomainURL().indexOf( 'staging' ) > -1
        ? firebase.database().ref( `stagingUsers/${ uid }` ) 
        : getDomainURL().indexOf( 'idr' ) > -1
          ? firebase.database().ref( `productionUsersIdr/${ uid }` ) 
          : firebase.database().ref( `productionUsers/${ uid }` )
      userRef.once( 'value', snapshot => {
        if( snapshot.exists() ){
          if( snapshot.hasChild( 'notifications' ) ){
            for( let id in snapshot.val().notifications ){
              let tmpMessageList = snapshot.val().notifications[ id ].message.split( '/' )
              let currentPatientId = tmpMessageList[ tmpMessageList.findIndex( x => x === 'patients' ) + 1 ]
              let currentMedicalRecordId = tmpMessageList[ tmpMessageList.findIndex( x => x === 'medical_record' ) + 1 ].replace( '\'>here<', '' )

              if( currentPatientId.toString() === patient.id.toString() && currentMedicalRecordId.toString() === medicalRecordId.toString() ){
                let frontDeskId = snapshot.val().notifications[ id ].frontDeskId
                let frontDeskRef = getDomainURL().indexOf( 'staging' ) > -1
                  ? firebase.database().ref( `stagingUsers/${ frontDeskId }` ) 
                  : getDomainURL().indexOf( 'idr' ) > -1
                    ? firebase.database().ref( `productionUsersIdr/${ frontDeskId }` ) 
                    : firebase.database().ref( `productionUsers/${ frontDeskId }` )

                frontDeskRef.once( 'value', snapshot => {
                  let tmpNotificationList = []
                  tmpNotificationList.push({
                    created_at: Moment().format(),
                    message: `<p>${ patient.name } ( patient ) is coming to make payment, click <a href='${ getDomainURL().indexOf( 'staging' ) > -1
                      ? 'https://app-staging.primeview.site'
                      : window.location.origin }/#/dashboard/patients/${ patient.id }/medical_record/${ medicalRecordId }/invoices/${ payload.invoice_id }${ priorPayment 
                        ? '' 
                        : `/payments/${ payload.id }` }'>here</a> to manage his/her payment.${ priorPayment && ' (Please create new payment for this)' }</p>`
                  })
                  if( snapshot.exists() ){
                    let tmpUser = {}
                    if( snapshot.hasChild( 'token' ) ){
                      tmpUser.token = snapshot.val().token
                    }
                    if( snapshot.hasChild( 'notifications' ) ){
                      for( let id in snapshot.val().notifications ){
                        tmpNotificationList.push( snapshot.val().notifications[ id ] )
                      }
                    }
                    tmpUser.notifications = tmpNotificationList
                    frontDeskRef.set( tmpUser ).then( () => priorPayment && this.requestSuccess( 'Notification to frontdesk is successfully sent' ) )
                  } else {
                    let tmpUser = { notifications: tmpNotificationList }
                    frontDeskRef.set( tmpUser ).then( () => priorPayment && this.requestSuccess( 'Notification to frontdesk is successfully sent' ) )
                  }
                }) 
                break
              }
            }
          }
        }
      })
    }

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          onLoadPayments={ this.state.loading }
          newPayment={ this.state.newPayment }
          payments={ this.state.payments }
          showNewPayment={ this.state.showNewPayment }
          showUpdatePayment={ this.state.showUpdatePayment }
          showDeletePaymentModal={ this.state.showDeletePaymentModal }
          selectedPayment={ this.state.selectedPayment }
          showViewPayment={ this.state.showViewPayment }
          onChangePaymentHOC={ this.onChangePaymentHOC }
          getPayments={ this.getPayments }
          getSelectedPayment={ this.getSelectedPayment }
          createPayment={ this.createPayment }
          updatePayment={ this.updatePayment }
          removePayment={ this.removePayment }
          getSelectedPaymentView={ this.getSelectedPaymentView }
          caPatients={ this.state.caPatients }
          caPatientQuery={ this.state.caPatientQuery }
          getCaPatients={ this.getCaPatients }
          selectedCaUser={ this.state.selectedCaUser }
          getSelectedCaUser={ this.getSelectedCaUser }
          totalPayments={ this.state.totalPayments }
          notifyFrontdeskOnPayment={ this.notifyFrontdeskOnPayment } />
      )
    }
  }
  return WithHOC
}

export default HOC