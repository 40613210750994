import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get, Post, Delete, Put } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      treatments: [],
      newTreatment: {
        treatment_id: 0,
        invoice_id: 0,
        price: 0,
        name: '',
        types: []
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeTreatmentHOC = ( key, val ) => this.setState({ [ key ]: val })

    getTreatments = () => Get(
      `/treatments`,
      this.getTreatmentsSuccess,
      this.getTreatmentsError,
      this.load
    )
    getTreatmentsSuccess = payload => this.setState({ treatments: payload })
    getTreatmentsError = error => this.requestError( error )

    addInvoiceTreatment = ( data, totalPrice ) => Post(
      `/invoices/${ data.invoice_id }/add_treatment`,
      data,
      payload => this.addInvoiceTreatmentSuccess( payload, totalPrice ),
      this.addInvoiceTreatmentError,
      this.load
    )
    addInvoiceTreatmentSuccess = ( payload, totalPrice ) => {
      this.requestSuccess( 'Treatment is assigned to invoice successfully.' )
      this.props.getSelectedInvoice( this.props.selectedInvoice.id )
      this.setState({
        newTreatment: {
          treatment_id: 0,
          invoice_id: 0,
          price: 0,
          name: '',
          types: []
        }
      })
    }
    addInvoiceTreatmentError = error => this.requestError( error )

    removeInvoiceTreatment = ( invoice_id, treatment_id, totalPrice ) => Delete(
      `/invoices/${ invoice_id }/remove_treatment?treatment_id=${ treatment_id }`,
      payload => this.removeInvoiceTreatmentSuccess( payload, totalPrice ),
      this.removeInvoiceTreatmentError,
      this.load
    )
    removeInvoiceTreatmentSuccess = ( payload, totalPrice ) => {
      this.requestSuccess( 'Treatment is removed from invoice successfully.' )
      this.props.getSelectedInvoice( this.props.selectedInvoice.id )
    }
    removeInvoiceTreatmentError = error => this.requestError( error )

    updateInvoiceTreatment = ( data ) => Put(
      `/invoices/${ data.id }/update_treatment`,
      data,
      this.updateInvoiceTreatmentSuccess,
      this.updateInvoiceTreatmentError,
      this.load
    )
    updateInvoiceTreatmentSuccess = () => {
      this.requestSuccess( 'Treatment type is successfully updated' )
      this.props.getSelectedInvoice( this.props.selectedInvoice.id ) }
    updateInvoiceTreatmentError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          onLoadInvoiceTreatments={ this.state.loading }
          treatments={ this.state.treatments }
          newTreatment={ this.state.newTreatment }
          onChangeTreatmentHOC={ this.onChangeTreatmentHOC }
          getTreatments={ this.getTreatments }
          addInvoiceTreatment={ this.addInvoiceTreatment }
          removeInvoiceTreatment={ this.removeInvoiceTreatment }
          updateInvoiceTreatment={ this.updateInvoiceTreatment } />
      )
    }
  }
  return WithHOC
}

export default HOC