import React from 'react'
import _ from 'lodash'
import {
  Form, FormGroup,
  Label, Input, Button
} from 'reactstrap'
import { Multiselect } from 'react-widgets'

const ListItem = ({ item }) => (
  <span>
    { `${ item.name } - ${ item.nric_no } - ${ item.contact_number }` }
  </span>
)

const ConsentForm  = ({
  consentFormType,
  currentConsentForm,
  onChangeConsentFormsHOC,
  profile,
  doctors,
  patients,
  getPatients,
  onChangePatientsHOC,
  onLoadPatientsHOC,
  searchParams
}) => {
  const updateConsentForm = ( key, val ) => {
    let tmp = _.cloneDeep( currentConsentForm )
    tmp[ key ] = val

    switch( key ){
      case 'company_id':
        tmp.branch_id = 0
        tmp.user_id = 0  
        break
      case 'branch_id': 
        tmp.user_id = 0
    }

    onChangeConsentFormsHOC( consentFormType, tmp )
  }

  return(
    <Form>
      <FormGroup>
        <Label>Company</Label>
        <Input
          type='select'
          value={ currentConsentForm.company_id }
          onChange={ e => updateConsentForm( 'company_id', e.target.value ) }>
          <option value={ 0 } ></option>
          { profile.companies.map( item => <option value={ item.id } >{ item.name }</option> ) }
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Branch</Label>
        <Input
          type='select'
          value={ currentConsentForm.branch_id }
          onChange={ e => updateConsentForm( 'branch_id', e.target.value ) }>
          <option value={ 0 } ></option>
          { 
            _.filter( 
              profile.branches, branch => parseInt( branch.company.id ) === parseInt( currentConsentForm.company_id )
            ).map( item => <option value={ item.id } >{ item.name }</option> ) 
          }
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Doctor</Label>
        <Input
          type='select'
          value={ currentConsentForm.user_id }
          onChange={ e => updateConsentForm( 'user_id', e.target.value ) }>
          <option value={ 0 } ></option>
          { 
            _.filter( 
              doctors, doctor => _.findIndex( doctor.branch_ids, branch => parseInt( branch ) === parseInt( currentConsentForm.branch_id ) ) > -1
            ).map( item => <option value={ item.id } >{ `${ item.salutation } ${ item.name }` }</option> ) 
          }
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Patient</Label>
        <div className="d-flex mb-2">
          <Input 
            type="text" 
            value={ searchParams }
            className='mr-2'
            onChange={ e => onChangePatientsHOC( 'searchParams', e.target.value ) }
            placeholder="Fill in patient NRIC or name here and click search button" />
          <Button 
            color="primary" 
            onClick={() => {
              getPatients( searchParams )
              updateConsentForm( 'patient_id', 0 ) }}>
            Search</Button>
        </div>
        {
          onLoadPatientsHOC 
            ? (
              <p>Loading patients......</p>
            ) : <>
              <Multiselect
                data={ patients }
                textField={ 'name' }
                itemComponent={ ListItem }
                value={ currentConsentForm.patient_id > 0 
                  ? _.find( patients, { id: parseInt( currentConsentForm.patient_id ) } ) 
                    ? [ _.find( patients, { id: parseInt( currentConsentForm.patient_id ) } ) ] 
                    : [ {} ]
                  : [] }
                onChange={ val => val.length === 1 
                  ? updateConsentForm( 'patient_id', val[ 0 ].id )
                  : updateConsentForm( 'patient_id', 0 )
                } />
            </>
        }
      </FormGroup>
    </Form>
  )
}

export default ConsentForm