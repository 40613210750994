import React, { useEffect } from 'react'
import {
  Row, Col,
  Form, FormGroup,
  Label, Input, Button, Table,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import { Multiselect } from 'react-widgets'
import { toast, ToastContainer } from 'react-toastify'
import _ from 'lodash'
import Moment from 'moment'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import TreatmentComponent from './TreatmentComponent'
import PaymentView from './Payment'

const ListItem = ({ item }) => (
  <span>
    { `${ item.name } - ${ item.nric_no } - ${ item.contact_number }` }
  </span>
)

const RefundForm  = ({
  data,
  refundType,
  currentRefund,
  onChangeRefundsHOC,
  patients,
  getPatients,
  onChangePatientsHOC,
  onLoadPatientsHOC,
  patientsSearchParam,
  selectedInvoice,
  getSelectedInvoice,
  onChangeInvoiceHOC,
  patientInvoices,
  getInvoiceByPatientId,
  medicines,
  getMedicines,
  products,
  getProducts,
  createRefund,
  selectedInvoicePayment,
  getSelectedInvoicePayment,
  onLoadRefundsHOC,
  onLoadInvoicesHOC,
  selectedCaUser,
  requestError,
  updateRefund
}) => {
  useEffect( () => {
    if( currentRefund.patient_id > 0 && refundType === 'newRefund' ){
      getInvoiceByPatientId( currentRefund.patient_id )
    } else if( currentRefund.patient_id === 0 && refundType === 'newRefund' ){
      onChangeInvoiceHOC( 'patientInvoices', [] )
    }
  }, [ currentRefund.patient_id ] )

  useEffect( () => {
    if( currentRefund.invoice_id > 0 ){
      Promise.all([
        getMedicines(),
        getProducts()
      ]).then( () => {
        getSelectedInvoice( currentRefund.invoice_id )
      })
    }
  }, [ currentRefund.invoice_id ] )

  useEffect( () => {
    if( currentRefund.payment_id > 0 && currentRefund.invoice_id > 0 ){
      getSelectedInvoicePayment( currentRefund.invoice_id, currentRefund.payment_id )
    }
  }, [ currentRefund.payment_id ] )

  const updateCurrentRefund = ( key, val ) => {
    let tmp = _.cloneDeep( currentRefund )
    tmp[ key ] = val
    if( key === 'patient_id' ){
      tmp.invoice_id = 0
      tmp.payment_id = 0
      tmp.amount = 0
      Promise.all([
        onChangeInvoiceHOC( 'selectedInvoice', {} ),
        onChangeInvoiceHOC( 'selectedInvoicePayment', {} )
      ])
    } else if ( key === 'invoice_id'  ) {
      tmp.payment_id = 0
      tmp.amount = 0
      onChangeInvoiceHOC( 'selectedInvoicePayment', {} )
    } else if ( key ===  'payment_id' ) {
      tmp.amount = 0
    }
    onChangeRefundsHOC( refundType, tmp )
  }

  const getAmountDue = () => {
    let tmpTotalAmountDue = 0
    selectedInvoice.invoice_treatments.map( item => {
      tmpTotalAmountDue = tmpTotalAmountDue + parseFloat( item.formated_price )
    }) 
    selectedInvoice.invoice_medicines.map( item => {
      tmpTotalAmountDue = tmpTotalAmountDue + ( parseFloat( item.quantity ) * parseFloat( item.price_per_unit ) )
    })
    selectedInvoice.invoice_products.map( item => {
      tmpTotalAmountDue = tmpTotalAmountDue + ( item.quantity * parseFloat( item.price_per_unit ) )
    })
    selectedInvoice.payments.map( item => {
      tmpTotalAmountDue = tmpTotalAmountDue - item.current_cost
    })
    return parseFloat( tmpTotalAmountDue ).toFixed( 2 )
  }

  const renderRefundTypeModal = () => <Modal size='lg' isOpen={ !_.isEmpty( selectedInvoicePayment ) && refundType === 'newRefund' }>
    <ModalHeader 
      toggle={ () => {
        updateCurrentRefund( 'payment_id', 0 )
        onChangeInvoiceHOC( 'selectedInvoicePayment', {} ) }}>
      Create Refund</ModalHeader>
    <ModalBody>
      <Form>
        <PaymentView
          data={ data }
          selectedInvoicePayment={ selectedInvoicePayment }
          selectedCaUser={ selectedCaUser }
          currentRefund={ currentRefund }
          refundType={ refundType }
          updateCurrentRefund={ updateCurrentRefund }
          requestError={ requestError } />
      </Form>
      <ToastContainer position={ toast.POSITION.BOTTOM_RIGHT } style={{ zIndex: 9999 }} />
      { ( onLoadRefundsHOC || onLoadInvoicesHOC ) && <LoadingOverlay/> }
    </ModalBody>
    <ModalFooter>
      <Button 
        color='primary'
        onClick={ () => { 
          let tmp = _.cloneDeep( currentRefund )
          switch ( currentRefund.type_id ){
            case 1:
              tmp.amount = selectedInvoicePayment.current_paid_amount
              createRefund( tmp )
              break
            case 2:
              if( currentRefund.amount <= 0 ){
                requestError({ message: 'Please enter a refund amount' })
              } else if( parseFloat( currentRefund.amount ) > parseFloat( selectedInvoicePayment.current_paid_amount ) ){
                requestError({ message: 'Please enter a refund amount smaller or requal to current paid amount' })
              } else {
                createRefund( tmp )
              }
              break 
          }
        }} >
        Submit</Button>
    </ModalFooter>
  </Modal>

  return(
    <Form>
      {
        refundType === 'newRefund' ? (
          <FormGroup>
            <Label>Patient</Label>
            <div className="d-flex mb-2">
              <Input 
                type="text" 
                value={ patientsSearchParam }
                className='mr-2'
                onChange={ e => onChangePatientsHOC( 'patientsSearchParam', e.target.value ) }
                placeholder="Fill in patient NRIC or name here and click search button" />
              <Button 
                color="primary" 
                onClick={() => {
                  getPatients( patientsSearchParam )
                  updateCurrentRefund( 'patient_id', 0 ) }}>
                Search</Button>
            </div>
            {
              onLoadPatientsHOC 
                ? (
                  <p>Loading patients......</p>
                ) : <>
                  <Multiselect
                    data={ patients }
                    textField={ 'name' }
                    itemComponent={ ListItem }
                    value={ currentRefund.patient_id > 0 
                      ? _.find( patients, { id: parseInt( currentRefund.patient_id ) } ) 
                        ? [ _.find( patients, { id: parseInt( currentRefund.patient_id ) } ) ] 
                        : [ {} ]
                      : [] }
                    onChange={ val => val.length === 1 
                      ? updateCurrentRefund( 'patient_id', val[ 0 ].id )
                      : updateCurrentRefund( 'patient_id', 0 )
                    } />
                </>
            }
          </FormGroup>
        ) : (
          <PaymentView
            data={ data }
            selectedInvoicePayment={ selectedInvoicePayment }
            selectedCaUser={ selectedCaUser }
            currentRefund={ currentRefund }
            refundType={ refundType }
            updateCurrentRefund={ updateCurrentRefund }
            updateRefund={ updateRefund }
            requestError={ requestError } />
        )
      }
      {
        patientInvoices.length > 0 && <FormGroup>
          <Label>Invoice number</Label>
          <Input
            type='select'
            value={ currentRefund.invoice_id }
            disabled={ refundType === 'selectedRefund' }
            onChange={ e => updateCurrentRefund( 'invoice_id', parseInt( e.target.value ) ) }>
            <option value={ 0 } ></option>
            { patientInvoices.map( invoice => <option value={ invoice.id } >{ invoice.invoice_number }</option> ) }
          </Input>
        </FormGroup>
      }
      {
        !_.isEmpty( selectedInvoice ) && <>
          <FormGroup>
            <p><b className="mb-3">Selected treatment(s):</b></p>
            {
              selectedInvoice.invoice_treatments.map( item => {
                return (
                  <TreatmentComponent  
                    tabType='Invoice' 
                    key={ item.id }
                    item={ item } />
                )
              })
            }
          </FormGroup>
          <FormGroup>
            <p><b>Selected medicine(s):</b></p>
            <Table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Quantity</td>
                  <td>Price per unit</td>
                  <td>Total price</td>
                </tr>
              </thead>
              <tbody>
              {
                selectedInvoice.invoice_medicines.map(( item, index ) => {
                  let tmp = _.find( medicines, { id: item.medicine_id } )
                  return (
                    <tr key={ `Medicine-${ item.id }` }>
                      <td>{ item.medicine_name }</td>
                      <td>{ item.quantity }</td>
                      <td>{ item.price_per_unit }</td>
                      <td>{ tmp ? ( item.price_per_unit * item.quantity ) : 0 }</td>
                    </tr>
                  )
                })
              }
              </tbody>
            </Table>
          </FormGroup>
          <FormGroup>
            <p><b>Selected product(s):</b></p>
            <Table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Quantity</td>
                  <td>Price</td>
                </tr>
              </thead>
              <tbody>
              {
                selectedInvoice.invoice_products.map( item => {
                  let tmp = _.find( products, { id: item.product_id } )
                  return (
                    <tr key={ `InvoiceProduct-${ item.id }` }>
                      <td>{ item.product_name }</td>
                      <td>{ item.quantity }</td>
                      <td>{ item.price_per_unit }</td>
                    </tr>
                  )
                })
              }
              </tbody>
            </Table>
          </FormGroup>
          <hr />
          <FormGroup>
            <Row>
              <Col md={ 6 }>
                <span>Total amount</span>
              </Col>
              <Col md={ 3 }></Col>
              <Col md={ 3 }>
                <Input
                  type="number"
                  value={ selectedInvoice.total_amount }
                  disabled={ true } />
              </Col>
              <Col md={ 12 }>
                <hr />
              </Col>
              <Col md={ 6 }>
                <span style={{ fontWeight: '600' }}>Lab costs:</span>
              </Col>
              <Col md={ 12 }>
                <hr />
                <p><b>Payment history</b></p>
              </Col>
              <Col md={ 12 }>
                <Table>
                  <thead>
                    <tr>
                      <td>Transaction date</td>
                      <td>Pay with</td>
                      <td>Amount</td>
                      <td>Cost</td>
                      <td>Change</td>
                      <td>Refunded amount</td>
                      { refundType === 'newRefund' && <td>Action</td> }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      selectedInvoice.payments.map( item => {
                        return (
                          <tr key={ item.id }>
                            <td>{ Moment( item.created_at ).format( 'DD MMM YYYY HH:mm' ) }</td>
                            <td>{ item.pay_with }</td>
                            <td>{ item.current_paid_amount }</td>
                            <td>{ item.current_cost }</td>
                            <td>{ item.change }</td>
                            <td>{ item.amount_refunded }</td>
                            {
                              refundType === 'newRefund' && <td>
                                <Button 
                                  color="primary"
                                  disabled={ parseFloat( item.amount_refunded ) > 0 }
                                  style={{ opacity: parseFloat( item.amount_refunded ) > 0 ? "0" : "1" }}
                                  onClick={() => updateCurrentRefund( 'payment_id', parseInt( item.id ) ) }>
                                  Refund</Button>
                              </td>
                            }
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              </Col>
            </Row>
            <hr />
            <Row className="mt-3">
              <Col md={ 6 }>
                <b>Amount due</b>
              </Col>
              <Col md={ 3 }></Col>
              <Col md={ 3 }>
                <Input
                  disabled
                  type="number"
                  value={ getAmountDue() } />
              </Col>
            </Row>
          </FormGroup>
        </>
      }
      { renderRefundTypeModal() }
    </Form>
  )
}

export default RefundForm