import React, { Component } from 'react'
import { toast } from 'react-toastify'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      roles: [],
      newRole: {
        name: ''
      },
      selectedRole: {},
      showCreateRole: false,
      showUpdateRole: false,
      loading: false,
      roleError: {}
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeRolesHOC = ( key, val ) => this.setState({ [ key ]: val })

    getRoles = () => Get(
      `/roles`,
      this.getRolesSuccess,
      this.getRolesError,
      this.load
    )
    getRolesSuccess = payload => this.setState({ roles: payload })
    getRolesError = error => this.requestError( error )

    getSelectedRole = id => Get(
      `/roles/${ id }`,
      this.getSelectedRoleSuccess,
      this.getSelectedRoleError,
      this.load
    )
    getSelectedRoleSuccess = payload => this.setState({ selectedRole: payload, showUpdateRole: true })
    getSelectedRoleError = error => this.requestError( error )

    createRole = data => Post(
      `/roles`,
      data,
      this.createRoleSuccess,
      this.createRoleError,
      this.load
    )
    createRoleSuccess = payload => {
      this.requestSuccess( 'Role is created successfully.' )
      this.setState({ showCreateRole: false, newRole: { name: '' } })
      this.getRoles()
    }
    createRoleError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ roleError: error })
      }
    }

    updateRole = data => Put(
      `/roles/${ data.id }`,
      data,
      this.updateRoleSuccess,
      this.updateRoleError,
      this.load
    )
    updateRoleSuccess = payload => {
      this.requestSuccess( 'Role is updated successfully.' )
      this.setState({ showUpdateRole: false, selectedRole: {} })
      this.getRoles()
    }
    updateRoleError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ roleError: error })
      }
    }

    render = () => {
      return (
        <>
          <WrappedComponent 
            { ...this.props }
            roles={ this.state.roles }
            newRole={ this.state.newRole }
            selectedRole={ this.state.selectedRole }
            showCreateRole={ this.state.showCreateRole }
            showUpdateRole={ this.state.showUpdateRole }
            onLoadRoles={ this.state.loading }
            onChangeRolesHOC={ this.onChangeRolesHOC }
            getRoles={ this.getRoles }
            getSelectedRole={ this.getSelectedRole }
            createRole={ this.createRole }
            updateRole={ this.updateRole } />
        </>
      )
    }
  }
  return WithHOC
}

export default HOC