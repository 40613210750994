import React, { useState } from 'react'
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label
} from 'reactstrap'
import _ from 'lodash'

import CurrencySettings from 'utils/currencies'

const TreatmentComponent = ({
  tabType,
  item,
}) => {
  const [ collapseTreatmentType, toggleCollapseTreatmentType ] = useState( false )
  const [ collapseTreatmentName, toggleCollapseTreatmentName ] = useState( false )

  return (
    <Card className='mb-2' key={ item.id }>
      <CardBody>
        <div>
          <span className="mr-3">{ item.treatment_name }</span>
          <span className="mr-3">{ `${ CurrencySettings() }${ tabType === 'General' ? item.price : item.formated_price }` }</span>
          {
            tabType === 'Invoice' && <Button 
              color="primary"
              className="mr-2"
              onClick={() => {
                toggleCollapseTreatmentName( !collapseTreatmentName )
              }}>
              Toggle receipt name
            </Button>
          }
          <Button 
            color="primary"
            className="mr-2"
            onClick={() => {
              toggleCollapseTreatmentType( !collapseTreatmentType )
            }}>
            Toggle treatment type
          </Button>
        </div>
        {
          collapseTreatmentType && (
            <>
              <hr />
              <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <FormGroup>
                  <div className="d-flex align-items-center pb-2">
                    <Label>List of treatment types</Label>
                  </div>
                  {
                    item.types && item.types.map( type => {
                      return (
                        <div className="d-flex mb-2">
                          <Input
                            className="mr-2"
                            type="text"
                            value={ type.content }
                            disabled />
                        </div>
                      )
                    })
                  }
                </FormGroup>
              </div>
            </>
          )
        }
        {
          collapseTreatmentName && (
            <>
              <hr />
              <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <FormGroup>
                  <Label>Receipt Name</Label>
                  <div className='d-flex'>
                    <Input
                      type='text'
                      className='mr-2'
                      value={ item.name }
                      disabled />
                  </div>
                </FormGroup>
              </div>
            </>
          )
        }
      </CardBody>
    </Card>
  )
}

export default TreatmentComponent