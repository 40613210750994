import React, { Component } from 'react'
import {
  DropdownToggle, DropdownMenu,
  Nav, Button, NavItem, 
  UncontrolledButtonDropdown
} from 'reactstrap'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Cookies from 'js-cookie'
import { purgeStoredState } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { compose } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import SetOffDay from 'containers/Dashboard/UserSettings/SetOffDay'
import ModalResetPassword from 'containers/Dashboard/UserSettings/ResetPassword'

import avatar1 from 'assets/Images/av1.png'
import city3 from 'assets/TemplateImages/dropdown-header/city3.jpg'

import WithRoles from 'actions/dictionary/roles'

class Userbox extends Component {
  state = {
    showSetOffDay: false,
    showResetPassword: false
  }

  componentDidMount = () => this.props.getRoles()

  onClickSignOut = () => {
    purgeStoredState({
      key: 'project-smile-web',
      storage
    }).then(() => {
      Cookies.remove( 'PRIMEVIEW_WEB_TOKEN' )
      this.props.history.push( '/' )
    })
  }

  checkRole = () => (
    <Button 
      block 
      className="mb-2 mr-2 btn-shadow btn-gradient-primary"
      onClick={() => {
        this.setState({ showSetOffDay: true })
      }}>
      Set off day
      { this.props.data.profileReducer.profile.current_user.role.name !== 'Doctor' ? ' for doctor' : '' }
    </Button>
  )

  render = () => {
    return (
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  <img width={ 42 } className="rounded-circle" src={ avatar1 } alt="" />
                  <FontAwesomeIcon className="ml-2 opacity-8" icon={ faAngleDown } />
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-info">
                      <div className="menu-header-image opacity-2" style={{ backgroundImage: 'url(' + city3 + ')' }} />
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <img width={42} className="rounded-circle" src={ avatar1 } alt="" />
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading">{ this.props.data.profileReducer.profile.current_user.name }</div>
                              <div className="widget-subheading opacity-8">{ this.props.data.profileReducer.profile.current_user.email }</div>
                            </div>
                            <div className="widget-content-right mr-2">
                              <Button className="btn-pill btn-shadow btn-shine" color="focus" onClick = { () => this.onClickSignOut() }>Logout</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="scroll-area-xs" style={{ height: '300px' }}>
                      <PerfectScrollbar>
                        <Nav vertical>
                          <NavItem className="p-3">
                            {
                              !_.isEmpty( this.props.data.profileReducer.profile.current_user.role ) && (
                                <p style={{ color: '#000' }}>{ `Role: ${ this.props.data.profileReducer.profile.current_user.role.name }` }</p>
                              )
                            }
                            {
                              this.props.data.profileReducer.profile.current_user.branches && this.props.data.profileReducer.profile.current_user.branches.length > 0 && (
                                <div className="mb-3" style={{ color: '#000' }}>
                                  <span>Assigned branches:</span>
                                  <ul>
                                  {
                                    this.props.data.profileReducer.profile.current_user.branches.map( item => {
                                      return (
                                        <li key={ item.id }>{ item.name }</li>
                                      )
                                    })
                                  }
                                  </ul>
                                </div>
                              )
                            }
                            {
                              !_.isEmpty( this.props.data.profileReducer.profile ) && !this.props.onLoadRoles && this.checkRole()
                            }
                            <Button 
                              color="primary"
                              block 
                              className="mb-2 mr-2 btn-shadow btn-gradient-primary"
                              onClick={() => {
                                this.setState({ showResetPassword: true })
                              }}>Change password</Button>
                          </NavItem>
                        </Nav>
                      </PerfectScrollbar>
                    </div>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
        <SetOffDay 
          showSetOffDay={ this.state.showSetOffDay } 
          hideSetOffDay={() => this.setState({ showSetOffDay: false })}
          { ...this.props } />
        <ModalResetPassword
          showResetPassword={ this.state.showResetPassword }
          hideResetPassword={() => this.setState({ showResetPassword: false })}
          { ...this.props } />
      </div>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default compose(
  connect( mapStateToProps ),
  WithRoles
)( Userbox )