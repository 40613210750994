import React, { useState } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import _ from 'lodash'
import ReactTable from 'react-table'

const PatientListings = props => {
  return (
    <Modal
      isOpen={ props.showPatients }
      size={ 'lg' }>
      <ModalHeader toggle={() => props.onChangePatientsHOC( 'showPatients', false )}>Patients treated by selected doctor</ModalHeader>
      <ModalBody>
        <ReactTable
          data={ props.patients }
          columns={[
            {
              Header: 'Name',
              accessor: 'name'
            },
            {
              Header: 'IC',
              accessor: 'nric_no'
            },
            {
              Header: 'Contact',
              accessor: 'contact_number'
            },
            {
              Header: 'Address',
              accessor: 'address'
            }
          ]} />
      </ModalBody>
    </Modal>
  )
}

export default PatientListings