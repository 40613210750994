import React, { useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import _ from 'lodash'
import { compose } from 'redux'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Typeahead from 'components/Typeahead'

import WithCountries from 'actions/dictionary/countries'
import WithStates from 'actions/dictionary/states'
import WithCities from 'actions/dictionary/cities'

const Update = ({
  showUpdateBranch,
  onChangeBranchesHOC,
  selectedBranch,
  updateBranch,
  onLoadBranches,
  onLoadCountries,
  countries,
  getCountries,
  onLoadStates,
  states,
  getStates,
  onLoadCities,
  cities,
  getCities
}) => {

  useEffect(() => {
    if( showUpdateBranch ) {
      getCountries()
      getStates( selectedBranch.country )
      getCities( selectedBranch.country, selectedBranch.state )
    }
  }, [ showUpdateBranch ])

  const onChangeBranchData = ( key, val ) => {
    let tmp = _.cloneDeep( selectedBranch )
    tmp[ key ] = val
    return onChangeBranchesHOC( 'selectedBranch', tmp )
  }

  return (
    <Modal
      isOpen={ showUpdateBranch }
      size={ 'md' }
      toggle={() => onChangeBranchesHOC( 'showUpdateBranch', false )}>
      <ModalHeader toggle={() => onChangeBranchesHOC( 'showUpdateBranch', false )}>Update branch</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              value={ selectedBranch.name }
              onChange={ e => {
                onChangeBranchData( 'name', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>Address</Label>
            <Input
              type="text"
              value={ selectedBranch.address }
              onChange={ e => {
                onChangeBranchData( 'address', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>Country</Label>
            <Typeahead
              id={ 'country' }
              multiple={ false }
              options={ countries }
              onSelect={ val => {
                if( val && val.length > 0 ) {
                  onChangeBranchData( 'country', val[ 0 ].code )
                  getStates( val[ 0 ].code )
                } else {
                  onChangeBranchData( 'country', '' )
                }
              }}
              selectedValue={ _.find( countries, { id: selectedBranch.country } ) ? [ _.find( countries, { id: selectedBranch.country }) ] : [] } />
          </FormGroup>
          <FormGroup>
            <Label>State</Label>
            <Input
              type="select"
              value={ selectedBranch.state }
              onChange={ e => {
                onChangeBranchData( 'state', e.target.value )
                getCities( selectedBranch.country, e.target.value )
              }}>
              <option></option>
              {
                states && states.map( item => {
                  return <option key={ item.code } value={ item.code }>{ item.name }</option>
                })
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Postcode</Label>
            <Input
              type="text"
              value={ selectedBranch.postcode }
              onChange={ e => {
                onChangeBranchData( 'postcode', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>City</Label>
            <Input
              type="text"
              value={ selectedBranch.city }
              onChange={ e => {
                onChangeBranchData( 'city', e.target.value )
              }}>
              <option></option>
              {
                cities && cities.map( item => {
                  return <option key={ item.code } value={ item.code }>{ item.code }</option>
                })
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Contact number*</Label>
            <Input
              type="text"
              value={ selectedBranch.contact_number }
              onChange={ e => {
                onChangeBranchData( 'contact_number', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>Email*</Label>
            <Input
              type="text"
              value={ selectedBranch.email }
              onChange={ e => {
                onChangeBranchData( 'email', e.target.value )
              }} />
          </FormGroup>
        </Form>
        { ( onLoadBranches || onLoadCountries || onLoadStates || onLoadCities ) && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary" 
          onClick={() => {
            updateBranch( selectedBranch )
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default compose(
  WithCountries,
  WithStates,
  WithCities
)( Update )