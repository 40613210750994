import React, { Component } from 'react'
import { 
  Row, 
  Col,
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'
import { compose } from 'redux'
import _ from 'lodash'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Pagination from 'components/Pagination'
import CreateMedicalCert from './Create'
import UpdateMedicalCert from './Update'
import SearchForm from './SearchForm'

import WithMedicalCerts from './actions'
import WithPatients from './actions/Patients'
import WithDoctors from 'actions/dictionary/doctors'

class MedicalCerts extends Component {

  componentDidMount = () => {
    this.props.getMedicalCerts( '', 1 )
    this.props.getDoctors( this.props.data.profileReducer.profile.current_user.branch_ids )
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading="Medical certs"
          subheading="Listings of all the medical certs presence in the system."
          icon="pe-7s-note2 icon-gradient bg-happy-itmeo"
          buttons={[
            {  
              color: 'primary',
              onClick: () => this.props.onChangeMedicalCertHOC( 'showCreateMedicalCert', true ),
              content: 'Create medical cert'
            }
          ]} />
        <div>
          <Row>
            <Col md={ 12 }>
              <SearchForm { ...this.props } />
            </Col>
          </Row>
          <Row>
            <Col md={ 12 }>
              <Card className="main-card mb-3">
                <CardHeader>{ this.props.searchString }</CardHeader>
                <CardBody>
                  <ReactTable
                    data={ this.props.medicalCerts.data }
                    columns={[
                      {
                        Header: 'Ref no.',
                        accessor: 'id'
                      },
                      {
                        Header: 'Patient name',
                        accessor: 'patient_name'
                      },
                      {
                        Header: 'Patient nric',
                        accessor: 'patient_nric'
                      },
                      {
                        Header: 'Doctor name',
                        accessor: 'doctor_name'
                      },
                      {
                        Header: 'Company name',
                        accessor: 'company_name'
                      },
                      {
                        Header: 'Branch name',
                        accessor: 'branch_name'
                      },
                      {
                        Header: 'Actions',
                        style: {
                          "overflow": "scroll" 
                        },
                        Cell: ( row ) => (
                          <>
                            <Button
                              className="mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={() => {
                                this.props.getSelectedMedicalCert( row.original.id )
                                this.props.getPatients( row.original.patient_name )
                              }}>
                              <i className="pe-7s-pen btn-icon-wrapper"> </i>
                            </Button>
                            <Button
                              className="btn-icon btn-icon-only"
                              color="success"
                              onClick={() => {
                                this.props.downloadPDF( row.original.id )
                              }}>
                              Download
                            </Button>
                          </>
                        )
                      }
                    ]}
                    defaultPageSize={ 10 }
                    showPagination={ false } />
                  <Pagination 
                    metadata={ this.props.medicalCerts.pagy } 
                    onChangePage={ pg => {
                      let tmp = ''
                      this.props.searchParams.map( item => {
                        tmp = tmp + `&${ item.key }=${ item.value }`
                      })
                      this.props.getMedicalCerts( tmp, pg )
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <CreateMedicalCert { ...this.props } />
        <UpdateMedicalCert { ...this.props } />
        { this.props.onLoadMedicalCerts && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose ( 
  WithMedicalCerts,
  WithPatients,
  WithDoctors
)( MedicalCerts )