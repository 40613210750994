import React from 'react'
import Moment from 'moment'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const Overview = props => {
  return (
    <VerticalTimeline
      animate={ false }>
      {
        props.medicalRecords.map( item => {
          return (
            <VerticalTimelineElement
              key={ item.id }
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'rgb(255, 255, 255, 0.4)', color: '#000', border: '1px solid #000' }}
              contentArrowStyle={{ borderRight: '7px solid rgb(33, 150, 243)' }}
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              icon={ <i className="pe-7s-note2" style={{ fontSize: '24px' }} /> }>
              <p>{ `Date: ${ Moment( item.created_at ).format( 'YYYY-MM-DD HH:mm' ) }` }</p>
              <p>{ `Complaints: ${ item.complaints }` }</p>
              <p>{ `Observations: ${ item.observations }` }</p>
              <p>{ `Notes: ${ item.notes }` }</p>
            </VerticalTimelineElement>
          )
        })
      }
    </VerticalTimeline>
  )
}

export default Overview