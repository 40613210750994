import React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { Button } from 'reactstrap'

const PageTitle = ({
  enablePageTitleIcon,
  enablePageTitleSubheading,
  icon,
  heading,
  subheading,
  buttons
}) => {
  return (
    <div className="app-page-title">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          <div className={ cx("page-title-icon", {'d-none': !enablePageTitleIcon}) }>
            <i className={ icon }/>
          </div>
          <div>
            { heading }
            <div className={ cx("page-title-subheading", {'d-none': !enablePageTitleSubheading}) }>
              { subheading }
            </div>
          </div>
        </div>
        <div className="page-title-actions">
          {
            buttons && buttons.map( item => {
              return (
                <Button color={ item.color } onClick={ item.onClick } className={ item.className }>{ item.content }</Button>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
})

export default connect( mapStateToProps )( PageTitle )