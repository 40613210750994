import React, { Component } from 'react'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'
import { connect } from 'react-redux'

import { Get, Post, Put } from 'utils/axios'
import getDomainURL from 'utils/api'

import CurrencySettings from 'utils/currencies'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newInvoice: {
        company_id: 0, // got
        branch_id: 0, // got
        user_id: 0, //doctor id
        total_amount: 0, 
        medical_record_id: 0,
        invoice_products_attributes: [],
        invoice_medicines_attributes: [],
        invoice_treatments_attributes: [],
        patient_id: 0, // got
        is_reload_credit_advance: true
      },
      selectedInvoice: {},
      newCreditAdvance: {
        currency: CurrencySettings(),
        amount: 0, // got
        id: 0 // invoice
      },
      newPayment: {
        customer_name: '',
        customer_address: '',
        customer_ic: '',
        current_paid_amount: 0,
        current_cost: 0,
        change: 0,
        pay_with: '',
        id: 0 // invoice_id
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeInvoiceHOC = ( key, val ) => this.setState({ [key]: val })
    onChangeInvoice = data => this.setState({ newInvoice: data })
    onChangePayment = data => this.setState({ newPayment: data })

    createInvoice = data => Post(
      `/invoices`,
      data,
      this.createInvoiceSuccess,
      this.createInvoiceError,
      this.load
    )
    createInvoiceSuccess = payload => {
      this.requestSuccess( 'Invoice is created successfully.' )
      this.setState({ 
        selectedInvoice: payload,
        newCreditAdvance: {
          ...this.state.newCreditAdvance,
          id: payload.id
        },
        newPayment: {
          ...this.state.newPayment,
          id: payload.id
        }
      }, () => {
        this.addCreditAdvance( this.state.newCreditAdvance )
      })
    }
    createInvoiceError = error => this.requestError( error )

    addCreditAdvance = data => Post(
      `/invoices/${ data.id }/add_credit_advance`,
      data,
      this.addCreditAdvanceSuccess,
      this.addCreditAdvanceError,
      this.load
    )
    addCreditAdvanceSuccess = payload => {
      this.requestSuccess( 'Credit advance is created successfully.' )
      this.createPayment( this.state.newPayment )
    }
    addCreditAdvanceError = error => this.requestError( 'Failed to add credit advance.' )

    createPayment = ( data ) => Post(
      `/invoices/${ data.id }/payments`,
      data,
      this.createPaymentSuccess,
      this.createPaymentError,
      this.load
    )
    createPaymentSuccess = payload => {
      this.requestSuccess( 'Payment is created successfully.' )
      this.downloadPDF( this.state.selectedInvoice.id )
    }
    createPaymentError = error => {
      this.requestError( 'Failed to process payment.' )
    }

    downloadPDF = invoice_id => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/invoices/${ invoice_id }/download`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( blobby => {
        this.load( false )
        this.setState({
          newInvoice: {
            company_id: 0, // got
            branch_id: 0, // got
            user_id: 0, //doctor id
            total_amount: 0, 
            medical_record_id: 0,
            invoice_products_attributes: [],
            invoice_medicines_attributes: [],
            invoice_treatments_attributes: [],
            patient_id: 0 // got
          },
          selectedInvoice: {},
          newCreditAdvance: {
            currency: CurrencySettings(),
            amount: 0, // got
            id: 0 // invoice
          },
          newPayment: {
            customer_name: '',
            customer_address: '',
            customer_ic: '',
            current_paid_amount: 0,
            current_cost: 0,
            change: 0,
            pay_with: '',
            id: 0 // invoice_id
          }
        })
        this.props.updateOpenReloadModal( false )
        this.props.getSelectedPatient( this.props.selectedPatient.id )
        return FileSaver.saveAs( blobby, 'Receipt.pdf' )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save receipt. Please try again.' )
      })
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadInvoices={ this.state.loading }
          newInvoice={ this.state.newInvoice }
          newPayment={ this.state.newPayment }
          newCreditAdvance={ this.state.newCreditAdvance }
          onChangeInvoiceHOC={ this.onChangeInvoiceHOC }
          onChangeInvoice={ this.onChangeInvoice }
          onChangePayment={ this.onChangePayment }
          createInvoice={ this.createInvoice } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC