import React, { useEffect } from 'react'
import { 
  Card, CardBody,
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import { compose } from 'redux'
import ReactTable from 'react-table'
import _ from 'lodash'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PromptModal from 'components/Indicator/Prompt'
import SearchForm from './components/SearchForm'
import Refund from './components/RefundForm'

import WithRefunds from './actions'
import WithPatients from './actions/Patients'
import WithInvoices from './actions/Invoices'
import WithMedicines from 'actions/dictionary/medicines'
import WithProducts from 'actions/dictionary/products'

const Refunds = props => {
  useEffect(() => {
    props.reloadNewRefund()
  }, [])

  useEffect(() => {
    if( props.showCreateRefund === false ){
      props.reloadInvoice()
    }
  }, [ props.showCreateRefund ] )

  useEffect(() => {
    if( props.showUpdateRefund === false ){
      props.reloadInvoice()
    }
  }, [ props.showUpdateRefund ] )

  const renderCreateModal = () => (
    <Modal size='lg' isOpen={ props.showCreateRefund }>
      <ModalHeader 
        toggle={ () => props.reloadNewRefund() }>
        Create Refund</ModalHeader>
      <ModalBody>
        <Refund
          refundType='newRefund'
          currentRefund={ props.newRefund }
          { ...props } />
        { 
          ( 
            props.onLoadRefundsHOC || 
            props.onLoadPatientsHOC ||
            props.onLoadInvoicesHOC || 
            props.onLoadMedicines || 
            props.onLoadProducts
          ) && <LoadingOverlay/> 
        }
      </ModalBody>
      <ModalFooter>
        <Button 
          color='primary'
          onClick={ () => props.reloadNewRefund() } >
          Close</Button>
      </ModalFooter>
    </Modal>
  )

  const renderViewModal = () => (
    <Modal size='lg' isOpen={ props.showUpdateRefund }>
      <ModalHeader 
        toggle={ () => props.reloadSelectedRefund() }>
        Update Refund</ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedRefund ) && <Refund
            refundType='selectedRefund'
            currentRefund={ props.selectedRefund }
            { ...props } />
        }
        { 
          ( 
            props.onLoadRefundsHOC || 
            props.onLoadPatientsHOC ||
            props.onLoadInvoicesHOC || 
            props.onLoadMedicines || 
            props.onLoadProducts
          ) && <LoadingOverlay/> 
        }
      </ModalBody>
      <ModalFooter>
        <Button 
          color='primary'
          onClick={ () => props.reloadSelectedRefund() } >
          Close</Button>
      </ModalFooter>
    </Modal>
  )
  
  return(
    <>
      <PageTitle
        heading="Refunds"
        subheading="Listings of all the refunds."
        icon="pe-7s-clock icon-gradient bg-happy-itmeo"
        buttons={[
          {  
            color: 'primary',
            onClick: () => props.onChangeRefundsHOC( 'showCreateRefund', true ),
            content: 'Create refund'
          }
        ]} />
      <Card className="main-card mb-3">
        <CardBody>
          <SearchForm { ...props } />
          <ReactTable
            showPagination={ false }
            pageSize={ 20 }
            data={ props.refunds }
            columns={[
              {
                Header: 'Patient',
                accessor: 'patient',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Invoice Number',
                accessor: 'invoice',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Payment date',
                accessor: 'payment_date',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Amount refunded',
                accessor: 'refund_amount',
                style: { "line-break": "anywhere" },
                Cell: row => parseFloat( row.original.refund_amount ).toFixed( 2 )
              },
              {
                Header: 'Action',
                style:{ 
                  "justify-content": "center",
                  "overflow": "scroll" 
                },
                Cell: row => <>
                  <Button 
                    color='primary'
                    className='mr-2'
                    onClick={ () => props.getSelectedRefund( row.original.invoice_id, row.original.id ) }>
                    { 
                      [ 'Super Admin', 'Admin' ].indexOf( props.data.profileReducer.profile.current_user.role.name ) > -1
                        ? <i className='pe-7s-pen'/>
                        : <i className='pe-7s-look'/>
                    }
                  </Button>
                </>
              }
            ]}/>
        </CardBody>
      </Card>
      <PromptModal
        showPromptModal={ props.selectedRefundToDelete > 0 }
        onClickYes={ () => props.removeRefund( props.selectedRefundToDelete ) }
        onClickNo={ () => props.onChangeRefundsHOC( 'selectedRefundToDelete', 0 ) }
        content={ 'Are you sure you want to delete the record?' } />
      { renderCreateModal() }
      { renderViewModal() }
      { 
        ( props.onLoadRefundsHOC || props.onLoadPatientsHOC  ) && <LoadingOverlay/> 
      }
    </>
  )
}

export default compose(
  WithRefunds,
  WithPatients,
  WithInvoices,
  WithMedicines,
  WithProducts
)( Refunds )

