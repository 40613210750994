import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Post } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      cities: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getCities = ( country_code, state_code ) => Post(
      `/users/get_city`,
      {
        country_code,
        state_code
      },
      this.getCitiesSuccess,
      this.getCitiesError,
      this.load
    )
    getCitiesSuccess = payload => this.setState({ cities: payload })
    getCitiesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          getCities={ this.getCities }
          onLoadCities={ this.state.loading }
          cities={ this.state.cities } />
      )
    }
  }
  return WithHOC
}

export default HOC