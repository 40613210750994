import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      newUser: {
        name: '',
        ic: '',
        dob: '',
        gender: 1,
        country: '',
        language: 1,
        contact_number: '',
        remarks: '',
        role_id: 0,
        status: 'active',
        created_at: '',
        updated_at: '',
        company_ids: [],
        password: '',
        password_confirmation: ''
      },
      users: [],
      searchParams: {
        user_name: ''
      },
      selectedUser: {},
      showCreateUser: false,
      showUpdateUser: false,
      userError: {},
      loading: false
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeUsersHOC = ( key, val ) => this.setState({ [ key ]: val })

    getUsers = () => Get(
      `/users${ this.state.searchParams.user_name === '' ? '' : `?search=${ this.state.searchParams.user_name }` }`,
      this.getUsersSuccess,
      this.getUsersError,
      this.load
    )
    getUsersSuccess = payload => {
      let tmp = []
      payload.map( item => {
        if( this.props.data.profileReducer.profile.current_user.role.name !== 'Super Admin' ) {
          if( item.role.name !== 'Super Admin' ) {
            tmp.push( item )
          }
        } else {
          tmp.push( item )
        }
      })
      this.setState({ users: tmp })
    }
    getUsersError = error => this.requestError( error )

    getSelectedUser = id => Get(
      `/users/${ id }`,
      this.getSelectedUserSuccess,
      this.getSelectedUserError,
      this.load
    )
    getSelectedUserSuccess = payload => {
      delete payload.role
      this.setState({ selectedUser: {
        ...payload,
        date_of_birth: new Date( payload.date_of_birth ),
        password: '',
        password_confirmation: ''
      }, showUpdateUser: true })
    }
    getSelectedUserError = error => this.requestError( error )

    createUser = data => Post(
      `/users`,
      data,
      this.createUserSuccess,
      this.createUserError,
      this.load
    )
    createUserSuccess = payload => {
      this.requestSuccess( 'User is created successfully.' )
      this.setState({ 
        showCreateUser: false,
        newUser: {
          name: '',
          ic: '',
          dob: '',
          gender: 1,
          country: '',
          language: 1,
          contact_number: '',
          remarks: '',
          role_id: 0,
          status: 'active',
          created_at: '',
          updated_at: '',
          company_ids: [],
          password: '',
          password_confirmation: ''
        } 
      })
      this.getUsers()
    }
    createUserError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ userError: error })
      }
    }

    updateUser = data => Put(
      `/users/${ data.id }`,
      data,
      this.updateUserSuccess,
      this.updateUserError,
      this.load
    )
    updateUserSuccess = payload => {
      this.requestSuccess( 'User is updated successfully.' )
      this.setState({ showUpdateUser: true, selectedUser: {} })
      this.getUsers()
    }
    updateUserError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ userError: error })
      }
    }

    updatePhoto = data => Put(
      `/users/upload_photo`,
      data,
      this.updatePhotoSuccess,
      this.updatePhotoError,
      this.load
    )
    updatePhotoSuccess = payload => {
      this.requestSuccess( 'Photo is uploaded successfully.' )
      this.getSelectedUser( this.state.selectedUser.id )
    }
    updatePhotoError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ userError: error })
      }
    }

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            users={ this.state.users }
            newUser={ this.state.newUser }
            selectedUser={ this.state.selectedUser }
            showCreateUser={ this.state.showCreateUser }
            showUpdateUser={ this.state.showUpdateUser }
            onLoadUsers={ this.state.loading }
            searchParams = {this.state.searchParams }
            onChangeUsersHOC={ this.onChangeUsersHOC }
            getUsers={ this.getUsers }
            getSelectedUser={ this.getSelectedUser }
            createUser={ this.createUser }
            updateUser={ this.updateUser }
            updatePhoto={ this.updatePhoto } />
          <PromptModal
            showPromptModal={ this.state.showPromptModal }
            onClickYes={() => {}}
            onClickNo={() => this.setState({ showPromptModal: false })}
            content={ 'Are you sure you want to delete the record?' } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC