import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import Moment from 'moment'
import _ from 'lodash'

import { Get } from 'utils/axios'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={
      loading: false,
      patientBirthdays: [],
      searchParams: {
        company_id: 0,
        date: Moment().format( 'YYYY-M-D' )
      }
    }

    load = param => this.setState({ loading: param })

    onChangePatientBirthdaysHOC = ( key, val ) => this.setState({ [ key ] : val })

    getPatientBirthdays = searchParams => Get(
      `/patients/birthdays${ searchParams.company_id > 0 ? `?company_id=${ searchParams.company_id }&date=${ searchParams.date }` : '' }`,
      payload => this.getPatientBirthdaysSuccess( payload, searchParams ),
      this.getPatientBirthdaysError,
      this.load
    )
    getPatientBirthdaysSuccess = ( payload, searchParams ) => this.setState({ 
      patientBirthdays: payload, 
      searchParams: searchParams 
    })
    getPatientBirthdaysError = error => toast.error( 'Fail to retrieve patients\' birthday' )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadPatientBirthdaysHOC={ this.state.loading }
          patientBirthdays={ this.state.patientBirthdays }
          searchParams={ this.state.searchParams }
          onChangePatientBirthdaysHOC={ this.onChangePatientBirthdaysHOC }
          getPatientBirthdays={ this.getPatientBirthdays } />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC