import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Collapse,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'

import SmileDatepicker from 'components/Datepicker'

const SearchForm = ({
  searchParams,
  resetSearchParams,
  onChangeMcReportsHOC,
  getMcReports,
  data,
  downloadMcReportsPDF
}) => {
  const [ isCardOpen, updateIsCardOpen ] = useState( false )

  const updateSearchParams = ( key, val ) => {
    let tmpSearchParams = _.cloneDeep( searchParams )
    tmpSearchParams[ key ] = val
    onChangeMcReportsHOC( 'searchParams', tmpSearchParams )
  }
  
  return (
    <Card className="main-card mb-3">
      <CardHeader 
        id='search-bar-toggle'
        className='d-flex justify-content-between' 
        onClick={ () => updateIsCardOpen( !isCardOpen ) } >
        <Label className='m-0' >Search</Label>
        <i 
          className={ isCardOpen ? 'pe-7s-angle-up' : 'pe-7s-angle-down' }
          style={{ fontSize: "3rem", color: "#000" }} />
        <UncontrolledTooltip target='search-bar-toggle' placement="top-end">
          { isCardOpen ? 'Collapse' : 'Expand' }</UncontrolledTooltip>
      </CardHeader>
      <Collapse isOpen={ isCardOpen } >
        <CardBody>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <Label>{ 'Select a company' }</Label>
              <Input
                type="select"
                value={ searchParams[ 'company_id' ] }
                onChange={ e => updateSearchParams( 'company_id', parseInt( e.target.value ) ) }>
                <option value={ 0 } ></option>
                {
                  data.profileReducer.profile.companies.map( item => {
                    return <option key={ item.id } value={ item.id }>{ item.name }</option>
                  })
                }
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>{ 'Select branch' }</Label>
              <Input
                type="select"
                value={ searchParams[ 'branch_id' ] }
                onChange={ e => updateSearchParams( 'branch_id', parseInt( e.target.value ) ) }>
                <option value={ 0 }></option>
                {
                  _.filter( 
                    data.profileReducer.profile.current_user.role.name === 'Super Admin'
                      ? data.profileReducer.profile.branches
                      : data.profileReducer.profile.current_user.branches,
                    item => ( data.profileReducer.profile.current_user.role.name === 'Super Admin' ? item.company.id : item.company_id ) === searchParams[ 'company_id' ]
                  ).map( branch => <option key={ branch.id } value={ branch.id }>{ branch.name }</option> )
                }
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Start date</Label>
              <SmileDatepicker
                showTimeSelect={ false }
                selectedDate={ searchParams[ 'start_date' ] }
                onChange={ val => updateSearchParams( 'start_date', val ) }
                dateFormat="MMMM d, yyyy"
                disabled={ data.profileReducer.profile.current_user.role.name === 'Frontdesk' } 
              />
            </FormGroup>
            <FormGroup>
              <Label>End date</Label>
              <SmileDatepicker
                showTimeSelect={ false }
                selectedDate={ searchParams[ 'end_date' ] }
                onChange={ val => updateSearchParams( 'end_date', val ) }
                dateFormat="MMMM d, yyyy"
                disabled={ data.profileReducer.profile.current_user.role.name === 'Frontdesk' } 
              />
            </FormGroup>
            <div className="mt-4">
              <Button 
                color="primary"
                style={{ marginRight: '10px', display: 'inline-block' }}
                onClick={() => getMcReports( 
                    searchParams[ 'company_id' ],
                    searchParams[ 'branch_id' ],
                    Moment( searchParams[ 'start_date' ] ).format( 'YYYY-MM-DD' ), 
                    Moment( searchParams[ 'end_date' ] ).format( 'YYYY-MM-DD' ),
                  )
                }>
                { 'Search' }</Button>
              <Button 
                color="danger"
                style={{ marginRight: '10px', display: 'inline-block' }}
                onClick={ () => resetSearchParams() }>
                { 'Reset' }</Button>
              <Button
                color="primary"
                style={{ marginRight: '10px', display: 'inline-block' }}
                onClick={ () => downloadMcReportsPDF(
                  searchParams[ 'company_id' ],
                  searchParams[ 'branch_id' ],
                  Moment( searchParams[ 'start_date' ] ).format( 'YYYY-MM-DD' ), 
                  Moment( searchParams[ 'end_date' ] ).format( 'YYYY-MM-DD' ),
                )}>
                { 'Export report' }</Button>
            </div>
          </Form>
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default SearchForm