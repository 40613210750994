import React, { Component } from 'react'
import { Col, Row, Button, Label } from 'reactstrap'
import Slider from "react-slick"
import { connect } from 'react-redux'
import Axios from 'axios'
import {
  AvForm,
  AvGroup,
  AvInput
} from 'availity-reactstrap-validation'
import Cookies from 'js-cookie'

import MainFrame from 'components/Frame'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import bg1 from 'assets/TemplateImages/originals/city.jpg';
import bg2 from 'assets/TemplateImages/originals/citydark.jpg'
import bg3 from 'assets/TemplateImages/originals/citynights.jpg'
import PrimeViewTextIcon from 'assets/Images/textIcon.png'

import { authUser } from 'actions/auth'

const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true
}

class Login extends Component {
  state = {
    email: '',
    password: ''
  }

  componentDidUpdate = pp => {
    if( this.props.data.ajaxReducer.ajaxCallProgress !== pp.data.ajaxReducer.ajaxCallProgress ) {
      if( this.props.data.ajaxReducer.ajaxCallProgress === 0 ) {
        if( !Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) ) {
        } else {
          Axios.defaults.headers = {
            common : {
              Authorization: `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }`
            },
            'Access-Control-Allow-Origin': '*'
          }
          this.props.history.push( '/dashboard' )
        }
      }
    }
  }

  render = () => {
    return (
      <MainFrame>
        <div className="h-100">
          <Row className="h-100 no-gutters">
            <Col lg="4" className="d-none d-lg-block">
              <div className="slider-light">
                <Slider { ...SliderSettings }>
                  <div
                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div
                      className="slide-img-bg"
                      style={{ backgroundImage: 'url(' + bg1 + ')' }}/>
                    <div className="slider-content"></div>
                  </div>
                  <div
                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div
                      className="slide-img-bg"
                      style={{ backgroundImage: 'url(' + bg2 + ')' }}/>
                    <div className="slider-content"></div>
                  </div>
                  <div
                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div
                      className="slide-img-bg"
                      style={{ backgroundImage: 'url(' + bg3 + ')' }}/>
                    <div className="slider-content"></div>
                  </div>
                </Slider>
              </div>
            </Col>
            <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <img src={ PrimeViewTextIcon } style={{ width: '245px', marginBottom: '20px' }} />
                <h4 className="mb-0">
                  <div>Welcome back,</div>
                  <span>Please sign in to your account.</span>
                </h4>
                <Row className="divider"/>
                <AvForm>
                  <Row form>
                    <Col md={ 12 }>
                      {
                        this.props.data.ajaxReducer.ajaxErrorMessage !== '' && (
                          <p className="text-danger">{ this.props.data.ajaxReducer.ajaxErrorMessage }</p>
                        )
                      }
                    </Col>
                    <Col md={6}>
                      <AvGroup>
                        <Label for="exampleEmail">Email</Label>
                        <AvInput
                          onChange={ e => this.setState({ email: e.target.value })}
                          type="email"
                          name="email"
                          id="exampleEmail"
                          value={ this.state.email }
                          placeholder="Email here..."
                          required />
                      </AvGroup>
                    </Col>
                    <Col md={6}>
                      <AvGroup>
                        <Label for="examplePassword">Password</Label>
                        <AvInput
                          onChange={ e => this.setState({ password: e.target.value })}
                          type="password"
                          name="password"
                          id="examplePassword"
                          value={ this.state.password }
                          onKeyPress= { e => {
                            if( e.key === 'Enter' ) {
                              this.props.authUser({ email: this.state.email, password: this.state.password })
                            }
                          }}
                          placeholder="Password here..."
                          required />
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row className="divider" />
                  <div className="d-flex align-items-center">
                    <div className="ml-auto">
                      <Button
                        color="link"
                        size="lg"
                        onClick={() => {
                          this.props.history.push( '/forgot-password' )
                        }}>Forgot Password</Button>
                      <Button
                        color="primary"
                        size="lg"
                        onClick={() => {
                          this.props.authUser({ email: this.state.email, password: this.state.password })
                        }}>Login to Dashboard</Button>
                    </div>
                  </div>
                </AvForm>
              </Col>
            </Col>
          </Row>
          { this.props.data.ajaxReducer.ajaxCallProgress > 0 && <LoadingOverlay /> }
        </div>
      </MainFrame>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  authUser
})( Login )