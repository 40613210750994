import React, { Component } from 'react'
import { 
  Row, 
  Col,
  Card,
  CardBody,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'

import PageTitle from 'components/Title'
import CreateRole from './Create'
import UpdateRole from './Update'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithRoles from './actions'

class Roles extends Component {

  componentDidMount = () => this.props.getRoles()

  render = () => {
    return (
      <>
        <PageTitle
          heading="Roles"
          subheading="Listings of all the roles presence in the system."
          icon="pe-7s-users icon-gradient bg-happy-itmeo"
          buttons={[
            {  
              color: 'primary',
              onClick: () => this.props.onChangeRolesHOC( 'showCreateRole', true ),
              content: 'Add role'
            }
          ]} />
        <div>
          <Row>
            <Col md={ 12 }>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    data={ this.props.roles }
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'name'
                      },
                      {
                        Header: 'Actions',
                        maxWidth: 120,
                        Cell: ( row ) => (
                          <>
                            <Button
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={() => {
                                this.props.getSelectedRole( row.original.id )
                              }}>
                              <i className="pe-7s-pen btn-icon-wrapper"> </i>
                            </Button>
                          </>
                        )
                      }
                    ]}
                    defaultPageSize={ 10 } />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <CreateRole { ...this.props } />
          <UpdateRole { ...this.props } />
        </div>
        { this.props.onLoadRoles && <LoadingOverlay /> }
      </>
    )
  }
}

export default WithRoles( Roles )