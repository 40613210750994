import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get, Put } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newMedicalRecord: {
        date: new Date(),
        complaints: '',
        observations: '',
        notes: '',
        treatments: [],
        tooth_chart: [],
        diagnosed_by: 1, // user_id - from appointment setup at front desk
        medicines: []
      },
      showMedicalRecord: false,
      medicalRecords: [],
      selectedMedicalRecord: {
        id: 0,
        medicines: [],
        treatments: [],
        tooth_charts: [],
        invoices: [],
        perio_chart_save_data: ''
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeMedicalRecordHOC = ( key, val ) => this.setState({ [ key ]: val })

    getMedicalRecords = patient_id => Get(
      `/medical_records/retrieve_from_patient?patient_id=${ patient_id }`,
      this.getMedicalRecordsSuccess,
      this.getMedicalRecordsError,
      this.load
    )
    getMedicalRecordsSuccess = payload => {
      let tmp = []
      if( payload && payload.length > 0 ) { 
        payload.map(( item, index ) => {
          tmp.push({
            ...item,
            is_editable: payload.length === index
          })
        })
      }
      this.setState({ 
        medicalRecords: tmp, 
        showMedicalRecord: true 
      })
    }
    getMedicalRecordsError = error => this.requestError( error )

    getSelectedMedicalRecord = id => Get(
      `/medical_records/${ id }`,
      this.getSelectedMedicalRecordSuccess,
      this.getSelectedMedicalRecordError,
      this.load
    )
    getSelectedMedicalRecordSuccess = payload => {
      let tmp = []
      if( payload.medical_record_treatments ) {
        payload.medical_record_treatments.map( item => {
          tmp.push({ ...item })
        })
      }
      this.setState({ selectedMedicalRecord: {
        ...payload,
        medical_record_treatments: tmp,
        tooth_charts: payload.tooth_charts ? payload.tooth_charts : [],
        perio_chart_save_data: payload.perio_chart_save_data ? payload.perio_chart_save_data : '',
        dental_chart_save_data: payload.dental_chart_save_data ? payload.dental_chart_save_data : ''
      }})
    }
    getSelectedMedicalRecordError = error => this.requestError( error )

    updateMedicalRecord = data => Put(
      `/medical_records/${ data.id }`,
      data,
      this.updateMedicalRecordSuccess,
      this.updateMedicalRecordError,
      this.load
    )
    updateMedicalRecordSuccess = payload => {
      this.requestSuccess( 'Medical record is updated successfully.' )
      this.getSelectedMedicalRecord( this.state.selectedMedicalRecord.id )
    }
    updateMedicalRecordError = error => this.requestError( error )

    updateMedicalRecordTreatment = data => Put(
      `/medical_records/${ data.medical_record_id }/update_medical_record_treatment?treatment_id=${ data.treatment_id }`,
      data,
      this.updateMedicalRecordTreatmentSuccess,
      this.updateMedicalRecordTreatmentError,
      this.load
    )
    updateMedicalRecordTreatmentSuccess = payload => {
      this.requestSuccess( 'Treatment is updated successfully.' )
      this.getSelectedMedicalRecord( this.state.selectedMedicalRecord.id )
    }
    updateMedicalRecordTreatmentError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          showMedicalRecord={ this.state.showMedicalRecord }
          newMedicalRecord={ this.state.newMedicalRecord }
          selectedMedicalRecord={ this.state.selectedMedicalRecord }
          onLoadMedicalRecords={ this.state.loading }
          medicalRecords={ this.state.medicalRecords }
          onChangeMedicalRecordHOC={ this.onChangeMedicalRecordHOC }
          getMedicalRecords={ this.getMedicalRecords }
          getSelectedMedicalRecord={ this.getSelectedMedicalRecord }
          updateMedicalRecord={ this.updateMedicalRecord }
          updateMedicalRecordTreatment={ this.updateMedicalRecordTreatment } />
      )
    }
  }
  return WithHOC
}

export default HOC