import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'

import { Get, Put, Post } from 'utils/axios'
import getDomainURL from 'utils/api'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      medicalCerts: {
        data: [],
        pagy: {}
      },
      selectedMedicalCert: {},
      showCreateMedicalCert: false,
      showUpdateMedicalCert: false,
      newMedicalCert: {
        patient_id: 0,
        user_id: 0,
        examine_date: '',
        start_date: '',
        end_date: '',
        remark: '',
        company_id: 0,
        branch_id: 0
      },
      searchParams: {
        patient_name: '',
        start_date: new Date(),
        end_date: new Date(),
        user_name: ''
      },
      searchString: ''
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeMedicalCertHOC = ( key, val ) => this.setState({ [ key ]: val })

    getMedicalCerts = ( search, page ) => Get(
      `/medical_certs?page=${ page }${ search ? search : '' }`,
      this.getMedicalCertsSuccess,
      this.getMedicalCertsError,
      this.load
    )
    getMedicalCertsSuccess = payload => this.setState({ medicalCerts: payload, searchString: 'Results' })
    getMedicalCertsError = error => this.requestError( error )

    getSelectedMedicalCert = id => Get(
      `/medical_certs/${ id }`,
      this.getSelectedMedicalCertSuccess,
      this.getSelectedMedicalCertError,
      this.load
    )
    getSelectedMedicalCertSuccess = payload => this.setState({ selectedMedicalCert: payload, showUpdateMedicalCert: true })
    getSelectedMedicalCertError = error => this.requestError( error )

    getMedicalCertsWithQuery = ( 
      patient_name,
      start_date,
      end_date,
      user_name
    ) => Get(
      `/medical_certs?q[patient_name_cont]=${ patient_name }&q[start_date_gteq]=${ start_date }&q[end_date_lteq]=${ end_date }&q[user_name_cont]=${ user_name }`,
      this.getMedicalCertsWithQuerySuccess,
      this.getMedicalCertsWithQueryError,
      this.load
    )
    getMedicalCertsWithQuerySuccess = payload => {
      let tmpSearchString = `Search results from ${ Moment( this.state.searchParams[ 'start_date' ] ).format( 'DD MMM YYYY' ) } to ${ Moment( this.state.searchParams[ 'end_date' ] ).format( 'DD MMM YYYY' ) } `
        + `${ !_.isEmpty( this.state.searchParams[ 'user_name' ] ) ? `from Dr. ${ this.state.searchParams[ 'user_name' ] } ` : '' }`
        + `${ !_.isEmpty( this.state.searchParams[ 'patient_name' ] ) ? `to patient, ${ this.state.searchParams[ 'patient_name' ] }` : '' }`
      this.setState({ medicalCerts: payload, searchString: tmpSearchString })
    }
    getMedicalCertsWithQueryError = error => this.requestError( error )

    createMedicalCert = data => Post(
      `/medical_certs`,
      data,
      this.createMedicalCertSuccess,
      this.createMedicalCertError,
      this.load
    )
    createMedicalCertSuccess = payload => {
      this.requestSuccess( 'Medical cert is created successfully.' )
      this.getMedicalCerts( '', 1 )
      this.setState({
        newMedicalCert: {
          patient_id: 0,
          user_id: 0,
          examine_date: '',
          start_date: '',
          end_date: '',
          remark: '',
          company_id: 0,
          branch_id: 0
        },
        showCreateMedicalCert: false
      })
    }
    createMedicalCertError = error => this.requestError( 'Failed to create medical cert.' )

    updateMedicalCert = data => Put(
      `/medical_certs/${ data.id }`,
      data,
      this.updateMedicalCertSuccess,
      this.updateMedicalCertError,
      this.load
    )
    updateMedicalCertSuccess = payload => {
      this.requestSuccess( 'Medical cert is updated successfully.' )
      this.getMedicalCerts( '', 1 )
      this.setState({ selectedMedicalCert: {}, showUpdateMedicalCert: false })
    }
    updateMedicalCertError = error => this.requestError( 'Failed to update medical cert.' )

    downloadPDF = id => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/medical_certs/${ id }/download`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( blobby => {
        this.load( false )
        return FileSaver.saveAs( blobby, 'MedCert.pdf' )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save medical cert. Please try again.' )
      })
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadMedicalCerts={ this.state.loading }
          medicalCerts={ this.state.medicalCerts }
          selectedMedicalCert={ this.state.selectedMedicalCert }
          newMedicalCert={ this.state.newMedicalCert }
          showCreateMedicalCert={ this.state.showCreateMedicalCert }
          showUpdateMedicalCert={ this.state.showUpdateMedicalCert }
          onChangeMedicalCertHOC={ this.onChangeMedicalCertHOC }
          getMedicalCerts={ this.getMedicalCerts }
          getMedicalCertsWithQuery={ this.getMedicalCertsWithQuery }
          getSelectedMedicalCert={ this.getSelectedMedicalCert }
          createMedicalCert={ this.createMedicalCert }
          updateMedicalCert={ this.updateMedicalCert }
          downloadPDF={ this.downloadPDF }
          searchParams={ this.state.searchParams }
          searchString={ this.state.searchString } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC