import React, { useEffect } from 'react'
import { 
  Card, CardBody, Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap'
import { compose } from 'redux'
import ReactTable from 'react-table'
import _ from 'lodash'

import PageTitle from 'components/Title'
import Pagination from 'components/Pagination'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PromptModal from 'components/Indicator/Prompt'
import ReferralForm from './form'

import WithReferralForms from './actions'
import WithPatients from './actions/Patients'
import WithDoctors from 'actions/dictionary/doctors'

const ReferralForms = props => {
  useEffect(() => {
    props.getReferralForms( 1 )
    props.reloadNewReferralForm()
    props.getDoctors( props.data.profileReducer.profile.current_user.branch_ids )
  }, [])

  const renderCreateModal = () => (
    <Modal size='md' isOpen={ props.showCreateReferralForm }>
      <ModalHeader 
        toggle={ () => props.reloadNewReferralForm() }>
        Create Referral Form</ModalHeader>
      <ModalBody>
        <ReferralForm
          referralFormType='newReferralForm'
          currentReferralForm={ props.newReferralForm }
          onChangeReferralFormsHOC={ props.onChangeReferralFormsHOC }
          profile={ props.data.profileReducer.profile }
          doctors={ props.doctors }
          patients={ props.patients }
          getPatients={ props.getPatients }
          searchParams={ props.searchParams }
          onChangePatientsHOC={ props.onChangePatientsHOC }
          onLoadPatientsHOC={ props.onLoadPatientsHOC } />
        { ( props.onLoadReferralFormsHOC || props.onLoadPatientsHOC ) && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color='primary'
          onClick={ () => props.createReferralForm( props.newReferralForm ) } >
          Submit</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateModal = () => (
    <Modal size='md' isOpen={ props.showUpdateReferralForm }>
      <ModalHeader 
        toggle={ () => props.reloadSelectedReferralForm() }>
        Update Referral Form</ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedReferralForm ) && <ReferralForm
            referralFormType='selectedReferralForm'
            currentReferralForm={ props.selectedReferralForm }
            onChangeReferralFormsHOC={ props.onChangeReferralFormsHOC }
            profile={ props.data.profileReducer.profile }
            doctors={ props.doctors }
            patients={ props.patients }
            getPatients={ props.getPatients }
            searchParams={ props.searchParams }
            onChangePatientsHOC={ props.onChangePatientsHOC }
            onLoadPatientsHOC={ props.onLoadPatientsHOC } />
        }
        { ( props.onLoadReferralFormsHOC || props.onLoadPatientsHOC ) && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color='primary'
          onClick={ () => props.updateReferralForm( props.selectedReferralForm ) } >
          Submit</Button>
      </ModalFooter>
    </Modal>
  )

  return(
    <>
      <PageTitle
        heading="Referral forms"
        subheading="Listings of all the referral forms."
        icon="pe-7s-clock icon-gradient bg-happy-itmeo"
        buttons={[
          {  
            color: 'primary',
            onClick: () => props.onChangeReferralFormsHOC( 'showCreateReferralForm', true ),
            content: 'Create referral form'
          }
        ]} />
      <Card className="main-card mb-3">
        <CardBody>
          <ReactTable
            showPagination={ false }
            pageSize={ 10 }
            data={ props.referralForms.data }
            columns={[
              {
                Header: 'Company',
                accessor: 'company_name',
                style: { "line-break": "break-word" }
              },
              {
                Header: 'Branch',
                accessor: 'branch_name',
                style: { "line-break": "break-word" }
              },
              {
                Header: 'Doctor',
                accessor: 'user_name',
                style: { "line-break": "break-word" }
              },
              {
                Header: 'Patient',
                accessor: 'patient_name',
                style: { "line-break": "break-word" }
              },
              {
                Header: 'Referred clinic',
                accessor: 'referred_clinic_name',
                style: { "line-break": "break-word" }
              },
              {
                Header: 'Referred dentist',
                accessor: 'referred_dentist',
                style: { "line-break": "break-word" }
              },
              {
                Header: 'Action',
                style:{ 
                  "justify-content": "center",
                  "overflow": "scroll" 
                },
                Cell: row => <>
                  <Button 
                    color='primary'
                    className='mr-2'
                    onClick={ () => {
                      props.getSelectedReferralForm( row.original.id )
                      props.getPatients( row.original.patient_name ) 
                    }}>
                    <i className='pe-7s-pen'/></Button>
                  <Button 
                    color='primary'
                    onClick={ () => props.downloadReferralFormPDF( row.original.id ) }>
                    <i className='pe-7s-download'/></Button>
                  <Button 
                    color='danger'
                    className='ml-2'
                    onClick={ () => props.onChangeReferralFormsHOC( 'selectedReferralFormToDelete', row.original.id ) }>
                    <i className='pe-7s-trash'/></Button>
                </>
              }
            ]}/>
        </CardBody>
      </Card>
      { 
        props.referralForms.pagy && <Pagination 
          metadata={ props.referralForms.pagy } 
          onChangePage={ pg => {
            props.getReferralForms( pg )
          }} 
        />
      }
      <PromptModal
        showPromptModal={ props.selectedReferralFormToDelete > 0 }
        onClickYes={ () => props.removeReferralForm( props.selectedReferralFormToDelete ) }
        onClickNo={ () => props.onChangeReferralFormsHOC( 'selectedReferralFormToDelete', 0 ) }
        content={ 'Are you sure you want to delete the record?' } />
      { renderCreateModal() }
      { renderUpdateModal() }
      { ( props.onLoadReferralFormsHOC || props.onLoadPatientsHOC ) && <LoadingOverlay/> }
    </>
  )
}

export default compose(
  WithReferralForms,
  WithDoctors,
  WithPatients
)( ReferralForms )

