import React from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap'
import _ from 'lodash'
import { connect } from 'react-redux'
import NumberFormat from "react-number-format"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import CurrencySettings from 'utils/currencies'

const Create = ({
  showCreateTreatment,
  newTreatment,
  onChangeTreatmentsHOC,
  createTreatment,
  onLoadTreatments,
  data
}) => {

  const onChangeTreatmentData = ( key, val ) => {
    let tmp = _.cloneDeep( newTreatment )
    tmp[ key ] = val
    return onChangeTreatmentsHOC( 'newTreatment', tmp )
  }

  return (
    <Modal
      isOpen={ showCreateTreatment }
      size={ 'md' }
      toggle={() => {
        onChangeTreatmentsHOC( 'showCreateTreatment', false )
      }}>
      <ModalHeader toggle={() => onChangeTreatmentsHOC( 'showCreateTreatment', false )}>Create treatment</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>Name</Label>
            <Input 
              type="text"
              value={ newTreatment.name }
              onChange={ e => {
                onChangeTreatmentData( 'name', e.target.value )
              }} />
          </FormGroup>  
          <FormGroup>
            <Label>{`Price - ${ CurrencySettings() }`}</Label>
            <NumberFormat
              className="form-control"
              value={ newTreatment.price }
              thousandSeparator={ "," }
              decimalSeparator={ "." }
              onValueChange={( values ) => {
                const { formattedValue, value } = values;
                onChangeTreatmentData( 'price', value )
              }}
              inputMode="numeric"
              displayType="input"
            />
          </FormGroup>  
        </Form>
        { onLoadTreatments && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={() => {
            createTreatment({ 
              ...newTreatment,
              company_id: data.profileReducer.profile.companies[ 0 ].id
            })  
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps )( Create )