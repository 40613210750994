import React, { useEffect } from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { compose } from 'redux'
import ReactTable from 'react-table'
import _ from 'lodash'
import Moment from 'moment'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SearchForm from './SearchForm'

import WithPanelCollections from './actions'
import CurrencySettings from 'utils/currencies'

const PanelCollections = props => {
  useEffect(() => resetSearchParams(), [])

  const resetSearchParams = () => {
    let tmpSearchParams = _.cloneDeep( props.searchParams )
    tmpSearchParams.company_id = props.data.profileReducer.profile.current_user.company_ids.length > 0 
      ? props.data.profileReducer.profile.current_user.company_ids[ 0 ]
      : 0
    tmpSearchParams.branch_id = _.find( props.data.profileReducer.profile.current_user.branches, { company_id: tmpSearchParams[ 'company_id' ] } ) 
      ? _.find( props.data.profileReducer.profile.current_user.branches, { company_id: tmpSearchParams[ 'company_id' ] } ).id
      : 0
    Promise.all([
      props.getPanelCollections(
        tmpSearchParams[ 'company_id' ],
        tmpSearchParams[ 'branch_id' ],
        Moment( tmpSearchParams[ 'start_date' ] ).format( 'YYYY-MM-DD' ), 
        Moment( tmpSearchParams[ 'end_date' ] ).format( 'YYYY-MM-DD' ),
      ),
      props.onChangePanelCollectionsHOC( 'searchParams', tmpSearchParams )
    ])
  }
  
  return(
    <>
      <PageTitle
        heading="Panel Collections"
        subheading="Listings of all the panel collections."
        icon="pe-7s-clock icon-gradient bg-happy-itmeo" />
      <SearchForm { ...props } resetSearchParams={ resetSearchParams } />
      <Card className="main-card mb-3">
        <CardHeader>
          { `Search results from ${ Moment( props.searchParams[ 'start_date' ] ).format( 'DD MMM YYYY' ) } to ${ Moment( props.searchParams[ 'end_date' ] ).format( 'DD MMM YYYY' ) }` }
        </CardHeader>
        <CardBody>
          <div className="card no-shadow rm-border bg-transparent widget-chart text-left pl-0">
            <div className="widget-chart-content">
              <div className="widget-subheading d-flex">
                Total collection for selected date
              </div>
              <div className="widget-numbers">
                { `${ CurrencySettings() } ${ props.panelCollections.total }` }
              </div>
            </div>
          </div>
          <ReactTable
            showPagination={ true }
            pageSize={ 20 }
            data={ props.panelCollections.collection }
            columns={[
              {
                Header: 'Panel',
                accessor: 'panel',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Doctor',
                accessor: 'user',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Patient',
                accessor: 'patient',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Amount',
                accessor: 'payment',
                style: { "line-break": "anywhere" }
              }
            ]}/>
        </CardBody>
      </Card>
      { props.onLoadPanelCollectionsHOC && <LoadingOverlay/> }
    </>
  )
}

export default compose (
  WithPanelCollections
)( PanelCollections )

