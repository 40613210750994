import React, { useState, useEffect } from 'react'
import {
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import _ from 'lodash'

const IsNewInvoice = ({
  isNewInvoice,
  newInvoice,
  selectedInvoice,
  onChangeInvoiceHOC,
  companies,
  selectedPatient,
  selectedMedicalRecord,
  createInvoice,
  invoices,
  branches
}) => {
  const [ invoiceNumber, updateInvoiceNumber ] = useState( '' )
  const [ submitButtonClicked, updateSubmitButtonClicked ] = useState( false )

  useEffect(() => {
    if( selectedMedicalRecord.invoices.length > 0 ) {
      updateInvoiceNumber( selectedMedicalRecord.invoices[ selectedMedicalRecord.invoices.length - 1 ].id )
    }
  }, [ selectedMedicalRecord ])

  if( isNewInvoice ) {
    return (
      <>
        <FormGroup>
          <Label>Select company</Label>
          <Input
            type="select"
            value={ newInvoice.company_id }
            onChange={ e => {
              let tmp = _.cloneDeep( newInvoice )
              tmp[ 'company_id' ] = e.target.value
              onChangeInvoiceHOC( 'newInvoice', tmp )
            }}>
            <option>Select a company</option>
            {
              companies.map( item => {
                if( selectedPatient.company_ids.indexOf( item.id ) > -1 ) {
                  return (
                    <option key={ item.id } value={ item.id }>{ item.name }</option>
                  )
                }
              })
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Select branch</Label>
          <Input
            type="select"
            value={ newInvoice.branch_id }
            onChange={ e => {
              let tmp = _.cloneDeep( newInvoice )
              tmp[ 'branch_id' ] = e.target.value
              onChangeInvoiceHOC( 'newInvoice', tmp )
            }}>
            <option></option>
            {
              branches.map( item => {
                return (
                  <option key={ item.id } value={ item.id }>{ item.name }</option>
                )
              })
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Receipt number</Label>
          <Input
            type="text"
            value={ selectedInvoice.invoice_number }
            disabled={ true } />
          <Button 
            color="primary"
            disabled={ submitButtonClicked }
            onClick={() => {
              let tmp = _.cloneDeep( newInvoice )
              let tmpTotalAmount = 0
              tmp[ 'user_id' ] = selectedMedicalRecord.user_id
              selectedMedicalRecord.medicines.map( item => {
                tmp.invoice_medicines_attributes.push({
                  medicine_id: item.id,
                  quantity: 1,
                  price_per_unit: parseFloat( item.price_per_unit )
                })
                tmpTotalAmount = tmpTotalAmount + parseFloat( item.price_per_unit )
              })
              selectedMedicalRecord.medical_record_treatments.map( item => {
                tmp.invoice_treatments_attributes.push({
                  treatment_id: item.treatment_id,
                  price: parseFloat( item.price ),
                  types: item.types
                })
                tmpTotalAmount = tmpTotalAmount + parseFloat( item.price )
              })
              tmp[ 'total_amount' ] = tmpTotalAmount
              tmp[ 'medical_record_id' ] = selectedMedicalRecord.id
              tmp[ 'patient_id' ] = selectedPatient.id
              updateSubmitButtonClicked( true )
              createInvoice( tmp )
            }}>Generate invoice number</Button>
        </FormGroup>
      </>
    )
  } else {
    return (
      <FormGroup>
        <Label>Receipt Number</Label>
        <div className="d-flex">
          <Input
            type="select"
            className="w-50"
            value={ invoiceNumber }
            onChange={ e => {
              updateInvoiceNumber( e.target.value )
            }}>
            <option></option>
            {
              invoices.map( item => {
                if( selectedMedicalRecord.invoices.indexOf( item.id ) === -1 ) {
                  return (
                    <option key={ item.id } value={ item.id }>{ item.invoice_number }</option>
                  )
                }
              })
            }
          </Input>
        </div>
      </FormGroup>
    )
  }
}

export default IsNewInvoice