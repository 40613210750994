import React, { Component } from 'react'
import {
  Button
} from 'reactstrap'
import ReactTable from 'react-table'
import Moment from 'moment'
import _ from 'lodash'
import { compose } from 'redux'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ModalDoctorPatients from './Patients'

import WithRoles from 'actions/dictionary/roles'
import WithPatients from './actions'

class Users extends Component {

  componentDidMount = () => this.props.getRoles()

  render = () => {
    return (
      <>
        <ReactTable
          data={ this.props.selectedCompany.users }
          columns={[
            {
              Header: 'Email',
              accessor: 'email',
              minWidth: 150
            },
            {
              Header: 'Name',
              accessor: 'name'
            },
            {
              Header: 'NRIC',
              accessor: 'nric_no'
            },
            {
              Header: 'Role',
              width: 100,
              Cell: ( row ) => (
                <span>{ _.find( this.props.roles, { id: row.original.role_id }) ? _.find( this.props.roles, { id: row.original.role_id }).name : '' }</span>
              )
            },
            {
              Header: 'Assigned branches',
              Cell: ( row ) => {
                return (
                  <ul>
                    {
                      row.original.branch_ids.map( item => {
                        let tmp = _.find( this.props.selectedCompany.branches, { id: item })
                        if( !_.isEmpty( tmp ) ){
                          return (
                            <li key={ tmp.name }>{ tmp.name }</li>
                          )
                        } else {
                          return( <></> )
                        }
                      })
                    }
                  </ul>
                )
              }
            },
            {
              Header: 'Created at',
              Cell: ( row ) => (
                <span>{ Moment( row.original.created_at ).format( 'DD MMM YYYY HH:mm' ) }</span>
              )
            },
            {
              Header: 'Actions',
              Cell: ( row ) => (
                <>
                  {
                    ( _.find( this.props.roles, { id: row.original.role_id }) && _.find( this.props.roles, { id: row.original.role_id }).name === 'Doctor' ) && (
                      <Button 
                        color="primary"
                        onClick={() => {
                          this.props.getPatients( row.original.id )
                        }}>
                        View related patients
                      </Button>
                    )
                  }
                </>
              )
            }
          ]}
          defaultPageSize={ 10 } />
        <ModalDoctorPatients { ...this.props } />
        { this.props.onLoadPatients && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithRoles,
  WithPatients
)( Users )