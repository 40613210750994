import React, { useState, useEffect } from 'react'
import {
  Card, CardHeader, CardBody,
  Row, Col, Label
} from 'reactstrap'
import { Line, Pie } from 'react-chartjs-2'
import _ from 'lodash'

const Statistics = props => {
  const [ saleFigureLabels, updateSaleFigureLabels ] = useState( [ "2020/Apr", "2020/May", "2020/Jun", "2020/Jul", "2020/Aug", "2020/Sep", "2020/Oct", "2020/Nov", "2020/Dec", "2021/Jan", "2021/Feb", "2021/Mac" ] )
  const [ saleFigures, updateSaleFigures ] = useState( [ 4233, 4322, 4644, 4700, 4877, 4989, 5003, 5123, 5211, 5223, 5311, 5431 ] )
  const [ appointmentAmount, updateAppointmentAmount ] = useState( [ 11, 22, 13, 16, 24, 17 ] )
  const [ appointmentLabels, updateAppointmentLabels ] = useState( [ 'Active', 'Confirmed', 'Shown', 'No show', 'Waiting list', 'Cancelled' ] )
  useEffect(() => {
    if( !_.isEmpty( props.dashboardInfoToday ) ) {
      let tmpSaleFigureLabels = []
      let tmpSaleFigures = []
      let tmpAppointmentAmounts = []
      let tmpAppointmentLabels = []
      props.dashboardInfoToday.by_sales_months.map( item => {
        Object.keys( item ).map( key => {
          tmpSaleFigureLabels.push( key )
          tmpSaleFigures.push( item[ key ] )
        })
      })
      Object.keys( props.dashboardInfoToday.appointments_by_status ).map( key => {
        tmpAppointmentLabels.push( key ) 
        tmpAppointmentAmounts.push( props.dashboardInfoToday.appointments_by_status[ key ] )
      })
      updateSaleFigureLabels( tmpSaleFigureLabels )
      updateSaleFigures( tmpSaleFigures )
      updateAppointmentAmount( tmpAppointmentAmounts )
      updateAppointmentLabels( tmpAppointmentLabels )
    }
  }, [ props.dashboardInfoToday ])
  return (
    <Row>
      <Col md={ 4 }>
        <Card className="main-card mb-3 card-shadow-primary card-btm-border border-primary">
          <CardBody className="d-flex flex-column justify-content-center">
            <Label
              className='w-100 mb-0'
              style={{
                fontSize: "50px",
                fontWeight: "500" 
              }}>
              { props.dashboardInfoToday.total_sales_amount }
            </Label>
            <Label
              className='w-100'
              style={{
                color: "#495057",
                fontSize: "1rem",
                opacity: ".5" 
              }}>
              Total Sales Value
            </Label>
          </CardBody>
        </Card>
      </Col>
      <Col md={ 4 }>
        <Card className="main-card mb-3 card-shadow-primary card-btm-border border-info">
          <CardBody className="d-flex flex-column justify-content-center">
            <Label
              className='w-100 mb-0'
              style={{
                fontSize: "50px",
                fontWeight: "500" 
              }}>
              { props.dashboardInfoToday.total_patients }
            </Label>
            <Label
              className='w-100'
              style={{
                color: "#495057",
                fontSize: "1rem",
                opacity: ".5" 
              }}>
              Total Patients Registered
            </Label>
          </CardBody>
        </Card>
      </Col>
      <Col md={ 4 }>
        <Card className="main-card mb-3 card-shadow-primary card-btm-border border-success">
          <CardBody className="d-flex flex-column justify-content-center">
            <Label
              className='w-100 mb-0'
              style={{
                fontSize: "50px",
                fontWeight: "500" 
              }}>
              { props.dashboardInfoToday.new_patients_this_month }
            </Label>
            <Label
              className='w-100'
              style={{
                color: "#495057",
                fontSize: "1rem",
                opacity: ".5" 
              }}>
              New Patients Registered
            </Label>
          </CardBody>
        </Card>
      </Col>
      <Col md={ 12 }>
        <Card className="mb-4">
          <CardHeader>
            Sales figures
          </CardHeader>
          <CardBody style={{ height: '40vh' }}>
            <Line
              data={{
                labels: saleFigureLabels,
                datasets: [
                  {
                    label: 'Total sales',
                    fill: true,
                    lineTension: 0.3,
                    backgroundColor: 'rgba( 241,142,5,0.4 )',
                    borderColor: '#F18E05',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    borderWidth: 3,
                    pointBorderColor: '#F18E05',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 3,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#EAF3F9',
                    pointHoverBorderColor: 'rgba( 220,220,220,1 )',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: saleFigures,
                    responsive: true,
                    maintainAspectRatio: true,
                  }
                ]
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false
              }} />
          </CardBody>
        </Card>
      </Col>
      <Col md={ 6 }>
        <Card>
          <CardHeader>
            Appointments by status
          </CardHeader>
          <CardBody style={{ height: '40vh' }}>
            <Pie
              data={{
                labels: appointmentLabels,
                datasets: [{
                  data: appointmentAmount,
                  backgroundColor: [
                    '#0075BC',
                    '#227093',
                    '#19DDEA',
                    '#C0D98A',
                    '#0075BC',
                    '#ff4500'
                  ],
                  hoverBackgroundColor: [
                    '#34ACE0',
                    '#2D95F4',
                    '#33D9B2',
                    '#2ECC71',
                    '#34ACE0',
                    '#ff0000'
                  ]
                }]
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false
              }} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Statistics