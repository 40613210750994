import React, { useRef } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap'
import PrismaZoom from 'react-prismazoom'

const LargeXray = ({
  url,
  showLargeXray,
  updateShowXray
}) => {
  const prismaZoom = useRef( null )

  return(
    <Modal isOpen={ showLargeXray } size='lg' >
      <ModalHeader toggle={ () => updateShowXray( false ) } >View X-ray</ModalHeader>
      <ModalBody>
        <div 
          style={{ 
            display: "flex", 
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            height: "70vh",
            overflow: "hidden" }}>
          <PrismaZoom 
            ref={ prismaZoom }
            topBoundary={ 1000 }
            bottomBoundary={ 1000 }
            leftBoundary={ 1000 }
            rightBoundary={ 1000 } >
            <img style={{ maxWidth: "100%", maxHeight: "68vh" }} src={ url } />
          </PrismaZoom>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => prismaZoom.current.zoomIn( 0.5 ) }>
          Zoom in</Button>
        <Button
          color='success'
          onClick={ () => prismaZoom.current.zoomOut( 0.5 ) }>
          Zoom out</Button>
        <Button
          color='warning'
          onClick={ () => prismaZoom.current.reset() }>
          Reset</Button>
        <Button
          color='danger'
          onClick={ () => updateShowXray( false ) }>
          Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default LargeXray