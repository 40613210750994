import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Axios from 'axios'

const HOC = WrappedComponent => {
  const clientId = 'AZhibMEJnhe8TP8-bfG7ZNpUq_GhCHu0PJiA4G1Owg5mZdre_QIzn9fK7nn-3v1QmBd6reiOYB7HwYoM'
  const clientSecret = 'ECinPkTkgBEjcRFOQdFQEgTeAVgFbRDHuCiE4coxcpO4HHuKFDVn9U4WuBeCyrZT6v7OREpc2nk1QqH_'
  const productId = 'PROD-6P791015NK422323R'
  // const sandboxClientId = 'ATqD6ihXN2A5GLAN3jWwT4IesV3DH9ljf_4tZMPEZechIbnq085nhzTY3wSwJ8OHsVVelKWL6XeS9MJl'
  // const sandboxClientSecret = 'EJY_VztWKeslLgAGsZc9_LEIcSOMcY0EwhviKvCeI4vfmu2L_tQPdP2u0p-Vy_pAZPplOkqCiK4etUVO'
  // const sandboxProductId = 'PROD-4PT97089GV1684209'

  class WithHOC extends Component {
    state={ 
      loading: false,
      paypalToken: '',
      paypalPlans: [],
      selectedPaypalPlan: {},
      outstandingPaypalSubscriptions: [],
      selectedPaypalSubscription: {},
      paypalTransactions: [],
      showReactivateSubscriptionModal: false,
      showSubscriptionModal: false,
      nonSubscribingCompanies: [],
      selectedNonSubscribingCompany: {}
    } 

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangePaypalHOC = ( key, val, isNewSubscriber ) => this.setState({ [ key ] : val }, () => {
      switch( key ){
        case 'outstandingPaypalSubscriptions':
          if( val.length > 0 ){
            this.setState({ 
              selectedPaypalSubscription: [],
              showReactivateSubscriptionModal: true 
            })
          }
          break
        case 'paypalPlans':
          this.setState({ 
            selectedPaypalPlan: val.length > 0 ? val[ 0 ] : {},
            showSubscriptionModal: isNewSubscriber
          })
          break
        case 'nonSubscribingCompanies':
          this.setState({ selectedNonSubscribingCompany: val.length > 0 ? val[ 0 ] : {} })
      }
    })

    getPaypalToken = () => {
      this.setState({ loading: true })
      let config = {
        auth: {
          'username': clientId,
          'password': clientSecret },
        headers: {
          'Accept': 'application/json',
          'Accept-Language': 'en_US',
          'content-type': 'application/x-www-form-urlencoded' }
      }
      return Axios.post( 'https://api.paypal.com/v1/oauth2/token?grant_type=client_credentials', {}, config ).then( res => {
        this.getPaypalTokenSuccess( res.data )
        this.setState({ loading: false })
      }).catch( err => {
        console.dir( err )
        if( err ){
          this.getPaypalTokenError( err.response.data )
        } else {
          this.getPaypalTokenError( 'Fail to get token' )
        }
        this.setState({ loading: false })
      })
    }
    getPaypalTokenSuccess = payload => this.setState({ paypalToken: payload.access_token })
    getPaypalTokenError = error => this.requestError( error )

    getPaypalPlans = () => {
      this.load( true )
      let config = {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${ this.state.paypalToken }`
        }
      }
      return Axios.get( 
        `https://api.paypal.com/v1/billing/plans?product_id=${ productId }&page_size=20&page=1&total_request=true`,
        config 
      ).then( res => {
        this.getPaypalPlansSuccess( res.data )
        this.load( false )
      }).catch( err => {
        console.dir( err )
        if( err && err.response ){
          this.getPaypalPlansError( err.response.data )
        } else {
          this.getPaypalPlansError( 'Fail to get plans' )
        }
        this.load( false )
      })
    }
    getPaypalPlansSuccess = payload => this.setState({ paypalPlans: payload.plans })
    getPaypalPlansError = error => this.requestError( error )

    getSelectedPaypalPlan = plan_id => {
      this.load( true )
      let config = {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${ this.state.paypalToken }`
        }
      }
      return Axios.get(
        `https://api.paypal.com/v1/billing/plans/${ plan_id }`,
        config
      ).then( res => {
        this.getSelectedPaypalPlanSuccess( res.data )
        this.load( false )
      }).catch( err => {
        console.dir( err )
        if( err && err.response ){
          this.getSelectedPaypalPlanError( err.response.data )
        } else {
          this.getSelectedPaypalPlanError( 'Fail to get plan' )
        }
        this.setState({ 
          loading: false, 
          selectedPaypalPlan: {}
        })
      })
    }
    getSelectedPaypalPlanSuccess = payload => this.setState({ selectedPaypalPlan: payload })
    getSelectedPaypalPlanError = error => this.requestError( error )

    getSelectedPaypalSubscription = subscription_id => {
      this.setState({ loading: true })
      let config = {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${ this.state.paypalToken }`
        }
      }
      return Axios.get( `https://api-m.paypal.com/v1/billing/subscriptions/${ subscription_id }`, config ).then( res => {
        this.getSelectedPaypalSubscriptionSuccess( res.data )
        this.setState({ loading: false })
      }).catch( err => {
        console.dir( err )
        if( err && err.response ){
          this.getSelectedPaypalSubscriptionError( err.response.data )
        } else {
          this.getSelectedPaypalSubscriptionError( 'Failed to get subscription info' )
        }
        this.setState({ loading: false })
      })
    }
    getSelectedPaypalSubscriptionSuccess = payload => this.setState({ selectedPaypalSubscription: payload })
    getSelectedPaypalSubscriptionError = error => {}

    getPaypalTransactions = ( subscription_id, start_datetime, end_datetime ) => {
      this.setState({ loading: true })
      let config = {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${ this.state.paypalToken }`
        }
      }
      return Axios.get( 
        `https://api-m.paypal.com/v1/billing/subscriptions/${ subscription_id }/transactions?start_time=${  start_datetime }&end_time=${ end_datetime }`, 
        config 
      ).then( res => {
        this.getPaypalTransactionsSuccess( res.data )
        this.setState({ loading: false })
      }).catch( err => {
        if( err & err.response ){
          this.getPaypalTransactionsError( err.response.data )
        } else {
          this.getPaypalTransactionsError( 'Failed to get Paypal transactions' )
        }
        this.setState({ loading: false })
      })
    }
    getPaypalTransactionsSuccess = payload => this.setState({ paypalTransactions: payload })
    getPaypalTransactionsError = error => this.requestError( error )

    suspendPaypalSubscription = subscription_id => {
      this.setState({ loading: true })
      let config = {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${ this.state.paypalToken }`
        }
      }
      return Axios.post(
        `https://api-m.paypal.com/v1/billing/subscriptions/${ subscription_id }/suspend`,
        {},
        config
      ).then( res => {
        this.suspendPaypalSubscriptionSuccess( res.data )
        this.setState({ loading: false })
      }).catch( err => {
        if( err && err.response ){
          this.suspendPaypalSubscriptionError( err.response.data )
        } else {
          this.suspendPaypalSubscriptionError( 'Failed to' )
        }
        this.setState({ loading: false })
      })
    }
    suspendPaypalSubscriptionSuccess = payload => {}
    suspendPaypalSubscriptionError = error => this.requestError( error )

    activatePaypalSubscription = subscription_id => {
      this.setState({ loading: true })
      let config = {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${ this.state.paypalToken }`
        }
      }
      return Axios.post(
        `https://api-m.paypal.com/v1/billing/subscriptions/${ subscription_id }/activate`,
        {},
        config
      ).then( res => {
        this.activatePaypalSubscriptionSuccess( res.data )
        this.setState({ loading: false })
      }).catch( err => {
        if( err && err.response ){
          this.activatePaypalSubscriptionError( err.response.data )
        } else {
          this.activatePaypalSubscriptionError( 'Failed to' )
        }
        this.setState({ loading: false })
      })
    }
    activatePaypalSubscriptionSuccess = payload => {}
    activatePaypalSubscriptionError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadPaypalHOC={ this.state.loading }
          onChangePaypalHOC={ this.onChangePaypalHOC }
          paypalError={ this.requestError }
          paypalToken={ this.state.paypalToken }
          getPaypalToken={ this.getPaypalToken }
          paypalPlans={ this.state.paypalPlans }
          getPaypalPlans={ this.getPaypalPlans }
          selectedPaypalPlan={ this.state.selectedPaypalPlan }
          getSelectedPaypalPlan={ this.getSelectedPaypalPlan }
          outstandingPaypalSubscriptions={ this.state.outstandingPaypalSubscriptions }
          getSelectedPaypalSubscription={ this.getSelectedPaypalSubscription }
          selectedPaypalSubscription={ this.state.selectedPaypalSubscription }
          getPaypalTransactions={ this.getPaypalTransactions }
          showReactivateSubscriptionModal={ this.state.showReactivateSubscriptionModal }
          showSubscriptionModal={ this.state.showSubscriptionModal }
          nonSubscribingCompanies={ this.state.nonSubscribingCompanies }
          selectedNonSubscribingCompany={ this.state.selectedNonSubscribingCompany }
          activatePaypalSubscription={ this.activatePaypalSubscription }
          suspendPaypalSubscription={ this.suspendPaypalSubscription } />
      )
    }
  }

  return WithHOC
}

export default HOC