import React from "react";
import {
  Switch,
  Route,
  BrowserRouter,
  HashRouter
} from "react-router-dom"
import Cookies from 'js-cookie'

import Login from './containers/Login'
import ForgotPassword from './containers/ForgotPassword'
import Dashboard from './containers/Dashboard'

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route {...rest} render={(props) => (
//     !Cookies.get( 'PRIMEVIEW_WEB_TOKEN' )
//       ? <Component {...props} />
//       : <Redirect to='/login' />
//   )} />
// );

const SmileRouter = ({ location }) => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={ Login } initialPath />
        <Route exact path="/forgot-password" component={ ForgotPassword } />
        <Route path="/dashboard" component={ Dashboard } />
      </Switch>
    </HashRouter>
  )
}

export default SmileRouter