import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithResetPassword from './actions'

class ResetPassword extends Component {

  updatePasswordData = ( key, val ) => {
    let tmp = _.cloneDeep( this.props.passwordInfo )
    tmp[ key ] = val
    return this.props.onChangePasswordHOC( 'passwordInfo', tmp )
  }

  render = () => {
    return (
      <Modal
        isOpen={ this.props.showResetPassword }
        size={ 'md' }
        toggle={() => this.props.hideResetPassword()}>
        <ModalHeader toggle={() => this.props.hideResetPassword()}>Reset password</ModalHeader>
        <ModalBody>
          <Form onSubmit={ e => e.preventDefault()}>
            <FormGroup>
              <Label>New password</Label>
              <Input
                type="password"
                value={ this.props.passwordInfo.password }
                onChange={ e => {
                  this.updatePasswordData( 'password', e.target.value )
                }} />
            </FormGroup>
            <FormGroup>
              <Label>Confirm new password</Label>
              <Input
                type="password"
                value={ this.props.passwordInfo.password_confirmation }
                onChange={ e => {
                  this.updatePasswordData( 'password_confirmation', e.target.value )
                }} />
            </FormGroup>
          </Form>
          { this.props.onLoadUpdatePassword && <LoadingOverlay /> }
        </ModalBody>
        <ModalFooter>
          <Button 
            color="primary"
            onClick={() => {
              this.props.updatePassword({ ...this.props.passwordInfo, id: this.props.data.profileReducer.profile.current_user.id })
            }}
            disabled={ this.props.passwordInfo.password === '' || this.props.passwordInfo.password === '' }>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default WithResetPassword( ResetPassword )