import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get, Post } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      showCreatePatient: false,
      showViewPatient: false,
      loading: false,
      newPatient: {
        name: '',
        contact_number: '',
        company_ids: []
      },
      selectedPatient: {
        name: '',
        contact_number: '',
        company_ids: []
      },
      patients: [],
      patientError: {},
      patientsSearchParam: ''
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangePatientsHOC = ( key, val ) => this.setState({ [ key ]: val })

    getPatients = ( searchParams ) => Get(
      `/patients?page=1&search=${ searchParams }`,
      this.getPatientsSuccess,
      this.getPatientsError,
      this.load
    )
    getPatientsSuccess = payload => this.setState({ patients: payload.data })
    getPatientsError = error => this.requestError( error )

    getSelectedPatient = ( id, type ) => Get(
      `/patients/${ id }`,
      payload => this.getSelectedPatientSuccess( payload, type ),
      this.getSelectedPatientError,
      this.load
    )
    getSelectedPatientSuccess = ( payload, type ) => this.setState({ selectedPatient: {
      ...payload,
      credit_advance: {
        ...payload.credit_advance,
        amount: parseFloat( payload.credit_advance.amount )
      }
    } })
    getSelectedPatientError = error => this.requestError( error )

    createPatient = data => Post(
      `/patients`,
      data,
      this.createPatientSuccess,
      this.createPatientError,
      this.load
    )
    createPatientSuccess = payload => {
      this.requestSuccess( 'Patient is created successfully.' )
      this.setState({ showCreatePatient: false })
      this.getPatients( this.state.newPatient.name )
      let tmp = _.cloneDeep( this.props.newAppointment )
      tmp[ 'patient_id' ] = payload.id
      this.props.onChangeAppointmentsHOC( 'newAppointment', tmp )
    }
    createPatientError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.requestError( error.exception )
        this.setState({ patientError: error })
      }
    }

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          onChangePatientsHOC={ this.onChangePatientsHOC }
          getPatients={ this.getPatients }
          getSelectedPatient={ this.getSelectedPatient }
          createPatient={ this.createPatient }
          showCreatePatient={ this.state.showCreatePatient }
          showViewPatient={ this.state.showViewPatient }
          onLoadPatientsHOC={ this.state.loading }
          newPatient={ this.state.newPatient }
          selectedPatient={ this.state.selectedPatient }
          patients={ this.state.patients }
          patientsSearchParam={ this.state.patientsSearchParam } />
      )
    }
  }
  return WithHOC
}

export default HOC