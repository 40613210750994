import React from 'react'
import _ from 'lodash'
import {
  Modal, ModalHeader, ModalBody, ModalFooter, 
  Row, Col, Label, Button,
  Input, InputGroup, InputGroupAddon, InputGroupText,
  Form, FormGroup,
  Card, CardHeader
} from 'reactstrap'
import Cookies from 'js-cookie'
import { purgeStoredState } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { Multiselect } from 'react-widgets'
import PaypalButton from 'react-paypal-express-checkout'

import CurrencySettings from 'utils/currencies'

const ListItem = ({ item }) => <span>{ item.name }</span>

const ReactivateSubscription = ({
  history,
  showReactivateSubscriptionModal,
  outstandingPaypalSubscriptions,
  selectedPaypalSubscription,
  onChangePaypalHOC,
  paypalError,
  activatePaypalSubscription,
  createCompanyReactivationPayment,
  paypalPlans,
  createCompanySubscriptionPayment,
  updateCompanySubscription
}) => {
  const onClickSignOut = () => {
    purgeStoredState({
      key: 'project-smile-web',
      storage
    }).then(() => {
      Cookies.remove( 'PRIMEVIEW_WEB_TOKEN' )
      history.push( '/' )
    })
  }

  return(
    <Modal isOpen={ showReactivateSubscriptionModal } size='xl' >
      <ModalHeader
        close={ <Button className="btn-pill btn-shadow btn-shine" color="focus" onClick = { () => onClickSignOut() }>Logout</Button> }>
        Reactivate Subscription</ModalHeader>
      <ModalBody>
        {
          _.filter( 
            // outstandingPaypalSubscriptions, outstandingSub => parseFloat( outstandingSub.billing_info.outstanding_balance.value ) === 0
            outstandingPaypalSubscriptions, outstandingSub => outstandingSub.paypal_subscription_id.indexOf( '-SUSPENDED' ) > -1
          ).length > 0 ? (
            <Row>
              <Col md={ 12 } >
                <Label className='w-100' >Following is/are the companies suspended:</Label>
                <ul>
                  {
                    _.filter( 
                      // outstandingPaypalSubscriptions, outstandingSub => parseFloat( outstandingSub.billing_info.outstanding_balance.value ) === 0
                      outstandingPaypalSubscriptions, outstandingSub => outstandingSub.paypal_subscription_id.indexOf( '-SUSPENDED' ) > -1
                    ).map( item => <li>{ item.name }</li> )
                  }
                </ul>
                <Label className='w-100' >Please contact super admin to reactivate the companies</Label>
              </Col>
            </Row>
          ) : (
            <Form>
              <Row>
                <Col md={ 8 } >
                  <FormGroup>
                    <Label>Select company</Label>
                    <Multiselect
                      data={ outstandingPaypalSubscriptions }
                      textField={ 'name' }
                      itemComponent={ ListItem }
                      value={ selectedPaypalSubscription }
                      onChange={ val => onChangePaypalHOC( 'selectedPaypalSubscription', val )} />
                  </FormGroup>
                  {/* <FormGroup>
                    <Label>Outstanding balance</Label>
                    <Input
                      type='text'
                      disabled={ true }
                      value={ `RM ${ 
                        selectedPaypalSubscription.length > 0 
                          ? parseFloat( _.reduce( 
                            selectedPaypalSubscription, 
                            ( sum, item ) => ( sum + parseFloat( item.billing_info.outstanding_balance.value ) ),
                            0
                          ) ).toFixed( 2 )
                          : '0.00' }` }/>
                  </FormGroup> */}
                </Col>
              </Row>
              <Row> 
                {
                  selectedPaypalSubscription.length > 0 
                  && paypalPlans[ 0 ].billing_cycles
                  && selectedPaypalSubscription 
                  && selectedPaypalSubscription.map( subscribingCompany => {
                    return(
                      <Col lg={ 4 } md={ 6 } sm={ 12 }>
                        <Card 
                          className='card-shadow-primary border-primary d-flex align-items-center p-3'
                          style={{ borderTop: "4px solid #ffffff" }} >
                          <CardHeader>{ `Company: ${ subscribingCompany.name }` }</CardHeader>
                          <Label
                            className='text-center font-weight-bold'
                            style={{ 
                              color: "#545cd8", 
                              fontSize:"24px",
                              maxWidth: "100%",
                              marginTop: "1rem",
                              marginBottom: "1.5rem" }} >
                            { subscribingCompany.paypal_plan.name }</Label>
                          <Label
                            className='text-center font-weight-bold'
                            style={{ color: "#545cd8", fontSize:"24px", marginBottom: ".2rem" }} >
                            <span
                              className='mr-2' 
                              style={{ color: "#000000", fontSize:'12px' }} >RM</span>
                            { `${ parseFloat( subscribingCompany.paypal_plan.billing_cycles[ 0 ].pricing_scheme.fixed_price.value ).toFixed( 2 ) }` }</Label>
                          <Label
                            className='text-center font-weight-bold'
                            style={{ color: "#545cd8", fontSize:"12px", marginBottom: "1.5rem" }} >
                            { `per ${ subscribingCompany.paypal_plan.billing_cycles[ 0 ].frequency.interval_unit.toLowerCase() }` }</Label>
                          <Row className='w-100 justify-content-center' >
                            <span style={{ width: "100%", height: "1px", background: "#dedede", marginBottom: "1.5rem" }} />
                          </Row>
                          <Row className='justify-content-center w-100 p-3 text-justify' >
                            <Label
                              className='font-weight-bold'
                              style={{ 
                                color: "#545cd8", 
                                fontSize:"12px",
                                maxWidth: "100%",
                                marginBottom: "1.5rem", }} >
                              { subscribingCompany.paypal_plan.description }</Label>
                          </Row> 
                          <InputGroup 
                            className='d-flex align-items-center'
                            style={{ background: "#fff" }}>
                            <Input
                              type='number'
                              min={ 1 }
                              step='1'
                              placeholder='Pay for...'
                              value={ subscribingCompany.cycle }
                              onChange={ e => { 
                                let tmp = _.cloneDeep( selectedPaypalSubscription )
                                tmp[ _.findIndex( tmp, item => parseInt( item.id ) === parseInt( subscribingCompany.id ) ) ][ 'cycle' ] = e.target.value
                                onChangePaypalHOC( 'selectedPaypalSubscription', tmp ) 
                              }}/>
                            <InputGroupAddon addonType="append">
                              <InputGroupText style={{ background: "#fff" }}>
                                { subscribingCompany.paypal_plan.billing_cycles[ 0 ].frequency.interval_unit.toLowerCase() + '(s)' }
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Card>
                      </Col>
                    )
                  })
                }
                <Col md={ 12 } >
                  <FormGroup style={{ width: "66%", marginTop: selectedPaypalSubscription.length > 0 ? "1rem" : "0" }}>
                    <Label>Amount to be paid</Label>
                    <Input
                      type='text'
                      disabled={ true }
                      value={ `RM ${ 
                        selectedPaypalSubscription.length > 0 
                          ? parseFloat( _.reduce( 
                            selectedPaypalSubscription, 
                            ( sum, item ) => ( 
                              sum 
                              + item.paypal_plan.billing_cycles[ 0 ].pricing_scheme.fixed_price.value
                              * item.cycle
                            ),
                            0
                          ) ).toFixed( 2 )
                          : '0.00' }` }/>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          )
        }
      </ModalBody>
      <ModalFooter>
        {
          _.filter( 
            // outstandingPaypalSubscriptions, outstandingSub => parseFloat( outstandingSub.billing_info.outstanding_balance.value ) === 0
            outstandingPaypalSubscriptions, outstandingSub => outstandingSub.paypal_subscription_id.indexOf( '-SUSPENDED' ) > -1
          ).length === 0 && (
            <PaypalButton
              env={ window.location.origin.indexOf( 'staging' ) > -1 ? 'sandbox' : 'production' }
              client={{ 
                sandbox: 'ATqD6ihXN2A5GLAN3jWwT4IesV3DH9ljf_4tZMPEZechIbnq085nhzTY3wSwJ8OHsVVelKWL6XeS9MJl',
                production: 'AZhibMEJnhe8TP8-bfG7ZNpUq_GhCHu0PJiA4G1Owg5mZdre_QIzn9fK7nn-3v1QmBd6reiOYB7HwYoM' }}
              currency={ CurrencySettings() }
              style={{
                shape: 'rect',
                color: 'gold'
              }}
              // total={ selectedPaypalSubscription.length > 0 
              //   ? parseFloat( _.reduce( 
              //     selectedPaypalSubscription, 
              //     ( sum, item ) => ( sum + parseFloat( item.billing_info.outstanding_balance.value ) ),
              //     0
              //   ) ).toFixed( 2 )
              //   : '0.00' }
              total={
                selectedPaypalSubscription.length > 0 
                  ? parseFloat( _.reduce( 
                    selectedPaypalSubscription, 
                    ( sum, item ) => ( 
                      sum 
                      + item.paypal_plan.billing_cycles[ 0 ].pricing_scheme.fixed_price.value
                      * item.cycle
                    ),
                    0
                  ) ).toFixed( 2 )
                  : 0
              }
              onError={ error => paypalError( error ) }
              onSuccess={ payment => {
                if( payment.paid ){
                  // Promise.all( selectedPaypalSubscription.map( async( subscription ) => {
                  //     await activatePaypalSubscription( subscription.id )
                  //     return
                  //   }) 
                  // ).then( () => {
                  //   Promise.all( selectedPaypalSubscription.map( async( companySubscription ) => {
                  //       await createCompanyReactivationPayment({
                  //         status_id: 1,
                  //         amount: companySubscription.billing_info.outstanding_balance.value,
                  //         company_id: companySubscription.company_id
                  //       })
                  //       return
                  //     }) 
                  //   ).then( () => {
                  //     window.location.reload()
                  //   })
                  // })
                  Promise.all( selectedPaypalSubscription.map( async( subscription ) => {
                      await createCompanySubscriptionPayment({
                        status_id: 1,
                        amount: subscription.paypal_plan.billing_cycles[ 0 ].pricing_scheme.fixed_price.value * subscription.cycle,
                        company_id: subscription.id,
                        cycle: parseInt( subscription.cycle )
                      })
                      return
                    }) 
                  ).then( () => {
                    Promise.all( selectedPaypalSubscription.map( async( companySubscription ) => {
                        await updateCompanySubscription({
                          payment_status_id: 1,
                          paypal_subscription_id: companySubscription.paypal_plan.id,
                          id: companySubscription.id
                        }) 
                        return
                      }) 
                    ).then( () => {
                      window.location.reload()
                    })
                  })
                }
              }} />
          )
        }
      </ModalFooter>
    </Modal>
  )
}

export default ReactivateSubscription