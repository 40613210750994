import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'
import Moment from 'moment'

import { Get } from 'utils/axios'
import getDomainURL from 'utils/api'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      collections: {
        by_users: [],
        dr_breakdown: [],
        payment_types: [],
        total: 0
      },
      loading: false,
      searchParams: [
        {
          key: 'start_date',
          value: new Date()
        },
        {
          key: 'end_date',
          value: new Date()
        }
      ],
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeCollectionsHOC = ( key, val ) => this.setState({ [key]: val })

    getCompaniesCollections = ( start_date, end_date ) => Get(
      `/collections/reserved_collection?start_date=${ start_date }&end_date=${ end_date }`,
      this.getCompaniesCollectionsSuccess,
      this.getCompaniesCollectionsError,
      this.load
    )
    getCompaniesCollectionsSuccess = payload => this.setState({ collections: payload })
    getCompaniesCollectionsError = error => this.requestError( 'Failed to get collections.' )

    exportCompaniesCollectionsReport = data => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/collections/reserved_download?start_date=${ data.start_date }&end_date=${ data.end_date }`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( blobby => {
        this.load( false )
        return FileSaver.saveAs( blobby, `${ Moment().format( 'YYYYMMDDHH:mm' ) }_collections.pdf` )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save receipt. Please try again.' )
      })
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          collections={ this.state.collections }
          onLoadCollections={ this.state.loading }
          searchParams={ this.state.searchParams }
          onChangeCollectionsHOC={ this.onChangeCollectionsHOC }
          getCompaniesCollections={ this.getCompaniesCollections }
          exportCompaniesCollectionsReport={ this.exportCompaniesCollectionsReport } />
      )
    }
  }
  return WithHOC
}

export default HOC