import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import _ from 'lodash'

const SearchForm = ({
  data,
  selectedCompanyId,
  getDashboardInfoToday,
  getDashboardOccupiedSize,
  onChangeDashboardHOC
}) => {
  return (
    <Card className="main-card mb-3">
      <CardHeader>{ 'Search' }</CardHeader>
      <CardBody>
        <Form onSubmit={ e => e.preventDefault() }>
          <FormGroup>
            <Label>{ 'Company' }</Label>
            <Input
              type='select'
              value={ selectedCompanyId }
              onChange={ e => onChangeDashboardHOC( 'selectedCompanyId', parseInt( e.target.value ) )}>
              <option key={ 0 } value={ 0 } ></option>
              { data.profileReducer.profile.companies.map( company => <option key={ company.id } value={ company.id } >{ company.name }</option> ) }
            </Input>
          </FormGroup>
          <div className="d-flex">
            <Button 
              color="primary"
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                getDashboardInfoToday( selectedCompanyId ) 
                getDashboardOccupiedSize( selectedCompanyId ) 
              }}>
              { 'Search' }</Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default SearchForm