import React, { Component } from 'react'
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead'
import _ from 'lodash'

import 'react-bootstrap-typeahead/css/Typeahead.css'

class CustomTypeahead extends Component {
  state = {
    preselectedValue: []
  };

  _renderMenuItemChildren = ( option, props, index ) => {
    return [
      <Highlighter
        key="name"
        search={ props.text }>
        { option.value }
      </Highlighter>
    ]
  };

  render = () => {
    const {
      options,
      onSelect, 
      selectedValue,
      filterBy,
      disableEdit,
      style,
      id
    } = this.props;
    return (
      <Typeahead
        id={ id }
        filterBy={ [ "value" ] }
        labelKey={ "value" }
        selected={ selectedValue }
        options={ options }
        onChange={ selected => {
          return onSelect( selected )
        }}
        renderMenuItemChildren={ this._renderMenuItemChildren }
        filterBy={ filterBy }
        disabled={ disableEdit }
        style={ style } />
    )
  }
}

export default CustomTypeahead