export default function() {
  if( window.location.host.indexOf( 'localhost' ) !== -1 ) {
    return 'https://cloud-staging.primeview.site';
  } else if( window.location.host.indexOf( 'app-staging.primeview.site' ) !== -1 ) {
    return 'https://cloud-staging.primeview.site';
  } else if( window.location.host.indexOf( 'app.primeview.site' ) !== -1 ) {
    return 'https://cloud.primeview.site';
  } else if( window.location.host.indexOf( 'app-idr.primeview.site' ) !== -1 ) {
    return 'https://cloud-idr.primeview.site';
  } else {
    return 'https://cloud-staging.primeview.site';
  }
}