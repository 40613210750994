import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { Multiselect } from 'react-widgets'
import _ from 'lodash'
import Moment from 'moment'

import Typeahead from 'components/Typeahead'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

const ListItem = ({ item }) => (
  <span>
    { `${ item.name } - ${ item.nric_no } - ${ item.contact_number }` }
  </span>
)

const Update = ({
  showUpdateMedicalCert,
  selectedMedicalCert,
  updateMedicalCert,
  onChangeMedicalCertHOC,
  patients,
  doctors,
  data,
  onLoadMedicalCerts
}) => {
  const onChangeMCData = ( key, val ) => {
    let tmp = _.cloneDeep( selectedMedicalCert )
    tmp[ key ] = val
    return onChangeMedicalCertHOC( 'selectedMedicalCert', tmp )
  }

  return (
    <Modal
      isOpen={ showUpdateMedicalCert }
      size={ 'lg' }
      toggle={() => {
        onChangeMedicalCertHOC( 'showUpdateMedicalCert', false )
      }}>
      <ModalHeader toggle={() => onChangeMedicalCertHOC( 'showUpdateMedicalCert', false )}>Update medical cert</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>Select company</Label>
            <Input
              type="select"
              disabled={ true }
              value={ selectedMedicalCert.company_id }
              onChange={ e => {
                onChangeMCData( 'company_id', e.target.value )
              }}>
              <option></option>
              {
                data.profileReducer && data.profileReducer.profile.companies.map( item => {
                  return (
                    <option key={ item.id } value={ item.id }>{ item.name }</option>
                  )
                })
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Select branch</Label>
            <Input
              type="select"
              disabled={ true }
              value={ selectedMedicalCert.branch_id }
              onChange={ e => {
                onChangeMCData( 'branch_id', e.target.value )
              }}>
              <option></option>
              {
                data.profileReducer && data.profileReducer.profile.branches.map( item => {
                  return (
                    <option key={ item.id } value={ item.id }>{ item.name }</option>
                  )
                })
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Select patient</Label>
            <Multiselect
              disabled
              data={ patients }
              textField={ 'name' }
              itemComponent={ ListItem }
              value={ selectedMedicalCert.patient_id > 0 
                ? _.find( patients, { id: parseInt( selectedMedicalCert.patient_id ) } ) 
                  ? [ _.find( patients, { id: parseInt( selectedMedicalCert.patient_id ) } ) ] 
                  : [ {} ]
                : [] }
              onChange={ val => val.length === 1 
                ? onChangeMCData( 'patient_id', val[ 0 ].id )
                : onChangeMCData( 'patient_id', 0 )
              } />
          </FormGroup>
          <FormGroup>
            <Label>Examine date</Label>
            <input
              type="date"
              disabled={ true }
              className="form-control"
              value={ selectedMedicalCert.examine_date ? Moment( selectedMedicalCert.examine_date ).format( 'YYYY-MM-DD' ) : '' }
              onChange={ e => {
                onChangeMCData( 'examine_date', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>Start date</Label>
            <input
              type="date"
              disabled={ true }
              className="form-control"
              value={ selectedMedicalCert.start_date ? Moment( selectedMedicalCert.start_date ).format( 'YYYY-MM-DD' ) : '' }
              onChange={ e => {
                onChangeMCData( 'start_date', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>End date</Label>
            <input
              type="date"
              disabled={ true }
              className="form-control"
              value={ selectedMedicalCert.end_date ? Moment( selectedMedicalCert.end_date ).format( 'YYYY-MM-DD' ) : '' }
              onChange={ e => {
                onChangeMCData( 'end_date', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>remark</Label>
            <Input
              type="text"
              value={ selectedMedicalCert.remark }
              onChange={ e => {
                onChangeMCData( 'remark', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>Select doctor</Label>
            <Typeahead
              id={ 'doctors' }
              disableEdit={ true }
              multiple={ false }
              options={ doctors }
              onSelect={ val => {
                if( val && val.length > 0 ) {
                  onChangeMCData( 'user_id', val[ 0 ].id )
                } else {
                  onChangeMCData( 'user_id', '' )
                }
              }}
              selectedValue={ _.find( doctors, { id: selectedMedicalCert.user_id }) ? [ _.find( doctors, { id: selectedMedicalCert.user_id }) ] : [] } />
          </FormGroup>
        </Form>
        { onLoadMedicalCerts && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={ () => updateMedicalCert( selectedMedicalCert ) }>
          Submit</Button>
      </ModalFooter>
    </Modal>
  )
}

export default Update