import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col
} from 'reactstrap'
import _ from 'lodash'
import { IoIosPersonAdd } from 'react-icons/io'
import { FaRegEye } from 'react-icons/fa'
import { Multiselect } from 'react-widgets'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Moment from 'moment'

import SmileDatepicker from 'components/Datepicker'
import CreatePatient from './CreatePatient'
import ViewPatient from './ViewPatient'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithPatients from './actions/Patients'
import WithOffDays from 'actions/dictionary/offDays'
import WithMedicalRecords from './actions/MedicalRecords'

const DummyPatients = [
  {
    id: 1,
    name: 'John Doe'
  },
  {
    id: 2,
    name: 'John Lark'
  }
]

const ListItem = ({ item }) => (
  <span>
    { `${ item.name } - ${ item.nric_no } - ${ item.contact_number }` }
  </span>
)

const Create = ( props ) => {
  let {
    data,
    showCreateAppointment,
    newAppointment,
    onChangeAppointmentsHOC,
    onLoadAppointments,
    createAppointment,
    onChangePatientsHOC,
    newPatient,
    selectedPatient,
    showCreatePatient,
    showViewPatient,
    onLoadPatients,
    getPatients,
    createPatient,
    patients,
    doctors,
    getOffDays
  } = props

  const [ viewedPatient, updateViewedPatient ] = useState({})

  useEffect(() => {
    if( showCreateAppointment ) {
      // getPatients( '' )
    }
  }, [ showCreateAppointment ])

  const onChangeAppointmentData = ( key, val ) => {
    let tmp = _.cloneDeep( newAppointment )
    tmp[ key ] = val

    switch( key ){
      case 'company_id':
        if( props.data.profileReducer.profile.current_user.branches.length > 0 ){
          let tmpBranch = _.find( props.data.profileReducer.profile.current_user.branches, { company_id: parseInt( val ) } )
          let tmpDr = _.find( doctors, { id: parseInt( newAppointment.user_id ) } )
          if( !_.isEmpty( tmpBranch ) ){
            tmp.branch_id = tmpBranch.id
            if( !_.isEmpty( tmpDr ) && _.findIndex( tmpDr.branch_ids, item => item === parseInt( tmp.branch_id ) ) === -1 ){
              tmp.user_id = ''
            }
          } else {
            tmp.branch_id = ''
            tmp.user_id = ''
          }
        } else {
          tmp.branch_id = ''
          tmp.user_id = ''
        }
        break
      case 'branch_id':
        let tmpDr = _.find( doctors, { id: parseInt( newAppointment.user_id ) } )
        if( !_.isEmpty( tmpDr ) && _.findIndex( tmpDr.branch_ids, item => item === parseInt( val ) ) === -1 ){
          tmp.user_id = ''
        }
        break
    }
    return onChangeAppointmentsHOC( 'newAppointment', tmp )
  }

  return (
    <Modal
      isOpen={ showCreateAppointment }
      size={ 'lg' }
      toggle={() => onChangeAppointmentsHOC( 'showCreateAppointment', false )}>
      <ModalHeader toggle={() => onChangeAppointmentsHOC( 'showCreateAppointment', false )}>Create appointment</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Select a company</Label>
                <Input
                  type="select"
                  value={ newAppointment.company_id }
                  onChange={ e => onChangeAppointmentData( 'company_id', parseInt( e.target.value )  ) }>
                  <option></option>
                  {
                    data.profileReducer.profile.companies.map( item => {
                      return (
                        <option key={ item.id } value={ item.id }>{ item.name }</option>
                      )
                    })
                  }
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Select a branch</Label>
                <Input
                  type="select"
                  value={ newAppointment.branch_id }
                  onChange={ e => onChangeAppointmentData( 'branch_id', parseInt( e.target.value )  ) }>
                  <option>Please select a branch</option>
                  {
                    _.filter( data.profileReducer.profile.current_user.branches, branch => branch.company_id === newAppointment.company_id ).map( item => {
                      return (
                        <option key={ item.id } value={ item.id }>{ item.name }</option>
                      )
                    })
                  }
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Select doctor</Label>
                <Input 
                  type="select"
                  value={ newAppointment.user_id }
                  onChange={ e => {
                    onChangeAppointmentData( 'user_id', e.target.value )
                    getOffDays( JSON.stringify( [ e.target.value ] ) )
                  }}>
                  <option></option>
                  {
                    _.filter( doctors, doctor => _.findIndex( doctor.branch_ids, item => item === newAppointment.branch_id ) > -1 ).map( item => {
                      return <option key={ item.id } value={ item.id }>{ `${ item.salutation } ${ item.name }` }</option>
                    })
                  }
                </Input>
                <span>If doctor is off, the date will not be able to be selected.</span>
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Start date time</Label>
                <SmileDatepicker
                  showTimeSelect={ true }
                  selectedDate={ newAppointment.start_datetime }
                  onChange={ val => {
                    onChangeAppointmentData( 'start_datetime', val )
                  }}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  disabled={ false } 
                />
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>End date time</Label>
                <SmileDatepicker
                  showTimeSelect={ true }
                  selectedDate={ newAppointment.end_datetime }
                  onChange={ val => {
                    onChangeAppointmentData( 'end_datetime', val )
                  }}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  // excludeDates={[ new Date() ]}
                  minDate={ newAppointment.start_datetime }
                />
              </FormGroup>
            </Col>
            <Col md={ 7 }>
              <FormGroup>
                <Label>Select patient</Label>
                <div className="d-flex">
                  <Input 
                    type="text" 
                    value={ props.searchParams }
                    onChange={ e => {
                      props.onChangePatientsHOC( 'searchParams', e.target.value )
                    }}
                    placeholder="Fill in patient NRIC or name here and click search button" />
                  <Button 
                    color="primary" 
                    onClick={() => {
                      props.getPatients( props.searchParams )
                    }}>Search</Button>
                </div>
                {
                  props.onLoadPatients 
                    ? (
                      <p>Loading patients......</p>
                    ) : (
                      <>
                        <Multiselect
                          data={ patients.data }
                          textField={ 'name' }
                          itemComponent={ ListItem }
                          value={ _.find( patients.data, { id: newAppointment.patient_id } ) ? [ _.find( patients.data, { id: newAppointment.patient_id } ) ] : [] }
                          onChange={ val => {
                            if( val.length === 1 ) {
                              updateViewedPatient( val[ 0 ] )
                              onChangeAppointmentData( 'patient_id', val[ 0 ].id )
                              props.getMedicalRecords( val[0].id )
                            } else {
                              updateViewedPatient( {} )
                              onChangeAppointmentData( 'patient_id', '' )
                            }
                          }} />
                        <Button 
                          color="primary" 
                          className="btn-icon mt-2"
                          onClick={() => {
                            onChangePatientsHOC( 'showCreatePatient', true )
                            onChangeAppointmentData( 'patient_id', '' )
                          }}>
                          <IoIosPersonAdd size={ 24 } />
                        </Button>
                        {
                          !_.isEmpty( viewedPatient ) && <Button
                            color="primary"
                            onClick={() => {
                              onChangePatientsHOC( 'showViewPatient', true )
                            }}>
                            <FaRegEye size={ 24 } />
                          </Button>
                        }
                      </>
                    )
                }
              </FormGroup>
            </Col>
            <Col md={ 5 }>
              <p>Past appts for selected patient:</p>
              {
                props.medicalRecords.length === 0 
                  ? <p>No medical record found.</p>
                  : props.medicalRecords.map( item => {
                    return (
                      <Button
                        color={ item.is_incomplete_treatment ? 'danger' : 'primary' }>
                        { Moment( item.created_at ).format( 'DD MMM YYYY HH:mm' ) }
                      </Button>
                    )
                  })
              }
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Status</Label>
                <Input 
                  type="select"
                  value={ newAppointment.status }
                  onChange={ e => {
                    onChangeAppointmentData( 'status', e.target.value )
                  }}>
                  <option></option>
                  <option value={ 'Active' }>Active</option>
                  <option value={ 'Confirmed' }>Confirmed</option>
                  <option value={ 'Shown' }>Shown</option>
                  <option value={ 'No show' }>No show</option>
                  <option value={ 'Waiting list' }>Waiting list</option>
                  <option value={ 'Cancelled' }>Cancelled</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Details</Label>
                <Input
                  type="textarea"
                  value={ newAppointment.details }
                  onChange={ e => {
                    onChangeAppointmentData( 'details', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup check>
                <Label check>
                  <Input
                    type={ 'checkbox' }
                    checked={ newAppointment.is_incomplete_treatment }
                    onChange={ e => {
                      onChangeAppointmentData( 'is_incomplete_treatment', e.target.checked )
                    }} />
                  Is patient coming back from incomplete treatment?
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <CreatePatient 
          newPatient={ newPatient }
          createPatient={ createPatient }
          showCreatePatient={ showCreatePatient }
          onLoadPatients={ onLoadPatients }
          onChangePatientsHOC={ onChangePatientsHOC } />
        <ViewPatient
          viewedPatient={ viewedPatient }
          showViewPatient={ showViewPatient }
          selectedPatient={ selectedPatient }
          onLoadPatients={ onLoadPatients }
          onChangePatientsHOC={ onChangePatientsHOC } />
        { onLoadAppointments && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={() => {
            createAppointment( newAppointment )
          }}>Submit</Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => ({ data: state })

export default compose(
  WithPatients,
  WithOffDays,
  WithMedicalRecords,
  connect( mapStateToProps )
)( Create )