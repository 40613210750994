import React, { useEffect } from 'react'
import { 
  Row, 
  Col,
  Card,
  CardBody,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithPatients from './actions'

const TreatedPatients = props => {
  useEffect(() => {
    props.getPatients( props.data.profileReducer.profile.current_user.id )
  }, [])
  return (
    <>
      <PageTitle
        heading="Treated Patients"
        subheading="Listings of all the treated patients presence in the system."
        icon="pe-7s-culture icon-gradient bg-happy-itmeo" />
      <div>
        <Row>
          <Col md={ 12 }>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  data={ props.patients }
                  columns={[
                    {
                      Header: 'Name',
                      accessor: 'name'
                    },
                    {
                      Header: 'IC',
                      accessor: 'nric_no'
                    },
                    {
                      Header: 'Contact',
                      accessor: 'contact_number'
                    },
                    {
                      Header: 'Address',
                      accessor: 'address'
                    }
                  ]} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      { props.onLoadPatients && <LoadingOverlay /> }
    </>
  )
}

export default WithPatients( TreatedPatients )