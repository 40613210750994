import React, { useEffect } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  Row,
  Col
} from 'reactstrap'
import Moment from 'moment'
import _ from 'lodash'
import { compose } from 'redux'

import WithCountries from 'actions/dictionary/countries'
import WithStates from 'actions/dictionary/states'
import WithCities from 'actions/dictionary/cities'

import UserIcon from 'assets/Images/av1.png'

const Languages = [
  { id: 1, name: 'English' },
  { id: 2, name: 'Mandarin' },
  { id: 3, name: 'Malay' }
]

const View = ({
  showViewPatient,
  onChangePatientsHOC,
  selectedPatient,
  countries,
  states,
  cities,
  getCountries,
  getStates,
  getCities
}) => {

  // useEffect(() => {
  //   if( showViewPatient && !_.isEmpty( selectedPatient ) ){
  //     getCountries()
  //     getStates( selectedPatient.country )
  //     getCities( selectedPatient.country, selectedPatient.state )
  //   }
  // }, [ showViewPatient, selectedPatient ] )

  return (
    <Modal
      isOpen={ showViewPatient }
      size={ 'lg' }
      toggle={() => {
        onChangePatientsHOC( 'showViewPatient', false )
      }}>
      <ModalHeader toggle={() => onChangePatientsHOC( 'showViewPatient', false )}>View patient</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={ 6 }>
            <span>Name</span>
            <h5>{ selectedPatient.name }</h5>
          </Col>
          <Col md={ 6 }>
            <img src={ selectedPatient.photo_url ? selectedPatient.photo_url : UserIcon } />
          </Col>
          <Col md={ 12 } style={{ height: '10px' }}></Col>
          <Col md={ 6 }>
            <span>NRIC/Passport</span>
            <h5>{ selectedPatient.nric_no }</h5>
          </Col>
          <Col md={ 6 }>
            <span>Email</span>
            <h5>{ selectedPatient.email }</h5>
          </Col>
          <Col md={ 6 }>
            <span>DOB</span>
            <h5>{ Moment( selectedPatient.date_of_birth ).format( 'DD MMM YYYY' ) }</h5>
          </Col>
          <Col md={ 6 }>
            <span>Age</span>
            <h5>{ selectedPatient.age }</h5>
          </Col>
          <Col md={ 6 }>
            <span>Gender</span>
            <h5>{ selectedPatient.gender }</h5>
          </Col>
          <Col md={ 12 }>
            <span>Medical allergies</span>
            <h5>{ selectedPatient.allergies }</h5>
          </Col>
          <Col md={ 12 }>
            <span>Address</span>
            <h5>{ selectedPatient.address }</h5>
          </Col>
          <Col md={ 6 }>
            <span>Country</span>
            <h5>{ selectedPatient.country }</h5>
            {/* <h5>{ _.find( countries, { id: selectedPatient.country }) ? _.find( countries, { id: selectedPatient.country }).name : '' }</h5> */}
          </Col>
          <Col md={ 6 }>
            <span>State</span>
            <h5>{ selectedPatient.state }</h5>
            {/* <h5>{ _.find( states, { code: selectedPatient.state }) ? _.find( states, { code: selectedPatient.state }).name : '' }</h5> */}
          </Col>
          <Col md={ 6 }>
            <span>Postcode</span>
            <h5>{ selectedPatient.postcode }</h5>
          </Col>
          <Col md={ 6 }>
            <span>City</span>
            <h5>{ selectedPatient.city }</h5>
          </Col>
          <Col md={ 6 }>
            <span>Language</span>
            <h5>{ _.find( Languages, { id: selectedPatient.language }) ? _.find( Languages, { id: selectedPatient.language }).name : '' }</h5>
          </Col>
          <Col md={ 6 }>
            <span>Contact number</span>
            <h5>{ selectedPatient.contact_number }</h5>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default compose(
  WithCountries,
  WithStates,
  WithCities
)( View )