import _ from 'lodash'

export const priceWithSeparators = value => {
  let tmp = []
  let tmpVal = parseFloat( value ).toFixed( 2 )
  let currency = {
    name: '',
    decimal_separator: '.'
  }
  tmp = tmpVal.split( '.' )
  if( tmp.length > 0 && !_.isEmpty( tmp[ 0 ] ) ){
    return `${ currency.name }${ tmp[ 0 ].replace( /\B(?=(\d{3})+(?!\d))/g, currency.decimal_separator === ',' ? '.' : ',' ) }${ tmp.length > 1 ? `${ currency.decimal_separator }${ tmp[ 1 ] }` : `${ currency.decimal_separator }00` }`
  } else {
    return `${ currency.name }0${ currency.decimal_separator }00`
  }
}

export default priceWithSeparators