import React from 'react'
import {
  FormGroup,
  Container,
  Row,
  Col
} from 'reactstrap'
import _ from 'lodash'

const topLeft = [ 18, 17, 16, 15, 14, 13, 12, 11 ]
const topRight = [ 21, 22, 23, 24, 25, 26, 27, 28 ]
const btmLeft = [ 48, 47, 46, 45, 44, 43, 42, 41 ]
const btmRight = [ 31, 32, 33, 34, 35, 36, 37, 38 ]
const topLeft2 = [ 55, 54, 53, 52, 51 ]
const topRight2 = [ 61, 62, 63, 64, 65 ]
const btmLeft2 = [ 85, 84, 83, 82, 81 ]
const btmRight2 = [ 71, 72, 73, 74, 75 ]

const ToothChart = ({
  data,
  onChange
}) => {
  return (
    <div className="w-100 pt-2">
      <FormGroup>
        <Container 
          style={{
            minWidth: "700px",
            paddingRight: "30px",
            paddingBottom: "30px" }} >
          <Row>
            <Col 
              xs={ 6 } 
              style={{ 
                border: "2px solid #000000",
                borderTop: "none",
                borderLeft: "none",
                paddingBottom: "15px" }}>
              <Row style={{ float: "right" }}>
              {
                topLeft.map( item => <div style={{
                  minWidth: "18px", 
                  display: "table",
                  float: "left",
                  marginRight: "20px" }}>
                  <label style={{ display: "table-row", width: "100%" }} >{ item }</label>
                  <input 
                    type="checkbox" 
                    style={{ display: 'table-row', width: "100%" }}
                    checked={ data.indexOf( item ) > -1 }
                    onChange={ e => {
                      let tmp = _.cloneDeep( data )
                      if( e.target.checked ) {
                        tmp.push( item )
                      } else {
                        tmp.splice( data.indexOf( item ), 1 )
                      }
                      onChange( tmp )
                    }} />
                </div> )
              }
              </Row>
            </Col>
            <Col 
              xs={ 6 } 
              style={{ 
                border: "2px solid #000000",
                borderTop: "none",
                borderRight: "none",
                borderLeft: "none",
                paddingBottom: "15px" }}>
              <Row style={{ float: "left" }}>
              {
                topRight.map( item => <div style={{
                  minWidth: "18px", 
                  display: "table",
                  float: "left",
                  marginLeft: "20px" }} >
                  <label style={{ display: "table-row", width: "100%" }} >{ item }</label>
                  <input 
                    type="checkbox" 
                    style={{ display: 'table-row', width: "100%" }}
                    checked={ data.indexOf( item ) > -1 }
                    onChange={ e => {
                      let tmp = _.cloneDeep( data )
                      if( e.target.checked ) {
                        tmp.push( item )
                      } else {
                        tmp.splice( data.indexOf( item ), 1 )
                      }
                      onChange( tmp )
                    }} />
                </div> )
              }
              </Row>
            </Col>
          </Row>
          <Row>
            <Col 
              xs={ 6 } 
              style={{ 
                border: "2px solid #000000",
                borderBottom: "none",
                borderTop: "none",
                borderLeft: "none",
                paddingTop  : "15px" }}>
              <Row style={{ float: "right" }} >
              {
                btmLeft.map( item => <div style={{
                  minWidth: "18px", 
                  display: "table",
                  float: "left",
                  marginRight: "20px" }} >
                  <input 
                    type="checkbox" 
                    style={{ display: 'table-row', width: "100%" }}
                    checked={ data.indexOf( item ) > -1 }
                    onChange={ e => {
                      let tmp = _.cloneDeep( data )
                      if( e.target.checked ) {
                        tmp.push( item )
                      } else {
                        tmp.splice( data.indexOf( item ), 1 )
                      }
                      onChange( tmp )
                    }} />
                  <label style={{ display: "table-row", width: "100%" }} >{ item }</label>
                </div> )
              }
              </Row>
            </Col>
            <Col 
              xs={ 6 }
              style={{ paddingTop: "15px" }} >
              <Row style={{ float: "left" }} >
              {
                btmRight.map( item => <div style={{
                  minWidth: "18px", 
                  display: "table",
                  float: "left",
                  marginLeft: "20px" }} >
                  <input 
                    type="checkbox" 
                    style={{ display: 'table-row', width: "100%"}}
                    checked={ data.indexOf( item ) > -1 }
                    onChange={ e => {
                      let tmp = _.cloneDeep( data )
                      if( e.target.checked ) {
                        tmp.push( item )
                      } else {
                        tmp.splice( data.indexOf( item ), 1 )
                      }
                      onChange( tmp )
                    }} />
                  <label style={{ display: "table-row", width: "100%" }} >{ item }</label>
                </div> )
              }
              </Row>
            </Col>
          </Row>
        </Container>
      </FormGroup>
      <FormGroup>
        <Container 
          style={{
            minWidth: "700px",
            paddingRight: "30px" }} >
          <Row>
            <Col 
              xs={ 6 } 
              style={{ 
                border: "2px solid #000000",
                borderTop: "none",
                borderLeft: "none",
                paddingBottom: "15px" }}>
              <Row style={{ float: "right" }}>
              {
                topLeft2.map( item => <div style={{
                  minWidth: "18px", 
                  display: "table",
                  float: "left",
                  marginRight: "20px" }} >
                  <label style={{ display: "table-row", width: "100%" }} >{ item }</label>
                  <input 
                    type="checkbox" 
                    style={{ display: 'table-row', width: "100%" }}
                    checked={ data.indexOf( item ) > -1 }
                    onChange={ e => {
                      let tmp = _.cloneDeep( data )
                      if( e.target.checked ) {
                        tmp.push( item )
                      } else {
                        tmp.splice( data.indexOf( item ), 1 )
                      }
                      onChange( tmp )
                    }} />
                </div> )
              }
              </Row>
            </Col>
            <Col 
              xs={ 6 } 
              style={{ 
                border: "2px solid #000000",
                borderTop: "none",
                borderRight: "none",
                borderLeft: "none",
                paddingBottom: "15px" }}>
              <Row style={{ float: "left" }}>
              {
                topRight2.map( item => <div style={{
                  minWidth: "18px", 
                  display: "table",
                  float: "left",
                  marginLeft: "20px" }} >
                  <label style={{ display: "table-row", width: "100%" }} >{ item }</label>
                  <input 
                    type="checkbox" 
                    style={{ display: 'table-row', width: "100%" }}
                    checked={ data.indexOf( item ) > -1 }
                    onChange={ e => {
                      let tmp = _.cloneDeep( data )
                      if( e.target.checked ) {
                        tmp.push( item )
                      } else {
                        tmp.splice( data.indexOf( item ), 1 )
                      }
                      onChange( tmp )
                    }} />
                </div> )
              }
              </Row>
            </Col>
          </Row>
          <Row>
            <Col 
              xs={ 6 } 
              style={{ 
                border: "2px solid #000000",
                borderBottom: "none",
                borderTop: "none",
                borderLeft: "none",
                paddingTop  : "15px" }}>
              <Row style={{ float: "right" }} >
              {
                btmLeft2.map( item => <div style={{
                  minWidth: "18px", 
                  display: "table",
                  float: "left",
                  marginRight: "20px" }} >
                  <input 
                    type="checkbox" 
                    style={{ display: 'table-row', width: "100%" }}
                    checked={ data.indexOf( item ) > -1 }
                    onChange={ e => {
                      let tmp = _.cloneDeep( data )
                      if( e.target.checked ) {
                        tmp.push( item )
                      } else {
                        tmp.splice( data.indexOf( item ), 1 )
                      }
                      onChange( tmp )
                    }} />
                  <label style={{ display: "table-row", width: "100%" }} >{ item }</label>
                </div> )
              }
              </Row>
            </Col>
            <Col 
              xs={ 6 }
              style={{ paddingTop: "15px" }} >
              <Row style={{ float: "left" }} >
              {
                btmRight2.map( item => <div style={{
                  minWidth: "18px", 
                  display: "table",
                  float: "left",
                  marginLeft: "20px" }} >
                  <input 
                    type="checkbox" 
                    style={{ display: 'table-row', width: "100%" }}
                    checked={ data.indexOf( item ) > -1 }
                    onChange={ e => {
                      let tmp = _.cloneDeep( data )
                      if( e.target.checked ) {
                        tmp.push( item )
                      } else {
                        tmp.splice( data.indexOf( item ), 1 )
                      }
                      onChange( tmp )
                    }} />
                  <label style={{ display: "table-row", width: "100%" }} >{ item }</label>
                </div> )
              }
              </Row>
            </Col>
          </Row>
        </Container>
      </FormGroup>
    </div>
  )
}

export default ToothChart