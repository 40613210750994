import React from 'react'
import cx from 'classnames'
import { connect } from 'react-redux'

import HeaderLogo from './Logo'
import Userbox from './Userbox'
import Dots from './Dots'

const Header = ({
  headerBackgroundColor,
  enableMobileMenuSmall,
  enableHeaderShadow,
  history,
  onClickToggleProfileModal,
  user
}) => {
  return (
    <div className={ cx("app-header", headerBackgroundColor, {'header-shadow': enableHeaderShadow}) }>
      <HeaderLogo />
      <div className={ cx( "app-header__content", { 'header-mobile-open': enableMobileMenuSmall } ) }>
        <div className="app-header-left">

        </div>
        <div className="app-header-right">
          <Dots user={ user } />
          <Userbox 
            onClickToggleProfileModal={ onClickToggleProfileModal } 
            history={ history }
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall
})

const mapDispatchToProps = dispatch => ({})

export default connect( mapStateToProps, mapDispatchToProps )( Header )