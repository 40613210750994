import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'
import ReactHTMLParser from 'react-html-parser'

import { Post, Get, Put, Delete } from 'utils/axios'
import getDomainURL from 'utils/api'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      consentForms: [],
      newConsentForm: {},
      selectedConsentForm: {},
      showCreateConsentForm: false,
      showDownloadConsentForm: false,
      showUpdateConsentForm: false,
      selectedConsentFormToDelete: 0,
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( ({ closeToast }) => ReactHTMLParser( error.message ) )

    onChangeConsentFormsHOC = ( key, val ) => this.setState({ [ key ]: val }, () => {
      if( key === 'showDownloadConsentForm' ){
        this.setState({ 
          showDownloadConsentForm: true, 
          selectedConsentForm: { 
            id: val, 
            type_id: 1 ,
            label: 'Cementation Approval'
          } 
      })
      }
    })

    createConsentForm = data => Post(
      `/consent_forms`,
      data,
      this.createConsentFormSuccess,
      this.createConsentFormError,
      this.load
    )
    createConsentFormSuccess = () => {
      this.requestSuccess( 'Consent form is successfully created' )
      this.getConsentForms()
      this.reloadNewConsentForm()
    }
    createConsentFormError = error => this.requestError( error )

    getConsentForms = () => Get(
      `/consent_forms`,
      this.getConsentFormsSuccess,
      this.getConsentFormsError,
      this.load
    )
    getConsentFormsSuccess = payload => this.setState({ consentForms: payload })
    getConsentFormsError = error => this.requestError( error )

    getSelectedConsentForm = form_id => Get(
      `/consent_forms/${ form_id }`,
      this.getSelectedConsentFormSuccess,
      this.getSelectedConsentFormError,
      this.load
    )
    getSelectedConsentFormSuccess = payload => this.setState({ selectedConsentForm: payload, showUpdateConsentForm: true })
    getSelectedConsentFormError = error => this.requestError( error )

    downloadConsentFormPDF = data => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/consent_forms/${ data.id }/download?type_id=${ data.type_id }`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( blobby => {
        this.load( false )
        this.reloadSelectedConsentForm()
        return FileSaver.saveAs( blobby, `${ data.label.replaceAll( ' ', '_' ) }.pdf` )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save consent form. Please try again.' )
      })
    }

    updateConsentForm = data => Put(
      `/consent_forms/${ data.id }`,
      data,
      this.updateConsentFormSuccess,
      this.updateConsentFormError,
      this.load
    )
    updateConsentFormSuccess = () => {
      this.requestSuccess( 'Consent form is successfully updated' )
      this.getConsentForms()
      this.reloadSelectedConsentForm()
    }
    updateConsentFormError = error => this.requestError( error )

    removeConsentForm = form_id => Delete(
      `/consent_forms/${ form_id }`,
      this.removeConsentFormSuccess,
      this.removeConsentFormError,
      this.load
    )
    removeConsentFormSuccess = () => {
      this.requestSuccess( 'Consent form is successfully removed' )
      this.getConsentForms()
      this.setState({ selectedConsentFormToDelete: 0 })
    }

    reloadNewConsentForm = () => this.setState({
      showCreateConsentForm: false,
      newConsentForm: {
        company_id: 0,
        branch_id: 0,
        user_id: 0,
        patient_id: 0
      }
    })

    reloadSelectedConsentForm = () => this.setState({
      showDownloadConsentForm: false,
      showUpdateConsentForm: false,
      selectedConsentForm: {}
    })

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadConsentFormsHOC = { this.state.loading }
          consentForms = { this.state.consentForms }
          newConsentForm = { this.state.newConsentForm }
          selectedConsentForm = { this.state.selectedConsentForm }
          showCreateConsentForm = { this.state.showCreateConsentForm }
          showUpdateConsentForm = { this.state.showUpdateConsentForm }
          showDownloadConsentForm={ this.state.showDownloadConsentForm }
          selectedConsentFormToDelete = { this.state.selectedConsentFormToDelete }
          onChangeConsentFormsHOC={ this.onChangeConsentFormsHOC }
          createConsentForm={ this.createConsentForm }
          getConsentForms={ this.getConsentForms }
          getSelectedConsentForm={ this.getSelectedConsentForm }
          downloadConsentFormPDF={ this.downloadConsentFormPDF }
          updateConsentForm={ this.updateConsentForm }
          removeConsentForm={ this.removeConsentForm }
          reloadNewConsentForm={ this.reloadNewConsentForm }
          reloadSelectedConsentForm={ this.reloadSelectedConsentForm } />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC