import React from 'react'
import { 
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from 'reactstrap'

const Login = ({
  selectedUser,
  onChangeUserData
}) => {
  return (
    <Form onSubmit={ e => e.preventDefault()}>
      <Row>
        <Col md={ 12 }>
          <Row className="mt-3">
            <Col md={ 12 }>
              <FormGroup>
                <Label>Email</Label>
                <Input 
                  type="email" 
                  disabled={ true }
                  value={ selectedUser.email } />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Password</Label>
                <Input 
                  type="password"
                  value={ selectedUser.password }
                  onChange={ e => {
                    onChangeUserData( 'password', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Confirm password</Label>
                <Input 
                  type="password"
                  value={ selectedUser.password_confirmation }
                  onChange={ e => {
                    onChangeUserData( 'password_confirmation', e.target.value )
                  }} />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

export default Login