import React from 'react'
import { InputGroup, InputGroupAddon } from 'reactstrap'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'

const TemplateDatepicker = ({
  selectedDate,
  onChange,
  disabled,
  showTimeSelect,
  dateFormat,
  excludeDates,
  excludeTimes,
  minTime,
  minDate
}) => {
  return (
    <>
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <div className="input-group-text">
            <FontAwesomeIcon icon={faCalendarAlt}/>
          </div>
        </InputGroupAddon>
        <DatePicker
          className="form-control"
          selected={ selectedDate }
          onChange={ onChange }
          disabled={ disabled }
          showTimeSelect={ showTimeSelect }
          dateFormat={ dateFormat }
          excludeDates={ excludeDates }
          excludeTimes={ excludeTimes }
          minTime={ minTime }
          minDate={ minDate }
        />
      </InputGroup>
    </>
  )
}

export default TemplateDatepicker