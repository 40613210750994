import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    uploadXrayImages = ( data, xrayImg ) => Post(
      `/medical_records/${ data.id }/upload_xray`,
      xrayImg,
      this.uploadXrayImagesSuccess,
      this.uploadXrayImagesError,
      this.load
    )
    uploadXrayImagesSuccess = () => {
      this.requestSuccess( 'X-ray image(s) is succesfully uploaded' )
      this.props.getSelectedMedicalRecord( this.props.selectedMedicalRecord.id )
    }
    uploadXrayImagesError = error => this.requestError( error )

    removeXrayImages = ( data, xray_id ) => Delete(
      `/medical_records/${ data.id }/remove_xray?xray_id=${ xray_id }`,
      this.removeXrayImagesSuccess,
      this.removeXrayImagesError,
      this.load
    )
    removeXrayImagesSuccess = () => {
      this.requestSuccess( 'X-ray image(s) is successfully removed' )
      this.props.getSelectedMedicalRecord( this.props.selectedMedicalRecord.id )
    }
    removeXrayImagesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadProcessXrayImages={ this.state.loading }
          uploadXrayImages={ this.uploadXrayImages }
          removeXrayImages={ this.removeXrayImages } />
      )
    }
  }
  return WithHOC
}

export default HOC