import React, { Component } from 'react'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'
import { compose } from 'redux'
import { connect } from 'react-redux'

import CreateBranch from './Create'
import UpdateBranch from './Update'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithBranches from './actions'
import WithCountries from 'actions/dictionary/countries'

class Branches extends Component {

  componentDidMount = () => this.props.getCountries()

  render = () => {
    return (
      <>
        {
          this.props.data.profileReducer.profile.current_user.role.name === 'Super Admin' && (
            <Button 
              color="primary" 
              className="mb-3"
              onClick={() => {
                this.props.onChangeBranchesHOC( 'showCreateBranch', true )
              }}>Add new branch</Button>
          )
        }
        <ReactTable
          data={ this.props.selectedCompany.branches }
          columns={[
            {
              Header: 'Name',
              accessor: 'name'
            },
            {
              Header: 'Address',
              accessor: 'address'
            },
            {
              Header: 'Actions',
              maxWidth: 120,
              Cell: ( row ) => (
                <>
                  <Button
                    className="mb-2 mr-2 btn-icon btn-icon-only"
                    color="primary"
                    onClick={() => {
                      this.props.getSelectedBranch( row.original.id )
                    }}>
                    <i className="pe-7s-pen btn-icon-wrapper"> </i>
                  </Button>
                </>
              )
            }
          ]}
          defaultPageSize={ 10 } />
        <CreateBranch { ...this.props } />
        <UpdateBranch { ...this.props } />
        { ( this.props.onLoadBranches || this.props.onLoadCountries ) && <LoadingOverlay /> }
      </>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default compose(
  WithBranches,
  WithCountries,
  connect( mapStateToProps )
)( Branches )