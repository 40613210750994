import React from 'react'
import { 
  Row, 
  Col,
  Card, CardHeader,
  CardBody,
  Button
} from 'reactstrap'
import ReactHTMLParser from 'react-html-parser'

import PageTitle from 'components/Title'

const ReleaseNotes = props => {
  return (
    <>
      <PageTitle
        heading="Release Notes"
        subheading="List down release details for every update of the app."
        icon="pe-7s-note2 icon-gradient bg-happy-itmeo"
        buttons={[]} />
      <Row>
        <Col md={ 12 }>
          <Card className="main-card mb-3">
            <CardHeader>Update 8th NOV 2021</CardHeader>
            <CardBody>
              { ReactHTMLParser( `<ol><li>Treatment record popup is now able to show overview which contained the timeline which shows complaints, observations and notes when treatments are done.</li></ol>` ) }
            </CardBody>
          </Card>
        </Col>
        <Col md={ 12 }>
          <Card className="main-card mb-3">
            <CardHeader>Update 29th OCTOBER 2021</CardHeader>
            <CardBody>
              { ReactHTMLParser( `<ol><li>Company is able to upload company logo under companies → update selected company.</li><li>Doctor is able to assign some other files into the treatment record aside from just xray images only.</li><li>Some minor bug fixes and enhancements.</li></ol>` ) }
            </CardBody>
          </Card>
        </Col>
        <Col md={ 12 }>
          <Card className="main-card mb-3">
            <CardHeader>Update 27th SEPTEMBER 2021</CardHeader>
            <CardBody>
              { ReactHTMLParser( `<ol><li>Patients' birthday is now displayed in calendar.</li><li>New feature: Panel<ol><li>Clinic can now register panel into the system.</li><li>When patient checkout and pay for the treatment, frontdesk can select panel as payment option and select which panel is involved with it</li><li>Under panel → collections, admin or frontdesk can view the collection from panel</li></ol></li></ol>` ) }
            </CardBody>
          </Card>
        </Col>
        <Col md={ 12 }>
          <Card className="main-card mb-3">
            <CardHeader>Update 3rd AUGUST 2021</CardHeader>
            <CardBody>
              { ReactHTMLParser( `<ol><li>Discount feature is now available in payment.</li><li>Discount percentage will be displayed in the receipt.</li></ol>` ) }
            </CardBody>
          </Card>
        </Col>
        <Col md={ 12 }>
          <Card className="main-card mb-3">
            <CardHeader>Update 29th JULY 2021</CardHeader>
            <CardBody>
              { ReactHTMLParser( `<ol><li>Product is now able to track stocks.</li><li>When front desk is assigning product with 0 stocks amount, front desk will get an error notification saying that product is running out of stocks.</li><li>The existing products in the system will get an amount of 99, feel free to update this when needed.</li><li>User is now able to view the payment even though after receipt is downloaded.</li></ol>` ) }
            </CardBody>
          </Card>
        </Col>
        <Col md={ 12 }>
          <Card className="main-card mb-3">
            <CardHeader>Update 24th JULY 2021</CardHeader>
            <CardBody>
              { ReactHTMLParser( `<ol><li>After frontdesk clicked download receipt, the payment cannot be update anymore.</li><li>Frontdesk can now export daily collection only.</li><li>The start date and end date control is available to admin only for collections.</li><li>New feature: Refund<br>- Frontdesk can issue full refund or partial refund.<br>- Each payment can only issue refund for once.<br>- Frontdesk will need to state reason for refund every time a refund is done.<br>- Only cash refund is presence for now, there will be no Credit Advance refund for now.</li></ol>` ) }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ReleaseNotes