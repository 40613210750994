import React, { Component } from 'react'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Table,
  CustomInput
} from 'reactstrap'
import _ from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Moment from 'moment'
import NumberFormat from "react-number-format"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SectionIsNewInvoice from './Sections/isNewInvoice'
import NewPayment from './Payments/New'
import UpdatePayment from './Payments/Update'
import ViewPayment from './Payments/View'
import TreatmentComponent from '../components/TreatmentComponent'
import PromptModal from 'components/Indicator/Prompt'
import Typeahead from 'components/Typeahead'
import { priceWithSeparators } from 'utils/priceWithSeparators'

import WithInvoices from './actions'
import WithCompanies from 'actions/dictionary/companies'
import WithTreatments from './actions/Treatments'
import WithMedicines from './actions/Medicines'
import WithProducts from './actions/Products'
import WithPayments from './actions/Payments'
import WithBranches from 'actions/dictionary/branches'
import WithPanels from 'actions/dictionary/panels'
import { getProfile } from 'actions/profile'

class Invoices extends Component {
  state = {
    uid: 0
  }

  componentDidMount = () => {
    this.props.getCompanies()
    this.props.getBranches()
    this.props.getTreatments()
    this.props.getMedicines()
    this.props.getProducts()
    this.props.getProfile()
    this.props.getPanels()
    if( this.props.selectedMedicalRecord.invoices.length > 0 ) {
      Promise.all( 
        this.props.selectedMedicalRecord.invoices.map( async( invoice ) => { 
          await this.props.getSelectedInvoice( invoice.id ) 
          return this.props.selectedInvoice
        }) 
      ).then( detailedInvoices => {
        this.props.onChangeInvoiceHOC( 'invoices', detailedInvoices )
      })
      this.props.getPayments( this.props.selectedMedicalRecord.invoices[ 0 ].id )
    }
    if( this.props.invoice_id > 0 && this.props.payment_id > 0 ){
      this.props.getSelectedPayment( this.props.invoice_id, this.props.payment_id, true )
    }
  }

  componentDidUpdate = pp => {
    if( this.props.data.ajaxReducer.ajaxCallProgress !== pp.data.ajaxReducer.ajaxCallProgress ) {
      if( this.props.data.ajaxReducer.ajaxCallProgress === 0 ) {
        this.setState({ uid: this.props.data.profileReducer.profile.current_user.id })
      }
    }
    if( ( !this.props.showNewPayment && pp.showNewPayment ) || ( !this.props.showUpdatePayment && pp.showUpdatePayment ) ){
      this.props.onChangePaymentHOC( 'selectedCaUser', {} )
      this.props.onChangePaymentHOC( 'caPatients', [] )
    }
  }

  render = () => {
    return (
      <Form onSubmit={ e => e.preventDefault()}>
        <style>{`.rbt-menu { min-width: fit-content; }`}</style>
        {
          this.props.selectedMedicalRecord.invoices.length === 0 && (
            <FormGroup check>
              <Label check>
                <Input
                  type={ 'checkbox' }
                  checked={ this.props.isNewInvoice }
                  onChange={ e => {
                    this.props.onChangeInvoiceHOC( 'isNewInvoice', e.target.checked )
                  }} />
                Is new receipt?
              </Label>
            </FormGroup>
          )
        }
        <SectionIsNewInvoice 
          { ...this.props }
          branches={ this.props.data.profileReducer.profile.branches } />
        {
          this.props.selectedMedicalRecord.invoices.length > 0 && (
            <>
              <FormGroup>
                <Label>Select a branch</Label>
                <Input
                  type="select"
                  value={ this.props.selectedInvoice.branch_id }
                  onChange={ e => {
                    let tmp = _.cloneDeep( this.props.selectedInvoice )
                    tmp[ 'branch_id' ] = e.target.value
                    this.props.onChangeInvoiceHOC( 'selectedInvoice', tmp )
                  }}>
                  <option></option>
                  {
                    this.props.data.profileReducer.profile.branches.map( item => {
                      return (
                        <option key={ item.id } value={ item.id }>{ item.name }</option>
                      )
                    })
                  }
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Treatments</Label>
                <Row>
                  <Col md={ 4 }>
                    <span>Treatment Name</span>
                    <Typeahead
                      id={ 'invoiceTabTreatment' }
                      multiple={ false }
                      options={ this.props.treatments.map( treatment => ({ 
                        id: treatment.id.toString(),
                        label: treatment.id.toString(),
                        value: treatment.name
                      }) )
                      }
                      onSelect={ val => {
                        let tmp = _.cloneDeep( this.props.newTreatment )
                        tmp[ 'treatment_id' ] = val && val.length > 0 ? parseInt( val[ 0 ].id ) : 0
                        this.props.onChangeTreatmentHOC( 'newTreatment', tmp )
                      }}
                      selectedValue={ _.find( this.props.treatments, { id: this.props.newTreatment.treatment_id } ) 
                        ? [{
                          id: _.find( this.props.treatments, { id: this.props.newTreatment.treatment_id } ).id.toString(),
                          label: _.find( this.props.treatments, { id: this.props.newTreatment.treatment_id } ).id.toString(),
                          value: _.find( this.props.treatments, { id: this.props.newTreatment.treatment_id } ).name
                        }] : [] 
                      } />
                  </Col>
                  <Col md={ 4 }>
                    <span>Receipt Name</span>
                    <Input
                      type="text"
                      value={ this.props.newTreatment.name }
                      onChange={ e => {
                        let tmp = _.cloneDeep( this.props.newTreatment )
                        tmp[ 'name' ] = e.target.value
                        this.props.onChangeTreatmentHOC( 'newTreatment', tmp )
                      }}/>
                  </Col>
                  <Col md={ 2 }>
                    <span>Price</span>
                    <NumberFormat
                      className="form-control"
                      value={ this.props.newTreatment.price }
                      thousandSeparator={ "," }
                      decimalSeparator={ "." }
                      onValueChange={( values ) => {
                        const { value } = values
                        let tmp = _.cloneDeep( this.props.newTreatment )
                        tmp[ 'price' ] = value
                        this.props.onChangeTreatmentHOC( 'newTreatment', tmp )
                      }}
                      inputMode="numeric"
                      displayType="input"
                    />
                  </Col>
                  <Col md={ 2 } className='d-flex flex-column justify-content-end'>
                    <Button 
                      color="primary" 
                      className="mt-1"
                      onClick={() => {
                        let tmpRes = parseFloat( this.props.selectedInvoice.total_amount ) + parseFloat( this.props.newTreatment.price )
                        this.props.addInvoiceTreatment({ 
                          ...this.props.newTreatment,
                          invoice_id: this.props.selectedInvoice.id
                        }, tmpRes )
                      }}>Add treatment</Button>
                  </Col>
                </Row>
                <br />
                <p><b className="mb-3">Selected treatment(s):</b></p>
                {
                  this.props.selectedInvoice.invoice_treatments.map(( item, index ) => {
                    return (
                      <TreatmentComponent  
                        tabType='Invoice' 
                        key={ item.id }
                        removeInvoiceTreatment={ this.props.removeInvoiceTreatment }
                        item={ item }
                        selectedInvoice={ this.props.selectedInvoice }
                        index={ index }
                        onChangeInvoiceHOC={ this.props.onChangeInvoiceHOC }
                        updateInvoiceTreatment={ this.props.updateInvoiceTreatment } />
                    )
                  })
                }
              </FormGroup>
              <FormGroup>
                <Row className='d-flex align-items-end'>
                  <Col md={ 5 }>
                    <Label>Medicines</Label>
                    <Typeahead
                      id={ 'invoiceTabMedicine' }
                      multiple={ false }
                      options={ this.props.medicines.map( medicine => ({ 
                        id: medicine.id.toString(),
                        label: medicine.id.toString(),
                        value: medicine.name
                      }) )
                      }
                      onSelect={ val => {
                        let tmp = _.cloneDeep( this.props.newMedicine )
                        tmp[ 'medicine_id' ] = val && val.length > 0 ? parseInt( val[ 0 ].id ) : 0
                        tmp[ 'price_per_unit' ] = val && val.length > 0 
                          ? _.find( this.props.medicines, { id: tmp[ 'medicine_id' ] } ).price_per_unit 
                          : 0
                        this.props.onChangeMedicineHOC( 'newMedicine', tmp )
                      }}
                      selectedValue={ _.find( this.props.medicines, { id: this.props.newMedicine.medicine_id } ) 
                        ? [{
                          id: _.find( this.props.medicines, { id: this.props.newMedicine.medicine_id } ).id.toString(),
                          label: _.find( this.props.medicines, { id: this.props.newMedicine.medicine_id } ).id.toString(),
                          value: _.find( this.props.medicines, { id: this.props.newMedicine.medicine_id } ).name
                        }] : [] 
                      } />
                  </Col>
                  <Col md={ 2 }>
                    <Label>Quantity</Label>
                    <Input
                      type="number"
                      value={ this.props.newMedicine.quantity }
                      disabled={ false }
                      onChange={ e => {
                        let tmp = _.cloneDeep( this.props.newMedicine )
                        tmp[ 'quantity' ] = parseInt( e.target.value, 10 )
                        this.props.onChangeMedicineHOC( 'newMedicine', tmp )
                      }} />
                  </Col>
                  <Col md={ 2 }>
                    <Label>Price per unit</Label>
                    <NumberFormat
                      className="form-control"
                      value={ this.props.newMedicine.price_per_unit }
                      thousandSeparator={ "," }
                      decimalSeparator={ "." }
                      onValueChange={( values ) => {
                        const { value } = values
                        let tmp = _.cloneDeep( this.props.newMedicine )
                        tmp[ 'price_per_unit' ] = value
                        this.props.onChangeMedicineHOC( 'newMedicine', tmp )
                      }}
                      inputMode="numeric"
                      displayType="input"
                    />
                  </Col>
                  <Col md={ 3 }>
                    <Button 
                      color="primary" 
                      className="mt-1"
                      onClick={() => {
                        console.log( this.props.newMedicine )
                        let tmpRes = parseFloat( this.props.selectedInvoice.total_amount ) + ( this.props.newMedicine.quantity * parseFloat( this.props.newMedicine.price_per_unit ) )
                        this.props.addInvoiceMedicine({
                          ...this.props.newMedicine,
                          invoice_id: this.props.selectedInvoice.id
                        }, tmpRes )
                      }}>Add medicine</Button>
                  </Col>
                </Row>
                <br />
                <p><b>Selected medicine(s):</b></p>
                <Table>
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Quantity</td>
                      <td>Price per unit</td>
                      <td>Total price</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    this.props.selectedInvoice.invoice_medicines.map(( item, index ) => {
                      let tmp = _.find( this.props.medicines, { id: item.medicine_id } )
                      return (
                        <tr key={ `Medicine-${ item.id }` }>
                          <td>{ item.medicine_name }</td>
                          <td>{ item.quantity }</td>
                          <td>{ priceWithSeparators( item.price_per_unit ) }</td>
                          <td>{ priceWithSeparators( tmp ? ( item.price_per_unit * item.quantity ) : 0 ) }</td>
                          <td>
                            <Button 
                              color="danger"
                              disabled={ _.isEmpty( tmp ) }
                              onClick={() => {
                                let tmpRes = parseFloat( this.props.selectedInvoice.total_amount ) - ( item.quantity * parseFloat( tmp.price_per_unit ) )
                                this.props.removeInvoiceMedicine( this.props.selectedInvoice.id, item.medicine_id, tmpRes )
                              }}>X</Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </Table>
              </FormGroup>
              <FormGroup>
                <Row className='d-flex align-items-end'>
                  <Col md={ 5 }>
                    <Label>Products</Label>
                    <Typeahead
                      id={ 'invoiceTabProduct' }
                      multiple={ false }
                      options={ this.props.products.map( product => ({ 
                        id: product.id.toString(),
                        label: product.id.toString(),
                        value: product.name
                      }) )
                      }
                      onSelect={ val => {
                        let tmp = _.cloneDeep( this.props.newProduct )
                        tmp[ 'product_id' ] = val && val.length > 0 ? parseInt( val[ 0 ].id ) : 0
                        tmp[ 'price_per_unit' ] = val && val.length > 0 
                          ? _.find( this.props.products, { id: tmp[ 'product_id' ] } ).price
                          : 0
                          this.props.onChangeProductsHOC( 'newProduct', tmp )
                      }}
                      selectedValue={ _.find( this.props.products, { id: this.props.newProduct.product_id } ) 
                        ? [{
                          id: _.find( this.props.products, { id: this.props.newProduct.product_id } ).id.toString(),
                          label: _.find( this.props.products, { id: this.props.newProduct.product_id } ).id.toString(),
                          value: _.find( this.props.products, { id: this.props.newProduct.product_id } ).name
                        }] : [] 
                      } />
                  </Col>
                  <Col md={ 2 }>
                    <Label>Quantity</Label>
                    <Input 
                      type="number" 
                      placeholder="Quantity"
                      value={ this.props.newProduct.quantity }
                      onChange={ e => {
                        let tmp = _.cloneDeep( this.props.newProduct )
                        tmp[ 'quantity' ] = parseInt( e.target.value, 10 )
                        this.props.onChangeProductsHOC( 'newProduct', tmp )
                      }} />
                  </Col>
                  <Col md={ 2 }>
                    <Label>Price per unit</Label>
                    <NumberFormat
                      className="form-control"
                      value={ this.props.newProduct.price_per_unit }
                      thousandSeparator={ "," }
                      decimalSeparator={ "." }
                      onValueChange={( values ) => {
                        const { value } = values
                        let tmp = _.cloneDeep( this.props.newProduct )
                        tmp[ 'price_per_unit' ] = value
                        this.props.onChangeProductsHOC( 'newProduct', tmp )
                      }}
                      inputMode="numeric"
                      displayType="input"
                    />
                  </Col>
                  <Col md={ 3 }>
                    <Button 
                      color="primary" 
                      className="mt-1"
                      onClick={() => {
                        let tmpRes = parseFloat( this.props.selectedInvoice.total_amount ) + ( this.props.newProduct.quantity * parseFloat( this.props.newProduct.price_per_unit ) )
                        this.props.addInvoiceProduct({ 
                          ...this.props.newProduct,
                          invoice_id: this.props.selectedInvoice.id
                        }, tmpRes )
                      }}>Add product</Button>
                  </Col>
                </Row>
                <br />
                <p><b>Selected product(s):</b></p>
                <Table>
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Quantity</td>
                      <td>Price</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    this.props.selectedInvoice.invoice_products.map( item => {
                      let tmp = _.find( this.props.products, { id: item.product_id } )
                      return (
                        <tr key={ `InvoiceProduct-${ item.id }` }>
                          <td>{ item.product_name }</td>
                          <td>{ item.quantity }</td>
                          <td>{ priceWithSeparators( item.price_per_unit ) }</td>
                          <td>
                            <Button 
                              color="danger"
                              className="mr-2"
                              onClick={() => {
                                let tmpRes = parseFloat( this.props.selectedInvoice.total_amount ) - ( item.quantity * parseFloat( tmp.price ) )
                                this.props.removeInvoiceProduct( this.props.selectedInvoice.id, item.product_id, tmpRes )
                              }}>X</Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </Table>
              </FormGroup>
              <hr />
              <FormGroup>
                <Row>
                  <Col md={ 6 }>
                    <span>Total amount</span>
                  </Col>
                  <Col md={ 3 }></Col>
                  <Col md={ 3 }>
                    <Input
                      type="text"
                      value={ priceWithSeparators( this.props.selectedInvoice.total_amount ) }
                      disabled={ true } />
                  </Col>
                  <Col md={ 12 }>
                    <hr />
                  </Col>
                  <Col md={ 6 }>
                    <span style={{ fontWeight: '600' }}>Lab costs:</span>
                  </Col>
                  <Col md={ 3 }></Col>
                  <Col md={ 3 }>
                    <NumberFormat
                      className="form-control"
                      value={ this.props.selectedInvoice.lab_cost }
                      thousandSeparator={ "," }
                      decimalSeparator={ "." }
                      onValueChange={( values ) => {
                        const { value } = values
                        let tmp = _.cloneDeep( this.props.selectedInvoice )
                        tmp[ 'lab_cost' ] = value
                        return this.props.onChangeInvoiceHOC( 'selectedInvoice', tmp )
                      }}
                      inputMode="numeric"
                      displayType="input"
                    />
                  </Col>
                  <Col md={ 12 }>
                    <hr />
                    <p><b>Payment history</b></p>
                  </Col>
                  <Col md={ 12 }>
                    <Table>
                      <thead>
                        <tr>
                          <td>Transaction date</td>
                          <td>Pay with</td>
                          <td>Amount</td>
                          <td>Cost</td>
                          <td>Change</td>
                          <td>Refunded Amount</td>
                          <td className={ this.props.data.profileReducer.profile.current_user.role_id === 4 ? 'd-flex justify-content-center' : '' } >
                            Action</td>
                          <td>Select payment(s)</td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.props.payments.map(( item, index ) => {
                            return (
                              <tr key={ item.id }>
                                <td>{ Moment( item.created_at ).format( 'DD MMM YYYY HH:mm' ) }</td>
                                <td>{ item.pay_with }</td>
                                <td>{ priceWithSeparators( item.current_paid_amount ) }</td>
                                <td>{ priceWithSeparators( item.current_cost ) }</td>
                                <td>{ priceWithSeparators( item.change ) }</td>
                                <td>{ priceWithSeparators( item.amount_refunded ) }</td>
                                <td>
                                  {
                                    ( item.is_editable || this.props.data.profileReducer.profile.current_user.role.name === 'Admin' ) && (
                                        <Button 
                                          color="primary"
                                          className={ this.props.data.profileReducer.profile.current_user.role_id === 4 ? "ml-2 mr-2": "" }
                                          onClick={() => {
                                            this.props.getSelectedPayment( item.invoice_id, item.id, true )
                                          }}
                                          disabled={ !item.is_editable && this.props.data.profileReducer.profile.current_user.role.name !== 'Admin' }>
                                          <i className="pe-7s-pen"></i>
                                        </Button>
                                    )
                                  }
                                  {
                                    (
                                      this.props.data.profileReducer.profile.current_user.role.name === 'Super Admin' ||
                                      this.props.data.profileReducer.profile.current_user.role.name === 'Admin'
                                    ) && <Button 
                                      color="danger"
                                      onClick={() => {
                                        this.props.getSelectedPayment( item.invoice_id, item.id, false )
                                      }}>
                                      <i className="pe-7s-trash"></i>
                                    </Button>
                                  }
                                  <Button 
                                    color="primary"
                                    className={ this.props.data.profileReducer.profile.current_user.role_id === 4 ? "ml-2 mr-2": "" }
                                    onClick={() => {
                                      this.props.getSelectedPaymentView( item )
                                    }}>
                                    <i className="pe-7s-look"></i>
                                  </Button>
                                </td>
                                <td className='d-flex justify-content-center'>
                                  <CustomInput
                                    id={ `multiPayment${ item.id }` }
                                    type='checkbox'
                                    checked={ _.findIndex( this.props.selectedPayments, payment => payment === item.id ) > -1 }
                                    onChange={ () => {
                                      let tmp = _.cloneDeep( this.props.selectedPayments )
                                      let tmpIndex = _.findIndex( this.props.selectedPayments, payment => payment === item.id )
                                      if( tmpIndex > -1 ){
                                        tmp.splice( tmpIndex, 1 )
                                      } else {
                                        tmp.push( item.id )
                                      }
                                      this.props.onChangeInvoiceHOC( 'selectedPayments', tmp ) }} />
                                </td>
                              </tr>
                            )
                          })
                        }
                        <tr>
                          <td>Total amount paid</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{ priceWithSeparators( this.props.totalPayments ) }</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={ 12 }>
                    <hr />
                    <Button
                      color="primary"
                      onClick={() => {
                        this.props.onChangePaymentHOC( 'showNewPayment', true )
                      }}>
                      Create payment
                    </Button>
                    <hr />
                  </Col>
                </Row>
                <hr />
                <Row className="mt-3">
                  <Col md={ 6 }>
                    <b>Amount due</b>
                  </Col>
                  <Col md={ 3 }></Col>
                  <Col md={ 3 }>
                    <Input
                      type="text"
                      value={ priceWithSeparators( this.props.amountDue ) }
                      disabled={ true } />
                  </Col>
                </Row>
              </FormGroup>
              {/* submit and generate receipt here */}
              <FormGroup style={{ textAlign: 'right' }}>
                {
                  this.props.data.profileReducer.profile.current_user.role.name === 'Doctor' && this.props.medical_record_id > 0 && <Button 
                    color="primary"
                    onClick={ () => this.props.notifyFrontdeskOnPayment( 
                      { invoice_id: this.props.selectedInvoice.id },
                      this.props.selectedPatient, 
                      this.props.selectedMedicalRecord.id,
                      this.state.uid,
                      true
                    )}
                    className="mr-2">
                    Notify frontdesk to create payment
                  </Button>
                }
                <Button 
                  color="primary"
                  onClick={() => {
                    this.props.updateInvoice({
                      ...this.props.selectedInvoice,
                      patient_id: this.props.selectedPatient.id
                    })
                  }}
                  className="mr-2">Submit</Button>
                {
                  this.props.selectedPayments.length > 0 && <Button 
                    color="primary"
                    onClick={() => {
                      Promise.all([
                        this.props.downloadPaymentsPDF( this.props.selectedInvoice.id, this.props.selectedPayments ),
                        this.props.getSelectedInvoice( this.props.selectedInvoice.id )
                      ]).then( () => {
                        this.props.getPayments( this.props.selectedInvoice.id )
                      })
                    }}
                    className="mr-2">Download receipt for selected payment(s)</Button>
                }
                <Button 
                  color="primary"
                  disabled={ this.props.payments.length === 0 }
                  onClick={() => {
                    Promise.all([
                      this.props.downloadPDF( this.props.selectedInvoice.id ),
                      this.props.getSelectedInvoice( this.props.selectedInvoice.id )
                    ]).then( () => {
                      this.props.getPayments( this.props.selectedInvoice.id )
                    })
                  }}>Download latest receipt</Button>
              </FormGroup>
            </>
          )
        }
        <UpdatePayment { ...this.props } />
        <NewPayment 
          { ...this.props }
          uid={ this.state.uid } />
        <ViewPayment { ...this.props } />
        <PromptModal
          showPromptModal={ this.props.showDeletePaymentModal }
          onClickYes={ () => this.props.removePayment( this.props.selectedInvoice.id, this.props.selectedPayment.id ) }
          onClickNo={ () => this.props.onChangePaymentHOC( 'showDeletePaymentModal', false ) }
          content='Are you sure to delete the selected payment?' />
        { ( this.props.onLoadInvoices || 
          this.props.onLoadProducts || 
          this.props.onLoadPayments || 
          this.props.onLoadBranches ||
          this.props.onLoadInvoiceMedicines ||
          this.props.onLoadProducts ||
          this.props.onLoadInvoiceTreatments ) && <LoadingOverlay /> }
      </Form>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  getProfile
})( 
  compose(
    WithInvoices,
    WithPayments,
    WithCompanies,
    WithBranches,
    WithTreatments,
    WithMedicines,
    WithProducts,
    WithPanels
  )( Invoices )
)
