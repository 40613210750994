import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import Appsignal from "@appsignal/javascript"
import { ErrorBoundary } from "@appsignal/react"

import SmileRouter from './router'
import configureStore from './store/config'

import 'stylesheets/base.scss'

const store = configureStore()
const persistor = persistStore( store );

const appSignal = new Appsignal({
  key: '898302d5-56e9-419b-a06d-2701089e5fe0'
})

const App = () => {
  return (
    ( window.location.href.indexOf( 'localhost' ) > -1 || window.location.href.indexOf( 'staging' ) > -1 ) ? (
      <Provider store={ store }>
        <PersistGate loading={ null } persistor={ persistor }>
          <SmileRouter />
        </PersistGate>
      </Provider>
    ) : (
      <ErrorBoundary instance={ appSignal } >
        <Provider store={ store }>
          <PersistGate loading={ null } persistor={ persistor }>
            <SmileRouter />
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    )
  )
}

export default App