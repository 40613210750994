import React, { useState } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap'
import Tabs from 'react-responsive-tabs'
import _ from 'lodash'

import General from './General'
import Login from './Login'
import Companies from './Companies'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

const TabsConfig = {
  activeTab: 0,
  showMore: true,
  transform: true,
  showInkBar: true,
  items: [
    { title: 'General' },
    // { title: 'Login' },
    // { title: 'Companies' }
  ],
  selectedTabKey: 0,
  transformWidth: 400,
}

const Create = ({
  showCreateUser,
  newUser,
  onChangeUsersHOC,
  roles,
  createUser,
  onLoadUsers
}) => {
  const [ currentTab, updateCurrentTab ] = useState( 0 )

  const onChangeUserData = ( key, val ) => {
    let tmp = _.cloneDeep( newUser )
    tmp[ key ] = val
    return onChangeUsersHOC( 'newUser', tmp )
  }

  return (
    <Modal
      isOpen={ showCreateUser }
      size={ 'xl' }
      toggle={() => {
        onChangeUsersHOC( 'showCreateUser', false )
      }}>
      <ModalHeader toggle={() => onChangeUsersHOC( 'showCreateUser', false )}>Create user</ModalHeader>
      <ModalBody>
        <Tabs
          tabsWrapperClass="body-tabs"
          { ...TabsConfig }
          selectedTabKey={ currentTab }
          onChange={ val => updateCurrentTab( val ) } />
        {
          currentTab === 0 && <General onChangeUserData={ onChangeUserData } newUser={ newUser } roles={ roles } />
        }
        {
          currentTab === 1 && <Login onChangeUserData={ onChangeUserData } newUser={ newUser } />
        }
        {
          currentTab === 2 && <Companies onChangeUserData={ onChangeUserData } assignedCompanies={ newUser.company_ids } />
        }
        { onLoadUsers && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={() => {
            createUser( newUser )
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Create