import React, { useEffect } from 'react'
import { 
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from 'reactstrap'
import _ from 'lodash'
import { compose } from 'redux'

import SmileDatepicker from 'components/Datepicker'
import Typeahead from 'components/Typeahead'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithCountries from 'actions/dictionary/countries'
import WithStates from 'actions/dictionary/states'
import WithCities from 'actions/dictionary/cities'

import UserIcon from 'assets/Images/av1.png'

const General = ({
  selectedUser,
  onChangeUserData,
  roles,
  onLoadCountries,
  countries,
  getCountries,
  onLoadStates,
  states,
  getStates,
  onLoadCities,
  cities,
  getCities,
  updatePhoto
}) => {

  useEffect(() => {
    getCountries()
    getStates( selectedUser.country )
    getCities( selectedUser.country, selectedUser.state )
  }, [] )

  return (
    <Form onSubmit={ e => e.preventDefault()}>
      <Row>
        <Col md={ 8 }>
          <Row className="mt-3">
            <Col md={ 6 }>
              <FormGroup>
                <Label>Salutation</Label>
                <Input
                  type="select"
                  value={ selectedUser.salutation }
                  onChange={ e => {
                    onChangeUserData( 'salutation', e.target.value )
                  }}>
                  <option></option>
                  <option value={ 'Dr' }>Dr</option>
                  <option value={ 'Mr' }>Mr</option>
                  <option value={ 'Mrs' }>Mrs</option>
                  <option value={ 'Ms' }>Ms</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Name</Label>
                <Input 
                  type="text"
                  value={ selectedUser.name }
                  onChange={ e => {
                    onChangeUserData( 'name', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>IC/Passport</Label>
                <Input 
                  type="text"
                  value={ selectedUser.nric_no }
                  onChange={ e => {
                    onChangeUserData( 'nric_no', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Email(use this to login into system)</Label>
                <Input 
                  type="email"
                  value={ selectedUser.email }
                  onChange={ e => {
                    onChangeUserData( 'email', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>DOB</Label>
                <SmileDatepicker
                  selectedDate={ selectedUser.date_of_birth }
                  onChange={ val => {
                    onChangeUserData( 'date_of_birth', val )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Age</Label>
                <Input
                  type="number"
                  value={ selectedUser.age }
                  onChange={ e => {
                    onChangeUserData( 'age', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Gender</Label>
                <Input
                  type="select"
                  value={ selectedUser.gender }
                  onChange={ e => {
                    onChangeUserData( 'gender', e.target.value )
                  }}>
                  <option value={ '0' }></option>
                  <option value={ '1' }>Male</option>
                  <option value={ '2' }>Female</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Role</Label>
                <Input
                  type="select"
                  value={ selectedUser.role_id }
                  onChange={ e => {
                    onChangeUserData( 'role_id', e.target.value )
                  }}>
                  <option></option>
                  {
                    roles.map( item => {
                      if( item.name !== 'Super Admin' ) {
                        return <option key={ item.id } value={ item.id }>{ item.name }</option>
                      }
                    })
                  }
                </Input>
                {
                  roles.length > 0 && _.find( roles, { id: selectedUser.role_id } ) && (
                    <p>{`Current selected role: ${ _.find( roles, { id: selectedUser.role_id } ).name }`}</p>
                  )
                }
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Address</Label>
                <Input
                  type="text"
                  value={ selectedUser.address }
                  onChange={ e => {
                    onChangeUserData( 'address', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Country</Label>
                <Typeahead
                  id={ 'country' }
                  multiple={ false }
                  options={ countries }
                  onSelect={ val => {
                    if( val && val.length > 0 ) {
                      onChangeUserData( 'country', val[ 0 ].code )
                      getStates( val[ 0 ].code )
                    } else {
                      onChangeUserData( 'country', '' )
                    }
                  }}
                  selectedValue={ _.find( countries, { id: selectedUser.country }) ? [ _.find( countries, { id: selectedUser.country }) ] : [] } />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Postcode</Label>
                <Input
                  type="text"
                  value={ selectedUser.postcode }
                  onChange={ e => {
                    onChangeUserData( 'postcode', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>State</Label>
                <Input
                  type="select"
                  value={ selectedUser.state }
                  onChange={ e => {
                    onChangeUserData( 'state', e.target.value )
                    getCities( selectedUser.country, e.target.value )
                  }}>
                  <option></option>
                  {
                    states && states.map( item => {
                      return <option key={ item.code } value={ item.code }>{ item.name }</option>
                    })
                  }
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>City</Label>
                <Input
                  type="select"
                  value={ selectedUser.city }
                  onChange={ e => {
                    onChangeUserData( 'city', e.target.value )
                  }}>
                  <option></option>
                  {
                    cities && cities.map( item => {
                      return <option key={ item.code } value={ item.code }>{ item.code }</option>
                    })
                  }
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Language</Label>
                <Input
                  type="select"
                  value={ selectedUser.language }
                  onChange={ e => {
                    onChangeUserData( 'language', e.target.value )
                  }}>
                  <option></option>
                  <option value={ 2 }>Mandarin</option>
                  <option value={ 1 }>English</option>
                  <option value={ 3 }>Malay</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Contact number</Label>
                <Input 
                  type="text"
                  value={ selectedUser.contact_number }
                  onChange={ e => {
                    onChangeUserData( 'contact_number', e.target.value )
                  }} />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md={ 4 }>
          <img src={ ( selectedUser.photo && selectedUser.photo !== '' ) ? selectedUser.photo : UserIcon } style={{ width: '60%' }} />
          <input 
            type="file" 
            accept="image/*" 
            className="mt-2"
            onChange={ e => {
              const reader = new FileReader()
              reader.onload = () => {
                updatePhoto({ photo: reader.result })
              }
              reader.readAsDataURL( e.target.files[ 0 ] )
            }} />
          <FormGroup className="pt-3">
            <Label>Status</Label>
            <Input 
              type="select"
              value={ selectedUser.status }
              onChange={ e => {
                onChangeUserData( 'status', e.target.value )
              }}>
              <option></option>
              <option value={ 'active' }>Active</option>
              <option value={ 'inactive' }>Inactive</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      { ( onLoadCountries || onLoadStates || onLoadCities ) && <LoadingOverlay /> }
    </Form>
  )
}

export default compose(
  WithCountries,
  WithStates,
  WithCities
)( General )