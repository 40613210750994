import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      roles: [],
      loading: false
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getRoles = () => Get(
      `/roles`,
      this.getRolesSuccess,
      this.getRolesError,
      this.load
    )
    getRolesSuccess = payload => this.setState({ roles: payload })
    getRolesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          getRoles={ this.getRoles }
          roles={ this.state.roles }
          onLoadRoles={ this.state.loading } />
      )
    }
  }
  return WithHOC
}

export default HOC