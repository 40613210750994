import React, { Component } from 'react'

class UserGuide extends Component {
  componentDidMount = () => {
    window.open( 'https://s3-ap-southeast-1.amazonaws.com/files.primeview.site/primeview_userguide.pdf' )
  }

  render = () => {
    return( 
      <></>
    )
  }
}
export default UserGuide