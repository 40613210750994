import React from 'react'
import _ from 'lodash'
import {
  Form, FormGroup,
  Label, Input, Button
} from 'reactstrap'
import { Multiselect } from 'react-widgets'

const ListItem = ({ item }) => (
  <span>
    { `${ item.name } - ${ item.nric_no } - ${ item.contact_number }` }
  </span>
)

const ReferralForm  = ({
  referralFormType,
  currentReferralForm,
  onChangeReferralFormsHOC,
  profile,
  doctors,
  patients,
  getPatients,
  onChangePatientsHOC,
  onLoadPatientsHOC,
  searchParams
}) => {
  const updateReferralForm = ( key, val ) => {
    let tmp = _.cloneDeep( currentReferralForm )
    tmp[ key ] = val

    switch( key ){
      case 'company_id':
        tmp.branch_id = 0
        tmp.user_id = 0  
        break
      case 'branch_id': 
        tmp.user_id = 0
    }

    onChangeReferralFormsHOC( referralFormType, tmp )
  }

  return(
    <Form>
      <FormGroup>
        <Label>Company</Label>
        <Input
          type='select'
          value={ currentReferralForm.company_id }
          onChange={ e => updateReferralForm( 'company_id', e.target.value ) }>
          <option value={ 0 } ></option>
          { profile.companies.map( item => <option value={ item.id } >{ item.name }</option> ) }
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Branch</Label>
        <Input
          type='select'
          value={ currentReferralForm.branch_id }
          onChange={ e => updateReferralForm( 'branch_id', e.target.value ) }>
          <option value={ 0 } ></option>
          { 
            _.filter( 
              profile.branches, branch => parseInt( branch.company.id ) === parseInt( currentReferralForm.company_id )
            ).map( item => <option value={ item.id } >{ item.name }</option> ) 
          }
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Doctor</Label>
        <Input
          type='select'
          value={ currentReferralForm.user_id }
          onChange={ e => updateReferralForm( 'user_id', e.target.value ) }>
          <option value={ 0 } ></option>
          { 
            _.filter( 
              doctors, doctor => _.findIndex( doctor.branch_ids, branch => parseInt( branch ) === parseInt( currentReferralForm.branch_id ) ) > -1
            ).map( item => <option value={ item.id } >{ `${ item.salutation } ${ item.name }` }</option> ) 
          }
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Patient</Label>
        <div className="d-flex mb-2">
          <Input 
            type="text" 
            value={ searchParams }
            className='mr-2'
            onChange={ e => onChangePatientsHOC( 'searchParams', e.target.value ) }
            placeholder="Fill in patient NRIC or name here and click search button" />
          <Button 
            color="primary" 
            onClick={() => {
              getPatients( searchParams )
              updateReferralForm( 'patient_id', 0 ) }}>
            Search</Button>
        </div>
        {
          onLoadPatientsHOC 
            ? (
              <p>Loading patients......</p>
            ) : <>
              <Multiselect
                data={ patients }
                textField={ 'name' }
                itemComponent={ ListItem }
                value={ currentReferralForm.patient_id > 0 
                  ? _.find( patients, { id: parseInt( currentReferralForm.patient_id ) } ) 
                    ? [ _.find( patients, { id: parseInt( currentReferralForm.patient_id ) } ) ] 
                    : [ {} ]
                  : [] }
                onChange={ val => val.length === 1 
                  ? updateReferralForm( 'patient_id', val[ 0 ].id )
                  : updateReferralForm( 'patient_id', 0 )
                } />
            </>
        }
      </FormGroup>
      <FormGroup>
        <Label>Referred clinic</Label>
        <Input
          type='text'
          value={ currentReferralForm.referred_clinic_name }
          onChange={ e => updateReferralForm( 'referred_clinic_name', e.target.value ) } />
      </FormGroup>
      <FormGroup>
        <Label>Referred dentist</Label>
        <Input
          type='text'
          value={ currentReferralForm.referred_dentist }
          onChange={ e => updateReferralForm( 'referred_dentist', e.target.value ) } />
      </FormGroup>
      <FormGroup>
        <Label>Referred dentist contact</Label>
        <Input
          type='text'
          value={ currentReferralForm.referred_dentist_contact }
          onChange={ e => updateReferralForm( 'referred_dentist_contact', e.target.value ) } />
      </FormGroup>
      <FormGroup>
        <Label>Referred dentist address</Label>
        <Input
          type='text'
          value={ currentReferralForm.referred_dentist_address }
          onChange={ e => updateReferralForm( 'referred_dentist_address', e.target.value ) } />
      </FormGroup>
      <FormGroup>
        <Label>Referred dentist e-mail</Label>
        <Input
          type='text'
          value={ currentReferralForm.referred_dentist_email }
          onChange={ e => updateReferralForm( 'referred_dentist_email', e.target.value ) } />
      </FormGroup>
      <FormGroup>
        <Label>Reason</Label>
        <Input
          type='textarea'
          value={ currentReferralForm.reason }
          onChange={ e => updateReferralForm( 'reason', e.target.value ) } />
      </FormGroup>
    </Form>
  )
}

export default ReferralForm