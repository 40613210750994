import React, { useEffect } from 'react'
import { 
  Card, CardBody, Button,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PromptModal from 'components/Indicator/Prompt'
import ConsentTemplateForm from './form'

import WithConsentTemplates from './actions'

const ConsentTemplates = props => {
  useEffect(() => {
    props.getConsentTemplates()
    props.reloadNewConsentTemplate()
  }, [])

  const renderCreateModal = () => (
    <Modal size='lg' isOpen={ props.showCreateConsentTemplate }>
      <ModalHeader 
        toggle={ () => props.reloadNewConsentTemplate() }>
        Create Consent Form</ModalHeader>
      <ModalBody>
        <ConsentTemplateForm
          consentTemplateType='newConsentTemplate'
          currentConsentTemplate={ props.newConsentTemplate }
          onChangeConsentTemplatesHOC={ props.onChangeConsentTemplatesHOC }
          profile={ props.data.profileReducer.profile }
          getDefaultConsentTemplate={ props.getDefaultConsentTemplate } />
        { props.onLoadConsentTemplatesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color='primary'
          onClick={ () => props.createConsentTemplate( props.newConsentTemplate ) } >
          Submit</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateModal = () => (
    <Modal size='lg' isOpen={ props.showUpdateConsentTemplate }>
      <ModalHeader 
        toggle={ () => props.reloadSelectedConsentTemplate() }>
        Update Consent Form</ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedConsentTemplate ) && <ConsentTemplateForm
            consentTemplateType='selectedConsentTemplate'
            currentConsentTemplate={ props.selectedConsentTemplate }
            onChangeConsentTemplatesHOC={ props.onChangeConsentTemplatesHOC }
            profile={ props.data.profileReducer.profile } />
        }
        { props.onLoadConsentTemplatesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color='primary'
          onClick={ () => props.updateConsentTemplate( props.selectedConsentTemplate ) } >
          Submit</Button>
      </ModalFooter>
    </Modal>
  )
  
  return(
    <>
      <PageTitle
        heading="Consent templates"
        subheading="Listings of all the consent templates."
        icon="pe-7s-clock icon-gradient bg-happy-itmeo"
        buttons={[
          {  
            color: 'primary',
            onClick: () => props.onChangeConsentTemplatesHOC( 'showCreateConsentTemplate', true ),
            content: 'Create consent template'
          }
        ]} />
      <Card className="main-card mb-3">
        <CardBody>
          <ReactTable
            showPagination={ false }
            pageSize={ 20 }
            data={ props.consentTemplates }
            columns={[
              {
                Header: 'Company',
                accessor: 'company_name',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Form type',
                accessor: 'type_name',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Action',
                style:{ 
                  "justify-content": "center",
                  "overflow": "scroll" 
                },
                Cell: row => <>
                  <Button 
                    color='primary'
                    className='mr-2'
                    onClick={ () => props.getSelectedConsentTemplate( row.original.id ) }>
                    <i className='pe-7s-pen'/></Button>
                  <Button 
                    color='danger'
                    className='ml-2'
                    onClick={ () => props.onChangeConsentTemplatesHOC( 'selectedConsentTemplateToDelete', row.original.id ) }>
                    <i className='pe-7s-trash'/></Button>
                </>
              }
            ]}/>
        </CardBody>
      </Card>
      <PromptModal
        showPromptModal={ props.selectedConsentTemplateToDelete > 0 }
        onClickYes={ () => props.removeConsentTemplate( props.selectedConsentTemplateToDelete ) }
        onClickNo={ () => props.onChangeConsentTemplatesHOC( 'selectedConsentTemplateToDelete', 0 ) }
        content={ 'Are you sure you want to delete the record?' } />
      { renderCreateModal() }
      { renderUpdateModal() }
      { props.onLoadConsentTemplatesHOC && <LoadingOverlay/> }
    </>
  )
}

export default WithConsentTemplates( ConsentTemplates )

