import React, { Component } from 'react'
import { 
  Row, 
  Col,
  Card,
  CardBody,
  Button,
  Form
} from 'reactstrap'
import Moment from 'moment'
import InfiniteCalendar from 'react-infinite-calendar'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import { compose } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import PageTitle from 'components/Title'
import SearchForm from './SearchForm'
import CreateAppointment from './Create'
import UpdateAppointment from './Update'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DayGrid from './components/DayGrid'

import WithAppointments from './actions'
import WithDoctors from 'actions/dictionary/doctors'
import WithOffDays from 'actions/dictionary/offDays'
import { getProfile } from 'actions/profile'

import 'react-infinite-calendar/styles.css'
import './calendar.scss'

let today = new Date()

class Appointments extends Component {
  state = {
    selectedDate: new Date(),
    uid: 0,
    calendarDoctors: [],
    showLeftCalendarPanel: false
  }

  componentDidMount = async() => {
    await this.props.getProfile()
    await this.props.getDoctors( this.props.data.profileReducer.profile.current_user.branch_ids )
    if( this.props.data.profileReducer.profile.current_user.role.name !== 'Doctor' ) {
      Promise.all([
        this.props.getOffDays( JSON.stringify( [ 0 ] ) )
      ]).then(() => {
        if( this.props.data.profileReducer.profile.current_user.role.name === 'Frontdesk' ) {
          this.props.getAppointments( 
            [], 
            [this.props.data.profileReducer.profile.current_user.branch_ids[0]], 
            Moment().clone().startOf('month').format( 'YYYY-MM-DD' ), 
            Moment().clone().endOf('month').add( 1, 'days' ).format( 'YYYY-MM-DD' )
          )
        } else {
          this.props.getAppointments( 
            [], 
            [], 
            Moment().clone().startOf('month').format( 'YYYY-MM-DD' ), 
            Moment().clone().endOf('month').add( 1, 'days' ).format( 'YYYY-MM-DD' )
          )
        }
      })
    } else {
      Promise.all([ 
        this.props.getOffDays([ this.props.data.profileReducer.profile.current_user.id ]),
        this.props.onChangeAppointmentsHOC( 'selectedDoctorID', [ _.find( this.props.doctors, dr => dr.id === this.props.data.profileReducer.profile.current_user.id ) ] )
      ]).then(() => {
        this.props.getAppointments( 
          [ this.props.data.profileReducer.profile.current_user.id ], 
          [], 
          Moment().clone().startOf('month').format( 'YYYY-MM-DD' ), 
          Moment().clone().endOf('month').add( 1, 'days' ).format( 'YYYY-MM-DD' )
        )
      })
    }
  }

  componentDidUpdate = pp => {
    if( this.props.data.ajaxReducer.ajaxCallProgress !== pp.data.ajaxReducer.ajaxCallProgress ) {
      if( this.props.data.ajaxReducer.ajaxCallProgress === 0 ) {
        this.setState({ uid: this.props.data.profileReducer.profile.current_user.id })
      }
    }
    if( pp.doctors !== this.props.doctors && this.state.calendarDoctors.length < 1 ){
      this.setState({ calendarDoctors: _.cloneDeep( this.props.doctors ) })
    }
  }

  checkRoles = () => {
    let tmp = []
    if( this.props.data.profileReducer.profile.current_user.role.name === 'Doctor' ) {
      let tmpDoc = _.find( this.state.calendarDoctors, { id: this.props.data.profileReducer.profile.current_user.id })
      tmp = [ tmpDoc ]
    } else {
      let tmpDocs = [];
      this.props.doctors.map(item => {
        if(this.props.selectedDoctorID.length > 0) {
          this.props.selectedDoctorID.map(tmpDoc => {
            if(item.id === tmpDoc.id) {
              tmpDocs.push(item)
            }
          })
        } else {
          tmpDocs.push(item)
        }
      })
      tmp = _.cloneDeep( _.reverse( tmpDocs ) )
    }
    return tmp
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading="Appointments"
          subheading="Listings of all the appointments presence in the system."
          icon="pe-7s-stopwatch icon-gradient bg-happy-itmeo"
          buttons={[
            // {  
            //   color: 'primary',
            //   onClick: () => this.props.onChangeAppointmentsHOC( 'showCreateAppointment', true ),
            //   content: 'Add appointment'
            // }
          ]} />
        <div>
          <Row>
            <Col md={ 12 }>
              <SearchForm { ...this.props } />
            </Col>
            <Col md={ 12 }>
              <Card className="main-card mb-3">
                <CardBody>
                  <Row>
                    <Col md={ 12 }>
                      <Button 
                        color="primary"
                        onClick={() => {
                          this.setState({ showLeftCalendarPanel: !this.state.showLeftCalendarPanel })
                        }}>Toggle Calendar</Button>
                    </Col>
                    {
                      this.state.showLeftCalendarPanel && (
                        <Col md={ 3 } style={{ paddingBottom: '30px' }}>
                          <InfiniteCalendar
                            width={ '100%' }
                            height={ 600 }
                            displayOptions={{
                              showHeader: true
                            }}
                            selected={ this.state.selectedDate }
                            onSelect={ val => {
                              this.setState({ selectedDate: val }, () => {
                                this.props.onChangeAppointmentsHOC( 'selectedDate', val )
                              })
                              if( this.props.data.profileReducer.profile.current_user.role.name !== 'Doctor' ) {
                                if( this.props.data.profileReducer.profile.current_user.role.name === 'Frontdesk' ) {
                                  this.props.getAppointments( 
                                    [], 
                                    [this.props.data.profileReducer.profile.current_user.branch_ids[0]], 
                                    Moment( val ).clone().startOf('month').format( 'YYYY-MM-DD' ), 
                                    Moment( val ).clone().endOf('month').add( 1, 'days' ).format( 'YYYY-MM-DD' ) 
                                  )
                                } else {
                                  this.props.getAppointments( 
                                    [], 
                                    [], 
                                    Moment( val ).clone().startOf('month').format( 'YYYY-MM-DD' ), 
                                    Moment( val ).clone().endOf('month').add( 1, 'days' ).format( 'YYYY-MM-DD' ) 
                                  )
                                }
                              } else {
                                this.props.getAppointments( 
                                  [ this.props.data.profileReducer.profile.current_user.id ], 
                                  [], 
                                  Moment( val ).clone().startOf('month').format( 'YYYY-MM-DD' ), 
                                  Moment( val ).clone().endOf('month').add( 1, 'days' ).format( 'YYYY-MM-DD' ) 
                                )
                              }
                            }}
                          />
                        )
                      </Col>
                      )
                    }
                    <Col md={ this.state.showLeftCalendarPanel ? 9 : 12 } style={{ overflowX: 'scroll' }}>
                      {
                        this.props.data.profileReducer.profile.current_user.role.name === 'Doctor' ? (
                          <Calendar
                            style={{
                              height: '700px',
                              width: this.props.doctors.length > 5 ? `${ (this.props.doctors.length * 150) + 100 }px` : '100%',
                              // width: this.props.selectedDoctorID.length > 0 ? `${ (this.props.selectedDoctorID.length * 150) + 300 }px` : this.props.doctors.length > 5 ? `${ (this.props.doctors.length * 150) + 70 }px` : '100%'
                            }}
                            selectable
                            resources={ this.checkRoles() }
                            date={ this.state.selectedDate }
                            localizer={ momentLocalizer( Moment ) }
                            defaultView={ Views.DAY }
                            step={ 7.5 }
                            min={ new Date( today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0 ) }
                            events={
                              this.props.showCancelledAppointments 
                                ? this.props.appointments
                                : _.filter( this.props.appointments, item => item.status !== 'Cancelled' )
                              // [
                                // {
                                //   id: 1,
                                //   title: `${ 'John Doe' } - ${ '920902055513' } - ${ 'Root canal' }`,
                                //   start_datetime: new Date( '2020-08-29 16:34' ),
                                //   end_datetime: new Date( '2020-08-29 17:34' ),
                                //   status: 'Active'
                                // },
                                // {
                                //   id: 2,
                                //   title: `${ 'John Lark' } - ${ '940902055513' } - ${ 'Root canal' }`,
                                //   start_datetime: new Date( '2020-08-29 17:34' ),
                                //   end_datetime: new Date( '2020-08-29 18:34' ),
                                //   status: 'Confirmed'
                                // },
                                // {
                                //   id: 3,
                                //   title: `${ 'John Lark' } - ${ '940902055513' } - ${ 'Root canal' }`,
                                //   start_datetime: new Date( '2020-08-29 18:34' ),
                                //   end_datetime: new Date( '2020-08-29 19:34' ),
                                //   status: 'Shown'
                                // }
                              // ]
                            }
                            startAccessor="start_datetime"
                            endAccessor="end_datetime"
                            titleAccessor={ event => `${ event.title } ( ${ event.status.toUpperCase() } )` }
                            eventPropGetter={( event, start, end, isSelected ) => {
                              let tmpBgColor = `007bff`
                              if( event.status === 'Confirmed' ) {
                                tmpBgColor = `28a745`
                              } else if( event.status === 'Active' ) {
                                tmpBgColor = `17a2b8`
                              } else if( event.status === 'No show' ) {
                                tmpBgColor = `4c4c4c`
                              } else if( event.status === 'Waiting list' ) {
                                tmpBgColor = `663399`
                              } else if( event.status === 'Off day' ) {
                                tmpBgColor = `42a7a4`
                              } else if( event.status === 'Cancelled' ) {
                                tmpBgColor = `ff1a40`
                              }
                              let style = {
                                backgroundColor: `#${ tmpBgColor }`,
                                borderRadius: '0px',
                                opacity: 0.8,
                                color: '#fff',
                                border: '0px',
                                display: 'block',
                                maxWidth: '100%'
                              }
                              return {
                                style: style
                              }
                            }}
                            onSelectEvent={( data, e ) => {
                              if( data.status !== 'Off day' ) {
                                this.props.getSelectedAppointment( data.id )
                              }
                            }}
                            onSelectSlot={( data, e ) => {
                              let tmp = _.cloneDeep( this.props.newAppointment )
                              tmp.start_datetime = new Date( data.start )
                              tmp.end_datetime = new Date( data.start )
                              Promise.all([
                                this.props.onChangeAppointmentsHOC( 'newAppointment', tmp )
                              ]).then(() => {
                                this.props.onChangeAppointmentsHOC( 'showCreateAppointment', tmp )
                              })
                            }}
                            dayLayoutAlgorithm="no-overlap"
                          />
                        ) : (
                          <Calendar
                            style={{
                              height: '700px',
                              // width: this.props.doctors.length > 5 ? `${ (this.props.doctors.length * 150) + 70 }px` : '100%',
                              width: this.props.selectedDoctorID.length > 0 ? `${ (this.props.selectedDoctorID.length * 150) + 100 }px` : this.props.doctors.length > 5 ? `${ (this.props.doctors.length * 150) + 70 }px` : '100%'
                            }}
                            selectable
                            resources={ this.checkRoles() }
                            date={ this.state.selectedDate }
                            localizer={ momentLocalizer( Moment ) }
                            defaultView={ Views.DAY }
                            step={ 7.5 }
                            min={ new Date( today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0 ) }
                            events={
                              this.props.showCancelledAppointments 
                                ? this.props.appointments
                                : _.filter( this.props.appointments, item => item.status !== 'Cancelled' )
                              // [
                                // {
                                //   id: 1,
                                //   title: `${ 'John Doe' } - ${ '920902055513' } - ${ 'Root canal' }`,
                                //   start_datetime: new Date( '2020-08-29 16:34' ),
                                //   end_datetime: new Date( '2020-08-29 17:34' ),
                                //   status: 'Active'
                                // },
                                // {
                                //   id: 2,
                                //   title: `${ 'John Lark' } - ${ '940902055513' } - ${ 'Root canal' }`,
                                //   start_datetime: new Date( '2020-08-29 17:34' ),
                                //   end_datetime: new Date( '2020-08-29 18:34' ),
                                //   status: 'Confirmed'
                                // },
                                // {
                                //   id: 3,
                                //   title: `${ 'John Lark' } - ${ '940902055513' } - ${ 'Root canal' }`,
                                //   start_datetime: new Date( '2020-08-29 18:34' ),
                                //   end_datetime: new Date( '2020-08-29 19:34' ),
                                //   status: 'Shown'
                                // }
                              // ]
                            }
                            startAccessor="start_datetime"
                            endAccessor="end_datetime"
                            titleAccessor={ event => `${ event.title } ( ${ event.status.toUpperCase() } )` }
                            eventPropGetter={( event, start, end, isSelected ) => {
                              let tmpBgColor = `007bff`
                              if( event.status === 'Confirmed' ) {
                                tmpBgColor = `28a745`
                              } else if( event.status === 'Active' ) {
                                tmpBgColor = `17a2b8`
                              } else if( event.status === 'No show' ) {
                                tmpBgColor = `4c4c4c`
                              } else if( event.status === 'Waiting list' ) {
                                tmpBgColor = `663399`
                              } else if( event.status === 'Off day' ) {
                                tmpBgColor = `42a7a4`
                              } else if( event.status === 'Cancelled' ) {
                                tmpBgColor = `ff1a40`
                              }
                              let style = {
                                backgroundColor: `#${ tmpBgColor }`,
                                borderRadius: '0px',
                                opacity: 0.8,
                                color: '#fff',
                                border: '0px',
                                display: 'block',
                                maxWidth: '100%'
                              }
                              return {
                                style: style
                              }
                            }}
                            onSelectEvent={( data, e ) => {
                              if( data.status !== 'Off day' ) {
                                this.props.getSelectedAppointment( data.id )
                              }
                            }}
                            onSelectSlot={( data, e ) => {
                              let tmp = _.cloneDeep( this.props.newAppointment )
                              tmp.start_datetime = new Date( data.start )
                              tmp.end_datetime = new Date( data.start )
                              Promise.all([
                                this.props.onChangeAppointmentsHOC( 'newAppointment', tmp )
                              ]).then(() => {
                                this.props.onChangeAppointmentsHOC( 'showCreateAppointment', tmp )
                              })
                            }}
                            dayLayoutAlgorithm="no-overlap"
                          />
                        )
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <CreateAppointment { ...this.props } />
        <UpdateAppointment 
          { ...this.props }
          uid={ this.state.uid } />
        { ( this.props.onLoadAppointments || this.props.onLoadDoctors || this.props.onLoadOffDays ) && <LoadingOverlay /> }
      </>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  getProfile
})( 
  compose(
    WithDoctors,
    WithOffDays,
    WithAppointments
  )( Appointments )
)