import React, { useState } from 'react'
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label
} from 'reactstrap'
import _ from 'lodash'

import ToothChart from './ToothChart'
import { priceWithSeparators } from 'utils/priceWithSeparators'

import CurrencySettings from 'utils/currencies'

const TreatmentComponent = ({
  tabType,
  removeInvoiceTreatment,
  removeTreatmentFromMedicalRecord,
  item,
  selectedInvoice,
  selectedMedicalRecord,
  updateToothChart,
  index,
  onChangeInvoiceHOC,
  updateInvoiceTreatment,
  updateMedicalRecordData,
  updateMedicalRecordTreatment
}) => {
  const [ collapse, toggleCollapse ] = useState( false )
  const [ collapseTreatmentType, toggleCollapseTreatmentType ] = useState( false )
  const [ collapseTreatmentName, toggleCollapseTreatmentName ] = useState( false )

  return (
    <Card className='mb-2' key={ item.id }>
      <CardBody>
        <div>
          <span className="mr-3">{ item.treatment_name }</span>
          <span className="mr-3">{ `${ CurrencySettings() }${ priceWithSeparators( tabType === 'General' ? item.price : item.formated_price ) }` }</span>
          {
            tabType === 'General' && <Button 
              color="primary"
              className="mr-2"
              onClick={() => {
                toggleCollapse( !collapse )
              }}>
              Toggle tooth chart
            </Button>
          }
          {
            tabType === 'Invoice' && <Button 
              color="primary"
              className="mr-2"
              onClick={() => {
                toggleCollapseTreatmentName( !collapseTreatmentName )
              }}>
              Toggle receipt name
            </Button>
          }
          <Button 
            color="primary"
            className="mr-2"
            onClick={() => {
              toggleCollapseTreatmentType( !collapseTreatmentType )
            }}>
            Toggle treatment type
          </Button>
          <Button 
            color="danger"
            onClick={() => {
              if( tabType === 'General' ){
                removeTreatmentFromMedicalRecord({
                  medical_record_id: selectedMedicalRecord.id,
                  treatment_id: item.treatment_id
                })
              } else {
                let tmpRes = parseFloat( selectedInvoice.total_amount ) - parseFloat( item.featured_price )
                removeInvoiceTreatment( selectedInvoice.id, item.treatment_id, tmpRes )
              }
            }}>Remove</Button>
        </div>
        {
          collapse && (
            <>
              <hr />
              <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <ToothChart
                  data={ item.tooth_charts ? item.tooth_charts : [] }
                  onChange={ val => {
                    let tmp = _.cloneDeep( selectedMedicalRecord )
                    tmp[ 'medical_record_treatments' ][ index ][ 'tooth_charts' ] = val
                    updateMedicalRecordData( 'medical_record_treatments', tmp[ 'medical_record_treatments' ] )
                  }} />
                <Button 
                  color="primary"
                  onClick={() => {
                    updateMedicalRecordTreatment({
                      types: item.types ? item.types : [],
                      tooth_charts: item.tooth_charts ? item.tooth_charts : [],
                      medical_record_id: selectedMedicalRecord.id,
                      id: item.id,
                      treatment_id: item.treatment_id
                    })
                  }}>Update tooth chart</Button>
              </div>
            </>
          )
        }
        {
          collapseTreatmentType && (
            <>
              <hr />
              <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <FormGroup>
                  <div className="d-flex align-items-center pb-2">
                    <Label>List of treatment types</Label>
                    <Button 
                      className="ml-3" 
                      color="primary"
                      onClick={ () => {
                        if( tabType === 'General' ){
                          let tmp = _.cloneDeep( selectedMedicalRecord )
                          if( tmp[ 'medical_record_treatments' ][ index ][ 'types' ] === null ) {
                            tmp[ 'medical_record_treatments' ][ index ][ 'types' ] = []
                          }
                          tmp[ 'medical_record_treatments' ][ index ][ 'types' ].push({ content: '' })
                          updateMedicalRecordData( 'medical_record_treatments', tmp[ 'medical_record_treatments' ] ) 
                        } else {
                          let tmp = _.cloneDeep( selectedInvoice )
                          tmp.invoice_treatments[ index ].types.push({ content: '' })
                          onChangeInvoiceHOC( 'selectedInvoice', tmp )
                        }
                      }}>
                      Add treatment type</Button>
                  </div>
                  {
                    item.types && item.types.map( ( type, treatmentTypeIndex ) => {
                      return (
                        <div className="d-flex mb-2">
                          <Input
                            className="mr-2"
                            type="text"
                            value={ type.content }
                            onChange={ e => {
                              if( tabType === 'General' ){
                                let tmp = _.cloneDeep( selectedMedicalRecord )
                                tmp[ 'medical_record_treatments' ][ index ][ 'types' ][ treatmentTypeIndex ][ 'content' ] = e.target.value
                                updateMedicalRecordData( 'medical_record_treatments', tmp[ 'medical_record_treatments' ] ) 
                              } else {
                                let tmpInvoice = _.cloneDeep( selectedInvoice )
                                let tmpTreatment = tmpInvoice.invoice_treatments[ index ]
                                if( !_.isEmpty( tmpTreatment ) ){
                                  tmpTreatment.types[ treatmentTypeIndex ][ 'content' ] = e.target.value
                                  tmpInvoice.invoice_treatments[ index ] = tmpTreatment
                                }
                                onChangeInvoiceHOC( 'selectedInvoice', tmpInvoice )
                              }
                            }} />
                          <Button 
                            color="danger"
                            onClick={ () => {
                              if( tabType === 'General' ){
                                let tmp = _.cloneDeep( selectedMedicalRecord )
                                tmp[ 'medical_record_treatments' ][ index ][ 'types' ].splice( treatmentTypeIndex, 1 )
                                updateMedicalRecordData( 'medical_record_treatments', tmp[ 'medical_record_treatments' ] ) 
                              } else {
                                let tmpInvoice = _.cloneDeep( selectedInvoice )
                                let tmpTreatment = tmpInvoice.invoice_treatments[ index ]
                                tmpTreatment.types.splice( treatmentTypeIndex, 1 )
                                tmpInvoice.invoice_treatments[ index ] = tmpTreatment
                                onChangeInvoiceHOC( 'selectedInvoice', tmpInvoice )
                              }
                            }}>
                            <i className="pe-7s-trash btn-icon-wrapper"> </i>
                          </Button>
                        </div>
                      )
                    })
                  }
                </FormGroup>
                <FormGroup>
                  <Button 
                    color="primary"
                    onClick={() => {
                      if( tabType === 'General' ){
                        updateMedicalRecordTreatment({
                          types: item.types ? item.types : [],
                          tooth_charts: item.tooth_charts ? item.tooth_charts : [],
                          medical_record_id: selectedMedicalRecord.id,
                          id: item.id,
                          treatment_id: item.treatment_id
                        })
                      } else {
                        updateInvoiceTreatment({
                          invoice_treatment_id: item.id,
                          treatment_id: item.treatment_id,
                          price: item.formated_price,
                          types: item.types,
                          name: item.name, 
                          id: selectedInvoice.id
                        })
                      }
                    }}>
                    Update treatment type</Button>
                </FormGroup>
              </div>
            </>
          )
        }
        {
          collapseTreatmentName && (
            <>
              <hr />
              <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <FormGroup>
                  <Label>Receipt Name</Label>
                  <div className='d-flex'>
                    <Input
                      type='text'
                      className='mr-2'
                      value={ item.name }
                      onChange={ e => {
                        let tmpInvoice = _.cloneDeep( selectedInvoice )
                        let tmpTreatment = tmpInvoice.invoice_treatments[ index ]
                        tmpTreatment.name = e.target.value
                        tmpInvoice.invoice_treatments[ index ] = tmpTreatment
                        onChangeInvoiceHOC( 'selectedInvoice', tmpInvoice )
                      }} />
                    <Button
                      color='primary'
                      onClick={ () => {
                        updateInvoiceTreatment({
                          invoice_treatment_id: item.id,
                          treatment_id: item.treatment_id,
                          price: item.formated_price,
                          types: item.types, 
                          name: item.name, 
                          id: selectedInvoice.id
                        })
                      }}>
                      <span style={{ whiteSpace: "nowrap" }} >Update receipt name</span>
                    </Button>
                  </div>
                </FormGroup>
              </div>
            </>
          )
        }
      </CardBody>
    </Card>
  )
}

export default TreatmentComponent