import React, { useState, useEffect } from 'react'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import { toast, ToastContainer } from 'react-toastify'
import { 
  Card, CardBody,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Label, Button
} from 'reactstrap'
import { compose } from 'redux'
import Moment from 'moment'
import ReactTable from 'react-table'
import _ from 'lodash'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SearchForm from './SearchForm'

import WithBirthdays from './actions'
import WithPatients from './actions/Patients'

const Birthdays = props => {
  const [ selectedBirthdays, updateSelectedBirthdays ] = useState({
    date: Moment().toDate(),
    events: []
  })

  useEffect( () => {
    if( props.data.profileReducer.profile.companies.length > 0 ){
      props.getPatientBirthdays({ 
        ...props.searchParams, 
        company_id: props.data.profileReducer.profile.companies[ 0 ].id 
      })
    }
  }, [] )

  const globalizeLocalizer = momentLocalizer( Moment )

  const handleCalendarNavigate = date => props.getPatientBirthdays({ 
    ...props.searchParams, 
    date: Moment( _.cloneDeep( date ) ).format( 'YYYY-M-D' )
  })

  const renderShowMoreBirthdaysModal = () => (
    <Modal isOpen={ selectedBirthdays.events.length > 0 } size='md' >
      <ModalHeader
        toggle={ () => updateSelectedBirthdays({ date: Moment().toDate(), events: [] }) }>
        { `Birthday: ${ Moment( selectedBirthdays.date ).format( 'DD-MM-YYYY' ) }` }</ModalHeader>
      <ModalBody>
        {
          selectedBirthdays.events.length > 1 ? (
            <ReactTable
              showPagination
              pageSize={ 10 }
              data={ selectedBirthdays.events }
              columns={[
                {
                  Header: 'Name',
                  accessor: 'name'
                },
                {
                  Header: 'Wishes via',
                  accessor: 'id',
                  Cell: row => <div className='d-flex flex-row flex-wrap w-100 justify-content-center' >
                    <Button 
                      color='primary' 
                      className='mr-2'
                      onClick={ () => props.getSelectedPatient( row.original.id, 'whatsapp' ) } >
                      Whatsapp
                    </Button>
                    <Button 
                      color='primary' 
                      onClick={ () => props.getSelectedPatient( row.original.id, 'email' ) } >
                      E-mail
                    </Button>
                  </div>
                }
              ]} />
          ) : (
            <div className='d-flex flex-row flex-wrap justify-content-between' >
              <Label>{ `Wishes ${ selectedBirthdays.events[ 0 ] && selectedBirthdays.events[ 0 ][ 'name' ] } via` }</Label>
              <div>
                <Button 
                  color='primary' 
                  className='mr-2'
                  onClick={ () => props.getSelectedPatient( selectedBirthdays.events[ 0 ][ 'id' ], 'whatsapp' ) } >
                  Whatsapp
                </Button>
                <Button 
                  color='primary' 
                  onClick={ () => props.getSelectedPatient( selectedBirthdays.events[ 0 ][ 'id' ], 'email' ) } >
                  E-mail
                </Button>
              </div>
            </div>
          )
        }
        <ToastContainer position={ toast.POSITION.BOTTOM_RIGHT } style={{ zIndex: 9999 }} />
        { ( props.onLoadPatientBirthdaysHOC || props.onLoadPatients ) && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color='primary' 
          onClick={ () => updateSelectedBirthdays({ date: Moment().toDate(), events: [] }) } >
          Close</Button>
      </ModalFooter>
    </Modal>
  )

  return(
    <>
      <PageTitle
        heading="Patient Birthdays"
        subheading="Listings of all the patient birhtdays under a company"
        icon="pe-7s-date icon-gradient bg-happy-itmeo" />
      <SearchForm { ...props } />
      <Card className="main-card mb-3">
        <CardBody>
          <div style={{ height: "100vh", overflow: "scroll" }} >
            <Calendar
              popup
              showMultiDayTimes
              events= { props.patientBirthdays }
              startAccessor={ event => Moment( event.date_of_birth, 'MM-DD' ).startOf( 'days' ).toDate() }
              endAccessor={ event => Moment( event.date_of_birth, 'MM-DD' ).startOf( 'days' ).toDate() }
              titleAccessor={ event => event.name }
              defaultView= { Views.MONTH }
              views={ [ Views.MONTH ] }
              defaultDate={ new Date( Date.now() ) }
              localizer={ globalizeLocalizer }
              onNavigate={ ( date ) => handleCalendarNavigate( date ) }
              onSelectEvent={ event => updateSelectedBirthdays({ date: Moment( event.date_of_birth ).toDate(), events: [ event ] }) }
              onShowMore={ ( events, date ) => updateSelectedBirthdays({ date: date, events: events }) }
            />
          </div>
        </CardBody>
      </Card>
      { renderShowMoreBirthdaysModal() }
      { ( props.onLoadPatientBirthdaysHOC || props.onLoadPatients ) && <LoadingOverlay/> }
    </>
  )
}

export default compose (
  WithBirthdays,
  WithPatients
)( Birthdays )