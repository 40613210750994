import React, { Component } from 'react'
import { toast } from 'react-toastify'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showCreateTreatment: false,
      showUpdateTreatment: false,
      newTreatment: {
        name: '',
        price: 0,
        company_id: 0
      },
      selectedTreatment: {
        name: '',
        price: 0,
        company_id: 0
      },
      treatments: [],
      treatmentError: {},
      searchParams: ''
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeTreatmentsHOC = ( key, val ) => this.setState({ [ key ]: val })

    getTreatments = () => Get(
      `/treatments${ this.state.searchParams !== '' ? `?search=${ this.state.searchParams }` : '' }`,
      this.getTreatmentsSuccess,
      this.getTreatmentsError,
      this.load
    )
    getTreatmentsSuccess = payload => this.setState({ treatments: payload })
    getTreatmentsError = error => this.requestError( error )

    getSelectedTreatment = id => Get(
      `/treatments/${ id }`,
      this.getSelectedTreatmentSuccess,
      this.getSelectedTreatmentError,
      this.load
    )
    getSelectedTreatmentSuccess = payload => this.setState({ selectedTreatment: payload, showUpdateTreatment: true })
    getSelectedTreatmentError = error => this.requestError( error )

    createTreatment = data => Post(
      `/treatments`,
      data,
      this.createTreatmentSuccess,
      this.createTreatmentError,
      this.load
    )
    createTreatmentSuccess = payload => {
      this.requestSuccess( 'Treatment is created successfully.' )
      this.setState({ showCreateTreatment: false })
      this.getTreatments()
    }
    createTreatmentError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ treatmentError: error })
      }
    }

    updateTreatment = data => Put(
      `/treatments/${ data.id }`,
      data,
      this.updateTreatmentSuccess,
      this.updateTreatmentError,
      this.load
    )
    updateTreatmentSuccess = payload => {
      this.requestSuccess( 'Treatment is updated successfully.' )
      this.setState({ showUpdateTreatment: false })
      this.getTreatments()
    }
    updateTreatmentError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ treatmentError: error })
      }
    }

    removeTreatment = id => Delete(
      `/treatments/${ id }`,
      this.removeTreatmentSuccess,
      this.removeTreatmentError,
      this.load
    )
    removeTreatmentSuccess = payload => {
      this.requestSuccess( 'Treatment is removed successfully.' )
      this.getTreatments()
      this.setState({ showPromptModal: false })
    }
    removeTreatmentError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent 
            { ...this.props }
            onLoadTreatments={ this.state.loading }
            showCreateTreatment={ this.state.showCreateTreatment }
            showUpdateTreatment={ this.state.showUpdateTreatment }
            newTreatment={ this.state.newTreatment }
            selectedTreatment={ this.state.selectedTreatment }
            treatments={ this.state.treatments }
            searchParams={ this.state.searchParams }
            onChangeTreatmentsHOC={ this.onChangeTreatmentsHOC }
            getTreatments={ this.getTreatments }
            getSelectedTreatment={ this.getSelectedTreatment }
            createTreatment={ this.createTreatment }
            updateTreatment={ this.updateTreatment } />
          <PromptModal
            showPromptModal={ this.state.showPromptModal }
            onClickYes={() => this.removeTreatment( this.state.toRemoveID )}
            onClickNo={() => this.setState({ showPromptModal: false })}
            content={ 'Are you sure you want to delete the record?' } />
        </>
      )
    }
  }
  return WithHOC
}

export default HOC