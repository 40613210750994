import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Post, Get, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      medicalRecordDocuments: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    createMedicalRecordDocument = ( medical_record_id, data ) => Post(
      `/medical_records/${ medical_record_id }/medical_record_documents`,
      data,
      this.createMedicalRecordDocumentSuccess,
      this.createMedicalRecordDocumentError,
      this.load
    )
    createMedicalRecordDocumentSuccess = () => {
      this.requestSuccess( 'Document is succesfully uploaded' )
      this.getMedicalRecordDocuments( this.props.selectedMedicalRecord.id )
      this.props.getSelectedMedicalRecord( this.props.selectedMedicalRecord.id )
    }
    createMedicalRecordDocumentError = error => this.requestError( error )

    getMedicalRecordDocuments = medical_record_id => Get(
      `/medical_records/${ medical_record_id }/medical_record_documents`,
      this.getMedicalRecordDocumentsSuccess,
      this.getMedicalRecordDocumentsError,
      this.load
    )
    getMedicalRecordDocumentsSuccess = payload => this.setState({ medicalRecordDocuments: payload })
    getMedicalRecordDocumentsError = error => this.requestError( error )

    removeMedicalRecordDocument = ( medical_record_id, document_id ) => Delete(
      `/medical_records/${ medical_record_id }/medical_record_documents/${ document_id }`,
      this.removeMedicalRecordDocumentSuccess,
      this.removeMedicalRecordDocumentError,
      this.load
    )
    removeMedicalRecordDocumentSuccess = () => {
      this.requestSuccess( 'Document is successfully removed' )
      this.getMedicalRecordDocuments( this.props.selectedMedicalRecord.id )
      this.props.getSelectedMedicalRecord( this.props.selectedMedicalRecord.id )
    }
    removeMedicalRecordDocumentError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadMedicalRecordDocuments={ this.state.loading }
          medicalRecordDocuments={ this.state.medicalRecordDocuments }
          createMedicalRecordDocument={ this.createMedicalRecordDocument }
          getMedicalRecordDocuments={ this.getMedicalRecordDocuments }
          removeMedicalRecordDocument={ this.removeMedicalRecordDocument } />
      )
    }
  }
  return WithHOC
}

export default HOC