import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { compose } from 'redux'
import ReactTable from 'react-table'
import Moment from 'moment'
import _ from 'lodash'

import SmileDatepicker from 'components/Datepicker'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithSetOffDays from './actions'
import WithDoctors from 'actions/dictionary/doctors'

class SetOffDay extends Component {

  componentDidMount = () => {
    if( this.props.data.profileReducer.profile.current_user.role.name !== 'Doctor' ){
      this.props.getDoctors( this.props.data.profileReducer.profile.current_user.branch_ids )
    }
  }

  componentDidUpdate = pp => {
    if( this.props.showSetOffDay !== pp.showSetOffDay ) {
      if( this.props.showSetOffDay && this.props.data.profileReducer.profile.current_user.role.name === 'Doctor' ) {
        this.props.getOffDays( JSON.stringify( [ this.props.data.profileReducer.profile.current_user.id ] ) )
      }
    }
  }

  render = () => {
    return (
      <Modal
        isOpen={ this.props.showSetOffDay }
        size={ 'lg' }
        toggle={() => this.props.hideSetOffDay()}>
        <ModalHeader toggle={() => this.props.hideSetOffDay()}>
          Set off day 
          { this.props.data.profileReducer.profile.current_user.role.name !== 'Doctor' ? ' for doctor' : '' }
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={ e => e.preventDefault()}>
            {
              this.props.data.profileReducer.profile.current_user.role.name !== 'Doctor' && <FormGroup>
                <Label>Select doctor</Label>
                <Input 
                  type='select'
                  value={ this.props.selectedDoctorId }
                  onChange={ e => this.props.onChangeSetOffDaysHOC( 'selectedDoctorId', parseInt( e.target.value ) ) } >
                  <option value='' ></option>
                  { 
                    _.filter( 
                      this.props.doctors, 
                      doctor => _.filter( 
                        doctor.branch_ids, 
                        branch_id => _.findIndex( this.props.data.profileReducer.profile.current_user.branch_ids, item => item === branch_id ) > -1 
                      ).length > 0 
                    ).map( item => <option value={ item.id } >{ item.salutation }. { item.name }</option> )
                  }
                </Input>
              </FormGroup>
            }
            {
              ( this.props.selectedDoctorId > 0 || this.props.data.profileReducer.profile.current_user.role.name === 'Doctor' ) && <>
                <FormGroup>
                  <Label>Add start time</Label>
                  <SmileDatepicker
                    selectedDate={ this.props.newOffDay.start_datetime }
                    onChange={ val => {
                      this.props.onChangeSetOffDaysHOC( 'newOffDay', {
                        ...this.props.newOffDay,
                        start_datetime: val
                      })
                    }}
                    showTimeSelect={ true }
                    dateFormat="dd MMM yyyy HH:mm"
                    excludeDates={ this.props.selectedOffDates } />
                </FormGroup>
                <FormGroup>
                  <Label>Add end time</Label>
                  <SmileDatepicker
                    selectedDate={ this.props.newOffDay.end_datetime }
                    onChange={ val => {
                      this.props.onChangeSetOffDaysHOC( 'newOffDay', {
                        ...this.props.newOffDay,
                        end_datetime: val
                      })
                    }}
                    dateFormat="dd MMM yyyy HH:mm"
                    showTimeSelect={ true }
                    excludeDates={ this.props.selectedOffDates } />
                  <Button 
                    color="primary"
                    className="mt-2"
                    disabled={ this.props.newOffDay.start_datetime === '' || this.props.newOffDay.end_datetime === '' }
                    onClick={() => {
                      // this.props.newOffDay.date.setHours( this.props.newOffDay.date.getHours() + 8 )
                      let tmpUserId = this.props.data.profileReducer.profile.current_user.role.name !== 'Doctor' 
                        ? this.props.selectedDoctorId
                        : this.props.data.profileReducer.profile.current_user.id
                      this.props.createOffDay( tmpUserId, { 
                        ...this.props.newOffDay,
                        user_id: tmpUserId
                      })
                    }}>Set</Button>
                </FormGroup>
                <FormGroup>
                  <Label>Selected off day(s)</Label>
                  <ReactTable
                    data={ _.orderBy( this.props.selectedOffDays, item => Moment( item.start_datetime ), [ 'desc' ] ) }
                    columns={[
                      {
                        Header: 'Start date time',
                        Cell: ( row ) => (
                          <span>{ row.original.start_datetime ? Moment( row.original.start_datetime ).format( 'DD MMM YYYY HH:mm' ) : '' }</span>
                        )
                      },
                      {
                        Header: 'End date time',
                        Cell: ( row ) => (
                          <span>{ row.original.end_datetime ? Moment( row.original.end_datetime ).format( 'DD MMM YYYY HH:mm' ) : '' }</span>
                        )
                      },
                      {
                        Header: 'Actions',
                        maxWidth: 80,
                        Cell: ( row ) => (
                          <>
                            <Button
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="danger"
                              onClick={() => {
                                Promise.all([
                                  this.props.onChangeSetOffDaysHOC( 'toRemoveID', row.original.id )
                                ]).then(() => {
                                  this.props.onChangeSetOffDaysHOC( 'showPromptModal', true )
                                })
                              }}>
                              <i className="pe-7s-trash btn-icon-wrapper"> </i>
                            </Button>
                          </>
                        )
                      }
                    ]}
                    defaultPageSize={ 10 } />
                </FormGroup>
              </>
            }
          </Form>
          { this.props.onLoadSetOffDays && <LoadingOverlay /> }
        </ModalBody>
      </Modal>
    )
  }
}

export default compose (
  WithSetOffDays,
  WithDoctors
)( SetOffDay )