import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Post } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      states: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getStates = ( country_code ) => Post(
      `/users/get_state`,
      {
        country_code
      },
      this.getStatesSuccess,
      this.getStatesError,
      this.load
    )
    getStatesSuccess = payload => this.setState({ states: payload })
    getStatesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          getStates={ this.getStates }
          onLoadStates={ this.state.loading }
          states={ this.state.states } />
      )
    }
  }
  return WithHOC
}

export default HOC