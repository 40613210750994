import React from 'react'
import _ from 'lodash'
import {
  Form, FormGroup,
  Label, Input
} from 'reactstrap'
import CKEditor from 'ckeditor4-react'

const ConsentTemplate  = ({
  consentTemplateType,
  currentConsentTemplate,
  onChangeConsentTemplatesHOC,
  profile,
  getDefaultConsentTemplate
}) => {
  const consentFormTypes = [
    { 
      type_id: 1,
      label: 'Cementation Approval' },
    { 
      type_id: 2,
      label: 'Crown consent' },
    { 
      type_id: 3,
      label: 'Extraction consent' },
    { 
      type_id: 4,
      label: 'Implant consent' },
    { 
      type_id: 5,
      label: 'Root canal consent' },
    { 
      type_id: 6,
      label: 'Wisdom tooth consent' }
  ]

  const updateConsentTemplate = ( key, val ) => {
    let tmp = _.cloneDeep( currentConsentTemplate )
    tmp[ key ] = val
    switch( key ){
      case 'company_id':
        if( parseInt( val ) === 0 ){
          tmp.type_id = 0
          tmp.template = ''
        }
        break
    }
    onChangeConsentTemplatesHOC( consentTemplateType, tmp )
  }

  return(
    <>
      <style>{` .cke_top { display: none; } `}</style>
      <Form>
        <FormGroup>
          <Label>Company</Label>
          <Input
            type='select'
            value={ currentConsentTemplate.company_id }
            disabled={ consentTemplateType === 'selectedConsentTemplate' }
            onChange={ e => updateConsentTemplate( 'company_id', e.target.value ) }>
            <option value={ 0 } ></option>
            { profile.companies.map( item => <option value={ item.id } >{ item.name }</option> ) }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Template type</Label>
          <Input
            type='select'
            value={ currentConsentTemplate.type_id }
            disabled={ consentTemplateType === 'selectedConsentTemplate' || currentConsentTemplate.company_id < 1 }
            onChange={ e => {
              updateConsentTemplate( 'type_id', e.target.value )
              getDefaultConsentTemplate( currentConsentTemplate.company_id, 1, e.target.value ) }}>
            <option value={ 0 } ></option>
            { consentFormTypes.map( item => <option value={ item.type_id } >{ item.label }</option> ) }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Form Template</Label>
          <CKEditor
            config={{ allowedContent: true }}
            readOnly={ parseInt( currentConsentTemplate.type_id ) < 1 }
            data={ currentConsentTemplate.template }
            onChange={ e => updateConsentTemplate( 'template', e.editor.getData() ) } />
        </FormGroup>
      </Form>
    </>
  )
}

export default ConsentTemplate