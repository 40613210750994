import React, { useState } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap'
import Tabs from 'react-responsive-tabs'
import _ from 'lodash'

import General from './General'
import Medical from './Medical'
import CreditAdvance from './CreditAdvance'
import Appointments from './Appointments'
import Settings from './Settings'
import LoadingOverlay from 'components/Indicator/LoadingOverlay' 

const TabsConfig = {
  activeTab: 0,
  showMore: true,
  transform: true,
  showInkBar: true,
  items: [
    { title: 'General' },
    { title: 'Medical' },
    { title: 'Credit Advance' },
    { title: 'Appointments' },
    { title: 'Settings' }
  ],
  selectedTabKey: 0,
  transformWidth: 400,
}

const Update = ( props ) => {
  const [ currentTab, updateCurrentTab ] = useState( 0 )

  let {
    showUpdatePatient,
    getSelectedPatient,
    selectedPatient,
    onChangePatientsHOC,
    onLoadPatients,
    updatePatient,
    getMedicalRecords,
    uploadPhoto,
    currencies,
    panels
  } = props

  const onChangePatientData = ( key, val ) => {
    let tmp = _.cloneDeep( selectedPatient )
    tmp[ key ] = val
    return onChangePatientsHOC( 'selectedPatient', tmp )
  }

  return (
    <Modal
      isOpen={ showUpdatePatient }
      size={ 'xl' }
      toggle={() => {
        onChangePatientsHOC( 'showUpdatePatient', false )
      }}>
      <ModalHeader toggle={() => onChangePatientsHOC( 'showUpdatePatient', false )}>Update patient</ModalHeader>
      <ModalBody>
        <Tabs
          tabsWrapperClass="body-tabs"
          { ...TabsConfig }
          selectedTabKey={ currentTab }
          onChange={ val => updateCurrentTab( val ) } />
        {
          currentTab === 0 && <General 
            onChangePatientData={ onChangePatientData } 
            selectedPatient={ selectedPatient } 
            uploadPhoto={ uploadPhoto } 
            onChangePatientsHOC= { onChangePatientsHOC }
            panels={ panels } />
        }
        {
          currentTab === 1 && <Medical onChangePatientData={ onChangePatientData } selectedPatient={ selectedPatient } />
        }
        {
          currentTab === 2 && <CreditAdvance 
            onChangePatientData={ onChangePatientData } 
            selectedPatient={ selectedPatient } 
            currencies={ currencies }
            getSelectedPatient={ getSelectedPatient } />
        }
        {
          currentTab === 3 && <Appointments selectedPatient={ selectedPatient } />
        }
        {
          currentTab === 4 && <Settings { ...props } />
        }
        { onLoadPatients && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        {
          currentTab !== 2 
            && (
              <Button 
                color="primary"
                onClick={() => {
                  updatePatient( selectedPatient )
                }}>
                Submit
              </Button>
            ) 
            // : (
            //   <Button 
            //     color='primary'
            //     onClick={ async() => {
            //       if( selectedPatient.credit_advance ){
            //         let tmp = _.cloneDeep( selectedPatient )
            //         tmp.credit_advance.amount = parseFloat( tmp.credit_advance.amount ).toFixed( 2 ).toString()
            //         await updateCreditAdvance( selectedPatient.credit_advance.id, selectedPatient.credit_advance )
            //       } else {
            //         await createCreditAdvance({
            //           amount: '0.00',
            //           currency: 'MYR',
            //           patient_id: selectedPatient.id
            //         })
            //       }
            //       getSelectedPatient( selectedPatient.id )
            //     }}>
            //     { selectedPatient.credit_advance ? 'Update credit advance' : 'Create credit advance' }
            //   </Button>
            // )
        }
        <Button
          color="primary"
          onClick={() => {
            getMedicalRecords( selectedPatient.id )
          }}>
          View treatment record
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Update