import { combineReducers } from 'redux'

import ajaxReducer from './ajax'
import profileReducer from './profile'
import ThemeOptions from './ThemeOptions'

export default combineReducers({
  ajaxReducer,
  profileReducer,
  ThemeOptions
})