import React, { useState } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

const Update = ({
  showUpdateRole,
  selectedRole,
  onChangeRolesHOC,
  updateRole
}) => {

  const onChangeRoleData = ( key, val ) => {
    let tmp = _.cloneDeep( selectedRole )
    tmp[ key ] = val
    return onChangeRolesHOC( 'selectedRole', tmp )
  }

  return (
    <Modal
      isOpen={ showUpdateRole }
      size={ 'xl' }
      toggle={() => {
        onChangeRolesHOC( 'showUpdateRole', false )
      }}>
      <ModalHeader toggle={() => onChangeRolesHOC( 'showUpdateRole', false )}>Update role</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              value={ selectedRole.name }
              onChange={ e => {
                onChangeRoleData( 'name', e.target.value )
              }} />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={() => {
            updateRole( selectedRole )
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Update