import React, { Component } from 'react'
import _ from 'lodash'
import { 
  Row, 
  Col,
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'
import { compose } from 'redux'
import Moment from 'moment'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SearchForm from './SearchForm'
import InvoiceListings from './Invoices'

import WithCollections from './actions'
import WithCompanies from 'actions/dictionary/companies'
import WithInvoices from './actions/Invoices'
import WithDoctors from 'actions/dictionary/doctors'
import CurrencySettings from 'utils/currencies'

class Collections extends Component {
  render = () => {
    return (
      <>
        <PageTitle
          heading="Collections"
          subheading="Listings of all the collections based on selected company."
          icon="pe-7s-mail-open-file icon-gradient bg-happy-itmeo"
          buttons={[]} />
        {
          !_.isEmpty( this.props.data.profileReducer.profile.current_user ) && (
            <SearchForm { ...this.props } />
          )
        }
        <Card className="main-card mb-3">
          <CardHeader>
            { `Search results from ${ Moment( this.props.searchParams[ 1 ][ 'value' ] ).format( 'DD MMM YYYY' ) } to ${ Moment( this.props.searchParams[ 2 ][ 'value' ] ).format( 'DD MMM YYYY' ) }` }
          </CardHeader>
          <CardBody>
            <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
              <div className="widget-chart-content">
                <div className="widget-subheading d-flex">
                  Total collection for selected date
                </div>
                <div className="widget-numbers">
                  { `${ CurrencySettings() } ${ this.props.collections.total }` }
                </div>
              </div>
            </div>
            <ReactTable
              data={ this.props.collections.by_users }
              defaultPageSize={ 10 }
              columns={[
                {
                  Header: 'Date',
                  accessor: 'date'
                },
                {
                  Header: 'Patient name',
                  accessor: 'customer_name'
                },
                {
                  Header: 'Total',
                  accessor: 'total',
                  Cell: ({ value }) => parseFloat( value ).toFixed( 2 )
                },
                {
                  Header: 'Collected amount',
                  accessor: 'total_current_cost',
                  Cell: ({ value }) => parseFloat( value ).toFixed( 2 )
                }
              ]} />
          </CardBody>
        </Card>
        <InvoiceListings { ...this.props } />
        { ( this.props.onLoadCollections || this.props.onLoadCompanies || this.props.onLoadInvoices ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithCollections,
  WithCompanies,
  WithInvoices,
  WithDoctors
)( Collections )