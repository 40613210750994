import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      doctors: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getDoctors = ( branch_ids, company_ids ) => Get(
      `/users/filter_doctors?${ 
        branch_ids 
          ? _.reduce( 
            branch_ids, 
            ( res, id, index ) => index === 0 ? `q[branches_id_in][]=${ id }` : `${ res }&q[branches_id_in][]=${ id }`,
            '' 
          ) : ( 
            'q[branches_id_in][]=' 
          )
      }${ 
        company_ids 
          ? _.reduce( company_ids, ( res, id ) => `${ res }&q[companies_id_in][]=${ id }`, '' )
          : '' 
      }`,
      this.getDoctorsSuccess,
      this.getDoctorsError,
      this.load
    )
    getDoctorsSuccess = payload => {
      if( payload && payload.length > 0 ) {
        let tmp = []
        payload.map( item => {
          tmp.push({
            ...item,
            label: item.id,
            value: `${ item.name } ${ item.nric_no }`,
            id: item.id,
            title: `Dr ${ item.name }`
          })
        })
        this.setState({ doctors: _.reverse( tmp ) })
      }
    }
    getDoctorsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadDoctors={ this.state.loading }
          doctors={ this.state.doctors }
          getDoctors={ this.getDoctors } />
      )
    }
  }
  return WithHOC
}

export default HOC