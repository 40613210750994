import React, { useEffect } from 'react'
import { 
  Row, Col,
  Card, CardHeader, CardBody,
  Form, FormGroup, Label,
  Input, Button
} from 'reactstrap'
import { compose } from 'redux'
import _ from 'lodash'
import Moment from 'moment'
import { Multiselect } from 'react-widgets'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CustomDatepicker from 'components/Datepicker'

import WithAppointments from './actions'
import WithPatients from '../actions/Patients'
import WithDoctors from 'actions/dictionary/doctors'
import WithCompanies from 'actions/dictionary/companies'

const ListItem = ({ item }) => (
  <span>
    { `${ item.name } - ${ item.nric_no } - ${ item.contact_number }` }
  </span>
)

const Appointments = props => {

  useEffect(() => {
    props.getDoctors( props.data.profileReducer.profile.current_user.branch_ids )
    // props.getPatients()
    props.getCompanies()
  }, [])

  return (
    <>
      <PageTitle
        heading="Appointments"
        subheading="Listings of all the appointments."
        icon="pe-7s-note2 icon-gradient bg-happy-itmeo" />
      <Card className="mb-4">
        <CardHeader>Appointment</CardHeader>
        <CardBody>
          <Form onSubmit={ e => e.preventDefault()}>
            <FormGroup>
              <Label>Start date time</Label>
              <Input
                type="datetime-local"
                value={ props.newAppointmentTreatmentRecord.appointment.start_datetime }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                  tmp[ 'appointment' ][ 'start_datetime' ] = e.target.value
                  return props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                }} />
            </FormGroup>
            <FormGroup>
              <Label>End date time</Label>
              <Input
                type="datetime-local"
                value={ props.newAppointmentTreatmentRecord.appointment.end_datetime }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                  tmp[ 'appointment' ][ 'end_datetime' ] = e.target.value
                  return props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                }} />
            </FormGroup>
            <FormGroup>
              <Label>Select doctor</Label>
              <Input
                type="select"
                value={ props.newAppointmentTreatmentRecord.appointment.user_id }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                  tmp[ 'appointment' ][ 'user_id' ] = e.target.value
                  return props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                }}>
                <option></option>
                {
                  props.doctors.map( item => {
                    return (
                      <option key={ item.id } value={ item.id }>{ item.name }</option>
                    )
                  })
                }
              </Input>
            </FormGroup>
            {/* <FormGroup>
              <Label>Select patient</Label>
              <Input
                type="select"
                value={ props.newAppointmentTreatmentRecord.appointment.patient_id }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                  tmp[ 'appointment' ][ 'patient_id' ] = e.target.value
                  return props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                }}>
                <option></option>
                {
                  props.patients.map( item => {
                    return (
                      <option key={ item.id } value={ item.id }>{ item.name }</option>
                    )
                  })
                }
              </Input>
            </FormGroup> */}
            <FormGroup>
              <Label>Select patient</Label>
              <div className="d-flex">
                <Input 
                  type="text" 
                  value={ props.searchParams }
                  onChange={ e => {
                    props.onChangePatientsHOC( 'searchParams', e.target.value )
                  }}
                  placeholder="Fill in patient NRIC or name here and click search button" />
                <Button 
                  color="primary" 
                  onClick={() => {
                    props.getPatients( props.searchParams )
                  }}>Search</Button>
              </div>
              {
                props.onLoadPatients 
                  ? (
                    <p>Loading patients......</p>
                  ) : (
                    <>
                    <Multiselect
                      data={ props.patients.data }
                      textField={ 'name' }
                      itemComponent={ ListItem }
                      value={ _.find( props.patients.data, { id: props.newAppointmentTreatmentRecord.appointment.patient_id } ) ? [ _.find( props.patients.data, { id: props.newAppointmentTreatmentRecord.appointment.patient_id } ) ] : [] }
                      onChange={ val => {
                        if( val.length === 1 ) {
                          let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                          tmp[ 'appointment' ][ 'patient_id' ] = val[ 0 ].id
                          props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                        } else {
                          let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                          tmp[ 'appointment' ][ 'patient_id' ] = ''
                          props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                        }
                      }} />
                    </>
                  )
              }
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <Input
                type="select"
                value={ props.newAppointmentTreatmentRecord.appointment.status }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                  tmp[ 'appointment' ][ 'status' ] = e.target.value
                  return props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                }}>
                <option></option>
                <option value={ 'Active' }>Active</option>
                <option value={ 'Confirmed' }>Confirmed</option>
                <option value={ 'Shown' }>Shown</option>
                <option value={ 'No show' }>No show</option>
                <option value={ 'Waiting list' }>Waiting list</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Details</Label>
              <Input
                type="text"
                value={ props.newAppointmentTreatmentRecord.appointment.details }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                  tmp[ 'appointment' ][ 'details' ] = e.target.value
                  return props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                }} />
            </FormGroup>
            <FormGroup check>
              <Input
                type="checkbox"
                checked={ props.newAppointmentTreatmentRecord.appointment.is_incomplete_treatment }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                  tmp[ 'appointment' ][ 'is_incomplete_treatment' ] = e.target.checked
                  return props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                }} /> { ' is incomplete treatment' }
            </FormGroup>
            <FormGroup>
              <Label>Select company</Label>
              <Input
                type="select"
                value={ props.newAppointmentTreatmentRecord.appointment.company_id }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                  tmp[ 'appointment' ][ 'company_id' ] = e.target.value
                  return props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                }}>
                <option></option>
                {
                  props.companies.map( item => {
                    return (
                      <option
                        key={ item.id }
                        value={ item.id }>
                        { item.name }
                      </option>
                    )
                  })
                }
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Select a branch</Label>
              <Input
                type="select"
                value={ props.newAppointmentTreatmentRecord.appointment.branch_id }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                  tmp[ 'appointment' ][ 'branch_id' ] = e.target.value
                  return props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                }}>
                <option>Please select a branch</option>
                {
                  props.data.profileReducer.profile.current_user.branches.map( item => {
                    return (
                      <option key={ item.id } value={ item.id }>{ item.name }</option>
                    )
                  })
                }
              </Input>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
      <Card className="mb-4">
        <CardHeader>Treatment record</CardHeader>
        <CardBody>
          <Form onSubmit={ e => e.preventDefault()}>
            <FormGroup>
              <Label>Date</Label>
              <Input
                type="date"
                value={ props.newAppointmentTreatmentRecord.medical_record.created_at }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                  tmp[ 'medical_record' ][ 'created_at' ] = e.target.value
                  return props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                }} />
            </FormGroup>
            <FormGroup>
              <Label>Complaints</Label>
              <Input
                type="text"
                value={ props.newAppointmentTreatmentRecord.medical_record.complaints }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                  tmp[ 'medical_record' ][ 'complaints' ] = e.target.value
                  return props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                }} />
            </FormGroup>
            <FormGroup>
              <Label>Observations</Label>
              <Input
                type="text"
                value={ props.newAppointmentTreatmentRecord.medical_record.observations }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                  tmp[ 'medical_record' ][ 'observations' ] = e.target.value
                  return props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                }} />
            </FormGroup>
            <FormGroup>
              <Label>Notes</Label>
              <Input
                type="text"
                value={ props.newAppointmentTreatmentRecord.medical_record.notes }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newAppointmentTreatmentRecord )
                  tmp[ 'medical_record' ][ 'notes' ] = e.target.value
                  return props.onChangeAppointmentHOC( 'newAppointmentTreatmentRecord', tmp )
                }} />
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
      <Button 
        color="primary"
        onClick={() => {
          let tmp = {}
          tmp = {
            appointment: {
              ...props.newAppointmentTreatmentRecord.appointment,
              start_datetime: Moment( props.newAppointmentTreatmentRecord.appointment.start_datetime ),
              end_datetime: Moment( props.newAppointmentTreatmentRecord.appointment.end_datetime ),
              created_by: props.data.profileReducer.profile.current_user.id
            },
            medical_record: {
              ...props.newAppointmentTreatmentRecord.medical_record,
              company_id: props.newAppointmentTreatmentRecord.appointment.company_id,
              branch_id: props.newAppointmentTreatmentRecord.appointment.branch_id,
              created_by: props.data.profileReducer.profile.current_user.id,
              created_at: Moment( props.newAppointmentTreatmentRecord.medical_record.created_at ),
              "user_id": props.newAppointmentTreatmentRecord.appointment.user_id
            }
          }
          props.createAppointmentTreatmentRecord( tmp )
        }}>Submit</Button>
      { ( props.onLoadAppointments || props.onLoadPatients || props.onLoadDoctors || props.onLoadCompanies ) && <LoadingOverlay /> }
    </>
  )
}

export default compose(
  WithAppointments,
  WithDoctors,
  WithPatients,
  WithCompanies
)( Appointments )