import React, { useEffect } from 'react'
import { 
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from 'reactstrap'
import { compose } from 'redux'
import _ from 'lodash'

import SmileDatepicker from 'components/Datepicker'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Typeahead from 'components/Typeahead'

import WithCountries from 'actions/dictionary/countries'
import WithStates from 'actions/dictionary/states'
import WithCities from 'actions/dictionary/cities'

import UserIcon from 'assets/Images/av1.png'

const General = ({
  newUser,
  onChangeUserData,
  roles,
  onLoadCountries,
  countries,
  getCountries,
  onLoadStates,
  states,
  getStates,
  onLoadCities,
  cities,
  getCities
}) => {

  useEffect(() => {
    getCountries()
  }, [] )

  return (
    <Form onSubmit={ e => e.preventDefault()}>
      <Row>
        <Col md={ 8 }>
          <Row className="mt-3">
            <Col md={ 6 }>
              <FormGroup>
                <Label>Salutation</Label>
                <Input
                  type="select"
                  value={ newUser.salutation }
                  onChange={ e => {
                    onChangeUserData( 'salutation', e.target.value )
                  }}>
                  <option></option>
                  <option value={ 'Dr' }>Dr</option>
                  <option value={ 'Mr' }>Mr</option>
                  <option value={ 'Mrs' }>Mrs</option>
                  <option value={ 'Ms' }>Ms</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Name</Label>
                <Input 
                  type="text"
                  value={ newUser.name }
                  onChange={ e => {
                    onChangeUserData( 'name', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>IC/Passport</Label>
                <Input 
                  type="text"
                  value={ newUser.nric_no }
                  onChange={ e => {
                    onChangeUserData( 'nric_no', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Email(use this to login into system)</Label>
                <Input 
                  type="email"
                  value={ newUser.email }
                  onChange={ e => {
                    onChangeUserData( 'email', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>DOB</Label>
                <SmileDatepicker
                  selectedDate={ newUser.date_of_birth }
                  onChange={ val => {
                    onChangeUserData( 'date_of_birth', val )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Age</Label>
                <Input
                  type="number"
                  value={ newUser.age }
                  onChange={ e => {
                    onChangeUserData( 'age', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Gender</Label>
                <Input
                  type="select"
                  value={ newUser.gender }
                  onChange={ e => {
                    onChangeUserData( 'gender', e.target.value )
                  }}>
                  <option></option>
                  <option value={ 'Male' }>Male</option>
                  <option value={ 'Female' }>Female</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Role</Label>
                <Input
                  type="select"
                  value={ newUser.role_id }
                  onChange={ e => {
                    onChangeUserData( 'role_id', e.target.value )
                  }}>
                  <option></option>
                  {
                    roles.map( item => {
                      return <option key={ item.id } value={ item.id }>{ item.name }</option>
                    })
                  }
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Address</Label>
                <Input
                  type="text"
                  value={ newUser.address }
                  onChange={ e => {
                    onChangeUserData( 'address', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Country</Label>
                <Typeahead
                  id={ 'country' }
                  multiple={ false }
                  options={ countries }
                  onSelect={ val => {
                    if( val && val.length > 0 ) {
                      onChangeUserData( 'country', val[ 0 ].code )
                      getStates( val[ 0 ].code )
                    } else {
                      onChangeUserData( 'country', '' )
                    }
                  }}
                  selectedValue={ _.find( countries, { id: newUser.country }) ? [ _.find( countries, { id: newUser.country }) ] : [] } />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Postcode</Label>
                <Input
                  type="text"
                  value={ newUser.postcode }
                  onChange={ e => {
                    onChangeUserData( 'postcode', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>State</Label>
                <Input
                  type="select"
                  value={ newUser.state }
                  onChange={ e => {
                    onChangeUserData( 'state', e.target.value )
                    getCities( newUser.country, e.target.value )
                  }}>
                  <option></option>
                  {
                    states && states.map( item => {
                      return <option key={ item.code } value={ item.code }>{ item.name }</option>
                    })
                  }
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>City</Label>
                <Input
                  type="select"
                  value={ newUser.city }
                  onChange={ e => {
                    onChangeUserData( 'city', e.target.value )
                  }}>
                  <option></option>
                  {
                    cities && cities.map( item => {
                      return <option key={ item.code } value={ item.code }>{ item.code }</option>
                    })
                  }
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Language</Label>
                <Input
                  type="select"
                  value={ newUser.language }
                  onChange={ e => {
                    onChangeUserData( 'language', e.target.value )
                  }}>
                  <option></option>
                  <option value={ 2 }>Mandarin</option>
                  <option value={ 1 }>English</option>
                  <option value={ 3 }>Malay</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Contact number</Label>
                <Input 
                  type="text"
                  value={ newUser.contact_number }
                  onChange={ e => {
                    onChangeUserData( 'contact_number', e.target.value )
                  }} />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md={ 4 }>
          <img src={ ( newUser.photo && newUser.photo !== '' ) ? newUser.photo : UserIcon } style={{ width: '60%' }} />
          <input 
            type="file" 
            accept="image/*" 
            className="mt-2"
            onChange={ e => {
              const reader = new FileReader()
              reader.onload = () => {
                onChangeUserData( 'photo', reader.result )
              }
              reader.readAsDataURL( e.target.files[ 0 ] )
            }} />
          <FormGroup className="pt-3">
            <Label>Status</Label>
            <Input 
              type="select"
              value={ newUser.status }
              onChange={ e => {
                onChangeUserData( 'status', e.target.value )
              }}>
              <option value={ 'active' }>Active</option>
              <option value={ 'inactive' }>Inactive</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={ 12 }>
          <Row className="mt-3">
            <Col md={ 12 }>
              <FormGroup>
                <Label>Email</Label>
                <Input 
                  type="email" 
                  disabled={ true }
                  value={ newUser.email }
                  onChange={ e => {
                    onChangeUserData( 'email', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Password</Label>
                <Input 
                  type="password"
                  value={ newUser.password }
                  onChange={ e => {
                    onChangeUserData( 'password', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Confirm password</Label>
                <Input 
                  type="password"
                  value={ newUser.password_confirmation }
                  onChange={ e => {
                    onChangeUserData( 'password_confirmation', e.target.value )
                  }} />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      { ( onLoadCountries || onLoadStates || onLoadCities ) && <LoadingOverlay /> }
    </Form>
  )
}

export default compose(
  WithCountries,
  WithStates,
  WithCities
)( General )