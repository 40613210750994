import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import _ from 'lodash'

const ViewPatient = ({
  viewedPatient,
  showViewPatient,
  onChangePatientsHOC
}) => {
  return (
    <Modal
      isOpen={ showViewPatient }
      size={ 'lg' }
      toggle={() => onChangePatientsHOC( 'showViewPatient', false )}>
      <ModalHeader toggle={() => onChangePatientsHOC( 'showViewPatient', false )}>View patient</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Name</Label>
            <Input 
              readOnly
              value={ viewedPatient.name ? viewedPatient.name : '-' } />
          </FormGroup>
          <FormGroup>
            <Label>Age</Label>
            <Input 
              readOnly
              value={ viewedPatient.age ? viewedPatient.age : '-' } />
          </FormGroup>
          <FormGroup>
            <Label>Gender</Label>
            <Input 
              readOnly
              value={ viewedPatient.gender ? viewedPatient.gender : '-' } />
          </FormGroup>
          <FormGroup>
            <Label>Contact number</Label>
            <Input 
              readOnly
              value={ viewedPatient.contact_number ? viewedPatient.contact_number : '-' } />
          </FormGroup>
          <FormGroup>
            <Label>Address</Label>
            <Input 
              readOnly
              value={ viewedPatient.address ? viewedPatient.address : '-' } />
          </FormGroup>
          <FormGroup>
            <Label>Allergies</Label>
            <Input 
              readOnly
              value={ viewedPatient.allergies ? viewedPatient.allergies : '-' } />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={() => onChangePatientsHOC( 'showViewPatient', false )}>
          Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ViewPatient