import React, { useEffect } from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { compose } from 'redux'
import ReactTable from 'react-table'
import _ from 'lodash'
import Moment from 'moment'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SearchForm from './SearchForm'

import WithMcReports from './actions'

const McReports = props => {
  useEffect(() => resetSearchParams(), [])

  const resetSearchParams = () => {
    let tmpSearchParams = _.cloneDeep( props.searchParams )
    tmpSearchParams.company_id = props.data.profileReducer.profile.current_user.company_ids.length > 0 
      ? props.data.profileReducer.profile.current_user.company_ids[ 0 ]
      : 0
    tmpSearchParams.branch_id = _.find( props.data.profileReducer.profile.current_user.branches, { company_id: tmpSearchParams[ 'company_id' ] } ) 
      ? _.find( props.data.profileReducer.profile.current_user.branches, { company_id: tmpSearchParams[ 'company_id' ] } ).id
      : 0
    Promise.all([
      props.getMcReports(
        tmpSearchParams[ 'company_id' ],
        tmpSearchParams[ 'branch_id' ],
        Moment( tmpSearchParams[ 'start_date' ] ).format( 'YYYY-MM-DD' ), 
        Moment( tmpSearchParams[ 'end_date' ] ).format( 'YYYY-MM-DD' ),
      ),
      props.onChangeMcReportsHOC( 'searchParams', tmpSearchParams )
    ])
  }
  
  return(
    <>
      <PageTitle
        heading="Medical Cert Reports"
        subheading="Listings of the medical cert reports."
        icon="pe-7s-clock icon-gradient bg-happy-itmeo" />
      <SearchForm { ...props } resetSearchParams={ resetSearchParams } />
      <Card className="main-card mb-3">
        <CardHeader>
          { `Search results from ${ Moment( props.searchParams[ 'start_date' ] ).format( 'DD MMM YYYY' ) } to ${ Moment( props.searchParams[ 'end_date' ] ).format( 'DD MMM YYYY' ) }` }
        </CardHeader>
        <CardBody>
          <ReactTable
            showPagination={ true }
            pageSize={ 20 }
            data={ props.mcReports }
            columns={[
              {
                Header: 'Doctor',
                accessor: 'Dr',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Issued date',
                accessor: 'Issued Date',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Patient',
                accessor: 'Patient',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Patient NRIC',
                accessor: 'Patient NRIC',
                style: { "line-break": "anywhere" }
              }
            ]}/>
        </CardBody>
      </Card>
      { props.onLoadMcReportsHOC && <LoadingOverlay/> }
    </>
  )
}

export default compose (
  WithMcReports
)( McReports )

