import React, { useState } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap'
import _ from 'lodash'
import Tabs from 'react-responsive-tabs'

import General from './General'
import Medical from './Medical'
import LoadingOverlay from 'components/Indicator/LoadingOverlay' 

const TabsConfig = {
  activeTab: 0,
  showMore: true,
  transform: true,
  showInkBar: true,
  items: [
    { title: 'General' },
    { title: 'Medical' }
  ],
  selectedTabKey: 0,
  transformWidth: 400,
}

const Create = ({
  showCreatePatient,
  newPatient,
  onChangePatientsHOC,
  createPatient,
  onLoadPatients,
  panels
}) => {
  const [ currentTab, updateCurrentTab ] = useState( 0 )

  const onChangePatientData = ( key, val ) => {
    let tmp = _.cloneDeep( newPatient )
    tmp[ key ] = val
    return onChangePatientsHOC( 'newPatient', tmp )
  }

  return (
    <Modal
      isOpen={ showCreatePatient }
      size={ 'xl' }
      toggle={() => {
        onChangePatientsHOC( 'showCreatePatient', false )
      }}>
      <ModalHeader toggle={() => onChangePatientsHOC( 'showCreatePatient', false )}>Create patient</ModalHeader>
      <ModalBody>
        <Tabs
          tabsWrapperClass="body-tabs"
          { ...TabsConfig }
          selectedTabKey={ currentTab }
          onChange={ val => updateCurrentTab( val ) } />
        {
          currentTab === 0 && <General  
            onChangePatientData={ onChangePatientData } 
            newPatient={ newPatient } 
            onChangePatientsHOC={ onChangePatientsHOC }
            panels={ panels } />
        } 
        {
          currentTab === 1 && <Medical onChangePatientData={ onChangePatientData } newPatient={ newPatient } />
        }
        { onLoadPatients && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={() => {
            createPatient( newPatient )
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Create