import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import _ from 'lodash'
import NumberFormat from "react-number-format"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithInvoices from './actions'

const ReloadCreditModal = ( props ) => {
  return (
    <Modal
      isOpen={ props.openReloadModal }
      size={ 'lg' }>
      <ModalHeader toggle={() => props.updateOpenReloadModal( false )}>Reload credit advance</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>Select company</Label>
            <Input
              type="select"
              value={ props.newInvoice.company_id }
              onChange={ e => {
                let tmp = _.cloneDeep( props.newInvoice )
                tmp[ 'company_id' ] = e.target.value
                props.onChangeInvoiceHOC( 'newInvoice', tmp )
              }}>
              <option></option>
              {
                props.data.profileReducer.profile.companies.map( item => {
                  return (
                    <option key={ item.id } value={ item.id }>
                      { item.name }
                    </option>
                  )
                })
              }
            </Input>
            <b>Required</b>
          </FormGroup>
          <FormGroup>
            <Label>Select branch</Label>
            <Input
              type="select"
              value={ props.newInvoice.branch_id }
              onChange={ e => {
                let tmp = _.cloneDeep( props.newInvoice )
                tmp[ 'branch_id' ] = e.target.value
                props.onChangeInvoiceHOC( 'newInvoice', tmp )
              }}>
              <option></option>
              {
                props.data.profileReducer.profile.branches.map( item => {
                  return (
                    <option key={ item.id } value={ item.id }>
                      { item.name }
                    </option>
                  )
                })
              }
            </Input>
            <b>Required</b>
          </FormGroup>
          <Button 
            color="primary"
            onClick={() => {
              let tmp = _.cloneDeep( props.newPayment )
              tmp[ 'customer_name' ] = props.selectedPatient.name
              tmp[ 'customer_address' ] = props.selectedPatient.address
              tmp[ 'customer_ic' ] = props.selectedPatient.nric_no
              props.onChangeInvoiceHOC( 'newPayment', tmp )
            }}>Copy patient data</Button>
          <FormGroup>
            <Label>Customer name</Label>
            <Input
              type="text"
              value={ props.newPayment.customer_name }
              onChange={ e => {
                let tmp = _.cloneDeep( props.newPayment )
                tmp[ 'customer_name' ] = e.target.value
                props.onChangeInvoiceHOC( 'newPayment', tmp )
              }}
              disabled={ false } />
            <b>Required</b>
          </FormGroup>
          <FormGroup>
            <Label>Customer address</Label>
            <Input
              type="text"
              value={ props.newPayment.customer_address }
              onChange={ e => {
                let tmp = _.cloneDeep( props.newPayment )
                tmp[ 'customer_address' ] = e.target.value
                props.onChangeInvoiceHOC( 'newPayment', tmp )
              }}
              disabled={ false } />
            <b>Required</b>
          </FormGroup>
          <FormGroup>
            <Label>Customer ic</Label>
            <Input
              type="text"
              value={ props.newPayment.customer_ic }
              onChange={ e => {
                let tmp = _.cloneDeep( props.newPayment )
                tmp[ 'customer_ic' ] = e.target.value
                props.onChangeInvoiceHOC( 'newPayment', tmp )
              }}
              disabled={ false } />
            <b>Required</b>
          </FormGroup>
          <FormGroup>
            <Label>To reload amount</Label>
            <NumberFormat
              className="form-control"
              value={ props.newCreditAdvance.amount }
              thousandSeparator={ "," }
              decimalSeparator={ "." }
              onValueChange={( values ) => {
                const { value } = values
                let tmp = _.cloneDeep( props.newCreditAdvance )
                tmp[ 'amount' ] = value
                props.onChangeInvoiceHOC( 'newCreditAdvance', tmp )
              }}
              inputMode="numeric"
              displayType="input"
            />
            <b>Required</b>
            <p>Please make sure there is no mistake when input this amount cause it is not reversible.</p>
          </FormGroup>
          <FormGroup>
            <Label>Pay with</Label>
            <Input
              type="select"
              value={ props.newPayment.pay_with }
              onChange={ e => {
                let tmp = _.cloneDeep( props.newPayment )
                tmp[ 'pay_with' ] = e.target.value
                props.onChangeInvoiceHOC( 'newPayment', tmp )
              }}>
              <option></option>
              <option value={ 'Cash' }>Cash</option>
              <option value={ 'Master' }>Master</option>
              <option value={ 'Visa' }>Visa</option>
              <option value={ 'TT' }>TT</option>
              <option value={ 'eWallet' }>eWallet</option>
              <option value={ 'Others' }>Others</option>
            </Input>
            <b>Required</b>
          </FormGroup>
        </Form>
        { props.onLoadInvoices && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={() => {
            let tmpInvoice = _.cloneDeep( props.newInvoice )
            let tmpPayment = _.cloneDeep( props.newPayment )
            let tmpCreditAdvance = _.cloneDeep( props.newCreditAdvance )
            tmpInvoice[ 'total_amount' ] = tmpCreditAdvance.amount
            tmpInvoice[ 'patient_id' ] = props.selectedPatient.id
            tmpPayment[ 'current_paid_amount' ] = tmpCreditAdvance.amount
            tmpPayment[ 'current_cost' ] = tmpCreditAdvance.amount
            Promise.all([
              props.onChangeInvoice( tmpInvoice ),
              props.onChangePayment( tmpPayment )
            ]).then(() => {
              props.createInvoice( tmpInvoice )
            })
          }}>Confirm and submit</Button>
      </ModalFooter>
    </Modal>
  )
}

export default WithInvoices( ReloadCreditModal )