import { get } from 'lodash'
import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Post, Get, Put } from 'utils/axios'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={
      loading: false,
      currencies: []
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    createCreditAdvance = data => Post(
      `/credit_advances`,
      data,
      this.createCreditAdvanceSuccess,
      this.createCreditAdvanceError,
      this.load
    )
    createCreditAdvanceSuccess = () => this.requestSuccess( 'Credit advance is succesfully created' )
    createCreditAdvanceError = error => this.requestError( error )

    updateCreditAdvance = ( credit_advance_id, data ) => Put(
      `/credit_advances/${ credit_advance_id }`,
      data,
      this.updateCreditAdvanceSuccess,
      this.updateCreditAdvanceError,
      this.load
    )
    updateCreditAdvanceSuccess = () => this.requestSuccess( 'Credit advance is succesfully updated' )
    updateCreditAdvanceError = error => this.requestError( error )

    getCurrencies = () => Get(
      `/credit_advances/all_currencies`,
      this.getCurrenciesSuccess,
      this.getCurrenciesError,
      this.load
    )
    getCurrenciesSuccess = payload => this.setState({ currencies: payload })
    getCurrenciesError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadCreditAdvanceHOC={ this.state.loading }
          onChangeCreditAdvanceHOC={ this.onChangeCreditAdvanceHOC }
          currencies={ this.state.currencies }
          getCurrencies={ this.getCurrencies }
          createCreditAdvance={ this.createCreditAdvance }
          updateCreditAdvance={ this.updateCreditAdvance } />
      )
    }
  }

  return WithHOC
}

export default HOC