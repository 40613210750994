import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )

    getSelectedPatient = ( id, notification_type ) => Get(
      `/patients/${ id }`,
      payload => this.getSelectedPatientSuccess( payload, notification_type ),
      this.getSelectedPatientError,
      this.load
    )
    getSelectedPatientSuccess = ( payload, notification_type ) => {
      if( notification_type === 'whatsapp' ){
        if( !_.isEmpty( payload.contact_number ) ){
          window.open(`https://api.whatsapp.com/send?phone=${ payload.contact_number }`)
        } else {
          this.requestError( 'Patient\'s contact number not recorded' )
        }
      } else {
        if( !_.isEmpty( payload.email ) ){
          window.location.href = `mailto:${ payload.email }`
        } else {
          this.requestError( 'Patient\'s e-mail not recorded' )
        }
      }
    }
    getSelectedPatientError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadPatients={ this.state.loading }
          getSelectedPatient={ this.getSelectedPatient } />
      )
    }
  }
  return WithHOC
}

export default HOC