import React from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import _ from 'lodash'
import NumberFormat from "react-number-format"
import { Multiselect } from 'react-widgets'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

const ListItem = ({ item }) => (
  <span>
    { `${ item.name } - ${ item.nric_no } - ${ item.contact_number }` }
  </span>
)

const NewPayment = ({
  showNewPayment,
  onChangePaymentHOC,
  newPayment,
  createPayment,
  data,
  selectedInvoice,
  onLoadPayments,
  selectedCaUser,
  getSelectedCaUser,
  caPatientQuery,
  caPatients,
  getCaPatients,
  panels
}) => {
  return (
    <Modal
      isOpen={ showNewPayment }
      size={ 'md' }
      toggle={() => {
        onChangePaymentHOC( 'showNewPayment', false )
      }}>
      <ModalHeader toggle={() => onChangePaymentHOC( 'showNewPayment', false )}>New payment</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>Customer name</Label>
            <Input
              type="text"
              value={ newPayment[ 'customer_name' ] }
              onChange={ e => {
                let tmp = _.cloneDeep( newPayment )
                tmp[ 'customer_name' ] = e.target.value
                onChangePaymentHOC( 'newPayment', tmp )
              }}
              disabled={ false } />
          </FormGroup>
          <FormGroup>
            <Label>Customer IC</Label>
            <Input
              type="text"
              value={ newPayment[ 'customer_ic' ] }
              onChange={ e => {
                let tmp = _.cloneDeep( newPayment )
                tmp[ 'customer_ic' ] = e.target.value
                onChangePaymentHOC( 'newPayment', tmp )
              }}
              disabled={ false } />
          </FormGroup>
          <FormGroup>
            <Label>Customer address</Label>
            <Input
              type="text"
              value={ newPayment[ 'customer_address' ] }
              onChange={ e => {
                let tmp = _.cloneDeep( newPayment )
                tmp[ 'customer_address' ] = e.target.value
                onChangePaymentHOC( 'newPayment', tmp )
              }}
              disabled={ false } />
          </FormGroup>
          <FormGroup>
            <Label>Remark(for Dr to tell Frontdesk something)</Label>
            <Input
              type="text"
              value={ newPayment.remark }
              onChange={ e => {
                let tmp = _.cloneDeep( newPayment )
                tmp[ 'remark' ] = e.target.value
                onChangePaymentHOC( 'newPayment', tmp )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>Current paid amount</Label>
            <NumberFormat
              className="form-control"
              value={ newPayment.current_paid_amount }
              thousandSeparator={ "," }
              decimalSeparator={ "." }
              onValueChange={( values ) => {
                const { value } = values
                let tmp = _.cloneDeep( newPayment )
                tmp[ 'current_paid_amount' ] = parseFloat( value )
                tmp[ 'final_price' ] = tmp[ 'discount' ] > 0
                  ? tmp[ 'discount_type_id' ] === 0
                    ? tmp[ 'current_cost' ] * ( 1 - tmp[ 'discount' ]/100 )
                    : tmp[ 'current_cost' ] - tmp[ 'discount' ]
                  : tmp[ 'current_cost' ]
                tmp[ 'change' ] = tmp[ 'current_paid_amount' ] - tmp[ 'final_price' ]
                onChangePaymentHOC( 'newPayment', tmp )
              }}
              inputMode="numeric"
              displayType="input"
            />
          </FormGroup>
          <FormGroup>
            <Label>Current cost</Label>
            <NumberFormat
              className="form-control"
              value={ newPayment.current_cost }
              thousandSeparator={ "," }
              decimalSeparator={ "." }
              onValueChange={( values ) => {
                const { value } = values
                let tmp = _.cloneDeep( newPayment )
                tmp[ 'current_cost' ] = parseFloat( value )
                tmp[ 'final_price' ] = tmp[ 'discount' ] > 0
                  ? tmp[ 'discount_type_id' ] === 0
                    ? tmp[ 'current_cost' ] * ( 1 - tmp[ 'discount' ]/100 )
                    : tmp[ 'current_cost' ] - tmp[ 'discount' ]
                  : tmp[ 'current_cost' ]
                tmp[ 'change' ] = tmp[ 'current_paid_amount' ] - tmp[ 'final_price' ]
                onChangePaymentHOC( 'newPayment', tmp )
              }}
              inputMode="numeric"
              displayType="input"
            />
          </FormGroup>
          <FormGroup>
            <Label>Discount type</Label>
            <Input
              type="select"
              value={ newPayment.discount_type_id }
              onChange={ e => {
                let tmp = _.cloneDeep( newPayment )
                tmp[ 'discount_type_id' ] = parseInt( e.target.value )
                tmp[ 'discount' ] = 0
                tmp[ 'final_price' ] = tmp[ 'current_cost' ]
                tmp[ 'change' ] = tmp[ 'current_paid_amount' ] - tmp[ 'final_price' ]
                onChangePaymentHOC( 'newPayment', tmp )
              }}
            >
              <option value={ 0 } >In percentage (%)</option>
              <option value={ 1 } >In fixed amount</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>{ newPayment.discount_type_id === 0 ? 'Discount percentage (%)' : 'Discount amount' }</Label>
            <NumberFormat
              allowNegative={ false }
              allowEmptyFormatting
              className="form-control"
              value={ newPayment.discount }
              thousandSeparator={ "," }
              decimalSeparator={ "." }
              decimalScale={ newPayment.discount_type_id === 0 ? 0 : 2 }
              fixedDecimalScale={ true }
              onValueChange={( values ) => {
                const { value } = values
                let tmp = _.cloneDeep( newPayment )
                tmp[ 'discount' ] = parseFloat( value )
                  ? parseFloat( value )
                  : 0
                if (
                  ( tmp[ 'discount_type_id' ] === 0 && tmp[ 'discount' ] > 100 ) ||
                  ( tmp[ 'discount_type_id' ] === 1 && tmp[ 'discount' ] > tmp[ 'current_cost' ] )
                ){
                  tmp[ 'discount' ] = tmp[ 'discount' ] / 10
                }
                tmp[ 'final_price' ] = tmp[ 'discount' ] > 0
                  ? tmp[ 'discount_type_id' ] === 0
                    ? tmp[ 'current_cost' ] * ( 1 - tmp[ 'discount' ]/100 )
                    : tmp[ 'current_cost' ] - tmp[ 'discount' ]
                  : tmp[ 'current_cost' ]
                tmp[ 'change' ] = tmp[ 'current_paid_amount' ] - tmp[ 'final_price' ]
                onChangePaymentHOC( 'newPayment', tmp )
              }}
              inputMode="numeric"
              displayType="input"
            />
          </FormGroup>
          <FormGroup>
            <Label>Final price</Label>
            <NumberFormat
              disabled
              className="form-control"
              value={ parseFloat( newPayment.final_price ).toFixed( 2 ) }
              thousandSeparator={ "," }
              decimalSeparator={ "." }
              inputMode="numeric"
              displayType="input"
            />
          </FormGroup>
          <FormGroup>
            <Label>Change</Label>
            <NumberFormat
              disabled
              className="form-control"
              value={ newPayment.change }
              thousandSeparator={ "," }
              decimalSeparator={ "." }
              decimalScale={ 2 }
              fixedDecimalScale
              inputMode="numeric"
              displayType="input"
            />
          </FormGroup>
          <FormGroup>
            <Label>Pay with</Label>
            <Input
              type="select"
              value={ newPayment.pay_with }
              onChange={ e => {
                let tmp = _.cloneDeep( newPayment )
                tmp[ 'pay_with' ] = e.target.value
                onChangePaymentHOC( 'newPayment', tmp )
              }}>
              <option></option>
              <option value={ 'Cash' }>Cash</option>
              <option value={ 'Master' }>Master</option>
              <option value={ 'Visa' }>Visa</option>
              <option value={ 'TT' }>TT</option>
              <option value={ 'eWallet' }>eWallet</option>
              <option value={ 'Credit advance' }>Credit advance</option>
              <option value={ 'Panel' }>Panel</option>
              <option value={ 'Others' }>Others</option>
            </Input>
          </FormGroup>
          {
            newPayment.pay_with === 'Panel' && (
              <FormGroup>
                <Label>Select panel</Label>
                <Input 
                  type='select'
                  value={ newPayment.panel_id }
                  onChange={ e => {
                    let tmp = _.cloneDeep( newPayment )
                    tmp[ 'panel_id' ] = e.target.value
                    onChangePaymentHOC( 'newPayment', tmp )
                  }}>
                  <option></option>
                  { panels.map( item => <option value={ item.id } >{ item.panel_name }</option> ) }
                </Input>
              </FormGroup>
            )
          }
          {
            newPayment.pay_with === 'Credit advance' && (
              <>
                <FormGroup>
                  <Label>Select patient</Label>
                  <div className="d-flex">
                    <Input 
                      type="text" 
                      className='mr-2 mb-2'
                      value={ caPatientQuery }
                      onChange={ e => onChangePaymentHOC( 'caPatientQuery', e.target.value ) }
                      placeholder="Fill in patient NRIC or name here and click search button" />
                    <Button 
                      className='mb-2'
                      color="primary" 
                      onClick={() => getCaPatients( caPatientQuery )}>
                      Search</Button>
                  </div>
                  <Multiselect
                    data={ _.filter( caPatients, item => !_.isEmpty( item.credit_advance ) && parseFloat( item.credit_advance.amount ) > 0 ) }
                    textField={ 'name' }
                    itemComponent={ ListItem }
                    value={ _.find( caPatients, { id: selectedCaUser.id } ) ? [ _.find( caPatients, { id: selectedCaUser.id } ) ] : [] }
                    onChange={ val => {
                      if( val.length === 1 ) {
                        getSelectedCaUser( val[ 0 ].id )
                      } else {
                        onChangePaymentHOC( 'selectedCaUser', {} )
                      }
                    }} />
                </FormGroup>
                {
                  selectedCaUser && selectedCaUser.credit_advance && (
                    <FormGroup>
                      <Label>Selected patient</Label>
                      <p>{ `Name: ${ selectedCaUser.name }` }</p>
                      <Label><b>Credit advance for selected patient</b></Label>
                      {
                        selectedCaUser && selectedCaUser.credit_advance && (
                          <>
                            <p>Before deduction</p>
                            <p>{ `${ selectedCaUser.credit_advance.currency } ${ selectedCaUser.credit_advance.amount }` }</p>
                            <p>After deduction</p>
                            <p>{ `${ selectedCaUser.credit_advance.currency } ${ parseFloat( selectedCaUser.credit_advance.amount ) - newPayment.final_price }` }</p>
                          </>
                        )
                      }
                    </FormGroup>
                  )
                }
              </>
            )
          }
        </Form>
        { onLoadPayments && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary" 
          disabled={ 
            newPayment.pay_with === 'Credit advance' && 
            ( _.isEmpty( selectedCaUser ) || !( selectedCaUser.credit_advance.amount - newPayment.final_price >= 0 ) )
          }
          onClick={() => {
            if( newPayment.current_cost !== '' && newPayment.final_price > 0 ) {
              createPayment(
                {
                  ...newPayment,
                  id: selectedInvoice.id
                }
              )
            } else {
              alert( 'Cost must not be empty or have 0 as value.' )
            }
          }}>Submit</Button>
      </ModalFooter>
    </Modal>
  )
}

export default NewPayment