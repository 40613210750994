import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col, UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import { IoIosPersonAdd } from 'react-icons/io'
import { FaRegEye } from 'react-icons/fa'
import { Multiselect } from 'react-widgets'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Moment from 'moment'

import SmileDatepicker from 'components/Datepicker'
import CreatePatient from './CreatePatient'
import ViewPatient from './ViewPatient'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithPatients from './actions/Patients'
import WithOffDays from 'actions/dictionary/offDays'
import WithMedicalRecords from './actions/MedicalRecords'
import WithInvoices from './actions/Invoices'

const ListItem = ({ item }) => (
  <span>
    { `${ item.name } - ${ item.nric_no } - ${ item.contact_number }` }
  </span>
)

const Update = ( props ) => {
  let {
    showUpdateAppointment,
    selectedAppointment,
    onChangeAppointmentsHOC,
    onLoadAppointments,
    updateAppointment,
    onChangePatientsHOC,
    newPatient,
    selectedPatient,
    showCreatePatient,
    showViewPatient,
    onLoadPatients,
    getPatients,
    updatePatient,
    patients,
    doctors,
    getOffDays,
    offDates,
    appointmentsOffDates,
    onLoadMedicalRecords,
    newMedicalRecord,
    data,
    createMedicalRecord,
    getSelectedAppointmentTreatmentRecord,
    selectedAppointmentMedicalRecord,
    uid,
    createPatient
  } = props

  const [ viewedPatient, updateViewedPatient ] = useState({})

  useEffect(() => {
    if( showUpdateAppointment ) {
      getOffDays( JSON.stringify( [ selectedAppointment.user_id ] ) )
      getSelectedAppointmentTreatmentRecord( selectedAppointment.id )
    }
    if( !showUpdateAppointment ) {
      if( props.selectedAppointment.id ) {
        if( props.selectedAppointmentMedicalRecord !== null ) {
          props.updateMedicalRecord({ ...props.selectedAppointmentMedicalRecord, user_id: props.selectedAppointment.user_id })  
          if( props.selectedAppointmentMedicalRecord.invoices && props.selectedAppointmentMedicalRecord.invoices.length > 0 ) {
            props.selectedAppointmentMedicalRecord.invoices.map( item => {
              props.updateSelectedInvoice({ ...item, user_id: props.selectedAppointment.user_id })
            })
          }
        }
      }
    }
  }, [ showUpdateAppointment ])

  const onChangeAppointmentData = ( key, val ) => {
    let tmp = _.cloneDeep( selectedAppointment )
    tmp[ key ] = val

    switch( key ){
      case 'company_id':
        if( props.data.profileReducer.profile.current_user.branches.length > 0 ){
          let tmpBranch = _.find( props.data.profileReducer.profile.current_user.branches, { company_id: parseInt( val ) } )
          let tmpDr = _.find( doctors, { id: parseInt( selectedAppointment.user_id ) } )
          if( !_.isEmpty( tmpBranch ) ){
            tmp.branch_id = tmpBranch.id
            if( !_.isEmpty( tmpDr ) && _.findIndex( tmpDr.branch_ids, item => item === parseInt( tmp.branch_id ) ) === -1 ){
              tmp.user_id = ''
            }
          } else {
            tmp.branch_id = ''
            tmp.user_id = ''
          }
        } else {
          tmp.branch_id = ''
          tmp.user_id = ''
        }
        break
      case 'branch_id':
        let tmpDr = _.find( doctors, { id: parseInt( selectedAppointment.user_id ) } )
        if( !_.isEmpty( tmpDr ) && _.findIndex( tmpDr.branch_ids, item => item === parseInt( val ) ) === -1 ){
          tmp.user_id = ''
        }
        break
    }
    return onChangeAppointmentsHOC( 'selectedAppointment', tmp )
  }

  return (
    <Modal
      isOpen={ showUpdateAppointment }
      size={ 'lg' }
      toggle={() => onChangeAppointmentsHOC( 'showUpdateAppointment', false )}>
      <ModalHeader toggle={() => onChangeAppointmentsHOC( 'showUpdateAppointment', false )}>Update appointment</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Select a company</Label>
                <Input
                  type="select"
                  value={ selectedAppointment.company_id }
                  onChange={ e => onChangeAppointmentData( 'company_id', parseInt( e.target.value )  ) }>
                  <option></option>
                  {
                    data.profileReducer.profile.companies.map( item => {
                      return (
                        <option key={ item.id } value={ item.id }>{ item.name }</option>
                      )
                    })
                  }
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Select a branch</Label>
                <Input
                  type="select"
                  value={ selectedAppointment.branch_id }
                  onChange={ e => onChangeAppointmentData( 'branch_id', parseInt( e.target.value )  ) }>
                  <option>Please select a branch</option>
                  {
                    _.filter( data.profileReducer.profile.current_user.branches, branch => branch.company_id === selectedAppointment.company_id ).map( item => {
                      return (
                        <option key={ item.id } value={ item.id }>{ item.name }</option>
                      )
                    })
                  }
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Select doctor</Label>
                <Input 
                  type="select"
                  value={ selectedAppointment.user_id }
                  onChange={ e => {
                    onChangeAppointmentData( 'user_id', e.target.value )
                    getOffDays( JSON.stringify( [ e.target.value ] ) )
                  }}>
                  <option></option>
                  {
                    _.filter( doctors, doctor => _.findIndex( doctor.branch_ids, item => item === selectedAppointment.branch_id ) > -1 ).map( item => {
                      return <option key={ item.id } value={ item.id }>{ `${ item.salutation } ${ item.name }` }</option>
                    })
                  }
                </Input>
                <span>If doctor is off, the date will not be able to be selected.</span>
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Start date time</Label>
                <SmileDatepicker
                  showTimeSelect={ true }
                  selectedDate={ selectedAppointment.start_datetime }
                  onChange={ val => {
                    onChangeAppointmentData( 'start_datetime', val )
                  }}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  excludeDates={ offDates } 
                  excludeTimes={ _.filter( appointmentsOffDates, appointment => ( selectedAppointment.user_id === appointment.user_id && Moment( appointment.offDatetime ).isSame( Moment( selectedAppointment.start_datetime ) , 'day' ) ) ).map( appointmentToRound => {
                      let tmpDiff = Moment( appointmentToRound.offDatetime ).minutes() % 30
                      if( tmpDiff > 15 ){
                        appointmentToRound.offDatetime = Moment( appointmentToRound.offDatetime ).add( tmpDiff, 'minutes' ).toDate()
                      } else if ( ( tmpDiff < 15 && tmpDiff !== 0 ) || tmpDiff === 15 ){
                        appointmentToRound.offDatetime = Moment( appointmentToRound.offDatetime ).subtract( tmpDiff, 'minutes' ).toDate()
                      }
                      return appointmentToRound.offDatetime
                    })
                  }
                />
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>End date time</Label>
                <SmileDatepicker
                  showTimeSelect={ true }
                  selectedDate={ selectedAppointment.end_datetime }
                  onChange={ val => {
                    onChangeAppointmentData( 'end_datetime', val )
                  }}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  minDate={ selectedAppointment.start_datetime } />
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup style={{ fontWeight: '600' }}>
                <Label>Selected patient</Label>
                <p>{ `Name: ${ selectedAppointment.patient_name ? selectedAppointment.patient_name : '' }` }</p>
              </FormGroup>
            </Col>
            <Col md={ 7 }>
              <FormGroup>
                <Label>Update selected patient</Label>
                <div className="d-flex">
                  <Input 
                    type="text" 
                    value={ props.searchParams }
                    onChange={ e => {
                      props.onChangePatientsHOC( 'searchParams', e.target.value )
                    }}
                    placeholder="Fill in patient NRIC or name here and click search button" />
                  <Button 
                    color="primary" 
                    onClick={() => {
                      props.getPatients( props.searchParams )
                    }}>Search</Button>
                </div>
                {
                  props.onLoadPatients 
                    ? (
                      <p>Loading patients......</p>
                    ) : (
                      <>
                        <Multiselect
                          data={ patients.data }
                          textField={ 'name' }
                          itemComponent={ ListItem }
                          value={ _.find( patients.data, { id: selectedAppointment.patient_id } ) ? [ _.find( patients.data, { id: selectedAppointment.patient_id } ) ] : [] }
                          onChange={ val => {
                            if( val.length === 1 ) {
                              updateViewedPatient( val[ 0 ] )
                              onChangeAppointmentData( 'patient_id', val[ 0 ].id )
                              props.getMedicalRecords( val[0].id )
                            } else {
                              updateViewedPatient( {} )
                              onChangeAppointmentData( 'patient_id', '' )
                            }
                          }} />
                        {
                          !_.isEmpty( viewedPatient ) && <Button
                            color="primary"
                            onClick={() => {
                              onChangePatientsHOC( 'showViewPatient', true )
                            }}>
                            <FaRegEye size={ 24 } />
                          </Button>
                        }
                      </>
                    )
                }
              </FormGroup>
            </Col>
            <Col md={ 5 }>
              <p>Past appts for selected patient:</p>
              {
                props.medicalRecords.length === 0 
                  ? <p>No medical record found.</p>
                  : props.medicalRecords.map( item => {
                    return (
                      <Button
                        color={ item.is_incomplete_treatment ? 'danger' : 'primary' }>
                        { Moment( item.created_at ).format( 'DD MMM YYYY HH:mm' ) }
                      </Button>
                    )
                  })
              }
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Status</Label>
                <Input 
                  type="select"
                  value={ selectedAppointment.status }
                  onChange={ e => {
                    onChangeAppointmentData( 'status', e.target.value )
                  }}>
                  <option></option>
                  <option value={ 'Active' }>Active</option>
                  <option value={ 'Confirmed' }>Confirmed</option>
                  <option value={ 'Shown' }>Shown</option>
                  <option value={ 'No show' }>No show</option>
                  <option value={ 'Waiting list' }>Waiting list</option>
                  <option value={ 'Cancelled' }>Cancelled</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Details</Label>
                <Input
                  type="textarea"
                  value={ selectedAppointment.details }
                  onChange={ e => {
                    onChangeAppointmentData( 'details', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup check>
                <Label check>
                  <Input
                    type={ 'checkbox' }
                    checked={ selectedAppointment.is_incomplete_treatment }
                    onChange={ e => {
                      onChangeAppointmentData( 'is_incomplete_treatment', e.target.checked )
                    }} />
                  Is patient coming back from incomplete treatment?
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <CreatePatient 
          createPatient={ createPatient }
          newPatient={ newPatient }
          showCreatePatient={ showCreatePatient }
          onLoadPatients={ onLoadPatients }
          onChangePatientsHOC={ onChangePatientsHOC } />
        <ViewPatient
          viewedPatient={ viewedPatient }
          showViewPatient={ showViewPatient }
          selectedPatient={ selectedPatient }
          onLoadPatients={ onLoadPatients }
          onChangePatientsHOC={ onChangePatientsHOC } />
        { onLoadAppointments && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        {/* <Button color="danger" className="mr-2">Remove appointment</Button> */}
        <Button
          color="success"
          className="mr-2"
          onClick={() => {
            window.open(`https://api.whatsapp.com/send?phone=${ selectedAppointment.patient_contact_number }&text=${ `Greetings from ${ selectedAppointment.company_name } - branch ${ selectedAppointment.branch_name }, please be reminded that you have an appointment on ${ Moment( selectedAppointment.start_datetime ).format( 'DD MMM YYYY HH:mm' ) }` }.`)
          }}>
          Whatsapp to notify patient
        </Button>
        <Button 
          color="primary" 
          className="mr-2"
          onClick={ () => updateAppointment( selectedAppointment ) }>
          Submit</Button>
        {/* patient is here and readied to go into treatment room */}
        {
          selectedAppointmentMedicalRecord === null 
            ? data.profileReducer.profile.current_user.role.name === 'Frontdesk' || data.profileReducer.profile.current_user.role.name === 'Admin'
              ? (
                <Button 
                  id={ `btn-update-appointment` }
                  color="success"
                  onClick={() => {
                    if( !selectedAppointment.is_incomplete_treatment ){
                      let tmp = _.cloneDeep( newMedicalRecord )
                      tmp = {
                        complaints: '',
                        observations: '',
                        notes: '',
                        appointment_id: selectedAppointment.id,
                        user_id: selectedAppointment.user_id,
                        is_incomplete_treatment: false
                      }
                      createMedicalRecord( tmp, uid, selectedAppointment.patient_name )
                      updateAppointment( 
                        { id: selectedAppointment.id, checkin_datetime: Moment().utc().format() },
                        uid,
                        selectedAppointment.patient_name,
                        true
                      )
                    } else {
                      updateAppointment( selectedAppointment, uid, selectedAppointment.patient_name )
                    }
                  }}
                  disabled={ selectedAppointment.status === 'Cancelled' }>{ `${ selectedAppointment.is_incomplete_treatment ? 'Notify doctor' : 'Create medical record' }` }</Button>
              ) : <></>
            : (
              <Button 
                color="light"
                disabled>Medical record is created</Button>
            )
        }
       {
         selectedAppointmentMedicalRecord === null 
         && data.profileReducer.profile.current_user.role.name === 'Frontdesk' 
         &&  <UncontrolledTooltip
            target={ `btn-update-appointment` }
            placement={ 'top' }>
            If appointment status is cancelled, frontdesk will not be able to create medical record.
          </UncontrolledTooltip>
       }
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => ({ data: state })

export default compose(
  WithMedicalRecords,
  WithPatients,
  WithOffDays,
  WithInvoices,
  connect( mapStateToProps )
)( Update )