import React from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from 'reactstrap'
import _ from 'lodash'
import { connect } from 'react-redux'
import NumberFormat from "react-number-format"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import CurrencySettings from 'utils/currencies'

const Create = ({
  showCreateProduct,
  newProduct,
  onChangeProductsHOC,
  onLoadProducts,
  createProduct,
  data
}) => {

  const onChangeProductData = ( key, val ) => {
    let tmp = _.cloneDeep( newProduct )
    tmp[ key ] = val
    return onChangeProductsHOC( 'newProduct', tmp )
  }

  return (
    <Modal
      isOpen={ showCreateProduct }
      size={ 'md' }
      toggle={() => {
        onChangeProductsHOC( 'showCreateProduct', false )
      }}>
      <ModalHeader toggle={() => onChangeProductsHOC( 'showCreateProduct', false )}>Create product</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>Name</Label>
            <Input 
              type="text"
              value={ newProduct.name }
              onChange={ e => {
                onChangeProductData( 'name', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>Stocks</Label>
            <Input 
              type="number"
              value={ newProduct.stocks }
              onChange={ e => {
                onChangeProductData( 'stocks', parseInt( e.target.value ) )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>{`Price per unit - ${ CurrencySettings() }`}</Label>
            <NumberFormat
              className="form-control"
              value={ newProduct.price }
              thousandSeparator={ "," }
              decimalSeparator={ "." }
              onValueChange={( values ) => {
                const { formattedValue, value } = values;
                onChangeProductData( 'price', value )
              }}
              inputMode="numeric"
              displayType="input"
            />
          </FormGroup>
        </Form>
        { onLoadProducts && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={() => {
            createProduct({
              ...newProduct,
              company_id: data.profileReducer.profile.companies[ 0 ].id
            })
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps )( Create )