import React, { Component } from 'react'
import { Col, Row, Button, Label } from 'reactstrap'
import Slider from "react-slick"
import { connect } from 'react-redux'
import Axios from 'axios'
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation'
import Cookies from 'js-cookie'

import MainFrame from 'components/Frame'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import bg1 from 'assets/TemplateImages/originals/city.jpg';
import bg2 from 'assets/TemplateImages/originals/citydark.jpg'
import bg3 from 'assets/TemplateImages/originals/citynights.jpg'
import PrimeViewIcon from 'assets/Images/icon.png'

import { authUser } from 'actions/auth'

const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true
}

class ForgotPassword extends Component {
  state = {
    email: ''
  }

  render = () => {
    return (
      <MainFrame>
        <div className="h-100">
          <Row className="h-100 no-gutters">
            <Col lg="4" className="d-none d-lg-block">
              <div className="slider-light">
                <Slider { ...SliderSettings }>
                  <div
                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div
                      className="slide-img-bg"
                      style={{ backgroundImage: 'url(' + bg1 + ')' }}/>
                    <div className="slider-content"></div>
                  </div>
                  <div
                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div
                      className="slide-img-bg"
                      style={{ backgroundImage: 'url(' + bg2 + ')' }}/>
                    <div className="slider-content"></div>
                  </div>
                  <div
                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div
                      className="slide-img-bg"
                      style={{ backgroundImage: 'url(' + bg3 + ')' }}/>
                    <div className="slider-content"></div>
                  </div>
                </Slider>
              </div>
            </Col>
            <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <img src={ PrimeViewIcon } style={{ width: '70px', marginBottom: '15px' }} />
                <h4 className="mb-0">
                  <div>Forgot password</div>
                  <span>Please fill in your email address.</span>
                </h4>
                <Row className="divider"/>
                <AvForm>
                  <Row form>
                    <Col md={6}>
                      <AvGroup>
                        <Label for="exampleEmail">Email</Label>
                        <AvInput
                          onChange={ e => this.setState({ email: e.target.value })}
                          type="email"
                          name="email"
                          id="exampleEmail"
                          value={ this.state.email }
                          placeholder="Email here..."
                          required />
                        {/* {
                          data.ajaxReducer.ajaxErrorMessage !== '' && (
                            <AvFeedback>{ processErrorMessage( data.ajaxReducer.ajaxErrorMessage, 'Email' ) }</AvFeedback>
                          )
                        } */}
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row className="divider" />
                  <div className="d-flex align-items-center">
                    <div className="ml-auto">
                      <Button
                        color="link"
                        size="lg"
                        onClick={() => {
                          this.props.history.goBack()
                        }}>Back to login</Button>
                      <Button
                        color="primary"
                        size="lg"
                        onClick={() => {

                        }}>Submit</Button>
                    </div>
                  </div>
                </AvForm>
              </Col>
            </Col>
          </Row>
          {/* { this.props.data.ajaxReducer.ajaxCallProgress > 0 && <LoadingOverlay /> } */}
        </div>
      </MainFrame>
    )
  }
}

export default ForgotPassword