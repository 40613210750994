import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      passwordInfo: {
        password: '',
        password_confirmation: '',
        id: ''
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangePasswordHOC = ( key, val ) => this.setState({ [key]: val })

    updatePassword = data => Put(
      `/users/${ data.id }/change_password`,
      data,
      this.updatePasswordSuccess,
      this.updatePasswordError,
      this.load
    )
    updatePasswordSuccess = payload => {
      this.requestSuccess( 'Password is updated successfully.' )
      this.props.hideResetPassword()
    }
    updatePasswordError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadUpdatePassword={ this.state.loading }
          passwordInfo={ this.state.passwordInfo }
          onChangePasswordHOC={ this.onChangePasswordHOC }
          updatePassword={ this.updatePassword } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC