import React, { Component } from 'react'
import { toast } from 'react-toastify'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newAssignMedicine: {
        medical_record_id: '',
        medicine_id: ''
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeAssignMedicineHOC = ( key, val ) => this.setState({ [ key ]: val })

    assignMedicineToMedicalRecord = data => Post(
      `/medical_records/${ data.medical_record_id }/add_medicine?medicine_id=${ data.medicine_id }`,
      {},
      this.assignMedicineToMedicalRecordSuccess,
      this.assignMedicineToMedicalRecordError,
      this.load
    )
    assignMedicineToMedicalRecordSuccess = payload => {
      this.setState({  
        newAssignMedicine: {
          medical_record_id: '',
          medicine_id: ''
        }
      })
      this.requestSuccess( 'Medicine is assigned to medical record successfully.' )
      this.props.getSelectedMedicalRecord( this.props.selectedMedicalRecord.id )
    }
    assignMedicineToMedicalRecordError = error => this.requestError( error.message )

    removeMedicineFromMedicalRecord = data => Delete(
      `/medical_records/${ data.medical_record_id }/remove_medicine?medicine_id=${ data.medicine_id }`,
      this.removeMedicineFromMedicalRecordSuccess,
      this.removeMedicineFromMedicalRecordError,
      this.load
    )
    removeMedicineFromMedicalRecordSuccess = payload => {
      this.requestSuccess( 'Medicine is removed from medical record successfully.' )
      this.props.getSelectedMedicalRecord( this.props.selectedMedicalRecord.id )
    }
    removeMedicineFromMedicalRecordError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadAssignMedicine={ this.state.loading }
          newAssignMedicine={ this.state.newAssignMedicine }
          removeMedicineFromMedicalRecord={ this.removeMedicineFromMedicalRecord }
          assignMedicineToMedicalRecord={ this.assignMedicineToMedicalRecord }
          onChangeAssignMedicineHOC={ this.onChangeAssignMedicineHOC } />
      )
    }
  }
  return WithHOC
}

export default HOC