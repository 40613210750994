import React, { useState } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

const Create = ({
  showCreateCompany,
  newCompany,
  onChangeCompaniesHOC,
  createCompany,
  onLoadCompanies
}) => {

  const onChangeCompanyData = ( key, val ) => {
    let tmp = _.cloneDeep( newCompany )
    tmp[ key ] = val
    return onChangeCompaniesHOC( 'newCompany', tmp )
  }

  return (
    <Modal
      isOpen={ showCreateCompany }
      size={ 'md' }
      toggle={() => {
        onChangeCompaniesHOC( 'showCreateCompany', false )
      }}>
      <ModalHeader toggle={() => onChangeCompaniesHOC( 'showCreateCompany', false )}>Create company</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              value={ newCompany.name }
              onChange={ e => {
                onChangeCompanyData( 'name', e.target.value )
              }} />
          </FormGroup>
        </Form>
        { onLoadCompanies && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={() => {
            createCompany( newCompany )
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Create