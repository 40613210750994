import React, { Component } from 'react'
import { 
  Row, Col, 
  Card, CardBody,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Input
} from 'reactstrap'
import ReactTable from 'react-table'
import { compose } from 'redux'
import _ from 'lodash'
import Moment from 'moment'

import PageTitle from 'components/Title'
import SearchForm from './SearchForm'
import CreatePatient from './Create'
import UpdatePatient from './Update'
import ViewPatient from './View'
import TreatmentRecordsModal from './TreatmentRecords'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Pagination from 'components/Pagination'

import WithPatients from './actions'
import WithMedicalRecords from './actions/MedicalRecords'
import WithCreditAdvance from './actions/CreditAdvance'
import WithPanels from 'actions/dictionary/panels'

class Patients extends Component {

  componentDidMount = () => {
    this.props.getPatients( this.props.searchParams, 1 )
    this.props.getPanels()
    if( !_.isEmpty( this.props.match.params ) ){
      this.navigateMedicalRecord()
    }
  }

  componentDidUpdate = pp => {
    if( this.props.match.params !== pp.match.params && !_.isEmpty( this.props.match.params ) && !_.isEmpty( pp.match.params ) ){
      if( 
        pp.match.params.patient_id !== this.props.match.params.patient_id ||
        pp.match.params.medical_record_id !== this.props.match.params.medical_record_id ||
        pp.match.params.payment_id !== this.props.match.params.payment_id 
      ){
        window.location.reload()
      }
    }
  }

  navigateMedicalRecord = async () => {
    if( !_.isEmpty( this.props.match.params.medical_record_id ) ){
      this.props.getSelectedPatient( this.props.match.params.patient_id , 'view' )
      await this.props.getMedicalRecords( this.props.match.params.patient_id )
      this.props.getSelectedMedicalRecord( this.props.match.params.medical_record_id )
    } else {
      this.props.getSelectedPatient( this.props.match.params.patient_id )
    }
  }

  renderDownloadPatientsModal = () => (
    <Modal size='xs' isOpen={ this.props.downloadPatientCompanyId > 0 } >
      <ModalHeader toggle={ () => this.props.onChangePatientsHOC( 'downloadPatientCompanyId', 0 ) } >
        Download patient listing</ModalHeader>
      <ModalBody>
        <Input 
          type='select'
          value={ this.props.downloadPatientCompanyId }
          onChange={ e => this.props.onChangePatientsHOC( 
            'downloadPatientCompanyId', 
            parseInt( e.target.value ) 
          )} >
          { 
            this.props.data.profileReducer.profile.companies.map( item => (
              <option value={ item.id } >{ item.name }</option>
            )) 
          }
        </Input>
        { this.props.onLoadPatients && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => this.props.downloadPatientsPdf( 
            this.props.downloadPatientCompanyId,
            _.find( this.props.data.profileReducer.profile.companies, { id: this.props.downloadPatientCompanyId } ).name
          )}>
          Submit</Button>
      </ModalFooter>
    </Modal>
  )

  render = () => {
    return (
      <>
        <PageTitle
          heading="Patients"
          subheading="Listings of all the patients presence in the system."
          icon="pe-7s-users icon-gradient bg-happy-itmeo"
          buttons={[
            {  
              color: 'primary',
              onClick: () => this.props.onChangePatientsHOC( 'showCreatePatient', true ),
              content: 'Add patient',
              className: 'mr-2'
            },
            {  
              color: 'primary',
              onClick: () => this.props.onChangePatientsHOC( 
                'downloadPatientCompanyId',
                this.props.data.profileReducer.profile.companies[ 0 ]
                  ? this.props.data.profileReducer.profile.companies[ 0 ].id
                  : 0
              ),
              content: 'Download patient listing'
            }
          ]} />
        <div>
          <Row>
            <Col md={ 12 }>
              <SearchForm { ...this.props } />
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    data={ this.props.patients.data }
                    columns={[
                      {
                        Header: 'Created at',
                        accessor: 'created_at',
                        Cell: row => Moment( row.original.created_at ).format( 'DD MMM YYYY' )
                      },
                      {
                        Header: 'Name',
                        accessor: 'name'
                      },
                      {
                        Header: 'IC/Passport',
                        accessor: 'nric_no'
                      },
                      {
                        Header: 'Contact',
                        accessor: 'contact_number'
                      },
                      {
                        Header: 'Actions',
                        maxWidth: 160,
                        Cell: ( row ) => (
                          <>
                            {/* <Button
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="success"
                              onClick={() => {}}>
                              <i className="pe-7s-look btn-icon-wrapper"> </i>
                            </Button> */}
                            <Button
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={() => {
                                this.props.getSelectedPatient( row.original.id )
                                this.props.getCurrencies()
                              }}>
                              <i className="pe-7s-pen btn-icon-wrapper"> </i>
                            </Button>
                            <Button
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="success"
                              onClick={() => {
                                this.props.getSelectedPatient( row.original.id, 'view' )
                                this.props.getMedicalRecords( row.original.id )
                              }}>
                              <i className="pe-7s-eyedropper btn-icon-wrapper"> </i>
                            </Button>
                          </>
                        )
                      }
                    ]}
                    defaultPageSize={ 20 }
                    showPagination={ false } />
                  <Pagination 
                    metadata={ this.props.patients.pagy } 
                    onChangePage={ pg => {
                      this.props.getPatients( this.props.searchParams, pg )
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <CreatePatient { ...this.props } />
        <UpdatePatient { ...this.props } />
        <ViewPatient { ...this.props } />
        <TreatmentRecordsModal { ...this.props } />
        { this.renderDownloadPatientsModal() }
        { this.props.onLoadPatients && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithPatients,
  WithMedicalRecords,
  WithCreditAdvance,
  WithPanels
)( Patients )