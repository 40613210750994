import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      treatments: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getTreatments = () => Get(
      `/treatments`,
      this.getTreatmentsSuccess,
      this.getTreatmentsError,
      this.load
    )
    getTreatmentsSuccess = payload => this.setState({ treatments: payload })
    getTreatmentsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          getTreatments={ this.getTreatments }
          onLoadTreatments={ this.state.loading }
          treatments={ this.state.treatments } />
      )
    }
  }
  return WithHOC
}

export default HOC