import React, { useState } from 'react'
import { 
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button
} from 'reactstrap'
import _ from 'lodash'

import ReloadCreditAdvanceModal from './Invoice'

const CreditAdvance = ({
  onChangePatientData,
  selectedPatient,
  getSelectedPatient
}) => {
  const [ openReloadModal, updateOpenReloadModal ] = useState( false )
  return(
    <Form onSubmit={ e => e.preventDefault()}>
      <Row>
        <Col md={ 8 }>
          {
            selectedPatient.credit_advance && (
              <Row>
                <Col md={ 12 }>
                  <FormGroup>
                    <Label>Currency</Label>
                    <Input 
                      type='text'
                      readOnly={ true }
                      value={ selectedPatient.credit_advance.currency } />
                  </FormGroup>
                </Col>
                <Col md={ 12 }>
                  <FormGroup>
                    <Label>Amount</Label>
                    <Input
                      type='text'
                      value={ selectedPatient.credit_advance.amount  }
                      onChange={ e => {
                        if( ( e.target.value.match(/\./g) || [] ).length < 2 && /^[0-9.]*$/.test( e.target.value ) ){
                          let tmp = _.cloneDeep( selectedPatient.credit_advance )
                          tmp.amount = e.target.value
                          onChangePatientData( 'credit_advance', tmp )
                        } 
                      }}
                      disabled={ true } />
                  </FormGroup>
                </Col>
              </Row>
            )
          }
        </Col>
      </Row>
      <Button 
        color="primary"
        onClick={() => {
          updateOpenReloadModal( true )
        }}>
        Reload credit advance
      </Button>
      <ReloadCreditAdvanceModal
        openReloadModal={ openReloadModal }
        updateOpenReloadModal={ updateOpenReloadModal }
        selectedPatient={ selectedPatient }
        getSelectedPatient={ getSelectedPatient } />
    </Form>
  )
}

export default CreditAdvance