import React, { useEffect, useRef } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button
} from 'reactstrap'
import CanvasDraw from "react-canvas-draw";

const DentalChart = props => {
  const TmpDentalCanvas = useRef( null )
  return (
    <Modal
      isOpen={ props.showDentalChart }
      size={ 'xl' }>
      <ModalHeader toggle={() => props.toggleShowDentalChart( false )}>Dental Chart</ModalHeader>
      <ModalBody>
        <CanvasDraw
          ref={ TmpDentalCanvas }
          imgSrc={ '/dental_chart.png' }
          canvasWidth={ 900 }
          canvasHeight={ 600 }
          enablePanAndZoom={ true }
          brushRadius={ 3 }
          lazyRadius={ 0 }
          brushColor={ '#000' }
          onChange={ e => { 
            console.log( e, 'e' )
          }}
          saveData={ props.selectedMedicalRecord.dental_chart_save_data } />
      </ModalBody>
      <ModalFooter>
        <Button 
          color="success"
          onClick={() => {
            TmpDentalCanvas.current.resetView()
          }}>
          Reset view
        </Button>
        <Button
          color="danger"
          className="ml-2"
          onClick={() => {
            TmpDentalCanvas.current.undo()
          }}>
          Undo
        </Button>
        <Button
          color="primary"
          className="ml-2"
          onClick={() => {
            props.updateMedicalRecordData( 'dental_chart_save_data', TmpDentalCanvas.current.getSaveData() )
            props.toggleShowDentalChart( false )
          }}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DentalChart