import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'

import { Post, Get, Put, Delete } from 'utils/axios'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      consentTemplates: [],
      newConsentTemplate: {},
      selectedConsentTemplate: {},
      showCreateConsentTemplate: false,
      showUpdateConsentTemplate: false,
      selectedConsentTemplateToDelete: 0
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( ({ closeToast }) => ReactHTMLParser( error.message ) )

    onChangeConsentTemplatesHOC = ( key, val ) => this.setState({ [ key ]: val })

    createConsentTemplate = data => Post(
      `/consent_templates`,
      data,
      this.createConsentTemplateSuccess,
      this.createConsentTemplateError,
      this.load
    )
    createConsentTemplateSuccess = () => {
      this.requestSuccess( 'Consent template is successfully created' )
      this.getConsentTemplates()
      this.reloadNewConsentTemplate()
    }
    createConsentTemplateError = error => this.requestError( error )

    getConsentTemplates = () => {
      Promise.all( this.props.data.profileReducer.profile.companies.map( async( company ) => {
        await this.getCompanyConsentTemplates( company.id, 1 )
        return this.state.consentTemplates
      })).then( compiledList => {
        let tmp = []
        compiledList.map( compiledItem => { tmp = _.concat( tmp, compiledItem ) })
        this.setState({ consentTemplates: tmp })
        this.reloadNewConsentTemplate()
      })
    }

    getCompanyConsentTemplates = ( company_id, branch_id ) => Get(
      `/consent_templates?company_id=${ company_id }&branch_id=${ branch_id }`,
      this.getCompanyConsentTemplatesSuccess,
      this.getCompanyConsentTemplatesError,
      this.load
    )
    getCompanyConsentTemplatesSuccess = payload => this.setState({ consentTemplates: payload })
    getCompanyConsentTemplatesError = error => this.requestError( error )

    getDefaultConsentTemplate = ( company_id, branch_id, type_id ) => Get(
      `/consent_templates/get_default_template?company_id=${ company_id }&branch_id=${ branch_id }&type_id=${ type_id }`,
      this.getDefaultConsentTemplateSuccess,
      this.getDefaultConsentTemplateError,
      this.load
    )
    getDefaultConsentTemplateSuccess = payload => {
      if( payload && payload.html ){
        let tmp = _.cloneDeep( this.state.newConsentTemplate )
        tmp.template = _.cloneDeep( payload.html )
        this.setState({ newConsentTemplate: tmp })
      }
    }
    getDefaultConsentTemplateError = error => this.requestError( error )

    getSelectedConsentTemplate = form_id => Get(
      `/consent_templates/${ form_id }`,
      this.getSelectedConsentTemplateSuccess,
      this.getSelectedConsentTemplateError,
      this.load
    )
    getSelectedConsentTemplateSuccess = payload => this.setState({ selectedConsentTemplate: payload, showUpdateConsentTemplate: true })
    getSelectedConsentTemplateError = error => this.requestError( error )

    updateConsentTemplate = data => Put(
      `/consent_templates/${ data.id }`,
      data,
      this.updateConsentTemplateSuccess,
      this.updateConsentTemplateError,
      this.load
    )
    updateConsentTemplateSuccess = () => {
      this.requestSuccess( 'Consent template is successfully updated' )
      this.getConsentTemplates()
      this.reloadSelectedConsentTemplate()
    }
    updateConsentTemplateError = error => this.requestError( error )

    removeConsentTemplate = form_id => Delete(
      `/consent_templates/${ form_id }`,
      this.removeConsentTemplateSuccess,
      this.removeConsentTemplateError,
      this.load
    )
    removeConsentTemplateSuccess = () => {
      this.requestSuccess( 'Consent template is successfully removed' )
      this.getConsentTemplates()
      this.setState({ selectedConsentTemplateToDelete: 0 })
    }

    reloadNewConsentTemplate = () => this.setState({
      showCreateConsentTemplate: false,
      newConsentTemplate: {
        company_id: 0,
        branch_id: 1,
        type_id: 0,
        template: ''
      }
    })

    reloadSelectedConsentTemplate = () => this.setState({
      showUpdateConsentTemplate: false,
      selectedConsentTemplate: {}
    })

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadConsentTemplatesHOC = { this.state.loading }
          consentTemplates = { this.state.consentTemplates }
          newConsentTemplate = { this.state.newConsentTemplate }
          selectedConsentTemplate = { this.state.selectedConsentTemplate }
          showCreateConsentTemplate = { this.state.showCreateConsentTemplate }
          showUpdateConsentTemplate = { this.state.showUpdateConsentTemplate }
          selectedConsentTemplateToDelete = { this.state.selectedConsentTemplateToDelete }
          onChangeConsentTemplatesHOC={ this.onChangeConsentTemplatesHOC }
          createConsentTemplate={ this.createConsentTemplate }
          getConsentTemplates={ this.getConsentTemplates }
          getSelectedConsentTemplate={ this.getSelectedConsentTemplate }
          getDefaultConsentTemplate={ this.getDefaultConsentTemplate }
          updateConsentTemplate={ this.updateConsentTemplate }
          removeConsentTemplate={ this.removeConsentTemplate }
          reloadNewConsentTemplate={ this.reloadNewConsentTemplate }
          reloadSelectedConsentTemplate={ this.reloadSelectedConsentTemplate } />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC