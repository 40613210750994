import React, { Component } from 'react'
import _ from 'lodash'

import { Get } from 'utils/axios'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      patients: [],
      patientsSearchParam: ''
    }

    load = param => this.setState({ loading: param })

    onChangePatientsHOC = ( key, val ) => this.setState({ [ key ] : val })

    getPatients = ( patientsSearchParam ) => Get(
      `/patients?page=1&search=${ patientsSearchParam }`,
      this.getPatientsSuccess,
      this.getPatientsError,
      this.load
    )
    getPatientsSuccess = payload => this.setState({ patients: payload.data })
    getPatientsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          getPatients={ this.getPatients }
          onChangePatientsHOC={ this.onChangePatientsHOC }
          onLoadPatientsHOC={ this.state.loading }
          patients={ this.state.patients }
          patientsSearchParam={ this.state.patientsSearchParam } />
      )
    }
  }
  return WithHOC
}

export default HOC