export const MainNav = [
  {
    icon: "pe-7s-rocket metismenu-icon-red",
    label: "Dashboard",
    to: "#/dashboard"
  },
  {
    icon: 'pe-7s-stopwatch metismenu-icon-orange',
    label: "Appointments",
    to: "#/dashboard/appointments"
  },
  {
    icon: "pe-7s-users metismenu-icon-yellow",
    label: "Users",
    to: "#/dashboard/users"
  },
  {
    icon: "pe-7s-note2 metismenu-icon-green",
    label: "Treatments",
    to: "#/dashboard/treatments"
  },
  {
    icon: "pe-7s-users metismenu-icon-blue",
    label: "Patients",
    content: [
      {
        icon: 'pe-7s-note2 metismenu-icon-red',
        label: 'Listing',
        to: '#/dashboard/patients'
      },
      {
        icon: 'pe-7s-medal metismenu-icon-orange',
        label: 'Birthdays',
        to: '#/dashboard/patients-birthday'
      }
    ]
  },
  {
    icon: "pe-7s-users metismenu-icon-blue",
    label: "Treated Patients",
    to: '#/dashboard/treated-patients'
  },
  {
    icon: "pe-7s-culture metismenu-icon-red",
    label: "Panels",
    content: [
      {
        icon: 'pe-7s-note2 metismenu-icon-orange',
        label: 'Listing',
        to: '#/dashboard/panels'
      },
      {
        icon: 'pe-7s-cash metismenu-icon-yellow',
        label: 'Collections',
        to: '#/dashboard/panel-collections'
      }
    ]
  },
  {
    icon: 'pe-7s-note2 metismenu-icon-violet',
    label: 'Medical Certs',
    content: [
      {
        icon: 'pe-7s-note2 metismenu-icon-red',
        label: 'Listing',
        to: '#/dashboard/medical-certs'
      },
      {
        icon: 'pe-7s-medal metismenu-icon-orange',
        label: 'Reports',
        to: '#/dashboard/medical-certs-reports'
      }
    ]
  },
  {
    icon: 'pe-7s-server metismenu-icon-purple',
    label: 'Pharmaceuticals',
    to: '#/dashboard/pharmaceuticals'
  },
  {
    icon: "pe-7s-key metismenu-icon-red",
    label: "Roles",
    to: "#/dashboard/roles"
  },
  {
    icon: 'pe-7s-culture metismenu-icon-orange',
    label: 'Companies',
    to: '#/dashboard/companies'
  },
  {
    icon: 'pe-7s-gift metismenu-icon-yellow',
    label: 'Products',
    to: '#/dashboard/products'
  },
  {
    icon: 'pe-7s-note2 metismenu-icon-green',
    label: 'Invoices',
    to: '#/dashboard/invoices'
  },
  {
    icon: 'pe-7s-cash metismenu-icon-green',
    label: 'Refunds',
    to: '#/dashboard/refunds'
  },
  {
    icon: 'pe-7s-mail-open-file metismenu-icon-purple',
    label: 'Super Admin Collections',
    to: '#/dashboard/collections-super-admin'
  },
  {
    icon: 'pe-7s-mail-open-file metismenu-icon-blue',
    label: 'Collections',
    to: '#/dashboard/collections'
  },
  {
    icon: 'pe-7s-mail-open-file metismenu-icon-violet',
    label: 'Doctor Collections',
    to: '#/dashboard/collections-dr'
  },
  {
    icon: 'pe-7s-mail-open-file metismenu-icon-purple',
    label: 'CA Collections',
    to: '#/dashboard/credit-advance-collections'
  },
  {
    icon: 'pe-7s-drop metismenu-icon-red',
    label: 'Medicine Collections',
    to: '#/dashboard/medicine-collections'
  },
  {
    icon: 'pe-7s-clock metismenu-icon-orange',
    label: 'Time Slip',
    to: '#/dashboard/time-slip'
  },
  {
    icon: 'pe-7s-server metismenu-icon-yellow',
    label: 'Data Migrations',
    content: [
      {
        icon: 'pe-7s-note2 metismenu-icon-red',
        label: 'Appointments',
        to: '#/dashboard/data-migrations/appointments'
      }
    ]
  },
  {
    icon: 'pe-7s-note2 metismenu-icon-green',
    label: 'Consent Forms',
    content: [
      {
        icon: 'pe-7s-photo-gallery  metismenu-icon-red',
        label: 'Listing',
        to: '#/dashboard/consent-forms'
      },
      {
        icon: 'pe-7s-photo metismenu-icon-orange',
        label: 'Templates',
        to: '#/dashboard/consent-templates'
      }
    ]
  },
  {
    icon: 'pe-7s-note2 metismenu-icon-red',
    label: 'Referral Forms',
    to: '#/dashboard/referral-forms'
  },
  {
    icon: 'pe-7s-display2 metismenu-icon-blue',
    label: 'Release Notes',
    to: '#/dashboard/release-notes'
  },
  {
    icon: 'pe-7s-display2 metismenu-icon-violet',
    label: 'User Guide',
    to: '#/dashboard/user-guide'
  }
]