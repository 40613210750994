import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Collapse,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'
import { Multiselect } from 'react-widgets'

import SmileDatepicker from 'components/Datepicker'

const SearchForm = ({
  data,
  searchParams,
  onChangeTimeSlipsHOC,
  getTimeSlipsWithQuery,
  doctors,
  patients,
  getPatients,
  onChangePatientsHOC,
  onLoadPatientsHOC,
  patientSearchParam
}) => {
  const [ isCardOpen, updateIsCardOpen ] = useState( false )

  const updateSearchParams = ( key, value ) => {
    let tmp = _.cloneDeep( searchParams )
    tmp[ key ] = value
    onChangeTimeSlipsHOC( 'searchParams', tmp )
  }

  const ListItem = ({ item }) => (
    <span>
      { `${ item.name } - ${ item.nric_no } - ${ item.contact_number }` }
    </span>
  )

  return (
    <Card className="main-card mb-3">
      <CardHeader 
        id='search-bar-toggle'
        className='d-flex justify-content-between' 
        onClick={ () => updateIsCardOpen( !isCardOpen ) } >
        <Label className='m-0' >Search</Label>
        <i 
          className={ isCardOpen ? 'pe-7s-angle-up' : 'pe-7s-angle-down' }
          style={{ fontSize: "3rem", color: "#000" }} />
        <UncontrolledTooltip target='search-bar-toggle' placement="top-end">
          { isCardOpen ? 'Collapse' : 'Expand' }</UncontrolledTooltip>
      </CardHeader>
      <Collapse isOpen={ isCardOpen } >
        <CardBody>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <Label>Start Date</Label>
              <SmileDatepicker
                showTimeSelect={ false }
                selectedDate={ searchParams[ 'start_datetime' ] }
                onChange={ val => updateSearchParams( 'start_datetime', val ) }
                dateFormat="MMMM d, yyyy"
                disabled={ false } 
              />
            </FormGroup>
            <FormGroup>
              <Label>End Date</Label>
              <SmileDatepicker
                showTimeSelect={ false }
                selectedDate={ searchParams[ 'end_datetime' ] }
                onChange={ val => updateSearchParams( 'end_datetime', val ) }
                dateFormat="MMMM d, yyyy"
                disabled={ false } 
              />
            </FormGroup>
            <FormGroup>
              <Label>Doctor name</Label>
              <Input
                type='select'
                value={ searchParams[ 'user_id' ] }
                onChange={ e => updateSearchParams( 'user_id', parseInt( e.target.value ) ) } >
                <option value={ '' } ></option>
                { 
                  _.filter( 
                    doctors, 
                    doctor => _.filter( doctor.company_ids , company_id => _.findIndex( data.profileReducer.profile.current_user.company_ids, item => item === company_id ) > -1 ).length > 0
                  ).map( doctor => <option 
                    key={ `doctor_${ doctor.id }` }
                    value={ doctor.id } >
                    { `${ doctor.salutation }. ${ doctor.name }`}</option> ) 
                }
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Patient name</Label>
              <div className="d-flex mb-2">
                <Input 
                  type="text" 
                  value={ patientSearchParam }
                  className='mr-2'
                  onChange={ e => onChangePatientsHOC( 'patientSearchParam', e.target.value ) }
                  placeholder="Fill in patient NRIC or name here and click search button" />
                <Button 
                  color="primary" 
                  onClick={() => {
                    getPatients( patientSearchParam )
                    updateSearchParams( 'patient_id', 0 ) }}>
                  Search</Button>
              </div>
              {
                onLoadPatientsHOC 
                  ? (
                    <p>Loading patients......</p>
                  ) : <>
                    <Multiselect
                      data={ patients }
                      textField={ 'name' }
                      itemComponent={ ListItem }
                      value={ searchParams.patient_id > 0 
                        ? _.find( patients, { id: parseInt( searchParams.patient_id ) } ) 
                          ? [ _.find( patients, { id: parseInt( searchParams.patient_id ) } ) ] 
                          : [ {} ]
                        : [] }
                      onChange={ val => val.length === 1 
                        ? updateSearchParams( 'patient_id', val[ 0 ].id )
                        : updateSearchParams( 'patient_id', 0 )
                      } />
                  </>
              }
            </FormGroup>
            <div className="d-flex">
              <Button 
                color="primary"
                style={{ marginLeft: 'auto' }}
                onClick={() => getTimeSlipsWithQuery(
                  _.find( patients, { id: searchParams[ 'patient_id' ] } ) ? _.find( patients, { id:searchParams[ 'patient_id' ] } ).name : '',
                  Moment( searchParams[ 'start_datetime' ] ).startOf( 'day' ).utc().format(),
                  Moment( searchParams[ 'end_datetime' ] ).endOf( 'day' ).utc().format(),
                  _.find( doctors, { id: searchParams[ 'user_id' ] } ) ? _.find( doctors, { id: searchParams[ 'user_id' ] } ).name : '',
                  1
                )}>
                { 'Search' }</Button>
              <Button 
                color="danger"
                style={{ marginLeft: '10px' }}
                onClick={ () => {
                  onChangeTimeSlipsHOC( 'searchParams', {
                    patient_id: 0,
                    start_datetime: new Date(),
                    end_datetime: new Date(),
                    user_id: 0
                  })
                  getTimeSlipsWithQuery(
                    '',
                    Moment( new Date() ).startOf( 'day' ).utc().format(),
                    Moment( new Date() ).endOf( 'day' ).utc().format(),
                    '',
                    1
                  )
                }}>
                { 'Reset' }</Button>
            </div>
          </Form>
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default SearchForm