import React from 'react'
import _ from 'lodash'
import {
  Form, FormGroup,
  Label, Input
} from 'reactstrap'

const Panel  = ({
  data,
  panelType,
  currentPanel,
  onChangePanelsHOC
}) => {
  const updatePanel = ( key, val ) => {
    let tmp = _.cloneDeep( currentPanel )
    tmp[ key ] = val
    onChangePanelsHOC( panelType, tmp )
  }

  return(
    <Form>
      <FormGroup>
        <Label>Company</Label>
        <Input
          type="select"
          value={ currentPanel.company_id }
          onChange={ e => updatePanel( 'company_id', parseInt( e.target.value ) ) }>
          <option value={ 0 } ></option>
          {
            data.profileReducer.profile.companies.map( item => {
              return <option key={ item.id } value={ item.id }>{ item.name }</option>
            })
          }
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Name</Label>
        <Input
          type='text'
          value={ currentPanel.panel_name }
          onChange={ e => updatePanel( 'panel_name', e.target.value ) } />
      </FormGroup>
      <FormGroup>
        <Label>Panel code</Label>
        <Input
          type='text'
          value={ currentPanel.code }
          onChange={ e => updatePanel( 'code', e.target.value ) } />
      </FormGroup>
      <FormGroup>
        <Label>Address</Label>
        <Input
          type='textarea'
          value={ currentPanel.address }
          onChange={ e => updatePanel( 'address', e.target.value ) } />
      </FormGroup>
      <FormGroup>
        <Label>Contact number</Label>
        <Input
          type='text'
          value={ currentPanel.contact_number }
          onChange={ e => updatePanel( 'contact_number', e.target.value ) } />
      </FormGroup>
      <FormGroup>
        <Label>E-mail</Label>
        <Input
          type='text'
          value={ currentPanel.email }
          onChange={ e => updatePanel( 'email', e.target.value ) } />
      </FormGroup>
      <FormGroup>
        <Label>Person-in-charge</Label>
        <Input
          type='text'
          value={ currentPanel.person_in_charge }
          onChange={ e => updatePanel( 'person_in_charge', e.target.value ) } />
      </FormGroup>
      <FormGroup>
        <Label>Person-in-charge contact number</Label>
        <Input
          type='text'
          value={ currentPanel.person_in_charge_contact_number }
          onChange={ e => updatePanel( 'person_in_charge_contact_number', e.target.value ) } />
      </FormGroup>
    </Form>
  )
}

export default Panel