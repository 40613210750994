import React, { useState, useEffect } from 'react'
import {
  Card, CardHeader, CardBody,
  Form, FormGroup,
  Label, Input, Button,
  Collapse, UncontrolledTooltip
} from 'reactstrap'
import { Multiselect } from 'react-widgets'
import _ from 'lodash'
import Moment from 'moment'

import WithCompanies from 'actions/dictionary/companies'

const ListItem = ({ item }) => (
  <span>
    { `${ item.name } - ${ item.nric_no } - ${ item.contact_number }` }
  </span>
)

const SearchForm = ({
  searchParams,
  getCompanies,
  onChangeInvoiceHOC,
  getInvoices,
  getPatients,
  patientsSearchParam,
  onChangePatientsHOC,
  onLoadPatientsHOC,
  patients
}) => {
  const [ isCardOpen, updateIsCardOpen ] = useState( false )

  useEffect(() => {
    getCompanies()
  }, [])

  return (
    <Card className="main-card mb-3">
      <CardHeader 
        id='search-bar-toggle'
        className='d-flex justify-content-between' 
        onClick={ () => updateIsCardOpen( !isCardOpen ) } >
        <Label className='m-0' >Search</Label>
        <i 
          className={ isCardOpen ? 'pe-7s-angle-up' : 'pe-7s-angle-down' }
          style={{ fontSize: "3rem", color: "#000" }} />
        <UncontrolledTooltip target='search-bar-toggle' placement="top-end">
          { isCardOpen ? 'Collapse' : 'Expand' }</UncontrolledTooltip>
      </CardHeader>
      <Collapse isOpen={ isCardOpen } >
        <CardBody>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <Label>Start date</Label>
              <input
                className="form-control"
                type="date"
                value={ searchParams ? Moment( searchParams[ 0 ][ 'value' ] ).format( 'YYYY-MM-DD' ) : '' }
                onChange={ e => {
                  let tmp = _.cloneDeep( searchParams )
                  tmp[ 0 ][ 'value' ] = e.target.value
                  return onChangeInvoiceHOC( 'searchParams', tmp )
                }} />
            </FormGroup>
            <FormGroup>
              <Label>End date</Label>
              <input
                className="form-control"
                type="date"
                value={ searchParams ? Moment( searchParams[ 1 ][ 'value' ] ).format( 'YYYY-MM-DD' ) : '' }
                onChange={ e => {
                  let tmp = _.cloneDeep( searchParams )
                  tmp[ 1 ][ 'value' ] = e.target.value
                  return onChangeInvoiceHOC( 'searchParams', tmp )
                }} />
            </FormGroup>
            <FormGroup>
              <Label>Patient</Label>
              <div className="d-flex mb-2">
                <Input 
                  type="text" 
                  value={ patientsSearchParam }
                  className='mr-2'
                  onChange={ e => onChangePatientsHOC( 'patientsSearchParam', e.target.value ) }
                  placeholder="Fill in patient NRIC or name here and click search button" />
                <Button 
                  color="primary" 
                  onClick={ () => {
                    let tmp = _.cloneDeep( searchParams )
                    tmp[ 2 ][ 'value' ] = ''
                    Promise.all([
                      getPatients( patientsSearchParam ),
                      onChangeInvoiceHOC( 'searchParams', tmp )
                    ])
                  }}>
                  Search</Button>
              </div>
              {
                onLoadPatientsHOC 
                  ? (
                    <p>Loading patients......</p>
                  ) : <>
                    <Multiselect
                      data={ patients }
                      textField={ 'name' }
                      itemComponent={ ListItem }
                      value={ parseInt( searchParams[ 2 ].value ) > 0 
                        ? _.find( patients, { id: parseInt( searchParams[ 2 ].value ) } ) 
                          ? [ _.find( patients, { id: parseInt( searchParams[ 2 ].value ) } ) ] 
                          : [ {} ]
                        : [] }
                      onChange={ val => {
                        let tmp = _.cloneDeep( searchParams )
                        if( val.length === 1 ){
                          tmp[ 2 ][ 'value' ] = val[ 0 ].id
                        } else {
                          tmp[ 2 ][ 'value' ] = ''
                        }
                        return onChangeInvoiceHOC( 'searchParams', tmp )
                      }} />
                  </>
              }
            </FormGroup>
            <div className="d-flex">
              <Button 
                color="primary"
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  let tmp = ''
                  searchParams.map( item => {
                    tmp = tmp + `&q[${ item.key }]=${ item.value }`
                  })
                  return getInvoices( tmp, 1 )
                }}>{ 'Search' }</Button>
              <Button 
                color="danger"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  let tmp = ''
                  let tmpSearchParams = [
                    {
                      label: 'Start date',
                      key: 'start_date',
                      value: '2020-01-01'
                    },
                    {
                      label: 'End date',
                      key: 'end_date',
                      value: '2020-12-31'
                    },
                    {
                      label: 'Patient',
                      key: 'patient_id',
                      value: ''
                    }
                  ]
                  tmpSearchParams.map( item => {
                    tmp = tmp + `&q[${ item.key }]=${ item.value }`
                  })
                  onChangeInvoiceHOC( 'searchParams', tmpSearchParams )
                  return getInvoices( tmp, 1 )
                }}>{ 'Reset' }</Button>
            </div>
          </Form>
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default WithCompanies( SearchForm )