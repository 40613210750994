export const RoleAccess = [
  {
    role_id: 1,
    role: 'Super Admin',
    modules: [
      'Dashboard',
      'Appointments',
      'Users',
      'Treatments',
      'Patients',
      'Medical Certs',
      'Pharmaceuticals',
      'Roles',
      'Companies',
      'Products',
      'Invoices',
      'Super Admin Collections',
      'Collections',
      'CA Collections',
      'Medicine Collections',
      'Time Slip',
      'Data Migrations',
      'Consent Forms',
      'Referral Forms',
      'User Guide',
      'Refunds',
      'Release Notes',
      'Panels'
    ]
  },
  {
    role_id: 4,
    role: 'Admin',
    modules: [
      'Dashboard',
      'Appointments',
      'Users',
      'Treatments',
      'Patients',
      'Medical Certs',
      'Pharmaceuticals',
      'Companies',
      'Products',
      'Invoices',
      'Collections',
      'CA Collections',
      'Medicine Collections',
      'Time Slip',
      'Data Migrations',
      'Consent Forms',
      'Referral Forms',
      'User Guide',
      'Refunds',
      'Release Notes',
      'Panels'
    ]
  },
  {
    role_id: 2,
    role: 'Doctor',
    modules: [
      'Dashboard',
      'Appointments',
      'Treatments',
      'Patients',
      'Treated Patients',
      'Medical Certs',
      'Pharmaceuticals', 
      'Doctor Collections',
      'Medicine Collections',
      'Time Slip',
      'Data Migrations',
      'Consent Forms',
      'Referral Forms',
      'User Guide',
      'Release Notes',
      'Panels'
    ]
  },
  {
    role_id: 3,
    role: 'Frontdesk',
    modules: [
      'Dashboard',
      'Appointments',
      'Treatments',
      'Patients',
      'Medical Certs',
      'Pharmaceuticals',
      'Products',
      'Invoices',
      'Collections',
      'CA Collections',
      'Medicine Collections',
      'Time Slip',
      'Consent Forms',
      'Referral Forms',
      'User Guide',
      'Refunds',
      'Release Notes',
      'Panels'
    ]
  }
]