import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      newCompany: {
        name: '',
        registration_number: ''
      },
      companies: [],
      selectedCompany: {
        name: '',
        branches: [],
        registration_number: ''
      },
      showCreateCompany: false,
      showUpdateCompany: false,
      loading: false,
      showPromptModal: false,
      toRemoveID: '',
      companyError: {},
      subscriptionTransactions: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeCompaniesHOC = ( key, val ) => this.setState({ [ key ]: val })

    getCompanies = () => Get(
      `/companies`,
      this.getCompaniesSuccess,
      this.getCompaniesError,
      this.load
    )
    getCompaniesSuccess = payload => this.setState({ companies: payload })
    getCompaniesError = error => this.requestError( error )

    getSelectedCompany = id => Get(
      `/companies/${ id }`,
      this.getSelectedCompanySuccess,
      this.getSelectedCompanyError,
      this.load
    )
    getSelectedCompanySuccess = payload => {
      let tmp = payload.users
      _.remove( tmp, { role: { name: 'Super Admin' } } )
      this.setState({ selectedCompany: {
        ...payload,
        users: tmp
      }, showUpdateCompany: true })
    }
    getSelectedCompanyError = error => this.requestError( error )

    createCompany = data => Post(
      `/companies`,
      data,
      this.createCompanySuccess,
      this.createCompanyError,
      this.load
    )
    createCompanySuccess = payload => {
      this.requestSuccess( 'Company is created successfully.' )
      this.setState({ showCreateCompany: false })
      this.getCompanies()
    }
    createCompanyError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ companyError: error })
      }
    }

    updateCompany = data => Put(
      `/companies/${ data.id }`,
      data,
      this.updateCompanySuccess,
      this.updateCompanyError,
      this.load
    )
    updateCompanySuccess = payload => {
      this.requestSuccess( 'Company is updated successfully.' )
      this.setState({ showUpdateCompany: false })
      this.getCompanies()
    }
    updateCompanyError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ companyError: error })
      }
    }

    updateCompanyLogo = ( company_id, data ) =>  Put(
      `/companies/${ company_id }/upload_logo`,
      data,
      this.updateCompanyLogoSuccess,
      this.updateCompanyLogoError,
      this.load
    )
    updateCompanyLogoSuccess = payload => {
      this.requestSuccess( 'Company logo is updated successfully.' )
      this.setState({ selectedCompany: payload })
    }
    updateCompanyLogoError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ companyError: error })
      }
    }

    removeCompany = id => Delete(
      `/companies/${ id }`,
      this.removeCompanySuccess,
      this.removeCompanyError,
      this.load
    )
    removeCompanySuccess = payload => {
      this.requestSuccess( 'Company is removed successfully.' )
      this.getCompanies()
      this.setState({ showPromptModal: false })
    }
    removeCompanyError = error => this.requestError( error )

    getSubscriptionTransactions = company_id => Get(
      `/companies/${ company_id }/company_payment_histories`,
      this.getSubscriptionTransactionsSuccess,
      this.getSubscriptionTransactionsError,
      this.load
    )
    getSubscriptionTransactionsSuccess = payload => this.setState({ subscriptionTransactions: payload })
    getSubscriptionTransactionsError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent 
            { ...this.props }
            newCompany={ this.state.newCompany }
            showCreateCompany={ this.state.showCreateCompany }
            showUpdateCompany={ this.state.showUpdateCompany }
            onLoadCompanies={ this.state.loading }
            companies={ this.state.companies }
            selectedCompany={ this.state.selectedCompany }
            onChangeCompaniesHOC={ this.onChangeCompaniesHOC }
            getCompanies={ this.getCompanies }
            getSelectedCompany={ this.getSelectedCompany }
            createCompany={ this.createCompany }
            updateCompany={ this.updateCompany }
            updateCompanyLogo={ this.updateCompanyLogo }
            removeCompany={ this.removeCompany }
            subscriptionTransactions={ this.state.subscriptionTransactions }
            getSubscriptionTransactions={ this.getSubscriptionTransactions } />
          <PromptModal
            showPromptModal={ this.state.showPromptModal }
            onClickYes={() => this.removeCompany( this.state.toRemoveID )}
            onClickNo={() => this.setState({ showPromptModal: false })}
            content={ 'Are you sure you want to delete the record?' } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC