import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      medicines: [],
      newMedicine: {
        medicine_id: 0,
        invoice_id: 0,
        quantity: 1,
        price_per_unit: 0
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeMedicineHOC = ( key, val ) => this.setState({ [ key ]: val })

    getMedicines = () => Get(
      `/medicines`,
      this.getMedicinesSuccess,
      this.getMedicinesError,
      this.load
    )
    getMedicinesSuccess = payload => this.setState({ medicines: payload })
    getMedicinesError = error => this.requestError( error )

    addInvoiceMedicine = ( data, totalPrice ) => Post(
      `/invoices/${ data.invoice_id }/add_medicine`,
      data,
      payload => this.addInvoiceMedicineSuccess( payload, totalPrice ),
      this.addInvoiceMedicineError,
      this.load
    )
    addInvoiceMedicineSuccess = ( payload, totalPrice ) => {
      this.requestSuccess( 'Medicine is assigned to invoice successfully.' )
      payload.stock_warning && this.requestError( 'This product is going to run out of stock, with stock count less or equal to 10' )
      this.props.getSelectedInvoice( this.props.selectedInvoice.id )
      this.setState({
        newMedicine: {
          medicine_id: 0,
          invoice_id: 0,
          quantity: 1,
          price_per_unit: 0
        }
      })
    }
    addInvoiceMedicineError = error => this.requestError( error.message )

    removeInvoiceMedicine = ( invoice_id, medicine_id, totalPrice ) => Delete(
      `/invoices/${ invoice_id }/remove_medicine?medicine_id=${ medicine_id }`,
      payload => this.removeInvoiceMedicineSuccess( payload, totalPrice ),
      this.removeInvoiceMedicineError,
      this.load
    )
    removeInvoiceMedicineSuccess = ( payload, totalPrice ) => {
      this.requestSuccess( 'Medicine is removed from invoice successfully.' )
      this.props.getSelectedInvoice( this.props.selectedInvoice.id )
    }
    removeInvoiceMedicineError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          onLoadInvoiceMedicines={ this.state.loading }
          medicines={ this.state.medicines }
          newMedicine={ this.state.newMedicine }
          onChangeMedicineHOC={ this.onChangeMedicineHOC }
          getMedicines={ this.getMedicines }
          addInvoiceMedicine={ this.addInvoiceMedicine }
          removeInvoiceMedicine={ this.removeInvoiceMedicine } />
      )
    }
  }
  return WithHOC
}

export default HOC