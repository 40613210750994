import React from 'react'
import { 
  Modal, ModalHeader, ModalBody,
  Form, FormGroup,
  Label, Input
} from 'reactstrap'
import NumberFormat from "react-number-format"

const ViewPayment = props => {
  return (
    <Modal
      isOpen={ props.showViewPayment }>
      <ModalHeader toggle={() => props.onChangePaymentHOC( 'showViewPayment', false )}>View selected payment</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>Customer name</Label>
            <Input
              type="text"
              value={ props.selectedPayment[ 'customer_name' ] }
              disabled={ true } />
          </FormGroup>
          <FormGroup>
            <Label>Customer IC</Label>
            <Input
              type="text"
              value={ props.selectedPayment[ 'customer_ic' ] }
              disabled={ true } />
          </FormGroup>
          <FormGroup>
            <Label>Customer address</Label>
            <Input
              type="text"
              value={ props.selectedPayment[ 'customer_address' ] }
              disabled={ true } />
          </FormGroup>
          <FormGroup>
            <Label>Remark(for Dr to tell Frontdesk something)</Label>
            <Input
              type="text"
              value={ props.selectedPayment[ 'remark' ] }
              disabled={ true } />
          </FormGroup>
          <FormGroup>
            <Label>Current paid amount</Label>
            <Input
              type="text"
              value={ props.selectedPayment[ 'current_paid_amount' ] }
              disabled={ true } />
          </FormGroup>
          <FormGroup>
            <Label>Current cost</Label>
            <Input
              type="text"
              value={ props.selectedPayment[ 'current_cost' ] }
              disabled={ true } />
          </FormGroup>
          <FormGroup>
            <Label>Discount type</Label>
            <Input
              disabled
              type="select"
              value={ props.selectedPayment.discount_type_id }>
              <option value={ 0 } >In percentage (%)</option>
              <option value={ 1 } >In fixed amount</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>{ props.selectedPayment.discount_type_id === 0 ? 'Discount percentage (%)' : 'Discount amount' }</Label>
            <NumberFormat
              disabled
              allowNegative={ false }
              allowEmptyFormatting
              className="form-control"
              value={ props.selectedPayment.discount }
              thousandSeparator={ "," }
              decimalSeparator={ "." }
              decimalScale={ props.selectedPayment.discount_type_id === 0 ? 0 : 2 }
              fixedDecimalScale={ true }
              inputMode="numeric"
              displayType="input" />
          </FormGroup>
          <FormGroup>
            <Label>Final price</Label>
            <Input
              type="number"
              value={ parseFloat( props.selectedPayment[ 'final_price' ] ).toFixed( 2 ) }
              disabled={ true } />
          </FormGroup>
          <FormGroup>
            <Label>Change</Label>
            <Input
              type="text"
              value={ parseFloat( props.selectedPayment[ 'change' ] ).toFixed( 2 ) }
              disabled={ true } />
          </FormGroup>
          <FormGroup>
            <Label>Pay with</Label>
            <Input
              type="text"
              value={ props.selectedPayment[ 'pay_with' ] }
              disabled={ true } />
          </FormGroup>
          {
            props.selectedPayment[ 'pay_with' ] === 'Panel' && <FormGroup>
              <Label>Panel</Label>
              <Input
                type="text"
                value={ props.selectedPayment[ 'panel' ] ? props.selectedPayment[ 'panel' ][ 'panel' ] : '' }
                disabled={ true } />
            </FormGroup>
          }
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ViewPayment