import React, { useState, useEffect } from 'react'
import { 
  Modal, ModalHeader, ModalBody,
  Button, Row, Col
} from 'reactstrap'
import _ from 'lodash'
import Tabs from 'react-responsive-tabs'
import Moment from 'moment'
import { compose } from 'redux'
import { toast, ToastContainer } from 'react-toastify'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import General from './General'
import Invoice from './Invoice'
import Overview from './Overview'

import WithTreatments from 'actions/dictionary/treatments'
import WithDoctors from 'actions/dictionary/doctors'
import WithMedicines from 'actions/dictionary/medicines'
import WithAssignMedicines from './actions/Medicines'
import WithAssignTreatments from './actions/Treatments'
import WithAssignXrays from './actions/Xrays'
import WithAssignDocuments from './actions/Documents'

const TabsConfig = {
  activeTab: 0,
  showMore: true,
  transform: true,
  showInkBar: true,
  items: [
    { title: 'General' },
    { title: 'Receipt' }
  ],
  selectedTabKey: 0,
  transformWidth: 400,
}

const Treatments = ({
  data,
  showMedicalRecord,
  onChangeMedicalRecordHOC,
  medicalRecords,
  updateMedicalRecord,
  updateMedicalRecordTreatment,
  selectedMedicalRecord,
  getSelectedMedicalRecord,
  onLoadMedicalRecords,
  onLoadTreatments,
  getTreatments,
  treatments,
  onLoadDoctors,
  doctors,
  getDoctors,
  onLoadMedicines,
  getMedicines,
  medicines,
  onChangeAssignMedicineHOC,
  onLoadAssignMedicine,
  newAssignMedicine,
  assignMedicineToMedicalRecord,
  removeMedicineFromMedicalRecord,
  onChangeAssignTreatmentHOC,
  onLoadAssignTreatment,
  newAssignTreatment,
  assignTreatmentToMedicalRecord,
  removeTreatmentFromMedicalRecord,
  selectedPatient,
  onChangePatientsHOC,
  match,
  getSelectedPatient,
  onLoadProcessXrayImages,
  uploadXrayImages,
  removeXrayImages,
  onLoadMedicalRecordDocuments,
  medicalRecordDocuments,
  createMedicalRecordDocument,
  getMedicalRecordDocuments,
  removeMedicalRecordDocument
}) => {
  const [ currentTab, updateCurrentTab ] = useState( 0 )
  const [ mode, updateMode ] = useState( 'overview' )
  useEffect(() => {
    if( showMedicalRecord ) {
      getTreatments()
      getDoctors( data.profileReducer.profile.current_user.branch_ids )
      getMedicines()
    } else {
      onChangeMedicalRecordHOC( 'selectedMedicalRecord', {
        id: 0,
        medicines: [],
        treatments: [],
        tooth_charts: []
      })
    }
    if( parseInt( match.params.medical_record_id ) ){
      updateMode( 'normal' )
    }
    if( parseInt( match.params.invoice_id ) > 0 || parseInt( match.params.payment_id ) > 0 ){
      updateCurrentTab( 1 )
    }
  }, [ showMedicalRecord ])
  return (
    <Modal
      isOpen={ showMedicalRecord }
      size={ 'xl' }
      toggle={() => {
        onChangeMedicalRecordHOC( 'showMedicalRecord', false )
      }}>
      <ModalHeader toggle={() => onChangeMedicalRecordHOC( 'showMedicalRecord', false )}>
        Treatment Records
        <Button 
          color="primary"
          className="ml-3"
          onClick={() => {
            onChangePatientsHOC( 'showViewPatient', true )
          }}>View patient</Button>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={ 12 }>
            <Button 
              color="primary" 
              className="mr-2 mb-2"
              onClick={() => updateMode( 'overview' )}>Overview</Button>
            <Button 
              color="primary"
              className="mr-2 mb-2"
              onClick={() => updateMode( 'normal' )}>Normal View</Button>
          </Col>
        </Row>
        {
          mode === 'overview' && (
            <Row>
              <Col md={ 12 }>
                <Overview medicalRecords={ medicalRecords } />
              </Col>
            </Row>
          )
        }
        {
          mode === 'normal' && (
            <Row>
              <Col md={ 4 }>
                <p>Record(s)</p>
                {
                  medicalRecords.map( item => {
                    return (
                      <Button 
                        key={ `Date-${ item.id }` }
                        className="w-100 mb-1"
                        color={ item.is_incomplete_treatment ? 'danger' : 'primary' }
                        active={ selectedMedicalRecord.id === item.id }
                        onClick={() => {
                          getSelectedMedicalRecord( item.id )
                          getMedicalRecordDocuments( item.id )
                          updateCurrentTab( 0 )
                        }}>
                        { Moment( item.created_at ).format( 'YYYY-MM-DD HH:mm' ) }
                      </Button>
                    )
                  })
                }
                {/* treatment is created at frontdesk when patient is readied to go into treatment room */}
                {/* <Button className="w-100 mb-1" color="success">Add new treatment record</Button> */}
              </Col>
              <Col md={ 8 }>
                {
                  selectedMedicalRecord.id > 0 && (
                    <>
                      <Tabs
                        tabsWrapperClass="body-tabs"
                        { ...TabsConfig }
                        selectedTabKey={ currentTab }
                        onChange={ val => updateCurrentTab( val ) } />
                      {
                        currentTab === 0 && <General 
                          onChangeMedicalRecordHOC={ onChangeMedicalRecordHOC }
                          updateMedicalRecord={ updateMedicalRecord }
                          getSelectedMedicalRecord={ getSelectedMedicalRecord }
                          selectedMedicalRecord={ selectedMedicalRecord }
                          treatments={ treatments }
                          doctors={ doctors }
                          medicines={ medicines }
                          newAssignMedicine={ newAssignMedicine }
                          onChangeAssignMedicineHOC={ onChangeAssignMedicineHOC }
                          assignMedicineToMedicalRecord={ assignMedicineToMedicalRecord }
                          removeMedicineFromMedicalRecord={ removeMedicineFromMedicalRecord }
                          onChangeAssignTreatmentHOC={ onChangeAssignTreatmentHOC }
                          newAssignTreatment={ newAssignTreatment }
                          assignTreatmentToMedicalRecord={ assignTreatmentToMedicalRecord }
                          removeTreatmentFromMedicalRecord={ removeTreatmentFromMedicalRecord }
                          updateMedicalRecordTreatment={ updateMedicalRecordTreatment }
                          uploadXrayImages={ uploadXrayImages }
                          removeXrayImages={ removeXrayImages }
                          medicalRecordDocuments={ medicalRecordDocuments }
                          createMedicalRecordDocument={ createMedicalRecordDocument }
                          removeMedicalRecordDocument={ removeMedicalRecordDocument } />
                      }
                      {
                        currentTab === 1 && <Invoice 
                          selectedMedicalRecord={ selectedMedicalRecord }
                          selectedPatient={ selectedPatient }
                          getSelectedPatient={ getSelectedPatient }
                          getSelectedMedicalRecord={ getSelectedMedicalRecord }
                          updateMedicalRecord={ updateMedicalRecord }
                          medical_record_id={ !_.isEmpty( match.params.medical_record_id ) ? match.params.medical_record_id : 0 }
                          invoice_id={ !_.isEmpty( match.params.invoice_id ) ? match.params.invoice_id : 0 }
                          payment_id={ !_.isEmpty( match.params.payment_id ) ? match.params.payment_id : 0 } />
                      }
                    </>
                  )
                }
              </Col>
            </Row>
          )
        }
        <ToastContainer position={ toast.POSITION.BOTTOM_RIGHT } style={{ zIndex: 9999 }} />
        { 
          ( 
            onLoadProcessXrayImages || onLoadMedicalRecords || onLoadTreatments || onLoadDoctors || 
            onLoadMedicines || onLoadAssignMedicine || onLoadAssignTreatment || onLoadMedicalRecordDocuments
          ) && <LoadingOverlay /> 
        }
      </ModalBody>
    </Modal>
  )
}

export default compose( 
  WithTreatments, 
  WithDoctors, 
  WithMedicines,
  WithAssignMedicines,
  WithAssignTreatments,
  WithAssignXrays,
  WithAssignDocuments
)( Treatments )