import React, { useState } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap'
import Tabs from 'react-responsive-tabs'
import _ from 'lodash'

import General from './General'
import Login from './Login'
import Companies from './Companies'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

const assignedCompanies = [
  {
    id: 1,
    name: 'Wong and Sim Dental'
  }
]

const TabsConfig = {
  activeTab: 0,
  showMore: true,
  transform: true,
  showInkBar: true,
  items: [
    { title: 'General' },
    { title: 'Login' },
    { title: 'Companies' }
  ],
  selectedTabKey: 0,
  transformWidth: 400,
}

const Update = ({
  showUpdateUser,
  selectedUser,
  onChangeUsersHOC,
  onLoadUsers,
  getSelectedUser,
  roles,
  updateUser,
  updatePhoto,
  data
}) => {
  const [ currentTab, updateCurrentTab ] = useState( 0 )

  const onChangeUserData = ( key, val ) => {
    let tmp = _.cloneDeep( selectedUser )
    tmp[ key ] = val
    return onChangeUsersHOC( 'selectedUser', tmp )
  }

  return (
    <Modal
      isOpen={ showUpdateUser }
      size={ 'xl' }
      toggle={() => {
        onChangeUsersHOC( 'showUpdateUser', false )
      }}>
      <ModalHeader toggle={() => onChangeUsersHOC( 'showUpdateUser', false )}>Update user</ModalHeader>
      <ModalBody>
        <Tabs
          tabsWrapperClass="body-tabs"
          { ...TabsConfig }
          selectedTabKey={ currentTab }
          onChange={ val => updateCurrentTab( val ) } />
        {
          currentTab === 0 && <General 
            onChangeUserData={ onChangeUserData } 
            updatePhoto={ updatePhoto } 
            selectedUser={ selectedUser } 
            roles={ roles } />
        }
        {
          currentTab === 1 && <Login onChangeUserData={ onChangeUserData } selectedUser={ selectedUser } />
        }
        {
          currentTab === 2 && !_.isEmpty( selectedUser ) && <Companies onChangeUserData={ onChangeUserData } 
            assignedCompanies={ selectedUser.company_ids }
            selectedUser={ selectedUser }
            getSelectedUser={ getSelectedUser }
            data={ data } />
        }
        { onLoadUsers && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={() => {
            updateUser( selectedUser )
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Update