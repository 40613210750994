import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Collapse,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'

const SearchForm = ({
  searchParams,
  getUsers,
  onChangeUsersHOC,
}) => {
  const updateSearchParams = ( key, value ) => {
    let tmp = _.cloneDeep( searchParams )
    tmp[ key ] = value
    onChangeUsersHOC( 'searchParams', tmp )
  }
  const [ isCardOpen, updateIsCardOpen ] = useState( false )
  return (
    <Card className="main-card mb-3">
      <CardHeader 
        id='search-bar-toggle'
        className='d-flex justify-content-between' 
        onClick={ () => updateIsCardOpen( !isCardOpen ) } >
        <Label className='m-0' >Search</Label>
        <i 
          className={ isCardOpen ? 'pe-7s-angle-up' : 'pe-7s-angle-down' }
          style={{ fontSize: "3rem", color: "#000" }} />
        <UncontrolledTooltip target='search-bar-toggle' placement="top-end">
          { isCardOpen ? 'Collapse' : 'Expand' }</UncontrolledTooltip>
      </CardHeader>
      <Collapse isOpen={ isCardOpen } >
        <CardBody>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <Label>{ 'Name' }</Label>
              <Input
                type='text'
                value={ searchParams.user_name }
                onChange={ e => 
                updateSearchParams( 'user_name', e.target.value ) 
              }/>
            </FormGroup>
            <div className="d-flex">
              <Button 
                color="primary"
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  getUsers(searchParams.user_name)
                }}>{ 'Search' }</Button>
              <Button 
                color="danger"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  updateSearchParams( 'user_name', '' ) 
                  getUsers()
                }}>{ 'Reset' }</Button>
            </div>
          </Form>
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default SearchForm