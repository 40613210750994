import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Collapse,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'

import SmileDatepicker from 'components/Datepicker'

const SearchForm = ({
  searchParams,
  onChangeMedicalCertHOC,
  getMedicalCerts,
  getMedicalCertsWithQuery
}) => {
  const [ isCardOpen, updateIsCardOpen ] = useState( false )

  const updateSearchParams = ( key, value ) => {
    let tmp = _.cloneDeep( searchParams )
    tmp[ key ] = value
    onChangeMedicalCertHOC( 'searchParams', tmp )
  }

  return (
    <Card className="main-card mb-3">
      <CardHeader 
        id='search-bar-toggle'
        className='d-flex justify-content-between' 
        onClick={ () => updateIsCardOpen( !isCardOpen ) } >
        <Label className='m-0' >Search</Label>
        <i 
          className={ isCardOpen ? 'pe-7s-angle-up' : 'pe-7s-angle-down' }
          style={{ fontSize: "3rem", color: "#000" }} />
        <UncontrolledTooltip target='search-bar-toggle' placement="top-end">
          { isCardOpen ? 'Collapse' : 'Expand' }</UncontrolledTooltip>
      </CardHeader>
      <Collapse isOpen={ isCardOpen } >
        <CardBody>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <Label>Start date</Label>
              <SmileDatepicker
                showTimeSelect={ false }
                selectedDate={ searchParams[ 'start_date' ] }
                onChange={ val => updateSearchParams( 'start_date', val ) }
                dateFormat="MMMM d, yyyy"
                disabled={ false } 
              />
            </FormGroup>
            <FormGroup>
              <Label>End date</Label>
              <SmileDatepicker
                showTimeSelect={ false }
                selectedDate={ searchParams[ 'end_date' ] }
                onChange={ val => updateSearchParams( 'end_date', val ) }
                dateFormat="MMMM d, yyyy"
                disabled={ false } 
              />
            </FormGroup>
            <FormGroup>
              <Label>Doctor name</Label>
              <Input
                type='text'
                value={ searchParams[ 'user_name' ] }
                onChange={ e => updateSearchParams( 'user_name', e.target.value ) } />
            </FormGroup>
            <FormGroup>
              <Label>Patient name</Label>
              <Input
                type='text'
                value={ searchParams[ 'patient_name' ] }
                onChange={ e => updateSearchParams( 'patient_name', e.target.value ) } />
            </FormGroup>
            <div className="d-flex">
              <Button 
                color="primary"
                style={{ marginLeft: 'auto' }}
                onClick={() => getMedicalCertsWithQuery(
                  searchParams[ 'patient_name' ].trim(),
                  Moment( searchParams[ 'start_date' ] ).format( 'YYYY-MM-DD' ),
                  Moment( searchParams[ 'end_date' ] ).format( 'YYYY-MM-DD' ),
                  searchParams[ 'user_name' ].trim()
                )}>
                { 'Search' }</Button>
              <Button 
                color="danger"
                style={{ marginLeft: '10px' }}
                onClick={ () => getMedicalCerts( '', 1 ) }>
                { 'Reset' }</Button>
            </div>
          </Form>
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default SearchForm