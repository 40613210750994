import Axios from 'axios'
import Cookies from 'js-cookie'

import { GET_PROFILE } from './type'
import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess
} from './ajax'
import getDomainURL from 'utils/api'

export const getProfile = () => dispatch => {
  dispatch( beginAjaxCall() )
  Axios.defaults.headers = {
    common : {
      Authorization: `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }`
    },
    'Access-Control-Allow-Origin': '*'
  }
  Axios.get(
    `${ getDomainURL() }/users/get_current_user`
  ).then( response => {
    dispatch( getProfileSuccess( response.data ) )
    dispatch( ajaxCallSuccess() )
  }).catch( error => {
    dispatch( ajaxCallError( error.response.data.message ) );
  })
}

export const getProfileSuccess = payload => ({
  type: GET_PROFILE,
  payload
})