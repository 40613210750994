import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'
import ReactHTMLParser from 'react-html-parser'

import { Get, Put, Post, Delete } from 'utils/axios'
import getDomainURL from 'utils/api'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      timeSlips: [],
      newTimeSlip: {
        patient_id: 0,
        start_time: Moment().format( 'HH:mm' ),
        end_time: Moment().add( 1, 'hours' ).format( 'HH:mm' ),
        date: new Date(),
        remark: '',
        user_id: 0,
        company_id: 0,
        branch_id: 0
      },
      selectedTimeSlip: {},
      showCreateTimeSlip: false,
      showUpdateTimeSlip: false,
      showDeleteTimeSlip: false,
      searchParams: {
        patient_id: 0,
        start_datetime: new Date(),
        end_datetime: new Date(),
        user_id: 0
      },
      searchString: 'Results'
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( ({ closeToast }) => ReactHTMLParser( error.message ) )

    onChangeTimeSlipsHOC = ( key, val ) => this.setState({ [ key ] : val })

    createTimeSlip = data => Post(
      `/timeslips`,
      data,
      this.createTimeSlipSuccess,
      this.createTimeSlipError,
      this.load
    )
    createTimeSlipSuccess = () => {
      this.getTimeSlipsWithQuery(
        '',
        Moment( new Date() ).startOf( 'day' ).utc().format(),
        Moment( new Date() ).endOf( 'day' ).utc().format(),
        '',
        1
      )
      this.requestSuccess( 'Time slip is successfully created' )
      this.setState({ 
        showCreateTimeSlip: false,
        searchParams: {
          patient_id: 0,
          start_datetime: new Date(),
          end_datetime: new Date(),
          user_id: 0
        }
      }) 
    }
    createTimeSlipError = error => this.requestError( error )

    getTimeSlipsWithQuery = ( 
      patient_name,
      start_datetime,
      end_datetime,
      user_name,
      page
    ) => Get(
      `/timeslips?page=${ page }&q[patient_name_cont]=${ patient_name }&q[start_datetime_gteq]=${ start_datetime }&q[end_datetime_lteq]=${ end_datetime }&q[user_name_cont]=${ user_name }`,
      payload => this.getTimeSlipsWithQuerySuccess( payload, patient_name, user_name ),
      this.getTimeSlipsWithQueryError,
      this.load
    )
    getTimeSlipsWithQuerySuccess = ( payload, patient_name, user_name ) => {
      let tmpSearchString = `Search results from ${ Moment( this.state.searchParams[ 'start_datetime' ] ).format( 'DD MMM YYYY' ) } `
        + `to ${ Moment( this.state.searchParams[ 'end_datetime' ] ).format( 'DD MMM YYYY' ) } `
        + `${ ( !_.isEmpty( user_name ) || !_.isEmpty( patient_name ) ) ? ', TIME SLIP(S) ' : '' }`
        + `${ !_.isEmpty( user_name ) ? `from Dr. ${ user_name } ` : '' }`
        + `${ !_.isEmpty( patient_name ) ? `to patient, ${ patient_name }` : '' }`
      this.setState({ timeSlips: payload, searchString: tmpSearchString })
    }
    getTimeSlipsWithQueryError = error => this.requestError( error )

    getSelectedTimeSlip = ( time_slip_id, isUpdate ) => Get(
      `/timeslips/${ time_slip_id }`,
      payload => this.getSelectedTimeSlipSuccess( payload, isUpdate ),
      this.getSelectedTimeSlipError,
      this.load
    )
    getSelectedTimeSlipSuccess = ( payload, isUpdate ) => isUpdate 
      ? this.setState({ selectedTimeSlip: payload, showUpdateTimeSlip: true })
      : this.setState({ selectedTimeSlip: payload, showDeleteTimeSlip: true })
    getSelectedTimeSlipError = error => this.requestError( error )

    updateTimeSlip = data => Put(
      `/timeslips/${ data.id }`,
      data,
      this.updateTimeSlipSuccess,
      this.updateTimeSlipError,
      this.load 
    )
    updateTimeSlipSuccess = () => {
      this.getTimeSlipsWithQuery(
        '',
        Moment( new Date() ).startOf( 'day' ).utc().format(),
        Moment( new Date() ).endOf( 'day' ).utc().format(),
        '',
        1
      )
      this.requestSuccess( 'Time slip is successfully updated' )
      this.setState({ 
        showUpdateTimeSlip: false,
        searchParams: {
          patient_id: 0,
          start_datetime: new Date(),
          end_datetime: new Date(),
          user_id: 0
        } 
      }) 
    }
    updateTimeSlipError = error => this.requestError( error )

    removeTimeSlip = ( time_slip_id ) => Delete(
      `/timeslips/${ time_slip_id }`,
      this.removeTimeSlipSuccess,
      this.removeTimeSlipError,
      this.load
    )
    removeTimeSlipSuccess = () => {
      this.getTimeSlipsWithQuery(
        '',
        Moment( new Date() ).startOf( 'day' ).utc().format(),
        Moment( new Date() ).endOf( 'day' ).utc().format(),
        '',
        1
      )
      this.requestSuccess( 'Time slip is successfully removed' )
      this.setState({ 
        showDeleteTimeSlip: false ,
        searchParams: {
          patient_id: 0,
          start_datetime: new Date(),
          end_datetime: new Date(),
          user_id: 0
        }
      }) 
    }
    removeTimeSlipError = error => this.requestError( error )

    downloadTimeSlipPdf = time_slip_id => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/timeslips/${ time_slip_id }/download`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( blobby => {
        this.load( false )
        return FileSaver.saveAs( blobby, `${ Moment().format( 'YYYYMMDDHH:mm' ) }_time_slip.pdf` )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save time slip. Please try again.' )
      })
    }

    render = () => {
      return <WrappedComponent
        { ...this.props }
        onLoadTimeSlips={ this.state.loading }
        onChangeTimeSlipsHOC={ this.onChangeTimeSlipsHOC }
        timeSlips={ this.state.timeSlips }
        newTimeSlip={ this.state.newTimeSlip }
        selectedTimeSlip={ this.state.selectedTimeSlip }
        showCreateTimeSlip={ this.state.showCreateTimeSlip }
        showUpdateTimeSlip={ this.state.showUpdateTimeSlip }
        showDeleteTimeSlip={ this.state.showDeleteTimeSlip }
        createTimeSlip={ this.createTimeSlip }
        getTimeSlipsWithQuery={ this.getTimeSlipsWithQuery }
        getSelectedTimeSlip={ this.getSelectedTimeSlip }
        updateTimeSlip={ this.updateTimeSlip }
        removeTimeSlip={ this.removeTimeSlip }
        searchParams={ this.state.searchParams }
        searchString={ this.state.searchString } 
        downloadTimeSlipPdf={ this.downloadTimeSlipPdf }
      />
    }
  }

  const mapStateToProps = state => ({ data: state })

  return connect( mapStateToProps )( WithHOC )
}

export default HOC