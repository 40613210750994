import firebase from 'firebase'

const config = {
  apiKey: "AIzaSyCEGn86ESS7qFJI1aMFvBfRUlLuhRpJoZ8",
  authDomain: "project-smile-web-firebase.firebaseapp.com",
  databaseURL: "https://project-smile-web-firebase.firebaseio.com",
  projectId: "project-smile-web-firebase",
  storageBucket: "project-smile-web-firebase.appspot.com",
  messagingSenderId: "263709260521",
  appId: "1:263709260521:web:e3dd1bad6c8f70f110ce78",
  measurementId: "G-WJRQ967HRY"
}

firebase.initializeApp( config )

export default firebase