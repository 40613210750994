import React from 'react'
import { FormGroup, Label, Input, Button } from 'reactstrap'
import _ from 'lodash'
import NumberFormat from "react-number-format"
import { Multiselect } from 'react-widgets'

const refundTypes = [
  {
    value: 1,
    name: 'Full refund'
  },
  {
    value: 2,
    name: 'Partial refund'
  }
]

const ListItem = ({ item }) => (
  <span>
    { `${ item.name } - ${ item.nric_no } - ${ item.contact_number }` }
  </span>
)

const PaymentView = ({
  data,
  selectedInvoicePayment,
  selectedCaUser,
  currentRefund,
  refundType,
  updateCurrentRefund,
  updateRefund,
  requestError
}) => {
  return (
    <>
      <FormGroup>
        <Label>Customer name</Label>
        <Input
          type="text"
          value={ selectedInvoicePayment[ 'customer_name' ] }
          disabled />
      </FormGroup>
      <FormGroup>
        <Label>Customer IC</Label>
        <Input
          type="text"
          value={ selectedInvoicePayment[ 'customer_ic' ] }
          disabled />
      </FormGroup>
      <FormGroup>
        <Label>Customer address</Label>
        <Input
          type="text"
          value={ selectedInvoicePayment[ 'customer_address' ] }
          disabled />
      </FormGroup>
      <FormGroup>
        <Label>Remark(for Dr to tell Frontdesk something)</Label>
        <Input
          type="text"
          value={ selectedInvoicePayment.remark }
          disabled />
      </FormGroup>
      <FormGroup>
        <Label>Current paid amount</Label>
        <Input
          type="number"
          value={ selectedInvoicePayment.current_paid_amount }
          disabled />
      </FormGroup>
      <FormGroup>
        <Label>Current cost</Label>
        <Input
          type="number"
          value={ selectedInvoicePayment.current_cost }
          disabled />
      </FormGroup>
      <FormGroup>
        <Label>Change</Label>
        <Input
          type="number"
          value={ selectedInvoicePayment.change }
          disabled={ true } />
      </FormGroup>
      <FormGroup>
        <Label>Pay with</Label>
        <Input
          type="select"
          value={ selectedInvoicePayment.pay_with }
          disabled >
          <option></option>
          <option value={ 'Cash' }>Cash</option>
          <option value={ 'Master' }>Master</option>
          <option value={ 'Visa' }>Visa</option>
          <option value={ 'TT' }>TT</option>
          <option value={ 'eWallet' }>eWallet</option>
          <option value={ 'Credit advance' }>Credit advance</option>
          <option value={ 'Others' }>Others</option>
        </Input>
      </FormGroup>
      {
        selectedInvoicePayment.pay_with === 'Credit advance' && <>
          <FormGroup>
            <Label>Select patient</Label>
            <Multiselect
              disabled
              data={ !_.isEmpty( selectedCaUser ) ? [ selectedCaUser ] : [] }
              textField={ 'name' }
              itemComponent={ ListItem }
              value={ !_.isEmpty( selectedCaUser ) ? [ selectedCaUser ] : [] } />
          </FormGroup>
          {
            selectedCaUser && selectedCaUser.credit_advance && (
              <FormGroup>
                <Label>Selected patient</Label>
                <p>{ `Name: ${ selectedCaUser.name }` }</p>
                <Label><b>Credit advance for selected patient</b></Label>
                {
                  selectedCaUser && selectedCaUser.credit_advance && (
                    <>
                      <p>Before deduction</p>
                      <p>{ `${ selectedCaUser.credit_advance.currency } ${ selectedCaUser.credit_advance.amount }` }</p>
                      <p>After deduction</p>
                      <p>{ `${ selectedCaUser.credit_advance.currency } ${ parseFloat( parseFloat( selectedCaUser.credit_advance.amount ) - selectedInvoicePayment.current_cost ).toFixed( 2 ) }` }</p>
                    </>
                  )
                }
              </FormGroup>
            )
          }
        </>
      }
      <FormGroup>
        <Label>Refund type</Label>
        <Input
          type='select'
          disabled={ 
            [ 'Super Admin', 'Admin' ].indexOf( data.profileReducer.profile.current_user.role.name ) === -1 &&
            refundType === 'selectedRefund'
          }
          value={ currentRefund.type_id }
          onChange={ e => updateCurrentRefund( 'type_id', parseInt( e.target.value ) ) }>
          { refundTypes.map( refundType => <option value={ refundType.value } >{ refundType.name }</option> ) }
        </Input>
      </FormGroup>
      {
        currentRefund.type_id === 2 && <FormGroup>
          <Label>Amount</Label>
          <NumberFormat
            className="form-control"
            disabled={ 
              [ 'Super Admin', 'Admin' ].indexOf( data.profileReducer.profile.current_user.role.name ) === -1 &&
              refundType === 'selectedRefund'
            }
            value={ currentRefund.amount }
            thousandSeparator={ "," }
            decimalSeparator={ "." }
            onValueChange={( values ) => {
              const { value } = values
              updateCurrentRefund( 'amount', parseFloat( value ) )
            }}
            inputMode="numeric"
            displayType="input"
          />
        </FormGroup>
      }
      <FormGroup>
        <Label>Reason</Label>
        <Input
          type='textarea'
          disabled={ 
            [ 'Super Admin', 'Admin' ].indexOf( data.profileReducer.profile.current_user.role.name ) === -1 &&
            refundType === 'selectedRefund'
          }
          value={ currentRefund.reason }
          onChange={ e => updateCurrentRefund( 'reason', e.target.value ) }/>
      </FormGroup>
      {
        refundType === 'selectedRefund' &&
        [ 'Super Admin', 'Admin' ].indexOf( data.profileReducer.profile.current_user.role.name ) > -1 && (
          <FormGroup className='d-flex justify-content-end' >
            <Button
              color='primary'
              onClick={ () => {
                let tmp = _.cloneDeep( currentRefund )
                switch ( currentRefund.type_id ){
                  case 1:
                    tmp.amount = selectedInvoicePayment.current_paid_amount
                    updateRefund( tmp )
                    break
                  case 2:
                    if( currentRefund.amount <= 0 ){
                      requestError({ message: 'Please enter a refund amount' })
                    } else if( parseFloat( currentRefund.amount ) > parseFloat( selectedInvoicePayment.current_paid_amount ) ){
                      requestError({ message: 'Please enter a refund amount smaller or requal to current paid amount' })
                    } else {
                      updateRefund( tmp )
                    }
                    break 
                }
              }}>
              Update
            </Button>
          </FormGroup>
        )
      }
    </>
  )
}

export default PaymentView