import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'
import Moment from 'moment'

import PromptModal from 'components/Indicator/Prompt'

import { Get } from 'utils/axios'
import getDomainURL from 'utils/api'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      invoices: [],
      showInvoices: false
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeInvoicesHOC = ( key, val ) => this.setState({ [ key ]: val })

    getInvoices = ( company_id, start_date, end_date, user_ids ) => Get(
      `/collections/get_invoices_of_company_and_period?company_id=${company_id}&start_date=${start_date}&end_date=${end_date}&user_ids=${ user_ids.length > 0 ? JSON.stringify( user_ids ) : '' }`,
      this.getInvoicesSuccess,
      this.getInvoicesError,
      this.load
    )
    getInvoicesSuccess = payload => {
      this.setState({
        invoices: payload,
        showInvoices: true
      })
    }
    getInvoicesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadInvoices={ this.state.loading }
          invoices={ this.state.invoices }
          showInvoices={ this.state.showInvoices }
          onChangeInvoicesHOC={ this.onChangeInvoicesHOC }
          getInvoices={ this.getInvoices } />
      )
    }
  }
  return WithHOC
}

export default HOC