import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showPatients: false,
      patients: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangePatientsHOC = ( key, val ) => this.setState({ [key]: val })

    getPatients = ( user_id ) => Get(
      `/patients/treated_by_doctor?user_id=${ user_id }`,
      this.getPatientsSuccess,
      this.getPatientsError,
      this.load
    )
    getPatientsSuccess = payload => {
      this.setState({ patients: payload, showPatients: true })
    }
    getPatientsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          onLoadPatients={ this.state.loading }
          showPatients={ this.state.showPatients }
          patients={ this.state.patients }
          onChangePatientsHOC={ this.onChangePatientsHOC }
          getPatients={ this.getPatients } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC