import React, { Component } from 'react'
import { connect } from 'react-redux'
import MetisMenu from 'react-metismenu'
import _ from 'lodash'

import { setEnableMobileMenu } from 'reducers/ThemeOptions'
import { MainNav } from './dataMainNav'
import { RoleAccess } from './role'
import getDomainURL from 'utils/api'

class VerticalNavWrapper extends Component {
  state = {
    nav: []
  }

  componentDidMount = () => {
    let tmpNav = []
    let tmp = _.find( RoleAccess, { role_id: this.props.data.profileReducer.profile.current_user.role_id })
    MainNav.map( item => {
      if( this.props.data.profileReducer.profile.current_user.email === 'ahhieng88@hotmail.com' ) {
        if( item.label === 'Super Admin Collections' || item.label === 'Collections' || item.label === 'Doctor Collections' ) {

        } else {
          tmpNav.push( item )
        }
      } else {
        if( tmp.modules.indexOf( item.label ) > -1 ) {
          tmpNav.push( item )
        }
      }
    })
    this.setState({ nav: tmpNav })
  }

  render = () => {
    return (
      <>
        <br />
        <style>
          { `
            .metismenu-icon-red { color: #ff0000; }
            .metismenu-icon-orange { color: #ff5500; }
            .metismenu-icon-yellow { color: #808000; }
            .metismenu-icon-green { color: #009933; }
            .metismenu-icon-blue { color: #0000ff; }
            .metismenu-icon-violet { color: #4b0082; }
            .metismenu-icon-purple { color: #9400d3; }
          ` }
        </style>
        <MetisMenu
          content={ this.state.nav }
          onSelected={ () => this.props.setEnableMobileMenu( !this.props.enableMobileMenu ) }
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down" />
      </>
    )
  }
}

const mapStateToProps = state => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  data: state
})

export default connect( mapStateToProps, {
  setEnableMobileMenu
})( VerticalNavWrapper )