import React, { Component } from 'react'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
  CustomInput
} from 'reactstrap'
import { FaPlus } from 'react-icons/fa'
import _ from 'lodash'
import Moment from 'moment'
import ReactTable from 'react-table'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PromptModal from 'components/Indicator/Prompt'

import WithPaypal from './actions/index'

class Paypal extends Component {
  state = {
    // showTransactions: false,
    showSubscriptionTransactions: false,
    // showConfirmCancellation: false
  }

  componentDidMount = () => this.props.getSubscriptionTransactions( this.props.selectedCompany.id )
  // componentDidMount = () => this.loadPaypal()

  // loadPaypal = async() => {
  //   this.props.getCompanyReactivationTransactions( this.props.selectedCompany.id )
  //   await this.props.getPaypalToken()
  //   await this.props.getSelectedPaypalSubscription( this.props.selectedCompany.paypal_subscription_id )
  //   if( !_.isEmpty( this.props.selectedPaypalSubscription ) ){
  //     this.props.getPaypalTransactions( this.props.selectedPaypalSubscription.id, this.props.selectedPaypalSubscription.create_time, Moment().utc().format() )
  //   }
  // }

  render = () => {
    return(
      <>
        <Row>
          <Col md={ 8 } >
            <Form>
              {
                !_.isEmpty( this.props.selectedCompany.paypal_subscription_id ) && <>
                  <FormGroup>
                    <Label>Subscription status</Label>
                    <Input
                      type='select'
                      // disabled={ this.props.selectedPaypalSubscription.status === 'CANCELLED' }
                      // value={ this.props.selectedPaypalSubscription.status }
                      value={ this.props.selectedCompany.paypal_subscription_id.indexOf( '-SUSPENDED' ) > -1
                        ? 'SUSPENDED'
                        : 'ACTIVE' }  
                      onChange={ e => {
                        switch( e.target.value ){
                          case 'ACTIVE':
                            this.props.adminUpdatePaypalSubscription( 
                              {
                                payment_status_id: 1,
                                paypal_subscription_id: this.props.selectedCompany.paypal_subscription_id.replace( '-SUSPENDED', '' ),
                                id: this.props.selectedCompany.id
                              },
                              'activate'
                            )
                            break
                          case 'SUSPENDED':
                            this.props.adminUpdatePaypalSubscription( 
                              {
                                payment_status_id: 1,
                                paypal_subscription_id: `${ this.props.selectedCompany.paypal_subscription_id }-SUSPENDED`,
                                id: this.props.selectedCompany.id
                              },
                              'suspend'
                            )
                            break
                          case 'CANCELLED':
                            this.setState({ showConfirmCancellation: true })
                            break
                        }
                      }}>
                      <option value={ 'ACTIVE' } >Active</option>
                      <option value={ 'SUSPENDED' } >Suspended</option>
                      <option value={ 'CANCELLED' } >Cancelled</option>
                    </Input>
                  </FormGroup>
                  {/* <FormGroup>
                    <Label>Outstanding balance</Label>
                    <Input
                      disabled
                      type='text'
                      value={ `RM ${ parseFloat( this.props.selectedPaypalSubscription.billing_info.outstanding_balance.value ).toFixed( 2 ) }` } />
                  </FormGroup> */}
                </>
              }
              <FormGroup>
                <Label>Trial mode</Label>
                <CustomInput
                  id='primeview-trial-mode'
                  type='switch'
                  bsSize={ 'lg' } 
                  checked={ this.props.selectedCompany.payment_status_id === 0 }
                  onChange={ e => e.target.checked 
                    ? this.props.adminUpdatePaypalSubscription( 
                      {
                        payment_status_id: 0,
                        paypal_subscription_id: this.props.selectedCompany.paypal_subscription_id,
                        id: this.props.selectedCompany.id
                      },
                      'suspend'
                    )
                    : this.props.adminUpdatePaypalSubscription( 
                      {
                        payment_status_id: 1,
                        paypal_subscription_id: this.props.selectedCompany.paypal_subscription_id,
                        id: this.props.selectedCompany.id
                      },
                      'activate'
                    )
                  } />
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row>
          {/* <Col className='mb-2' md={ 12 } >
            <Card>
              <CardHeader className="d-flex justify-content-between" >
                <Button
                  color='link'
                  onClick={ () => this.setState({ showTransactions: !this.state.showTransactions }) }>
                  Transactions</Button>
                <Button
                  id='transactions-expand'
                  color='primary'
                  onClick={ () => this.setState({ showTransactions: !this.state.showTransactions }) }>
                  <FaPlus/></Button>
                <UncontrolledTooltip
                  target='transactions-expand'
                  placement="top">
                  Expand</UncontrolledTooltip>
              </CardHeader>
              <Collapse isOpen={ this.state.showTransactions } >
                <CardBody>
                  <ReactTable
                    showPagination
                    defaultPageSize={ 10 }
                    data={ this.props.paypalTransactions }
                    columns={[
                      {
                        Header: 'Date',
                        accessor: 'time',
                        Cell: row => Moment( row.original.time ).format( 'DD MMM YYYY' )
                      },
                      {
                        Header: 'Payer\'s name',
                        accessor: 'payer_name',
                        style: { "line-break": "anywhere" },
                        Cell: row => `${ row.original.payer_name.surname } ${ row.original.payer_name.given_name }`
                      },
                      {
                        Header: 'Payer\'s e-mail',
                        accessor: 'payer_email',
                        style: { "line-break": "anywhere" },
                      },
                      {
                        Header: 'Amount',
                        accessor: 'amount_with_breakdown',
                        Cell: row => `RM ${ row.original.amount_with_breakdown.gross_amount.value }`
                      }
                    ]} />
                </CardBody>
              </Collapse>
            </Card>
          </Col> */}
          <Col md={ 12 } >
            <Card>
              <CardHeader className="d-flex justify-content-between" >
                <Button
                  color='link'
                  onClick={ () => this.setState({ showSubscriptionTransactions: !this.state.showSubscriptionTransactions }) }>
                  Subscription Transactions</Button>
                <Button
                  id='reactivation-transactions-expand'
                  color='primary'
                  onClick={ () => this.setState({ showSubscriptionTransactions: !this.state.showSubscriptionTransactions }) }>
                  <FaPlus/></Button>
                <UncontrolledTooltip
                  target='reactivation-transactions-expand'
                  placement="top">
                  Expand</UncontrolledTooltip>
              </CardHeader>
              <Collapse isOpen={ this.state.showSubscriptionTransactions } >
                <CardBody>
                  <ReactTable
                    showPagination
                    defaultPageSize={ 10 }
                    data={ _.orderBy( this.props.subscriptionTransactions, item => Moment( item.created_at ), [ 'desc' ] ) }
                    columns={[
                      {
                        Header: 'Date',
                        accessor: 'created_at',
                        Cell: row => Moment( row.original.created_at ).format( 'DD MMM YYYY' )
                      },
                      {
                        Header: 'Amount',
                        accessor: 'amount',
                        Cell: row => `RM ${ parseFloat( row.original.amount ).toFixed( 2 ) }`
                      }, 
                      {
                        Header: 'Status',
                        accessor: 'status'
                      } 
                    ]} />
                </CardBody>
              </Collapse>
            </Card>
          </Col>
        </Row>
        <PromptModal
          showPromptModal={ this.state.showConfirmCancellation }
          onClickYes={ () => {
            // this.props.managePaypalSubscription( this.props.selectedPaypalSubscription.id, 'cancel' )
            this.props.adminUpdatePaypalSubscription({
              ...this.props.selectedCompany,
              paypal_subscription_id: ''
            })
            this.setState({ showConfirmCancellation: false })
          }}
          onClickNo={ () => this.setState({ showConfirmCancellation: false }) }
          content='Cancelled subscription cannot be reactivated, are you sure to cancel the Paypal subscription ?' />
        { ( this.props.onLoadPaypalHOC || this.props.onLoadCompanies ) && <LoadingOverlay/> }
      </>
    )
  }
}

export default WithPaypal( Paypal )