import React, { useState } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from 'reactstrap'
import _ from 'lodash'
import { connect } from 'react-redux'
import NumberFormat from "react-number-format"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import CurrencySettings from 'utils/currencies'

const Create = ({
  showCreateMedicine,
  newMedicine,
  onChangeMedicineHOC,
  onLoadMedicines,
  createMedicine,
  data
}) => {

  const onChangeMedicineData = ( key, val ) => {
    let tmp = _.cloneDeep( newMedicine )
    tmp[ key ] = val
    return onChangeMedicineHOC( 'newMedicine', tmp )
  }

  return (
    <Modal
      isOpen={ showCreateMedicine }
      size={ 'xl' }
      toggle={() => {
        onChangeMedicineHOC( 'showCreateMedicine', false )
      }}>
      <ModalHeader toggle={() => onChangeMedicineHOC( 'showCreateMedicine', false )}>Create medicine</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>Name</Label>
            <Input 
              type="text"
              value={ newMedicine.name }
              onChange={ e => {
                onChangeMedicineData( 'name', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>Stocks</Label>
            <Input 
              type="number"
              value={ newMedicine.stocks }
              onChange={ e => {
                onChangeMedicineData( 'stocks', e.target.value )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>{`Price per unit - ${ CurrencySettings() }`}</Label>
            <NumberFormat
              className="form-control"
              value={ newMedicine.price_per_unit }
              thousandSeparator={ "," }
              decimalSeparator={ "." }
              onValueChange={( values ) => {
                const { formattedValue, value } = values;
                onChangeMedicineData( 'price_per_unit', value )
              }}
              inputMode="numeric"
              displayType="input"
            />
          </FormGroup>
        </Form>
        { onLoadMedicines && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={() => {
            createMedicine({ 
              ...newMedicine,
              company_id: data.profileReducer.profile.companies[ 0 ].id
            })
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps )( Create )