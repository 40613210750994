import React, { Component } from 'react'
import { 
  Row, 
  Col,
  Card,
  CardBody,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'

import PageTitle from 'components/Title'
import CreateProduct from './Create'
import UpdateProduct from './Update'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithProducts from './actions'

class Products extends Component {

  componentDidMount = () => this.props.getProducts()

  render = () => {
    return (
      <>
        <PageTitle
          heading="Products"
          subheading="Listings of all the products presence in the system."
          icon="pe-7s-gift icon-gradient bg-happy-itmeo"
          buttons={[
            {  
              color: 'primary',
              onClick: () => this.props.onChangeProductsHOC( 'showCreateProduct', true ),
              content: 'Add product'
            }
          ]} />
        <div>
          <Row>
            <Col md={ 12 }>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    data={ this.props.products }
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'name'
                      },
                      {
                        Header: 'Inventory amount',
                        accessor: 'stocks'
                      },
                      {
                        Header: 'Price',
                        accessor: 'price'
                      },
                      {
                        Header: 'Actions',
                        maxWidth: 120,
                        Cell: ( row ) => (
                          <>
                            <Button
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={() => {
                                this.props.getSelectedProduct( row.original.id )
                              }}>
                              <i className="pe-7s-pen btn-icon-wrapper"> </i>
                            </Button>
                            <Button
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="danger"
                              onClick={() => {
                                Promise.all([
                                  this.props.onChangeProductsHOC( 'toRemoveID', row.original.id )
                                ]).then(() => {
                                  this.props.onChangeProductsHOC( 'showPromptModal', true )
                                })
                              }}>
                              <i className="pe-7s-trash btn-icon-wrapper"> </i>
                            </Button>
                          </>
                        )
                      }
                    ]}
                    defaultPageSize={ 10 } />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <CreateProduct { ...this.props } />
        <UpdateProduct { ...this.props } />
        { this.props.onLoadProducts && <LoadingOverlay /> }
      </>
    )
  }
}

export default WithProducts( Products )