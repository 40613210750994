import React, { Component } from 'react'
import { 
  Row, 
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import ReactTable from 'react-table'
import { compose } from 'redux'
import Moment from 'moment'
import _ from 'lodash'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PromptModal from 'components/Indicator/Prompt'
import Pagination from 'components/Pagination'
import SearchForm from './SearchForm'
import TimeSlipForm from './components/timeSlipForm'

import WithTimeSlips from './actions'
import WithPatients from './actions/Patients'
import WithDoctors from 'actions/dictionary/doctors'
import WithCompanies from 'actions/dictionary/companies'
import WithBranches from 'actions/dictionary/branches'

class TimeSlip extends Component {
  componentDidMount = () => {
    this.props.getCompanies()
    this.props.getBranches()
    this.props.getDoctors( this.props.data.profileReducer.profile.current_user.branch_ids )
    this.props.getTimeSlipsWithQuery(
      _.find( this.props.patients, { id: this.props.searchParams[ 'patient_id' ] } ) ? _.find( this.props.patients, { id: this.props.searchParams[ 'patient_id' ] } ).name : '',
      Moment( this.props.searchParams[ 'start_datetime' ] ).startOf( 'day' ).utc().format(),
      Moment( this.props.searchParams[ 'end_datetime' ] ).endOf( 'day' ).utc().format(),
      _.find( this.props.doctors, { id: this.props.searchParams[ 'user_id' ] } ) ? _.find( this.props.doctors, { id: this.props.searchParams[ 'user_id' ] } ).name : '',
      1
    )
  }

  componentDidUpdate = pp => {
    if( pp.showCreateTimeSlip && !this.props.showCreateTimeSlip ){
      this.props.onChangeTimeSlipsHOC( 'newTimeSlip', {
        patient_id: 0,
        start_time: Moment().format( 'HH:mm' ),
        end_time: Moment().add( 1, 'hours' ).format( 'HH:mm' ),
        date: new Date(),
        remark: '',
        user_id: 0,
        company_id: 0,
        branch_id: 0
      })
    }

    if( pp.showUpdateTimeSlip && !this.props.showUpdateTimeSlip ){
      this.props.onChangeTimeSlipsHOC( 'selectedTimeSlip', {} )
    }

    if( _.isEmpty( pp.selectedTimeSlip ) && !_.isEmpty( this.props.selectedTimeSlip ) ){
      let tmp = _.cloneDeep( this.props.selectedTimeSlip )
      tmp.date = Moment( tmp.start_datetime ).toDate()
      tmp.start_time = Moment( tmp.start_datetime ).format( 'HH:mm' )
      tmp.end_time = Moment( tmp.end_datetime ).format( 'HH:mm' )
      delete tmp.start_datetime
      delete tmp.end_datetime

      this.props.onChangeTimeSlipsHOC( 'selectedTimeSlip', tmp )
    }
  }

  renderCreateTimeSlip = () => (
    <Modal isOpen={ this.props.showCreateTimeSlip } size='xl' >
      <ModalHeader toggle={ () => this.props.onChangeTimeSlipsHOC( 'showCreateTimeSlip', false ) }>
        Create Time Slip</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={ 8 } >
            <TimeSlipForm 
              currentTimeSlip={ this.props.newTimeSlip }
              timeSlipType='newTimeSlip'
              onChangeTimeSlipsHOC={ this.props.onChangeTimeSlipsHOC }
              doctors={ this.props.doctors }
              patients={ this.props.patients }
              getPatients={ this.props.getPatients }
              onChangePatientsHOC={ this.props.onChangePatientsHOC }
              onLoadPatientsHOC={ this.props.onLoadPatientsHOC }
              patientSearchParam={ this.props.patientSearchParam }
              branches={ this.props.branches }
              companies={ this.props.companies } />
          </Col>
        </Row>
        { this.props.onLoadTimeSlips && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let tmp = _.cloneDeep( this.props.newTimeSlip )
            let tmpStartTime = tmp.start_time.split( ':' ) 
            let tmpEndTime = tmp.end_time.split( ':' )

            tmp.start_datetime = new Date( tmp.date )
            tmp.end_datetime = new Date( tmp.date )
            tmp.start_datetime.setHours( tmpStartTime[ 0 ] )
            tmp.start_datetime.setMinutes( tmpStartTime[ 1 ] )
            tmp.end_datetime.setHours( tmpEndTime[ 0 ] )
            tmp.end_datetime.setMinutes( tmpEndTime[ 1 ] )
            tmp.start_datetime = Moment( tmp.start_datetime ).utc().format()
            tmp.end_datetime = Moment( tmp.end_datetime ).utc().format()
            delete tmp.date
            delete tmp.start_time
            delete tmp.end_time
            
            this.props.createTimeSlip( tmp )
          }}>
          Submit</Button>
      </ModalFooter>
    </Modal>
  )

  renderUpdateTimeSlip = () => (
    <Modal isOpen={ this.props.showUpdateTimeSlip } size='xl' >
      <ModalHeader toggle={ () => this.props.onChangeTimeSlipsHOC( 'showUpdateTimeSlip', false ) }>
        Update Time Slip</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={ 8 } >
            {
              !_.isEmpty( this.props.selectedTimeSlip.end_time ) &&  <TimeSlipForm 
                currentTimeSlip={ this.props.selectedTimeSlip }
                timeSlipType='selectedTimeSlip'
                onChangeTimeSlipsHOC={ this.props.onChangeTimeSlipsHOC }
                doctors={ this.props.doctors }
                patients={ this.props.patients }
                getPatients={ this.props.getPatients }
                onChangePatientsHOC={ this.props.onChangePatientsHOC }
                onLoadPatientsHOC={ this.props.onLoadPatientsHOC }
                patientSearchParam={ this.props.patientSearchParam }
                branches={ this.props.branches }
                companies={ this.props.companies } />
            }
          </Col>
        </Row>
        { this.props.onLoadTimeSlips && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let tmp = _.cloneDeep( this.props.selectedTimeSlip )
            let tmpStartTime = tmp.start_time.split( ':' ) 
            let tmpEndTime = tmp.end_time.split( ':' )

            tmp.start_datetime = new Date( tmp.date )
            tmp.end_datetime = new Date( tmp.date )
            tmp.start_datetime.setHours( tmpStartTime[ 0 ] )
            tmp.start_datetime.setMinutes( tmpStartTime[ 1 ] )
            tmp.end_datetime.setHours( tmpEndTime[ 0 ] )
            tmp.end_datetime.setMinutes( tmpEndTime[ 1 ] )
            tmp.start_datetime = Moment( tmp.start_datetime ).utc().format()
            tmp.end_datetime = Moment( tmp.end_datetime ).utc().format()
            delete tmp.date
            delete tmp.start_time
            delete tmp.end_time
            
            this.props.updateTimeSlip( tmp )
          }}>
          Submit</Button>
      </ModalFooter>
    </Modal>
  )

  render = () => {
    return(
      <>
        <PageTitle
          heading="Time slips"
          subheading="Listings of all the time slips."
          icon="pe-7s-clock icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'primary',
              onClick: () => this.props.onChangeTimeSlipsHOC( 'showCreateTimeSlip', true ),
              content: 'Create time slip'
            }
          ]} />
        <SearchForm { ...this.props } />
        <Card className="main-card mb-3">
          <CardHeader>{ this.props.searchString }</CardHeader>
          <CardBody>
            <ReactTable
              showPagination={ false }
              pageSize={ 10 }
              data={ this.props.timeSlips.data }
              columns={[
                {
                  Header: 'Doctor',
                  accessor: 'doctor_name',
                },
                {
                  Header: 'Date',
                  accessor: 'start_datetime',
                  Cell: row => Moment( row.original.start_datetime ).format( 'D MMM YYYY' )
                },
                {
                  Header: 'Time',
                  accessor: 'start_datetime',
                  Cell: row => <span>{ Moment( row.original.start_datetime ).format( 'h:mm a' ) } - { Moment( row.original.end_datetime ).format( 'h:mm a' ) }</span>
                },
                {
                  Header: 'Patient',
                  accessor: 'patient_name'
                },
                {
                  Header: 'Actions',
                  accessor: 'id',
                  style: { "justify-content": "center" },
                  Cell: row => <>
                    <Button 
                      color='primary'
                      className='mr-2'
                      onClick={ () => {
                        this.props.getSelectedTimeSlip( row.original.id, true )
                        this.props.getPatients( row.original.patient_name )  
                      }} >
                      <i className='pe-7s-pen'/></Button>
                    <Button 
                      color='primary'
                      className='mr-2'
                      onClick={ () => this.props.downloadTimeSlipPdf( row.original.id ) } >
                      <i className='pe-7s-download'/></Button>
                    <Button 
                      color='danger'
                      onClick={ () => this.props.getSelectedTimeSlip( row.original.id, false ) } >
                      <i className='pe-7s-trash' /></Button>
                  </>
                }
              ]} 
            />
            { 
              this.props.timeSlips.pagy && <Pagination 
                metadata={ this.props.timeSlips.pagy } 
                onChangePage={ pg => this.props.getTimeSlipsWithQuery(
                  _.find( this.props.patients, { id: this.props.searchParams[ 'patient_id' ] } ) ? _.find( this.props.patients, { id: this.props.searchParams[ 'patient_id' ] } ).name : '',
                  Moment( this.props.searchParams[ 'start_datetime' ] ).startOf( 'day' ).utc().format(),
                  Moment( this.props.searchParams[ 'end_datetime' ] ).endOf( 'day' ).utc().format(),
                  _.find( this.props.doctors, { id: this.props.searchParams[ 'user_id' ] } ) ? _.find( this.props.doctors, { id: this.props.searchParams[ 'user_id' ] } ).name : '',
                  pg
                )} 
              />
            }
          </CardBody>
        </Card>
        <PromptModal
          showPromptModal={ this.props.showDeleteTimeSlip }
          onClickYes={() => this.props.removeTimeSlip( this.props.selectedTimeSlip.id )}
          onClickNo={() => this.props.onChangeTimeSlipsHOC({ showDeleteTimeSlip: false })}
          content={ 'Are you sure you want to delete the record?' } />
        { this.renderCreateTimeSlip() }
        { this.renderUpdateTimeSlip() }
        { ( this.props.onLoadTimeSlips || this.props.onLoadDoctors ) && <LoadingOverlay/> }
      </>
    )
  }
}

export default compose(
  WithTimeSlips,
  WithPatients,
  WithDoctors,
  WithCompanies,
  WithBranches
)( TimeSlip )