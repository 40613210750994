import React from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup,
  Label, Card, Button, 
  Row, Col,
  Input, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap'
// import { PayPalButton } from 'react-paypal-button-v2'
import PaypalButton from 'react-paypal-express-checkout'
import Cookies from 'js-cookie'
import { purgeStoredState } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import _ from 'lodash'

import CurrencySettings from 'utils/currencies'

const SubscriptionForm = ({
  history,
  showSubscriptionModal,
  onChangePaypalHOC,
  paypalPlans,
  selectedPaypalPlan,
  nonSubscribingCompanies,
  selectedNonSubscribingCompany,
  paypalError,
  updateCompanySubscription,
  createCompanySubscriptionPayment
}) => {
  const onClickSignOut = () => {
    purgeStoredState({
      key: 'project-smile-web',
      storage
    }).then(() => {
      Cookies.remove( 'PRIMEVIEW_WEB_TOKEN' )
      history.push( '/' )
    })
  }

  return(
    <Modal isOpen={ showSubscriptionModal } size='xl' >
      <ModalHeader 
        close={ <Button className="btn-pill btn-shadow btn-shine" color="focus" onClick = { () => onClickSignOut() }>Logout</Button> }>
        Subscribe to PrimeView </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Row>
              <Col md={ 8 } >
                <Label className='w-100' >Select a company</Label>
                <Input 
                  type='select'
                  value={ selectedNonSubscribingCompany.id } 
                  onChange={ e => onChangePaypalHOC( 
                    'selectedNonSubscribingCompany', 
                    _.find( nonSubscribingCompanies, item => item.id === parseInt( e.target.value ) ) 
                  ) }>
                  { nonSubscribingCompanies.map( item => <option id={ item.id } value={ item.id } >{ item.name }</option> ) }
                </Input>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={ 12 } >
                <Label className='w-100' >Select a plan :</Label>
              </Col>
              {
                paypalPlans.length > 0 
                && paypalPlans[ 0 ].billing_cycles
                && selectedPaypalPlan 
                && paypalPlans.map( plan => {
                  return(
                    <Col lg={ 4 } md={ 6 } sm={ 12 }>
                      <Card 
                        className='card-shadow-primary border-primary d-flex align-items-center p-3'
                        style={{ borderTop: "4px solid #ffffff" }} >
                        <Label
                          className='text-center font-weight-bold'
                          style={{ 
                            color: "#545cd8", 
                            fontSize:"24px",
                            maxWidth: "100%",
                            marginTop: "1rem",
                            marginBottom: "1.5rem" }} >
                          { plan.name }</Label>
                        <Label
                          className='text-center font-weight-bold'
                          style={{ color: "#545cd8", fontSize:"24px", marginBottom: ".2rem" }} >
                          <span
                            className='mr-2' 
                            style={{ color: "#000000", fontSize:'12px' }} >RM</span>
                          { `${ parseFloat( plan.billing_cycles[ 0 ].pricing_scheme.fixed_price.value ).toFixed( 2 ) }` }</Label>
                        <Label
                          className='text-center font-weight-bold'
                          style={{ color: "#545cd8", fontSize:"12px", marginBottom: "1.5rem" }} >
                          { `per ${ plan.billing_cycles[ 0 ].frequency.interval_unit.toLowerCase() }` }</Label>
                        <Row className='w-100 justify-content-center' >
                          <span style={{ width: "100%", height: "1px", background: "#dedede", marginBottom: "1.5rem" }} />
                        </Row>
                        <Row className='justify-content-center w-100 p-3 text-justify' >
                          <Label
                            className='font-weight-bold'
                            style={{ 
                              color: "#545cd8", 
                              fontSize:"12px",
                              maxWidth: "100%",
                              marginBottom: "1.5rem", }} >
                            { plan.description }</Label>
                        </Row>
                        <Button
                          color={ selectedPaypalPlan.id === plan.id ? 'success' : 'primary'}
                          style={{ width: "100%", marginBottom: "1rem" }}
                          onClick={ () => onChangePaypalHOC( 'selectedPaypalPlan', { ...plan, cycle: 1 } ) } >
                          { selectedPaypalPlan.id === plan.id ? 'Selected' : 'Select' }  
                          </Button>
                        {
                          selectedPaypalPlan.id === plan.id && <InputGroup 
                            className='d-flex align-items-center'
                            style={{ background: "#fff" }}>
                            <Input
                              type='number'
                              min={ 1 }
                              step='1'
                              placeholder='Pay for...'
                              value={ selectedPaypalPlan.cycle }
                              onChange={ e => onChangePaypalHOC( 'selectedPaypalPlan', { ...plan, cycle: e.target.value } ) }/>
                            <InputGroupAddon addonType="append">
                              <InputGroupText style={{ background: "#fff" }}>
                                { plan.billing_cycles[ 0 ].frequency.interval_unit.toLowerCase() + '(s)' }
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        }
                      </Card>
                    </Col>
                  )
                })
              }
            </Row>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        {
          selectedPaypalPlan.billing_cycles && <PaypalButton
            env={ window.location.origin.indexOf( 'staging' ) > -1 ? 'sandbox' : 'production' }
            client={{ 
              sandbox: 'ATqD6ihXN2A5GLAN3jWwT4IesV3DH9ljf_4tZMPEZechIbnq085nhzTY3wSwJ8OHsVVelKWL6XeS9MJl',
              production: 'AZhibMEJnhe8TP8-bfG7ZNpUq_GhCHu0PJiA4G1Owg5mZdre_QIzn9fK7nn-3v1QmBd6reiOYB7HwYoM' }}
            currency={ CurrencySettings() }
            style={{
              shape: 'rect',
              color: 'gold'
            }}
            total={ selectedPaypalPlan.billing_cycles[ 0 ].pricing_scheme.fixed_price.value * selectedPaypalPlan.cycle }
            onError={ error => paypalError( error ) }
            onSuccess={ payment => {
              if( payment.paid ){
                Promise.all([
                  createCompanySubscriptionPayment({
                    status_id: 1,
                    amount: selectedPaypalPlan.billing_cycles[ 0 ].pricing_scheme.fixed_price.value * selectedPaypalPlan.cycle,
                    company_id: selectedNonSubscribingCompany.id,
                    cycle: parseInt( selectedPaypalPlan.cycle )
                  }),
                  updateCompanySubscription({
                    payment_status_id: 1,
                    paypal_subscription_id: selectedPaypalPlan.id,
                    id: selectedNonSubscribingCompany.id
                  }) 
                ]).then( () => {
                  window.location.reload() 
                })
              }
            }} />
        }
        {/* <PayPalButton
          currency='MYR'
          createSubscription={ ( data, actions ) => actions.subscription.create({ plan_id: selectedPaypalPlan.id }) }
          onApprove={ ( data, actions ) => {
            return actions.subscription.get().then( details => {
              if( details.status === 'ACTIVE' ){
                return updateCompanySubscription({
                  payment_status_id: 1,
                  paypal_subscription_id: details.id,
                  id: selectedNonSubscribingCompany.id
                }) 
              }
            })
          }}
          onError={ err => paypalError( `Subscription failed with error, ${ err }` ) }
          catchError={ err => paypalError( `Subscription failed with error, ${ err }` ) }
          onCancel={ err => paypalError( `Subscription failed with error, ${ err }` ) }
          options={{
            clientId: 'AZhibMEJnhe8TP8-bfG7ZNpUq_GhCHu0PJiA4G1Owg5mZdre_QIzn9fK7nn-3v1QmBd6reiOYB7HwYoM',
            vault:true }}
          style={{
            shape: 'rect',
            color: 'gold',
            layout: 'horizontal',
            label: 'subscribe' }} /> */}
      </ModalFooter>
    </Modal>
  )
}

export default SubscriptionForm