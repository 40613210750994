import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      dashboardInfoToday: {
        total_appointments: 0,
        appointments: [],
        outstanding_invoice: []
      },
      dashboardOccupiedSize: {},
      selectedCompanyId: 0
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangeDashboardHOC = ( key, val ) => this.setState({ [ key ] : val })

    getDashboardInfoToday = company_id => Get(
      `/dashboard/today?company_id=${ company_id }`,
      this.getDashboardInfoTodaySuccess,
      this.getDashboardInfoTodayError,
      this.load
    )
    getDashboardInfoTodaySuccess = payload => this.setState({ 
      dashboardInfoToday: {
        ...payload,
        appointments: payload.appointments.sort(( a,b ) => {
          let dateA = new Date(a.start_datetime);
          let dateB = new Date(b.start_datetime);
          return dateA - dateB;
        })
      }
    })
    getDashboardInfoTodayError = error => this.requestError( error )

    getDashboardOccupiedSize = company_id => Get(
      `/dashboard/occupied_size?company_id=${ company_id }`,
      this.getDashboardOccupiedSizeSuccess,
      this.getDashboardOccupiedSizeError,
      this.load
    )
    getDashboardOccupiedSizeSuccess = payload => this.setState({ dashboardOccupiedSize: payload })
    getDashboardOccupiedSizeError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadDashboardHOC={ this.state.loading }
          onChangeDashboardHOC={ this.onChangeDashboardHOC }
          dashboardInfoToday={ this.state.dashboardInfoToday }
          getDashboardInfoToday={ this.getDashboardInfoToday }
          dashboardOccupiedSize={ this.state.dashboardOccupiedSize }
          getDashboardOccupiedSize={ this.getDashboardOccupiedSize }
          selectedCompanyId={ this.state.selectedCompanyId } />
      )
    }
  }

  return WithHOC
}

export default HOC