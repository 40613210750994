import React, { useEffect, useState } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Table
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'

const Create = ({
  newInvoice,
  showCreateInvoice,
  onChangeInvoiceHOC,
  data,
  createInvoice
}) => {

  const onChangeInvoiceData = ( key, val ) => {
    let tmp = _.cloneDeep( newInvoice )
    tmp[ key ] = val
    return onChangeInvoiceHOC( 'newInvoice', tmp )
  }

  return (
    <Modal
      isOpen={ showCreateInvoice }
      size={ 'lg' }
      toggle={() => {
        onChangeInvoiceHOC( 'showCreateInvoice', false )
      }}>
      <ModalHeader toggle={() => onChangeInvoiceHOC( 'showCreateInvoice', false )}>Create invoice</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>Select company</Label>
            <Input
              type="select"
              value={ newInvoice.company_id }
              onChange={ e => {
                onChangeInvoiceData( 'company_id', e.target.value )
              }}>
              <option></option>
              {
                data.profileReducer.profile.companies.map( item => {
                  return (
                    <option key={ item.id } value={ item.id }>{ item.name }</option>
                  )
                })
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Select branch</Label>
            <Input
              type="select"
              value={ newInvoice.branch_id }
              onChange={ e => {
                onChangeInvoiceData( 'branch_id', e.target.value )
              }}>
              <option></option>
              {
                data.profileReducer.profile.branches.map( item => {
                  return (
                    <option key={ item.id } value={ item.id }>{ item.name }</option>
                  )
                })
              }
            </Input>
          </FormGroup>
          {/* <FormGroup>
            <input 
              type="checkbox"
              checked={ newInvoice.is_reload_credit_advance }
              onChange={ e => {
                onChangeInvoiceData( 'is_reload_credit_advance', e.target.checked )
              }} /> { ' ' }
            Is patient reloading credit advance? 
          </FormGroup> */}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          className="mr-2"
          onClick={() => {
            createInvoice( newInvoice )
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Create