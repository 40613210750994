import React, { Component } from 'react'
import { toast } from 'react-toastify'
import Axios from 'axios'

import { Put } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  const clientId = 'AZhibMEJnhe8TP8-bfG7ZNpUq_GhCHu0PJiA4G1Owg5mZdre_QIzn9fK7nn-3v1QmBd6reiOYB7HwYoM'
  const clientSecret = 'ECinPkTkgBEjcRFOQdFQEgTeAVgFbRDHuCiE4coxcpO4HHuKFDVn9U4WuBeCyrZT6v7OREpc2nk1QqH_'
  // const sanboxClientId = 'ATqD6ihXN2A5GLAN3jWwT4IesV3DH9ljf_4tZMPEZechIbnq085nhzTY3wSwJ8OHsVVelKWL6XeS9MJl'
  // const sanboxClientSecret = 'EJY_VztWKeslLgAGsZc9_LEIcSOMcY0EwhviKvCeI4vfmu2L_tQPdP2u0p-Vy_pAZPplOkqCiK4etUVO'

  class WithHOC extends Component {
    state = {
      loading: false,
      paypalToken: '',
      selectedPaypalSubscription: {},
      paypalTransactions: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangePaypalHOC = ( key, val ) => this.setState({ [ key ]: val })

    // getPaypalToken = () => {
    //   this.setState({ loading: true })
    //   let config = {
    //     auth: {
    //       'username': clientId,
    //       'password': clientSecret },
    //     headers: {
    //       'Accept': 'application/json',
    //       'Accept-Language': 'en_US',
    //       'content-type': 'application/x-www-form-urlencoded' }
    //   }
    //   return Axios.post( 'https://api.paypal.com/v1/oauth2/token?grant_type=client_credentials', {}, config ).then( res => {
    //     this.getPaypalTokenSuccess( res.data )
    //     this.setState({ loading: false })
    //   }).catch( err => {
    //     console.dir( err )
    //     if( err ){
    //       this.getPaypalTokenError( err.response.data )
    //     } else {
    //       this.getPaypalTokenError( 'Fail to get token' )
    //     }
    //     this.setState({ loading: false })
    //   })
    // }
    // getPaypalTokenSuccess = payload => this.setState({ paypalToken: payload.access_token })
    // getPaypalTokenError = error => this.requestError( error )

    // getSelectedPaypalSubscription = subscription_id => {
    //   this.setState({ loading: true })
    //   let config = {
    //     headers: {
    //       'content-type': 'application/json',
    //       'Authorization': `Bearer ${ this.state.paypalToken }`
    //     }
    //   }
    //   return Axios.get( `https://api-m.paypal.com/v1/billing/subscriptions/${ subscription_id }`, config ).then( res => {
    //     this.getSelectedPaypalSubscriptionSuccess( res.data )
    //     this.setState({ loading: false })
    //   }).catch( err => {
    //     console.dir( err )
    //     if( err && err.response ){
    //       this.getSelectedPaypalSubscriptionError( err.response.data )
    //     } else {
    //       this.getSelectedPaypalSubscriptionError( 'Failed to get subscription info' )
    //     }
    //     this.setState({ loading: false })
    //   })
    // }
    // getSelectedPaypalSubscriptionSuccess = payload => this.setState({ selectedPaypalSubscription: payload })
    // getSelectedPaypalSubscriptionError = error => {}

    // getPaypalTransactions = ( subscription_id, start_datetime, end_datetime ) => {
    //   this.setState({ loading: true })
    //   let config = {
    //     headers: {
    //       'content-type': 'application/json',
    //       'Authorization': `Bearer ${ this.state.paypalToken }`
    //     }
    //   }
    //   return Axios.get( 
    //     `https://api-m.paypal.com/v1/billing/subscriptions/${ subscription_id }/transactions?start_time=${  start_datetime }&end_time=${ end_datetime }`, 
    //     config 
    //   ).then( res => {
    //     this.getPaypalTransactionsSuccess( res.data )
    //     this.setState({ loading: false })
    //   }).catch( err => {
    //     if( err & err.response ){
    //       this.getPaypalTransactionsError( err.response.data )
    //     } else {
    //       this.getPaypalTransactionsError( 'Failed to get Paypal transactions' )
    //     }
    //     this.setState({ loading: false })
    //   })
    // }
    // getPaypalTransactionsSuccess = payload => this.setState({ paypalTransactions: payload.transactions })
    // getPaypalTransactionsError = error => {}

    // managePaypalSubscription = ( subscription_id, action ) => {
    //   this.setState({ loading: true })
    //   let config = {
    //     headers: {
    //       'content-type': 'application/json',
    //       'Authorization': `Bearer ${ this.state.paypalToken }`
    //     }
    //   }
    //   return Axios.post(
    //     `https://api-m.paypal.com/v1/billing/subscriptions/${ subscription_id }/${ action }`,
    //     {},
    //     config
    //   ).then( res => {
    //     this.managePaypalSubscriptionSuccess( subscription_id )
    //   }).catch( err => {
    //     if( err && err.response ){
    //       this.managePaypalSubscriptionError( err.response.data )
    //     } else {
    //       this.managePaypalSubscriptionError( 'Failed to' )
    //     }
    //     this.setState({ loading: false })
    //   })
    // }
    // managePaypalSubscriptionSuccess = subscription_id => {
    //   this.getSelectedPaypalSubscription( subscription_id )
    //   this.requestSuccess( 'Paypal subscription is successfully updated' )
    // }
    // managePaypalSubscriptionError = () => {}

    adminUpdatePaypalSubscription = ( data, action ) => Put(
      `/companies/${ data.id }/update_payment_detail`,
      data,
      () => this.adminUpdatePaypalSubscriptionSuccess( data, action ),
      this.adminUpdatePaypalSubscriptionError,
      this.load
    )
    adminUpdatePaypalSubscriptionSuccess = ( data, action ) => { 
      this.props.getSelectedCompany( data.id )
      // this.managePaypalSubscription( data.paypal_subscription_id, action )
    }
    adminUpdatePaypalSubscriptionError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent 
            { ...this.props }
            onLoadPaypalHOC={ this.state.loading }
            onChangePaypalHOC={ this.onChangePaypalHOC }
            // getPaypalToken={ this.getPaypalToken }
            // selectedPaypalSubscription={ this.state.selectedPaypalSubscription }
            // getSelectedPaypalSubscription={ this.getSelectedPaypalSubscription }
            // paypalTransactions={ this.state.paypalTransactions }
            // getPaypalTransactions={ this.getPaypalTransactions }
            // managePaypalSubscription={ this.managePaypalSubscription }
            adminUpdatePaypalSubscription={ this.adminUpdatePaypalSubscription } />
        </>
      )
    }
  }
  return WithHOC
}

export default HOC