import React, { Component } from 'react'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'
import { connect } from 'react-redux'

import { Get, Post, Put } from 'utils/axios'
import getDomainURL from 'utils/api'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      isNewInvoice: false,
      newInvoice: {
        company_id: 0,
        user_id: 0, //doctor id
        total_amount: 0,
        medical_record_id: 0,
        invoice_products_attributes: [],
        invoice_medicines_attributes: [],
        invoice_treatments_attributes: [],
        branch_id: 0,
        patient_id: 0,
        lab_cost: 0
      },
      selectedInvoice: {
        id: '',
        company_id: 0,
        user_id: 0, //doctor id
        total_amount: 0,
        medical_record_id: 0,
        invoice_products: [],
        invoice_medicines: [],
        invoice_treatments: [],
        credit_advances_invoices: [],
        branch_id: 0,
        patient_id: 0,
        lab_cost: 0
      },
      invoices: [],
      amountDue: 0,
      showDownloadInvoice: false,
      selectedPayments: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeInvoiceHOC = ( key, val ) => this.setState({ [key]: val })

    getSelectedInvoice = id => Get(
      `/invoices/${ id }`,
      this.getSelectedInvoiceSuccess,
      this.getSelectedInvoiceError,
      this.load
    )
    getSelectedInvoiceSuccess = payload => {
      this.setState({ selectedInvoice: payload }, () => {
        let tmpTotalAmount = 0
        payload.invoice_treatments.map( item => {
          tmpTotalAmount = tmpTotalAmount + parseFloat( item.formated_price ? item.formated_price.replaceAll( ',', '' ) : 0 )
        }) 
        payload.invoice_medicines.map( item => {
          tmpTotalAmount = tmpTotalAmount + ( parseFloat( item.quantity ) * parseFloat( item.price_per_unit ) )
        })
        payload.invoice_products.map( item => {
          tmpTotalAmount = tmpTotalAmount + ( item.quantity * parseFloat( item.price_per_unit ) )
        })
        this.updateTotalAmount({
          id: this.state.selectedInvoice.id,
          total_amount: tmpTotalAmount
        })
      })
    }
    getSelectedInvoiceError = error => this.requestError( error )

    createInvoice = data => Post(
      `/invoices`,
      data,
      this.createInvoiceSuccess,
      this.createInvoiceError,
      this.load
    )
    createInvoiceSuccess = payload => {
      this.requestSuccess( 'Invoice is created successfully.' )
      this.getSelectedInvoice( payload.id )
      this.props.getSelectedMedicalRecord( this.props.selectedMedicalRecord.id )
    }
    createInvoiceError = error => this.requestError( error.message[ 0 ] )

    updateInvoice = data => Put(
      `/invoices/${ data.id }`,
      data,
      this.updateInvoiceSuccess,
      this.updateInvoiceError,
      this.load
    )
    updateInvoiceSuccess = payload => {
      this.requestSuccess( 'Invoice is updated successfully.' )
      this.getSelectedInvoice( payload.id )
    }
    updateInvoiceError = error => this.requestError( error )

    updateTotalAmount = data => Put(
      `/invoices/${ data.id }/update_total_amount`,
      data,
      this.updateTotalAmountSuccess,
      this.updateTotalAmountError,
      this.load
    )
    updateTotalAmountSuccess = payload => {
      this.requestSuccess( 'Invoice total amount is updated successfully.' )
      this.setState({
        selectedInvoice: payload
      }, () => {
        let tmpAmountDue = this.state.selectedInvoice.total_amount
        payload.payments.map( item => {
          tmpAmountDue = tmpAmountDue - parseFloat( item.current_cost )
        })
        this.setState({ amountDue: tmpAmountDue })
      })
    }
    updateTotalAmountError = error => this.requestError( 'Failed to update invoice total amount.' )

    downloadPDF = invoice_id => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/invoices/${ invoice_id }/download`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( blobby => {
        this.load( false )
        this.getSelectedInvoice( invoice_id )
        return FileSaver.saveAs( blobby, 'Receipt.pdf' )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save receipt. Please try again.' )
      })
    }

    downloadPaymentsPDF = ( invoice_id, payment_ids ) => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/invoices/${ invoice_id }/download?payment_ids=${ JSON.stringify( payment_ids ) }`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( blobby => {
        this.load( false )
        this.getSelectedInvoice( invoice_id )
        return FileSaver.saveAs( blobby, 'Receipt.pdf' )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save receipt. Please try again.' )
      })
    }

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          onChangeInvoiceHOC={ this.onChangeInvoiceHOC }
          getSelectedInvoice={ this.getSelectedInvoice }
          createInvoice={ this.createInvoice }
          updateInvoice={ this.updateInvoice }
          updateTotalAmount={ this.updateTotalAmount }
          downloadPDF={ this.downloadPDF }
          onLoadInvoices={ this.state.loading }
          isNewInvoice={ this.state.isNewInvoice }
          newInvoice={ this.state.newInvoice }
          selectedInvoice={ this.state.selectedInvoice }
          invoices={ this.state.invoices }
          amountDue={ this.state.amountDue }
          showDownloadInvoice={ this.state.showDownloadInvoice }
          selectedPayments={ this.state.selectedPayments }
          downloadPaymentsPDF={ this.downloadPaymentsPDF } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC