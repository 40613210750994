import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Post, Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      countries: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getCountries = () => Post(
      `/users/get_country`,
      {},
      this.getCountriesSuccess,
      this.getCountriesError,
      this.load
    )
    getCountriesSuccess = payload => this.setState({ countries: payload }, () => {
      if( payload && payload.length > 0 ) {
        let tmp = []
        payload.map( item => {
          tmp.push({
            ...item,
            label: item.code,
            value: item.name,
            id: item.code
          })
        })
        this.setState({ countries: tmp })
      }
    })
    getCountriesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          getCountries={ this.getCountries }
          onLoadCountries={ this.state.loading }
          countries={ this.state.countries } />
      )
    }
  }
  return WithHOC
}

export default HOC