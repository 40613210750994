import React, { useEffect, useState } from 'react'
import { 
  Form, FormGroup,
  Input, Label, CustomInput,
  Row, Col
} from 'reactstrap'
import { Multiselect } from 'react-widgets'
import _ from 'lodash'
import { compose } from 'redux'
import Moment from 'moment'

import Typeahead from 'components/Typeahead'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import UserIcon from 'assets/Images/av1.png'

import WithCountries from 'actions/dictionary/countries'
import WithStates from 'actions/dictionary/states'
import WithCities from 'actions/dictionary/cities'
import WithCompanies from 'actions/dictionary/companies'

import moment from 'moment'

const ListItem = ({ item }) => (
  <span>
    { `${ item.name }` }
  </span>
)

const General = ({
  selectedPatient,
  onChangePatientData,
  onLoadCountries,
  countries,
  getCountries,
  onLoadStates,
  states,
  getStates,
  onLoadCities,
  cities,
  getCities,
  companies,
  onLoadCompanies,
  getCompanies,
  uploadPhoto,
  onChangePatientsHOC,
  panels
}) => {
  const [ selectedCompanies, updateSelectedCompanies ] = useState( [] )
  const [ photo, updatePhoto ] = useState( {} )

  useEffect(() => {
    getCountries()
    getStates( selectedPatient.country )
    getCities( selectedPatient.country, selectedPatient.state )
    getCompanies()
  }, [] )

  useEffect(() => {
    if( companies.length > 0 ) {
      let tmp = []
      selectedPatient.company_ids.map( item => {
        let tmpCompany = _.find( companies, { id: item } )
        tmp.push( tmpCompany )
      })
      updateSelectedCompanies( tmp )
    }
  }, [ companies ])

  useEffect(() => {
    if( !_.isEmpty( photo ) ) {
      uploadPhoto( photo )
    }
  }, [ photo ])

  return (
    <Form onSubmit={ e => e.preventDefault()}>
      <Row>
        <Col md={ 8 }>
          <Row>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Name</Label>
                <Input 
                  type="text"
                  value={ selectedPatient.name }
                  onChange={ e => {
                    onChangePatientData( 'name', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>IC/Passport</Label>
                <Input 
                  type="text"
                  value={ selectedPatient.nric_no }
                  onChange={ e => {
                    onChangePatientData( 'nric_no', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Email</Label>
                <Input 
                  type="email"
                  value={ selectedPatient.email }
                  onChange={ e => {
                    onChangePatientData( 'email', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>DOB</Label>
                <input
                  className="form-control"
                  type="date"
                  value={ selectedPatient.date_of_birth ? Moment( selectedPatient.date_of_birth ).format( 'YYYY-MM-DD' ) : '' }
                  onChange={ e => {
                    let tmpAge = 0 ;
                    let today = moment( new Date() ).get( 'year' )
                    let DOB = moment( e.target.value ).get( 'year' )
                    tmpAge= today - DOB 
                    let tmp = _.cloneDeep( selectedPatient )
                    tmp[ 'date_of_birth' ] = e.target.value
                    tmp[ 'age' ] = tmpAge
                    onChangePatientsHOC( 'selectedPatient', tmp )}} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Age</Label>
                <Input
                  type="number"
                  value={ selectedPatient.age }
                  disabled={ true } />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Gender</Label>
                <Input
                  type="select"
                  value={ selectedPatient.gender }
                  onChange={ e => {
                    onChangePatientData( 'gender', e.target.value )
                  }}>
                  <option></option>
                  <option value={ 'Male' }>Male</option>
                  <option value={ 'Female' }>Female</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <CustomInput
                  id='isVaccinated'
                  type='checkbox'
                  label='Is vaccinated'
                  checked={ selectedPatient.is_vaccinated }
                  onChange={ e => onChangePatientData( 'is_vaccinated', e.target.checked ) } />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Parent/Guardian name</Label>
                <Input 
                  type="text"
                  value={ selectedPatient.guardian_name }
                  onChange={ e => {
                    onChangePatientData( 'guardian_name', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Parent/Guardian contact</Label>
                <Input 
                  type="text"
                  value={ selectedPatient.guardian_contact }
                  onChange={ e => {
                    onChangePatientData( 'guardian_contact', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <Label>Address</Label>
                <Input
                  type="text"
                  value={ selectedPatient.address }
                  onChange={ e => {
                    onChangePatientData( 'address', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Country</Label>
                <Typeahead
                  id={ 'country' }
                  multiple={ false }
                  options={ countries }
                  onSelect={ val => {
                    if( val && val.length > 0 ) {
                      onChangePatientData( 'country', val[ 0 ].code )
                      getStates( val[ 0 ].code )
                    } else {
                      onChangePatientData( 'country', '' )
                    }
                  }}
                  selectedValue={ _.find( countries, { id: selectedPatient.country }) ? [ _.find( countries, { id: selectedPatient.country }) ] : [] } />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Postcode</Label>
                <Input
                  type="text"
                  value={ selectedPatient.postcode }
                  onChange={ e => {
                    onChangePatientData( 'postcode', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>State</Label>
                <Input
                  type="text"
                  value={ selectedPatient.state }
                  onChange={ e => {
                    onChangePatientData( 'state', e.target.value )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>City</Label>
                <Input
                  type="text"
                  value={ selectedPatient.city }
                  onChange={ e => {
                    onChangePatientData( 'city', e.target.value )
                  }}/>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Language</Label>
                <Input
                  type="select"
                  value={ selectedPatient.language }
                  onChange={ e => {
                    onChangePatientData( 'language', e.target.value )
                  }}>
                  <option></option>
                  <option value={ 2 }>Mandarin</option>
                  <option value={ 1 }>English</option>
                  <option value={ 3 }>Malay</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>Contact number(Please put country code, eg for Malaysia +60123456789 in front of contact number for whatsapp use - appointment notification)</Label>
                <Input 
                  type="text"
                  value={ selectedPatient.contact_number }
                  onChange={ e => {
                    onChangePatientData( 'contact_number', e.target.value )
                  }} />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md={ 4 }>
          <img 
            src={ selectedPatient.photo_url ? selectedPatient.photo_url : UserIcon } 
            style={{ width: '60%' }} />
          <input 
            type="file" 
            accept="image/*" 
            className="mt-2"
            onChange={ e => {
              const reader = new FileReader()
              reader.onload = () => {
                updatePhoto({
                  id: selectedPatient.id,
                  photo: reader.result
                })
              }
              reader.readAsDataURL( e.target.files[ 0 ] )
            }} />
          <FormGroup className="pt-3">
            <Label>Status</Label>
            <Input 
              type="select"
              value={ selectedPatient.status }
              onChange={ e => {
                onChangePatientData( 'status', e.target.value )
              }}>
              <option value={ 'active' }>Active</option>
              <option value={ 'inactive' }>Inactive</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Company/Clinic</Label>
            <Multiselect
              data={ companies }
              textField={ 'name' }
              itemComponent={ ListItem }
              onChange={ val => {
                let tmp = []
                let tmpSelectedCompanies = []
                val.map( item => {
                  let tmpCompany = _.find( companies, { id: item.id } )
                  tmp.push( item.id )
                  tmpSelectedCompanies.push( tmpCompany )
                })
                updateSelectedCompanies( tmpSelectedCompanies )
                onChangePatientData( 'company_ids', tmp )
              }}
              value={ selectedCompanies } />
          </FormGroup>
          <FormGroup>
            <Label>Panel</Label>
            <Input
              type='select'
              value={ selectedPatient.panel_id }
              onChange={ e => onChangePatientData( 'panel_id', parseInt( e.target.value ) ) }>
              <option value={ 0 } ></option>
              { panels.map( item => <option value={ item.id } >{ item.panel_name }</option> ) }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Upload file from ic reader</Label>
            <Input
              type="file"
              onChange={ e => {
                let file = e.target.files[ 0 ]
                const reader = new FileReader()
                reader.addEventListener('load', (event) => {
                  let tmp = JSON.parse( event.target.result )
                  let tmpSelectedPatient = _.cloneDeep( selectedPatient )
                  tmpSelectedPatient[ 'name' ] = tmp[ 'name' ]
                  tmpSelectedPatient[ 'nric_no' ] = tmp[ 'IC' ]
                  tmpSelectedPatient[ 'date_of_birth' ] = tmp[ 'dob' ]
                  tmpSelectedPatient[ 'gender' ] = tmp[ 'gender' ] === 'L' ? '1' : '2'
                  tmpSelectedPatient[ 'address' ] = tmp[ 'add1' ] + ' ' + tmp[ 'add2' ] + ' ' + tmp[ 'add3' ]
                  tmpSelectedPatient[ 'country' ] = 'MY'
                  tmpSelectedPatient[ 'state' ] = tmp[ 'state' ]
                  tmpSelectedPatient[ 'city' ] = tmp[ 'city' ]
                  tmpSelectedPatient[ 'postcode' ] = tmp[ 'postcode' ]
                  tmpSelectedPatient['age' ] = Moment().diff( Moment( tmp[ 'dob' ] ), 'years', false )
                  updatePhoto({
                    id: selectedPatient.id,
                    photo: 'data:image/png;base64,' + tmp[ 'fullImage' ],
                    fromIcReader: true,
                    selectedPatient: tmpSelectedPatient
                  })
                });
                reader.readAsText( file )
              }} />
          </FormGroup>
        </Col>
      </Row>
      { ( onLoadCountries || onLoadStates || onLoadCities || onLoadCompanies ) && <LoadingOverlay /> }
    </Form>
  )
}

export default compose(
  WithCountries,
  WithStates,
  WithCities,
  WithCompanies
)( General )