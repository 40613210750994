import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    assignBranch = id => Post(
      `/users/assign_branch`,
      {
        user_id: this.props.selectedUser.id,
        branch_id: id
      },
      this.assignBranchSuccess,
      this.assignBranchError,
      this.load
    )
    assignBranchSuccess = payload => {
      this.requestSuccess( 'Branch is assigned successfully.' )
      this.props.getSelectedUser( this.props.selectedUser.id )
    }
    assignBranchError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {}
    }

    unassignBranch = id => Delete(
      `/users/remove_assigned_branch?user_id=${ this.props.selectedUser.id }&branch_id=${ id }`,
      this.unassignBranchSuccess,
      this.unassignBranchError,
      this.load
    )
    unassignBranchSuccess = payload => {
      this.requestSuccess( 'Branch is removed successfully.' )
      this.props.getSelectedUser( this.props.selectedUser.id )
    }
    unassignBranchError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {}
    }
    
    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          onLoadBranches={ this.state.loading }
          assignBranch={ this.assignBranch }
          unassignBranch={ this.unassignBranch } />
      )
    }
  }
  return WithHOC
}

export default HOC