import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { connect } from 'react-redux'

import { Post } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newAppointmentTreatmentRecord: {
        "appointment": {
          "start_datetime": "",
          "end_datetime": "",
          "user_id": "",
          "patient_id": "",
          "status": "",
          "details": "",
          "is_incomplete_treatment": false,
          "company_id": "",
          "branch_id": "",
          "created_by": ""
        },
        "medical_record": {
          "created_at": "",
          "complaints": "",
          "observations": "",
          "notes": "",
          "company_id": "",
          "branch_id": "",
          "user_id": ""
        }
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeAppointmentHOC = ( key, val ) => this.setState({ [key]: val })

    createAppointmentTreatmentRecord = data => Post(
      `/medical_records/customized_create`,
      data,
      this.createAppointmentTreatmentRecordSuccess,
      this.createAppointmentTreatmentRecordError,
      this.load
    )
    createAppointmentTreatmentRecordSuccess = payload => {
      this.requestSuccess( 'Appointment treatment record is created successfully.' )
      this.setState({
        newAppointmentTreatmentRecord: {
          "appointment": {
            "start_datetime": "",
            "end_datetime": "",
            "user_id": "",
            "patient_id": "",
            "status": "",
            "details": "",
            "is_incomplete_treatment": false,
            "company_id": "",
            "branch_id": "",
            "created_by": ""
          },
          "medical_record": {
            "created_at": "",
            "complaints": "",
            "observations": "",
            "notes": "",
            "company_id": "",
            "branch_id": "",
            "user_id": ""
          }
        }
      })
    }
    createAppointmentTreatmentRecordError = error => {
      this.requestError( 'Failed to create appointment treatment record.' )
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadAppointments={ this.state.loading }
          newAppointmentTreatmentRecord={ this.state.newAppointmentTreatmentRecord }
          onChangeAppointmentHOC={ this.onChangeAppointmentHOC }
          createAppointmentTreatmentRecord={ this.createAppointmentTreatmentRecord } /> 
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC