import Axios from 'axios'
import Cookies from 'js-cookie'

import { AUTH_USER } from './type'
import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess
} from './ajax'
import { storeItem } from 'utils/token'
import getDomainURL from 'utils/api'

export const authUser = ({
  email, password
}) => dispatch => {
  dispatch( beginAjaxCall() )
  Axios.post(
    `${ getDomainURL() }/authenticate`, {
      email, 
      password
    }
  ).then( response => {
    Cookies.set( 'PRIMEVIEW_WEB_TOKEN', response.data.auth_token )
    dispatch( ajaxCallSuccess( 'Login Success!' ) )
  }).catch( error => {
    dispatch( ajaxCallError( error.response.data.message ) );
  })
}

const authUserSuccess = payload => ({
  type: AUTH_USER,
  payload
})