import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      companies: [],
      loading: false,
      selectedCompany: {
        branches: []
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getCompanies = () => Get(
      `/companies`,
      this.getCompaniesSuccess,
      this.getCompaniesError,
      this.load
    )
    getCompaniesSuccess = payload => this.setState({ companies: payload })
    getCompaniesError = error => this.requestError( error )

    getSelectedCompany = id => Get(
      `/companies/${ id }`,
      this.getSelectedCompanySuccess,
      this.getSelectedCompanyError,
      this.load
    )
    getSelectedCompanySuccess = payload => {
      this.setState({ selectedCompany: payload })
    }
    getSelectedCompanyError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          getCompanies={ this.getCompanies }
          getSelectedCompany={ this.getSelectedCompany }
          onLoadCompanies={ this.state.loading }
          companies={ this.state.companies }
          selectedCompany={ this.state.selectedCompany } />
      )
    }
  }
  return WithHOC
}

export default HOC