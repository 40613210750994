import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'
import Moment from 'moment'

import { Get } from 'utils/axios'
import getDomainURL from 'utils/api'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      medicineCollections: [],
      searchParams: [
        {
          key: 'start_date',
          value: new Date()
        },
        {
          key: 'end_date',
          value: new Date()
        },
        {
          key: 'company_id',
          value: this.props.data.profileReducer.profile.current_user.company_ids.length > 0 ? this.props.data.profileReducer.profile.current_user.company_ids[0] : 0
        }
      ],
      selectedCompanyId: this.props.data.profileReducer.profile.current_user.company_ids.length > 0 ? this.props.data.profileReducer.profile.current_user.company_ids[0] : 0,
      selectedBranchID: this.props.data.profileReducer.profile.current_user.branch_ids.length > 0 ? this.props.data.profileReducer.profile.current_user.branch_ids[0] : 0
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangeMedicineCollectionsHOC = ( key, val ) => this.setState({ [ key ] : val })

    getMedicineCollections = ( company_id, start_date, end_date ) => Get(
      `/collections/medicines_collection?company_id=${ company_id }&start_date=${ start_date }&end_date=${ end_date }${ this.state.selectedBranchID !== 0 ? `&branch_id=${ this.state.selectedBranchID }` : '' }`,
      this.getMedicineCollectionsSuccess,
      this.getMedicineCollectionsError,
      this.load
    )
    getMedicineCollectionsSuccess = payload => this.setState({ medicineCollections: payload })
    getMedicineCollectionsError = error => this.requestError( 'Failed to get medicine collections.' )

    exportMedicineCollectionsReport = data => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/collections/download_medicines_collection?company_id=${ data.company_id }&start_date=${ data.start_date }&end_date=${ data.end_date }${ this.state.selectedBranchID !== 0 ? `&branch_id=${ this.state.selectedBranchID }` : '' }`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( blobby => {
        this.load( false )
        this.setState({ showCompanySelection: false })
        return FileSaver.saveAs( blobby, `${ Moment().format( 'YYYYMMDDHH:mm' ) }_collections.pdf` )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save receipt. Please try again.' )
      })
    }
    
    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadMedicineCollections={ this.state.loading }
          searchParams={ this.state.searchParams }
          medicineCollections={ this.state.medicineCollections }
          onChangeMedicineCollectionsHOC={ this.onChangeMedicineCollectionsHOC }
          getMedicineCollections={ this.getMedicineCollections }
          exportMedicineCollectionsReport={ this.exportMedicineCollectionsReport }
          selectedCompanyId={ this.state.selectedCompanyId }
          selectedBranchID={ this.state.selectedBranchID } />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect ( mapStateToProps )( WithHOC )
}

export default HOC