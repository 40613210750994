import React, { useState } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from 'reactstrap'
import _ from 'lodash'
import Tabs from 'react-responsive-tabs'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

import Branches from './Branches'
import Users from './Users'
import Paypal from './Paypal'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"
registerPlugin( FilePondPluginFileValidateType, FilePondPluginFileValidateSize )

const Update = ({
  data,
  showUpdateCompany,
  selectedCompany,
  onChangeCompaniesHOC,
  updateCompany,
  getSelectedCompany,
  onLoadCompanies,
  subscriptionTransactions,
  getSubscriptionTransactions,
  updateCompanyPaymentDetail,
  updateCompanyLogo
}) => {
  const [ currentTab, updateCurrentTab ] = useState( 0 )

  const TabsConfig = {
    activeTab: 0,
    showMore: true,
    transform: true,
    showInkBar: true,
    items: data.profileReducer.profile.current_user.role_id === 1 
      ? [
        { title: 'General' },
        { title: 'Branches' },
        { title: 'Users' },
        { title: 'Paypal' }
      ] : [
        { title: 'General' },
        { title: 'Branches' },
        { title: 'Users' }
      ],
    selectedTabKey: 0,
    transformWidth: 400,
  }

  const onChangeCompanyData = ( key, val ) => {
    let tmp = _.cloneDeep( selectedCompany )
    tmp[ key ] = val
    return onChangeCompaniesHOC( 'selectedCompany', tmp )
  }

  return (
    <Modal
      isOpen={ showUpdateCompany }
      size={ 'xl' }
      toggle={() => {
        onChangeCompaniesHOC( 'showUpdateCompany', false )
      }}>
      <ModalHeader toggle={() => onChangeCompaniesHOC( 'showUpdateCompany', false )}>Update company</ModalHeader>
      <ModalBody>
        <Tabs
          tabsWrapperClass="body-tabs"
          { 
            ...TabsConfig
          }
          selectedTabKey={ currentTab }
          onChange={ val => updateCurrentTab( val ) } />
        {
          currentTab === 0 && (
            <Form onSubmit={ e => e.preventDefault()}>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type="text"
                  value={ selectedCompany.name }
                  onChange={ e => {
                    onChangeCompanyData( 'name', e.target.value )
                  }} />
              </FormGroup>
              <FormGroup>
                <Label>Registration number</Label>
                <Input
                  type="text"
                  value={ selectedCompany.registration_number }
                  onChange={ e => {
                    onChangeCompanyData( 'registration_number', e.target.value )
                  }} />
              </FormGroup>
              <FormGroup>
                <Label>Invoice Prefix</Label>
                <Input
                  value={ selectedCompany.invoice_prefix }
                  disabled />
              </FormGroup>
              <FormGroup>
                <Label>Logo</Label>
                {
                  !_.isEmpty( selectedCompany.logo_url ) && <div className='col-md-6 col-sm-12 p-0 mb-2' >
                    <Input 
                      disabled
                      type={ 'text' } 
                      value={ selectedCompany.logo_url } 
                      style={{ marginRight: '5px', borderRadius: "0.25rem 0.25rem 0 0" }} />
                    <div 
                      style={{
                        display: 'inline-block', 
                        overflow: 'hidden', 
                        width: '100%', 
                        height: '250px',
                        background: "#fff",
                        backgroundImage: `url(${ selectedCompany.logo_url })`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        borderRadius: '0 0 0.25rem 0.25rem',
                        border: '1.7px solid #ced4da',
                        borderTop: '0'
                      }} 
                    />
                  </div>
                }
                <FilePond
                  files={ [] }
                  labelIdle='Click here to update company logo'
                  allowMultiple={ false }
                  allowFileTypeValidation={ true }
                  allowFileSizeValidation={ true }
                  maxFileSize={ '1MB' }
                  acceptedFileTypes={[ 'image/png', 'image/jpeg', 'image/jpg' ]}
                  onupdatefiles={ fileItems => {
                    fileItems.map( fileItem => {
                      if( fileItem.fileSize <= 1000000 ){
                        const reader = new FileReader()
                        reader.onload = e => updateCompanyLogo( 
                          selectedCompany.id, 
                          { logo: e.target.result }
                        )
                        reader.readAsDataURL( fileItem.file )
                      }
                    })
                  }} 
                />
              </FormGroup>
            </Form> 
          )
        }
        {
          currentTab === 1 && <Branches 
            data={ data }
            getSelectedCompany={ getSelectedCompany } 
            selectedCompany={ selectedCompany } />
        }
        {
          currentTab === 2 && <Users selectedCompany={ selectedCompany } />
        }
        {
          currentTab === 3 && <Paypal 
            selectedCompany={ selectedCompany } 
            subscriptionTransactions={ subscriptionTransactions }
            getSelectedCompany={ getSelectedCompany }
            getSubscriptionTransactions={ getSubscriptionTransactions }
            updateCompanyPaymentDetail={ updateCompanyPaymentDetail }
            onLoadCompanies={ onLoadCompanies } />
        }
        { onLoadCompanies && <LoadingOverlay /> }
      </ModalBody>
      {
        currentTab === 0 && (
          <ModalFooter>
            <Button 
              color="primary"
              onClick={() => {
                updateCompany( selectedCompany )
              }}>
              Submit
            </Button>
          </ModalFooter>
        )
      }
    </Modal>
  )
}

export default Update