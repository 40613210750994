import React, { useState } from 'react'
import { 
  Card, CardHeader, CardBody,
  FormGroup, Label, Button
} from 'reactstrap'
import ModalConfirmDelete from './ConfirmDelete'

const Settings = props => {
  const [ showConfirmDelete, updateShowConfirmDelete ] = useState( false )
  return (
    <div>
      <Card outline color="danger">
        <CardHeader>Danger Zone</CardHeader>
        <CardBody>
          <FormGroup>
            <div className="row">
              <div className="col-md-7">
                <Label style={{ fontSize: '18px', fontWeight: '600' }}>Delete Patient</Label>
                <p className="mb-0">Once you removed a patient, there is no going back.</p>
              </div>
              <div className="col-md-5 d-flex">
                <Button
                  color="danger"
                  onClick={() => {
                    updateShowConfirmDelete( true )
                  }}>Delete Patient</Button>
              </div>
            </div>
          </FormGroup>
        </CardBody>
      </Card>
      <ModalConfirmDelete 
        { ...props } 
        showConfirmDelete={ showConfirmDelete }
        updateShowConfirmDelete={ updateShowConfirmDelete } />
    </div>
  )
}

export default Settings