import React, { useEffect, useState } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Table,
  CustomInput
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'
import NumberFormat from "react-number-format"
import { toast, ToastContainer } from 'react-toastify'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import NewPaymentModal from './Payments/New'
import UpdatePaymentModal from './Payments/Update'
import ViewPayment from './Payments/View'
import PromptModal from 'components/Indicator/Prompt'
import Typeahead from 'components/Typeahead'
import { priceWithSeparators } from 'utils/priceWithSeparators'

const Update = ( props ) => {
  let {
    showUpdateInvoice,
    selectedInvoice,
    onChangeInvoiceHOC,
    companies,
    branches,
    treatments,
    medicines,
    products,
    payments,
    downloadPDF,
    newProduct,
    onChangeProductsHOC,
    addInvoiceProduct,
    amountDue,
    selectedPayments,
    downloadPaymentsPDF,
    getPayments
  } = props

  const onChangeInvoiceData = ( key, val ) => {
    let tmp = _.cloneDeep( selectedInvoice )
    tmp[ key ] = val
    return onChangeInvoiceHOC( 'selectedInvoice', tmp )
  }

  return (
    <Modal
      isOpen={ showUpdateInvoice }
      size={ 'lg' }
      toggle={() => {
        onChangeInvoiceHOC( 'showUpdateInvoice', false )
      }}>
      <ModalHeader toggle={() => onChangeInvoiceHOC( 'showUpdateInvoice', false )}>Update invoice</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>Select company</Label>
            <Input
              type="select"
              value={ selectedInvoice.company_id }
              onChange={ e => {
                let tmp = _.cloneDeep( selectedInvoice )
                tmp[ 'company_id' ] = e.target.value
                onChangeInvoiceHOC( 'selectedInvoice', tmp )
              }}
              disabled={ false }>
              <option>Select a company</option>
              {
                props.data.profileReducer.profile.companies.map( item => {
                  return (
                    <option key={ item.id } value={ item.id }>{ item.name }</option>
                  )
                })
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Select a branch</Label>
            <Input
              type="select"
              value={ selectedInvoice.branch_id }
              onChange={ e => {
                let tmp = _.cloneDeep( selectedInvoice )
                tmp[ 'branch_id' ] = e.target.value
                onChangeInvoiceHOC( 'selectedInvoice', tmp )
              }}>
              <option></option>
              {
                props.data.profileReducer.profile.branches.map( item => {
                  return (
                    <option key={ item.id } value={ item.id }>{ item.name }</option>
                  )
                })
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Receipt number</Label>
            <Input
              type="text"
              value={ selectedInvoice.invoice_number }
              disabled={ true } />
          </FormGroup>
          <FormGroup>
            <input 
              type="checkbox"
              checked={ selectedInvoice.is_reload_credit_advance }
              onChange={ e => {
                // let tmp = _.cloneDeep( selectedInvoice )
                // tmp[ 'is_reload_credit_advance' ] = e.target.checked
                // onChangeInvoiceHOC( 'selectedInvoice', tmp )
              }} /> { ' ' }
            Is patient reloading credit advance? 
          </FormGroup>
          <FormGroup>
            <Button 
              color="primary" 
              onClick={() => {
                props.updateInvoice( props.selectedInvoice )
              }}>Update invoice</Button>
          </FormGroup>
          {
            !selectedInvoice.is_reload_credit_advance && (
              <>
                <FormGroup>
                  <p><b className="mb-3">Selected treatment(s):</b></p>
                  <Table>
                    <thead>
                      <tr>
                        <td>Name</td>
                        <td>Price</td>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      selectedInvoice.invoice_treatments.map( item => {
                        return (
                          <tr key={ `Treatment-${ item.id }` }>
                            <td>{ !_.isEmpty( item.name ) ? item.name : item.treatment_name }</td>
                            <td>{ priceWithSeparators( item.formated_price ) }</td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </Table>
                </FormGroup>
                <FormGroup>
                  <p><b>Selected medicine(s):</b></p>
                  <Table>
                    <thead>
                      <tr>
                        <td>Name</td>
                        <td>Quantity</td>
                        <td>Price per unit</td>
                        <td>Total price</td>
                        <td>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      selectedInvoice.invoice_medicines.map(( item, index ) => {
                        let tmp = _.find( medicines, { id: item.medicine_id } )
                        return (
                          <tr key={ `Medicine-${ item.id }` }>
                            <td>{ item.medicine_name }</td>
                            <td>{ item.quantity }</td>
                            <td>{ priceWithSeparators( item.price_per_unit ) }</td>
                            <td>{ priceWithSeparators( item.price_per_unit * item.quantity ) }</td>
                            <td>
                              {/* <Button 
                                color="danger"
                                onClick={() => {
                                  let tmpRes = parseFloat( selectedInvoice.total_amount ) - ( item.quantity * parseFloat( tmp.price_per_unit ) )
                                  removeInvoiceMedicine( selectedInvoice.id, item.medicine_id, tmpRes )
                                }}>X</Button> */}
                            </td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </Table>
                </FormGroup>
                <FormGroup>
                  <Row className='d-flex align-items-end' >
                    <Col md={ 5 }>
                      <Label>Products</Label>
                      <Typeahead
                        id={ 'invoiceMenuProduct' }
                        multiple={ false }
                        options={ products.map( product => ({ 
                          id: product.id.toString(),
                          label: product.id.toString(),
                          value: product.name
                        }) )
                        }
                        onSelect={ val => {
                          let tmp = _.cloneDeep( newProduct )
                          tmp[ 'product_id' ] = val && val.length > 0 ? parseInt( val[ 0 ].id ) : 0
                          onChangeProductsHOC( 'newProduct', tmp )
                        }}
                        selectedValue={ _.find( products, { id: newProduct.product_id } ) 
                          ? [{
                            id: _.find( products, { id: newProduct.product_id } ).id.toString(),
                            label: _.find( products, { id: newProduct.product_id } ).id.toString(),
                            value: _.find( products, { id: newProduct.product_id } ).name
                          }] : [] 
                        } />
                    </Col>
                    <Col md={ 2 }>
                      <Label>Quantity</Label>
                      <Input 
                        type="number" 
                        placeholder="Quantity"
                        value={ newProduct.quantity }
                        onChange={ e => {
                          let tmp = _.cloneDeep( newProduct )
                          tmp[ 'quantity' ] = e.target.value
                          onChangeProductsHOC( 'newProduct', tmp )
                        }} />
                    </Col>
                    <Col md={ 2 }>
                      <Label>Price per unit</Label>
                      <NumberFormat
                        className="form-control"
                        value={ newProduct.price_per_unit }
                        thousandSeparator={ "," }
                        decimalSeparator={ "." }
                        onValueChange={( values ) => {
                          const { value } = values
                          let tmp = _.cloneDeep( newProduct )
                          tmp[ 'price_per_unit' ] = value
                          onChangeProductsHOC( 'newProduct', tmp )
                        }}
                        inputMode="numeric"
                        displayType="input"
                      />
                    </Col>
                    <Col md={ 3 }>
                      <Button 
                        color="primary" 
                        className="mt-1"
                        onClick={() => {
                          // let tmp = _.find( products, { id: newProduct.product_id })
                          let tmpRes = parseFloat( selectedInvoice.total_amount ) + ( newProduct.quantity * parseFloat( newProduct.price_per_unit ) )
                          addInvoiceProduct({ 
                            ...newProduct,
                            invoice_id: selectedInvoice.id
                          }, tmpRes )
                        }}>Add product</Button>
                    </Col>
                  </Row>
                  <br />
                  <p><b>Selected product(s):</b></p>
                  <Table>
                    <thead>
                      <tr>
                        <td>Name</td>
                        <td>Quantity</td>
                        <td>Price</td>
                        <td>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      selectedInvoice.invoice_products.map( item => {
                        return (
                          <tr key={ `InvoiceProduct-${ item.id }` }>
                            <td>{ item.product_name }</td>
                            <td>{ item.quantity }</td>
                            <td>{ priceWithSeparators( item.price_per_unit ) }</td>
                            <td>
                              <Button 
                                color="danger"
                                className="mr-2"
                                onClick={() => {
                                  let tmpRes = parseFloat( selectedInvoice.total_amount ) - ( item.quantity * parseFloat( item.price_per_unit ) )
                                  props.removeInvoiceProduct( selectedInvoice.id, item.product_id, tmpRes )
                                }}>X</Button>
                            </td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </Table>
                </FormGroup>
              </>
            )
          }
          <hr />
          <FormGroup>
            <Row>
              <Col md={ 6 }>
                <span><b>Lab costs:</b></span>
              </Col>
              <Col md={ 3 }>
                <NumberFormat
                  className="form-control"
                  value={ selectedInvoice.lab_cost }
                  thousandSeparator={ "," }
                  decimalSeparator={ "." }
                  onValueChange={( values ) => {
                    const { value } = values
                    let tmp = _.cloneDeep( selectedInvoice )
                    tmp[ 'lab_cost' ] = value
                    onChangeInvoiceHOC( 'selectedInvoice', tmp )
                  }}
                  inputMode="numeric"
                  displayType="input"
                />
              </Col>
              <Col md={ 3 }>
                <Button 
                  color="primary" 
                  onClick={() => {
                    props.updateInvoice( props.selectedInvoice )
                  }}>Update invoice</Button>
              </Col>
            </Row>
          </FormGroup>
          <hr />
          <FormGroup>
            <p><b>Payment history</b></p>
            <Table>
              <thead>
                <tr>
                  <td>Transaction date</td>
                  <td>Pay with</td>
                  <td>Amount</td>
                  <td>Cost</td>
                  <td>Change</td>
                  <td>Refunded amount</td>
                  <td className={ props.data.profileReducer.profile.current_user.role_id === 4 ? 'd-flex justify-content-center' : '' } >
                    Action</td>
                  <td>Select payment(s)</td>
                </tr>
              </thead>
              <tbody>
                {
                  payments.map( item => {
                    return (
                      <tr key={ item.id }>
                        <td>{ Moment( item.created_at ).format( 'DD MMM YYYY HH:mm' ) }</td>
                        <td>{ item.pay_with }</td>
                        <td>{ priceWithSeparators( item.current_paid_amount ) }</td>
                        <td>{ priceWithSeparators( item.current_cost ) }</td>
                        <td>{ priceWithSeparators( item.change ) }</td>
                        <td>{ priceWithSeparators( item.amount_refunded ) }</td>
                        <td>
                          {
                            ( item.is_editable || props.data.profileReducer.profile.current_user.role.name === 'Admin' ) && (
                              <Button 
                                color="primary"
                                className={ props.data.profileReducer.profile.current_user.role_id === 4 ? "ml-2 mr-2": "" }
                                onClick={() => {
                                  props.getSelectedPayment( selectedInvoice.id, item.id, true )
                                }}
                                disabled={ !item.is_editable && props.data.profileReducer.profile.current_user.role.name !== 'Admin' }>
                                <i className="pe-7s-pen"></i>
                              </Button>
                            )
                          }
                          {
                            (
                              props.data.profileReducer.profile.current_user.role.name === 'Super Admin' ||
                              props.data.profileReducer.profile.current_user.role.name === 'Admin'
                            ) && <Button 
                              color="danger"
                              onClick={() => {
                                props.getSelectedPayment( item.invoice_id, item.id, false )
                              }}>
                              <i className="pe-7s-trash"></i>
                            </Button>
                          }
                          <Button 
                            color="primary"
                            className={ props.data.profileReducer.profile.current_user.role_id === 4 ? "ml-2 mr-2": "" }
                            onClick={() => {
                              props.getSelectedPaymentView( item )
                            }}>
                            <i className="pe-7s-look"></i>
                          </Button>
                        </td>
                        <td className='d-flex justify-content-center'>
                          <CustomInput
                            id={ `multiPayment${ item.id }` }
                            type='checkbox'
                            checked={ _.findIndex( selectedPayments, payment => payment === item.id ) > -1 }
                            onChange={ () => {
                              let tmp = _.cloneDeep( selectedPayments )
                              let tmpIndex = _.findIndex( selectedPayments, payment => payment === item.id )
                              if( tmpIndex > -1 ){
                                tmp.splice( tmpIndex, 1 )
                              } else {
                                tmp.push( item.id )
                              }
                              onChangeInvoiceHOC( 'selectedPayments', tmp ) }} />
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={ 6 }>
                <span>Total amount</span>
              </Col>
              <Col md={ 3 }></Col>
              <Col md={ 3 }>
                <Input
                  type="text"
                  value={ priceWithSeparators( selectedInvoice.total_amount ) }
                  disabled={ true } />
              </Col>
            </Row>
          </FormGroup>
          <hr />
          <FormGroup>
            <Row>
              <Col md={ 12 }>
                <hr />
                <Button
                  color="primary"
                  onClick={() => {
                    props.onChangePaymentHOC( 'showNewPayment', true )
                  }}>
                  Create payment
                </Button>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col md={ 6 }>
                <b>Amount due</b>
              </Col>
              <Col md={ 3 }></Col>
              <Col md={ 3 }>
                <Input
                  type="text"
                  value={ priceWithSeparators( amountDue ) }
                  disabled={ true } />
              </Col>
            </Row>
          </FormGroup>
        </Form>
        <NewPaymentModal { ...props } />
        <UpdatePaymentModal { ...props } />
        <ViewPayment { ...props } />
        <PromptModal
          showPromptModal={ props.showDeletePaymentModal }
          onClickYes={ () => props.removePayment( props.selectedInvoice.id, props.selectedPayment.id ) }
          onClickNo={ () => props.onChangePaymentHOC( 'showDeletePaymentModal', false ) }
          content='Are you sure to delete the selected payment?' />
        <ToastContainer position={ toast.POSITION.BOTTOM_RIGHT } style={{ zIndex: 9999 }} />
        { ( props.onLoadInvoices || props.onLoadPayments ) && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        {
          selectedPayments.length > 0 && <Button 
            color="primary"
            onClick={() => {
              Promise.all([
                downloadPaymentsPDF( selectedInvoice.id, selectedPayments )
              ]).then( () => {
                getPayments( selectedInvoice.id )
              })
            }}>Download receipt for selected payment(s)</Button>
        }
        <Button 
          color="primary"
          disabled={ payments.length === 0 }
          onClick={() => {
            Promise.all([
              downloadPDF( selectedInvoice.id )
            ]).then( () => {
              getPayments( selectedInvoice.id )
            })
          }}>Download latest receipt</Button>
      </ModalFooter>
    </Modal>
  )
}

export default Update