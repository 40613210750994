import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Moment from 'moment'
import { connect } from 'react-redux'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedInvoice: {
        branch_id: 0,
        invoice_treatments: [],
        invoice_products: [],
        invoice_medicines: [],
        is_reload_credit_advance: false,
        lab_cost: 0
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getSelectedInvoice = id => Get(
      `/invoices/${ id }`,
      this.getSelectedInvoiceSuccess,
      this.getSelectedInvoiceError,
      this.load
    )
    getSelectedInvoiceSuccess = payload => {
      this.setState({ selectedInvoice: payload })
    }
    getSelectedInvoiceError = error => this.requestError( error )

    updateSelectedInvoice = data => Put(
      `/invoices/${ data.id }`,
      data,
      this.updateSelectedInvoiceSuccess,
      this.updateSelectedInvoiceError,
      this.load
    )
    updateSelectedInvoiceSuccess = payload => {

    }
    updateSelectedInvoiceError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          selectedInvoice={ this.state.selectedInvoice }
          onLoadInvoices={ this.state.loading }
          getSelectedInvoice={ this.getSelectedInvoice }
          updateSelectedInvoice={ this.updateSelectedInvoice } />
      )
    }
  }
  return WithHOC
}

export default HOC