import React from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button
} from 'reactstrap'

const ConfirmDelete = props => {
  return (
    <Modal
      isOpen={ props.showConfirmDelete }
      toggle={() => {
        props.updateShowConfirmDelete( false )
      }}>
      <ModalHeader toggle={() => props.updateShowConfirmDelete( false )}>Notification</ModalHeader>
      <ModalBody>
        Are you sure to remove the patient? 
      </ModalBody>
      <ModalFooter>
        <Button 
          color="danger"
          onClick={() => {
            props.removePatient( props.selectedPatient.id )
            props.updateShowConfirmDelete( false )
          }}>
          Confirm Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmDelete