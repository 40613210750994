import React, { useEffect, useRef } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button
} from 'reactstrap'
import CanvasDraw from "react-canvas-draw";

const PerioChart = props => {
  const TmpCanvas = useRef( null )
  return (
    <Modal
      isOpen={ props.showPerioChart }
      size={ 'xl' }>
      <ModalHeader toggle={() => props.toggleShowPerioChart( false )}>Perio Chart</ModalHeader>
      <ModalBody>
        <CanvasDraw
          ref={ TmpCanvas }
          imgSrc={ '/perio_chart.jpg' }
          canvasWidth={ 700 }
          canvasHeight={ 800 }
          enablePanAndZoom={ true }
          brushRadius={ 3 }
          lazyRadius={ 0 }
          brushColor={ '#000' }
          onChange={ e => { 
            console.log( e, 'e' )
          }}
          saveData={ props.selectedMedicalRecord.perio_chart_save_data } />
      </ModalBody>
      <ModalFooter>
        <Button 
          color="success"
          onClick={() => {
            TmpCanvas.current.resetView()
          }}>
          Reset view
        </Button>
        <Button
          color="danger"
          className="ml-2"
          onClick={() => {
            TmpCanvas.current.undo()
          }}>
          Undo
        </Button>
        <Button
          color="primary"
          className="ml-2"
          onClick={() => {
            props.updateMedicalRecordData( 'perio_chart_save_data', TmpCanvas.current.getSaveData() )
            props.toggleShowPerioChart( false )
          }}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default PerioChart