import React, { Component } from 'react'
import { toast } from 'react-toastify'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showCreateMedicine: false,
      showUpdateMedicine: false,
      newMedicine: {
        stocks: 0,
        name: '',
        price_per_unit: 0,
        company_id: 0
      },
      medicines: [],
      selectedMedicine: {},
      medicineError: {}
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeMedicineHOC = ( key, val ) => this.setState({ [ key ]: val })

    getMedicines = () => Get(
      `/medicines`,
      this.getMedicinesSuccess,
      this.getMedicinesError,
      this.load
    )
    getMedicinesSuccess = payload => this.setState({ medicines: payload })
    getMedicinesError = error => this.requestError( error )

    getSelectedMedicine = id => Get(
      `/medicines/${ id }`,
      this.getSelectedMedicineSuccess,
      this.getSelectedMedicineError,
      this.load
    )
    getSelectedMedicineSuccess = payload => this.setState({ selectedMedicine: payload, showUpdateMedicine: true })
    getSelectedMedicineError = error => this.requestError( error )

    createMedicine = data => Post(
      `/medicines`,
      data,
      this.createMedicineSuccess,
      this.createMedicineError,
      this.load
    )
    createMedicineSuccess = payload => {
      this.requestSuccess( 'Medicine is created successfully.' )
      this.setState({ showCreateMedicine: false })
      this.getMedicines()
    }
    createMedicineError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ medicineError: error })
      }
    }

    updateMedicine = data => Put(
      `/medicines/${ data.id }`,
      data,
      this.updateMedicineSuccess,
      this.updateMedicineError,
      this.load
    )
    updateMedicineSuccess = payload => {
      this.requestSuccess( 'Medicine is updated successfully.' )
      this.setState({ showUpdateMedicine: false })
      this.getMedicines()
    }
    updateMedicineError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ medicineError: error })
      }
    }

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          onLoadMedicines={ this.state.loading }
          showCreateMedicine={ this.state.showCreateMedicine }
          showUpdateMedicine={ this.state.showUpdateMedicine }
          newMedicine={ this.state.newMedicine }
          selectedMedicine={ this.state.selectedMedicine }
          medicines={ this.state.medicines }
          onChangeMedicineHOC={ this.onChangeMedicineHOC }
          getMedicines={ this.getMedicines }
          getSelectedMedicine={ this.getSelectedMedicine }
          createMedicine={ this.createMedicine }
          updateMedicine={ this.updateMedicine } />
      )
    }
  }
  return WithHOC
}

export default HOC