import React, { Component } from 'react'
import _ from 'lodash'
import { 
  Row,Col,
  Card, CardHeader, CardBody, CardDeck
} from 'reactstrap'
import ReactTable from 'react-table'
import Moment from 'moment'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SearchForm from './SearchForm'

import WithCollections from './actions'
import CurrencySettings from 'utils/currencies'

class CollectionsSuperAdmin extends Component {
  componentDidMount = () => this.props.getCompaniesCollections( 
    Moment( this.props.searchParams[ 0 ][ 'value' ] ).format( 'YYYY-MM-DD' ), 
    Moment( this.props.searchParams[ 1 ][ 'value' ] ).format( 'YYYY-MM-DD' ),
  )

  renderCollectionsTables = ( collections, company_name ) => (
    <Card className="main-card mb-3">
      <CardHeader>
        {`
          Search results from ${ 
            Moment( this.props.searchParams[ 0 ][ 'value' ] ).format( 'DD MMM YYYY' ) } to ${ 
            Moment( this.props.searchParams[ 1 ][ 'value' ] ).format( 'DD MMM YYYY' ) }${ 
            company_name ? ` for ${ company_name }` : '' }
        `}
      </CardHeader>
      <CardBody>
        <Card className="main-card mb-3">
          <CardHeader>{ `Doctor Collections` }</CardHeader>
          <CardBody>
            <ReactTable
              pageSize={ collections.dr_breakdown.length }
              data={ collections.dr_breakdown }
              columns={[
                {
                  Header: 'Salutation',
                  accessor: 'salutation'
                },
                {
                  Header: 'Name',
                  accessor: 'name'
                },
                {
                  Header: 'Amount',
                  accessor: 'amount'
                }
              ]} />
          </CardBody>
        </Card>
        <Card className="main-card mb-3">
          <CardHeader>{ `Collections Listings` }</CardHeader>
          <CardBody>
          {
            <ReactTable
              data={ collections.by_users }
              columns={[
                {
                  Header: 'Patient name',
                  accessor: 'patient_name'
                },
                {
                  Header: 'Payments',
                  Cell: ( row ) => {
                    return (
                      <div>
                        {
                          Object.keys( row.original.payments ).map( key => {
                            return (
                              <>
                                <p style={{ marginBottom: 0 }}>{ `${ key }: ${ row.original.payments[ key ] }` }</p>
                              </>
                            )
                          })
                        }
                      </div>
                    )
                  }
                },
                {
                  Header: 'Total collected amount',
                  accessor: 'total'
                }
              ]}
              pageSize={ 10 } />
          }
          </CardBody>
        </Card>
        <Card className="main-card mb-3">
          <CardHeader>{ `Payment Types` }</CardHeader>
          <CardBody>
            <ReactTable
              pageSize={ collections.payment_types.length }
              data={ collections.payment_types }
              columns={[
                {
                  Header: 'Payment types',
                  accessor: 'pay_with'
                },
                {
                  Header: 'Amount',
                  accessor: 'amount'
                }
              ]} />
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  )

  render = () => {
    return (
      <>
        <PageTitle
          heading="Collections (Super Admin View)"
          subheading="Listings of all the collections from all companies"
          icon="pe-7s-mail-open-file icon-gradient bg-happy-itmeo"
          buttons={[]} />
        <Row>
          <Col md={ 12 }>
            <CardDeck>
              <SearchForm { ...this.props } />
              <Card 
                className="main-card mb-3 card-shadow-primary card-btm-border border-primary"
                style={{ maxWidth: "35%" }} >
                <CardBody className="d-flex flex-column justify-content-center widget-chart align-items-start text-left" >
                  <div className="widget-subheading d-flex">
                    Total collection for selected date
                  </div>
                  <div className="widget-numbers">
                    { `${ CurrencySettings() } ${ this.props.collections.total }` }
                  </div>
                </CardBody>
              </Card>
            </CardDeck>
          </Col>
        </Row>
        {
          this.props.collections 
          && this.props.collections.by_company 
          && this.props.collections.by_company.map( company => this.renderCollectionsTables( company.collection, company.company_name ) )
        }
        { this.props.onLoadCollections && <LoadingOverlay /> }
      </>
    )
  }
}

export default WithCollections( CollectionsSuperAdmin )