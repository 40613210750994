import React, { Component } from 'react'
import { 
  Row, 
  Col,
  Card,
  CardBody,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CreateCompany from './Create'
import UpdateCompany from './Update'

import WithCompanies from './actions'

class Companies extends Component {

  componentDidMount = () => this.props.getCompanies()

  componentDidUpdate = pp => {
    if( pp.showUpdateCompany && !this.props.showUpdateCompany ){
      this.props.onChangeCompaniesHOC( 'selectedCompany', {
        name: '',
        branches: [],
        registration_number: ''
      })
    }
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading="Companies"
          subheading="Listings of all the companies presence in the system."
          icon="pe-7s-culture icon-gradient bg-happy-itmeo"
          buttons={
            this.props.data.profileReducer.profile.current_user.role.name === 'Super Admin'
              ? [
                  {  
                    color: 'primary',
                    onClick: () => this.props.onChangeCompaniesHOC( 'showCreateCompany', true ),
                    content: 'Add company'
                  }
                ]
              : []
          } />
        <div>
          <Row>
            <Col md={ 12 }>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    data={ this.props.companies }
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'name'
                      },
                      {
                        Header: 'Actions',
                        maxWidth: 120,
                        Cell: ( row ) => (
                          <>
                            <Button
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={() => {
                                this.props.getSelectedCompany( row.original.id )
                              }}>
                              <i className="pe-7s-pen btn-icon-wrapper"> </i>
                            </Button>
                          </>
                        )
                      }
                    ]}
                    defaultPageSize={ 10 } />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <CreateCompany { ...this.props } />
        <UpdateCompany { ...this.props } />
        { this.props.onLoadCompanies && <LoadingOverlay /> }
      </>
    )
  }
}

export default WithCompanies( Companies )