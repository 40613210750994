import React, { useEffect } from 'react'
import { 
  Card, CardBody,
  Button, Input, Label,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup,
} from 'reactstrap'
import { compose } from 'redux'
import ReactTable from 'react-table'
import _ from 'lodash'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PromptModal from 'components/Indicator/Prompt'
import ConsentForm from './form'

import WithConsentForms from './actions'
import WithPatients from './actions/Patients'
import WithDoctors from 'actions/dictionary/doctors'

const ConsentForms = props => {
  useEffect(() => {
    props.getConsentForms()
    props.reloadNewConsentForm()
    props.getDoctors( props.data.profileReducer.profile.current_user.branch_ids )
  }, [])

  const consentFormTypes = [
    { 
      type_id: 1,
      label: 'Cementation Approval' },
    { 
      type_id: 2,
      label: 'Crown consent' },
    { 
      type_id: 3,
      label: 'Extraction consent' },
    { 
      type_id: 4,
      label: 'Implant consent' },
    { 
      type_id: 5,
      label: 'Root canal consent' },
    { 
      type_id: 6,
      label: 'Wisdom tooth consent' }
  ]

  const renderCreateModal = () => (
    <Modal size='md' isOpen={ props.showCreateConsentForm }>
      <ModalHeader 
        toggle={ () => props.reloadNewConsentForm() }>
        Create Consent Form</ModalHeader>
      <ModalBody>
        <ConsentForm
          consentFormType='newConsentForm'
          currentConsentForm={ props.newConsentForm }
          onChangeConsentFormsHOC={ props.onChangeConsentFormsHOC }
          profile={ props.data.profileReducer.profile }
          doctors={ props.doctors }
          patients={ props.patients }
          getPatients={ props.getPatients }
          searchParams={ props.searchParams }
          onChangePatientsHOC={ props.onChangePatientsHOC }
          onLoadPatientsHOC={ props.onLoadPatientsHOC } />
        { ( props.onLoadConsentFormsHOC || props.onLoadPatientsHOC ) && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color='primary'
          onClick={ () => props.createConsentForm( props.newConsentForm ) } >
          Submit</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateModal = () => (
    <Modal size='md' isOpen={ props.showUpdateConsentForm }>
      <ModalHeader 
        toggle={ () => props.reloadSelectedConsentForm() }>
        Update Consent Form</ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedConsentForm ) && <ConsentForm
            consentFormType='selectedConsentForm'
            currentConsentForm={ props.selectedConsentForm }
            onChangeConsentFormsHOC={ props.onChangeConsentFormsHOC }
            profile={ props.data.profileReducer.profile }
            doctors={ props.doctors }
            patients={ props.patients }
            getPatients={ props.getPatients }
            searchParams={ props.searchParams }
            onChangePatientsHOC={ props.onChangePatientsHOC }
            onLoadPatientsHOC={ props.onLoadPatientsHOC } />
        }
        { ( props.onLoadConsentFormsHOC || props.onLoadPatientsHOC ) && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color='primary'
          onClick={ () => props.updateConsentForm( props.selectedConsentForm ) } >
          Submit</Button>
      </ModalFooter>
    </Modal>
  )

  const renderDownloadModal = () => (
    <Modal size='sm' isOpen={ props.showDownloadConsentForm }>
      <ModalHeader toggle={ () => props.reloadSelectedConsentForm() }>
        Download Consent Form</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Type</Label>
            <Input
              type='select'
              value={ props.selectedConsentForm.type_id }
              onChange={ e => {
                let tmp = _.cloneDeep( props.selectedConsentForm )
                tmp = {
                  ...tmp,
                  ..._.find( consentFormTypes, item => parseInt( item.type_id ) === parseInt( e.target.value ) )
                }
                props.onChangeConsentFormsHOC( 'selectedConsentForm', tmp )
              }}>
              { consentFormTypes.map( item => <option value={ item.type_id } >{ item.label }</option> ) }
            </Input>
          </FormGroup>
        </Form>
        { props.onLoadConsentFormsHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color='primary'
          onClick={ () => props.downloadConsentFormPDF( props.selectedConsentForm ) } >
          Submit</Button>
      </ModalFooter>
    </Modal>
  )
  
  return(
    <>
      <PageTitle
        heading="Consent forms"
        subheading="Listings of all the consent forms."
        icon="pe-7s-clock icon-gradient bg-happy-itmeo"
        buttons={[
          {  
            color: 'primary',
            onClick: () => props.onChangeConsentFormsHOC( 'showCreateConsentForm', true ),
            content: 'Create consent form'
          }
        ]} />
      <Card className="main-card mb-3">
        <CardBody>
          <ReactTable
            showPagination={ true }
            pageSize={ 20 }
            data={ props.consentForms }
            columns={[
              {
                Header: 'Company',
                accessor: 'company_name',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Branch',
                accessor: 'branch_name',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Doctor',
                accessor: 'user_name',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Patient',
                accessor: 'patient_name',
                style: { "line-break": "anywhere" }
              },
              {
                Header: 'Action',
                style:{ 
                  "justify-content": "center",
                  "overflow": "scroll" 
                },
                Cell: row => <>
                  <Button 
                    color='primary'
                    className='mr-2'
                    onClick={ () => {
                      props.getSelectedConsentForm( row.original.id )
                      props.getPatients( row.original.patient_name ) }}>
                    <i className='pe-7s-pen'/></Button>
                  <Button 
                    color='primary'
                    onClick={ () => props.onChangeConsentFormsHOC( 'showDownloadConsentForm', row.original.id ) }>
                    <i className='pe-7s-download'/></Button>
                  <Button 
                    color='danger'
                    className='ml-2'
                    onClick={ () => props.onChangeConsentFormsHOC( 'selectedConsentFormToDelete', row.original.id ) }>
                    <i className='pe-7s-trash'/></Button>
                </>
              }
            ]}/>
        </CardBody>
      </Card>
      <PromptModal
        showPromptModal={ props.selectedConsentFormToDelete > 0 }
        onClickYes={ () => props.removeConsentForm( props.selectedConsentFormToDelete ) }
        onClickNo={ () => props.onChangeConsentFormsHOC( 'selectedConsentFormToDelete', 0 ) }
        content={ 'Are you sure you want to delete the record?' } />
      { renderCreateModal() }
      { renderUpdateModal() }
      { renderDownloadModal() }
      { ( props.onLoadConsentFormsHOC || props.onLoadPatientsHOC ) && <LoadingOverlay/> }
    </>
  )
}

export default compose(
  WithConsentForms,
  WithDoctors,
  WithPatients
)( ConsentForms )

