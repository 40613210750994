import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'
import { connect } from 'react-redux'
import Moment from 'moment'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post } from 'utils/axios'
import getDomainURL from 'utils/api'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      invoices: {
        data: [],
        pagy: {}
      },
      selectedInvoice: {
        branch_id: 0,
        invoice_treatments: [],
        invoice_products: [],
        invoice_medicines: [],
        is_reload_credit_advance: false,
        lab_cost: 0
      },
      newInvoice: {
        company_id: 0,
        branch_id: 0,
        invoice_treatments: [],
        invoice_products: [],
        invoice_medicines: [],
        is_reload_credit_advance: false,
        lab_cost: 0
      },
      loading: false,
      showUpdateInvoice: false,
      showCreateInvoice: false,
      amountDue: 0,
      searchParams: [
        {
          label: 'Start date',
          key: 'start_date',
          value: Moment().format( 'YYYY-MM-DD' )
        },
        {
          label: 'End date',
          key: 'end_date',
          value: Moment().add( 1, 'days' ).format( 'YYYY-MM-DD' )
        },
        {
          label: 'Patient',
          key: 'patient_id',
          value: ''
        }
      ],
      selectedPayments: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeInvoiceHOC = ( key, val ) => this.setState({ [key]: val })

    getInvoices = ( search, page ) => Get(
      `/invoices/filter_by_date?page=${ page }${ search ? search : '' }`,
      this.getInvoicesSuccess,
      this.getInvoicesError,
      this.load
    )
    getInvoicesSuccess = payload => this.setState({ invoices: payload })
    getInvoicesError = error => this.requestError( error )

    getSelectedInvoice = id => Get(
      `/invoices/${ id }`,
      this.getSelectedInvoiceSuccess,
      this.getSelectedInvoiceError,
      this.load
    )
    getSelectedInvoiceSuccess = payload => {
      this.setState({ selectedInvoice: payload, showUpdateInvoice: true }, () => {
        let tmpTotalAmount = 0
        payload.invoice_treatments.map( item => {
          tmpTotalAmount = tmpTotalAmount + parseFloat( item.formated_price ? item.formated_price.replaceAll( ',', '' ) : 0 )
        }) 
        payload.invoice_medicines.map( item => {
          tmpTotalAmount = tmpTotalAmount + ( parseFloat( item.quantity ) * parseFloat( item.price_per_unit ) )
        })
        payload.invoice_products.map( item => {
          tmpTotalAmount = tmpTotalAmount + ( item.quantity * parseFloat( item.price_per_unit ) )
        })
        if( payload.is_reload_credit_advance && payload.payments.length > 0 ) {
          tmpTotalAmount = payload.payments[0].current_cost
        }
        this.updateTotalAmount({
          id: this.state.selectedInvoice.id,
          total_amount: tmpTotalAmount
        })
      })
    }
    getSelectedInvoiceError = error => this.requestError( error )

    createInvoice = data => Post(
      `/invoices`,
      data,
      this.createInvoiceSuccess,
      this.createInvoiceError,
      this.load
    )
    createInvoiceSuccess = payload => {
      let tmp = ''
      this.state.searchParams.map( item => {
        tmp = tmp + `&q[${ item.key }]=${ item.value }`
      })
      this.getInvoices( tmp, 1 )
      this.requestSuccess( 'Invoice is created successfully.' )
      this.getSelectedInvoice( payload.id )
    }
    createInvoiceError = error => {
      this.requestSuccess( 'Failed to create invoice.' )
    }

    updateInvoice = data => Put(
      `/invoices/${ data.id }`,
      data,
      this.updateInvoiceSuccess,
      this.updateInvoiceError,
      this.load
    )
    updateInvoiceSuccess = payload => {
      let tmp = ''
      this.state.searchParams.map( item => {
        tmp = tmp + `&q[${ item.key }]=${ item.value }`
      })
      this.getInvoices( tmp, 1 )
      this.requestSuccess( 'Invoice is updated successfully.' )
    }
    updateInvoiceError = error => this.requestError( error )

    updateTotalAmount = data => Put(
      `/invoices/${ data.id }/update_total_amount`,
      data,
      this.updateTotalAmountSuccess,
      this.updateTotalAmountError,
      this.load
    )
    updateTotalAmountSuccess = payload => {
      this.requestSuccess( 'Invoice total amount is updated successfully.' )
      this.setState({
        selectedInvoice: payload
      }, () => {
        let tmpAmountDue = this.state.selectedInvoice.total_amount
        payload.payments.map( item => {
          tmpAmountDue = tmpAmountDue - parseFloat( item.current_cost )
        })
        this.setState({ amountDue: tmpAmountDue })
      })
    }
    updateTotalAmountError = error => this.requestError( 'Failed to update invoice total amount.' )

    downloadPDF = invoice_id => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/invoices/${ invoice_id }/download`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( async blobby => {
        this.load( false )
        await this.getSelectedInvoice( invoice_id )
        return FileSaver.saveAs( blobby, 'Receipt.pdf' )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save receipt. Please try again.' )
      })
    }

    downloadPaymentsPDF = ( invoice_id, payment_ids ) => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/invoices/${ invoice_id }/download?payment_ids=${ JSON.stringify( payment_ids ) }`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( async blobby => {
        this.load( false )
        await this.getSelectedInvoice( invoice_id )
        return FileSaver.saveAs( blobby, 'Receipt.pdf' )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save receipt. Please try again.' )
      })
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          invoices={ this.state.invoices }
          onLoadInvoices={ this.state.loading }
          showCreateInvoice={ this.state.showCreateInvoice }
          showUpdateInvoice={ this.state.showUpdateInvoice }
          selectedInvoice={ this.state.selectedInvoice }
          newInvoice={ this.state.newInvoice }
          amountDue={ this.state.amountDue }
          searchParams={ this.state.searchParams }
          getInvoices={ this.getInvoices }
          createInvoice={ this.createInvoice }
          getSelectedInvoice={ this.getSelectedInvoice }
          updateInvoice={ this.updateInvoice }
          updateTotalAmount={ this.updateTotalAmount }
          downloadPDF={ this.downloadPDF }
          onChangeInvoiceHOC={ this.onChangeInvoiceHOC }
          selectedPayments={ this.state.selectedPayments }
          downloadPaymentsPDF={ this.downloadPaymentsPDF } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC