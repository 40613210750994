import { GET_PROFILE } from '../actions/type'

let initialState = {
  profile: {
    branches: [],
    companies: [],
    current_user: {
      id: 0,
      name: 'Dummy User',
      email: 'dummyuser@gmail.com',
      role_id: 0,
      role: {
        name: ''
      }
    }
  }
}

export default ( state = initialState, action ) => {
  switch( action.type ) {
    case GET_PROFILE: 
      return {
        ...state,
        profile: action.payload
      }
    default: return state
  }
}