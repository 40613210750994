import React, { Component } from 'react'
import { toast } from 'react-toastify'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showCreateProduct: false,
      showUpdateProduct: false,
      newProduct: {
        name: '',
        price: 0,
        company_id: 0,
        stocks: 0
      },
      products: [],
      selectedProduct: {},
      productError: {},
      searchParams: ''
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeProductsHOC = ( key, val ) => this.setState({ [key]: val })

    getProducts = () => Get(
      `/products${ this.state.searchParams !== '' ? `?search=${ this.state.searchParams }` : '' }`,
      this.getProductsSuccess,
      this.getProductsError,
      this.load
    )
    getProductsSuccess = payload => this.setState({ products: payload })
    getProductsError = error => this.requestError( error )

    getSelectedProduct = id => Get(
      `/products/${ id }`,
      this.getSelectedProductSuccess,
      this.getSelectedProductError,
      this.load
    )
    getSelectedProductSuccess = payload => this.setState({ selectedProduct: payload, showUpdateProduct: true })
    getSelectedProductError = error => this.requestError( error )

    createProduct = data => Post(
      `/products`,
      data,
      this.createProductSuccess,
      this.createProductError,
      this.load
    )
    createProductSuccess = payload => {
      this.requestSuccess( 'Product is created successfully.' )
      this.setState({ showCreateProduct: false })
      this.getProducts()
    }
    createProductError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.requestError( error )
        this.setState({ productError: error })
      }
    }

    updateProduct = data => Put(
      `/products/${ data.id }`,
      data,
      this.updateProductSuccess,
      this.updateProductError,
      this.load
    )
    updateProductSuccess = payload => {
      this.requestSuccess( 'Product is updated successfully.' )
      this.setState({ showUpdateProduct: false })
      this.getProducts()
    }
    updateProductError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ productError: error })
      }
    }

    removeProduct = id => Delete(
      `/products/${ id }`,
      this.removeProductSuccess,
      this.removeProductError,
      this.load
    )
    removeProductSuccess = payload => {
      this.requestSuccess( 'Product is removed successfully.' )
      this.getProducts()
      this.setState({ showPromptModal: false })
    }
    removeProductError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent 
            { ...this.props }
            onChangeProductsHOC={ this.onChangeProductsHOC }
            getProducts={ this.getProducts }
            getSelectedProduct={ this.getSelectedProduct }
            createProduct={ this.createProduct }
            updateProduct={ this.updateProduct }
            onLoadProducts={ this.state.loading }
            newProduct={ this.state.newProduct }
            showCreateProduct={ this.state.showCreateProduct }
            showUpdateProduct={ this.state.showUpdateProduct }
            selectedProduct={ this.state.selectedProduct }
            products={ this.state.products }
            searchParams={ this.state.searchParams } />
          <PromptModal
            showPromptModal={ this.state.showPromptModal }
            onClickYes={() => this.removeProduct( this.state.toRemoveID )}
            onClickNo={() => this.setState({ showPromptModal: false })}
            content={ 'Are you sure you want to delete the record?' } />
        </>
      )
    }
  }
  return WithHOC
}

export default HOC