import React, { useEffect, useState } from 'react'
import { 
  Card,
  CardBody,
  Alert,
  Badge,
  Row, Col
} from 'reactstrap'
import { Multiselect } from 'react-widgets'
import _ from 'lodash'
import { compose } from 'redux'
import Moment from 'moment'

const Appointments = props => {
  return (
    <div>
      <Alert color="primary">
        { `Today's date: ${ Moment().format( 'DD-MMM-YYYY dddd HH:mm' ) }` }
      </Alert>
      {
        props.selectedPatient.appointments.map(( item, index ) => {
          return (
            <Card key={ item.id } className="mb-2">
              <CardBody style={{ fontWeight: '600' }}>
                { index === 0 && <Badge color="danger" className="mb-2">Latest</Badge> }
                <p>{ `Start datetime: ` + Moment( item.start_datetime ).format( 'DD-MMM-YYYY dddd HH:mm' ) }</p>
                <p>{ `End datetime: ` + Moment( item.end_datetime ).format( 'DD-MMM-YYYY dddd HH:mm' ) }</p>
                <span>{ `Description: ${ item.details }(${ item.status })` }</span>
              </CardBody>
            </Card>
          )
        })
      }
    </div>
  )
}

export default Appointments