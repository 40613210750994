import React, { Component } from 'react'
import _ from 'lodash'
import { 
  Row, 
  Col,
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'
import { compose } from 'redux'
import Moment from 'moment'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SearchForm from './SearchForm'
import InvoiceListings from './Invoices'

import WithCollections from './actions'
import WithCompanies from 'actions/dictionary/companies'
import WithInvoices from './actions/Invoices'
import WithDoctors from 'actions/dictionary/doctors'
import CurrencySettings from 'utils/currencies'

class Collections extends Component {

  componentDidMount = () => {
    if( 
      this.props.data.profileReducer.profile.current_user.role.name === 'Admin' ||
      this.props.data.profileReducer.profile.current_user.role.name === 'Frontdesk'
    ) {
      this.props.getDoctors( this.props.data.profileReducer.profile.current_user.branch_ids )
    }
    if( this.props.data.profileReducer.profile.current_user.role.name === 'Super Admin' ) {
      this.props.getCompanies()
      this.props.getSelectedCompany( this.props.data.profileReducer.profile.current_user.company_ids[0] )
    } else {
      if( this.props.data.profileReducer.profile.companies.length > 0 ) {
        this.props.getCollections( 
          this.props.data.profileReducer.profile.companies[ 0 ].id, 
          Moment( this.props.searchParams[ 1 ][ 'value' ] ).format( 'YYYY-MM-DD' ), 
          Moment( this.props.searchParams[ 2 ][ 'value' ] ).format( 'YYYY-MM-DD' ),
          this.props.searchParams[ 3 ][ 'value' ]
        )
      }
    }
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading="Collections"
          subheading="Listings of all the collections based on selected company."
          icon="pe-7s-mail-open-file icon-gradient bg-happy-itmeo"
          buttons={[]} />
        {
          !_.isEmpty( this.props.data.profileReducer.profile.current_user ) && (
            <SearchForm { ...this.props } />
          )
        }
        <Card className="main-card mb-3">
          <CardHeader>
            { `Search results from ${ Moment( this.props.searchParams[ 1 ][ 'value' ] ).format( 'DD MMM YYYY' ) } to ${ Moment( this.props.searchParams[ 2 ][ 'value' ] ).format( 'DD MMM YYYY' ) }` }
          </CardHeader>
          <CardBody>
            <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
              <div className="widget-chart-content">
                <div className="widget-subheading d-flex">
                  Total collection for selected date
                </div>
                <div className="widget-numbers">
                  { `${ CurrencySettings() } ${ this.props.collections.total }` }
                </div>
              </div>
            </div>
            <Card className="main-card mb-5">
              <CardHeader>{ `Doctor Collections` }</CardHeader>
              <CardBody>
                <ReactTable
                  pageSize={ this.props.collections.dr_breakdown.length }
                  data={ this.props.collections.dr_breakdown }
                  columns={[
                    {
                      Header: 'Salutation',
                      accessor: 'salutation'
                    },
                    {
                      Header: 'Name',
                      accessor: 'name'
                    },
                    {
                      Header: 'Amount',
                      accessor: 'amount'
                    }
                  ]} />
              </CardBody>
            </Card>
            <Card className="main-card">
              <CardHeader>{ `Collections Listings` }</CardHeader>
              <CardBody>
              {
                this.props.data.profileReducer.profile.current_user.role.name === 'Doctor'
                  ? (
                    <ReactTable
                      data={ this.props.collections.by_users }
                      pageSize={ 10 }
                      columns={[
                        {
                          Header: 'Date',
                          accessor: 'date'
                        },
                        {
                          Header: 'Patient name',
                          accessor: 'customer_name'
                        },
                        {
                          Header: 'Total',
                          accessor: 'total'
                        }
                      ]} />
                  )
                  : (
                    <ReactTable
                      data={ this.props.collections.by_users }
                      columns={[
                        {
                          Header: 'Patient name',
                          accessor: 'patient_name'
                        },
                        {
                          Header: 'Payments',
                          Cell: ( row ) => {
                            return (
                              <div>
                                {
                                  Object.keys( row.original.payments ).map( key => {
                                    return (
                                      <>
                                        <p style={{ marginBottom: 0 }}>{ `${ key }: ${ row.original.payments[ key ] }` }</p>
                                      </>
                                    )
                                  })
                                }
                              </div>
                            )
                          }
                        },
                        {
                          Header: 'Total collected amount',
                          accessor: 'total'
                        }
                      ]}
                      pageSize={ 10 } />
                  )
              }
              </CardBody>
            </Card>
          </CardBody>
        </Card>
        <Card className="main-card mb-3">
          <CardHeader>{ `Payment Types` }</CardHeader>
          <CardBody>
            <ReactTable
              pageSize={ this.props.collections.payment_types.length }
              data={ this.props.collections.payment_types }
              columns={[
                {
                  Header: 'Payment types',
                  accessor: 'pay_with'
                },
                {
                  Header: 'Amount',
                  accessor: 'amount'
                }
              ]} />
          </CardBody>
        </Card>
        <InvoiceListings { ...this.props } />
        { ( this.props.onLoadCollections || this.props.onLoadCompanies || this.props.onLoadInvoices ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithCollections,
  WithCompanies,
  WithInvoices,
  WithDoctors
)( Collections )