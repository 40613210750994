import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedInvoice: {},
      patientInvoices: [],
      selectedInvoicePayment: {},
      selectedCaUser: {}
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeInvoiceHOC = ( key, val ) => this.setState({ [key]: val })

    getSelectedInvoice = id => Get(
      `/invoices/${ id }`,
      this.getSelectedInvoiceSuccess,
      this.getSelectedInvoiceError,
      this.load
    )
    getSelectedInvoiceSuccess = payload => this.setState({ selectedInvoice: payload })
    getSelectedInvoiceError = error => this.requestError( error )

    getInvoiceByPatientId = patient_id => Get(
      `/invoices/by_patient?patient_id=${ patient_id }`,
      this.getInvoiceByPatientIdSuccess,
      this.getInvoiceByPatientIdError,
      this.load
    )
    getInvoiceByPatientIdSuccess = payload => this.setState({ patientInvoices: payload })
    getInvoiceByPatientIdError = error => this.requestError( error )

    getSelectedInvoicePayment = ( invoice_id, payment_id ) => Get(
      `/invoices/${ invoice_id }/payments/${ payment_id }`,
      this.getSelectedInvoicePaymentSuccess,
      this.getSelectedInvoicePaymentError,
      this.load
    )
    getSelectedInvoicePaymentSuccess = payload => {
      if( payload.credit_advance_payment_logs.length > 0 ){  
        this.getSelectedCaUser( payload.credit_advance_payment_logs[ payload.credit_advance_payment_logs.length - 1 ].patient_id ) 
      }
      this.setState({ selectedInvoicePayment: payload })
    }
    getSelectedInvoicePaymentError = error => {
      this.requestError( 'Failed to get selected payment' )
      this.setState({ selectedInvoicePayment: {} })
    }

    getSelectedCaUser = id => Get(
      `/patients/${ id }`,
      this.getSelectedCaUserSuccess,
      this.getSelectedCaUserError,
      this.load
    )
    getSelectedCaUserSuccess = payload => this.setState({ selectedCaUser: payload })
    getSelectedCaUserError = error => this.requestError( error )

    reloadInvoice = () => this.setState({ selectedInvoice: {}, selectedInvoicePayment: {}, selectedCaUser: {} })

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadInvoicesHOC={ this.state.loading }
          selectedInvoice={ this.state.selectedInvoice }
          getSelectedInvoice={ this.getSelectedInvoice }
          patientInvoices={ this.state.patientInvoices }
          getInvoiceByPatientId={ this.getInvoiceByPatientId }
          selectedInvoicePayment={ this.state.selectedInvoicePayment }
          getSelectedInvoicePayment={ this.getSelectedInvoicePayment }
          onChangeInvoiceHOC={ this.onChangeInvoiceHOC }
          selectedCaUser={ this.state.selectedCaUser }
          reloadInvoice={ this.reloadInvoice } />
      )
    }
  }

  return WithHOC
}

export default HOC