import React, { Component } from 'react'
import { 
  Row, 
  Col,
  Card,
  CardBody,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'

import PageTitle from 'components/Title'
import CreateMedicine from './Create'
import UpdateMedicine from './Update'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithPharmaceuticals from './actions'

class Pharmaceuticals extends Component {

  componentDidMount = () => this.props.getMedicines()

  render = () => {
    return (
      <>
        <PageTitle
          heading="Medicines"
          subheading="Listings of all the medicines presence in the system."
          icon="pe-7s-users icon-gradient bg-happy-itmeo"
          buttons={[
            {  
              color: 'primary',
              onClick: () => this.props.onChangeMedicineHOC( 'showCreateMedicine', true ),
              content: 'Add medicine'
            }
          ]} />
        <div>
          <Row>
            <Col md={ 12 }>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    data={ this.props.medicines }
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'name'
                      },
                      {
                        Header: 'Inventory amount',
                        accessor: 'stocks'
                      },
                      {
                        Header: 'Price per unit',
                        accessor: 'price_per_unit'
                      },
                      {
                        Header: 'Actions',
                        maxWidth: 120,
                        Cell: ( row ) => (
                          <>
                            <Button
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={() => {
                                this.props.getSelectedMedicine( row.original.id )
                              }}>
                              <i className="pe-7s-pen btn-icon-wrapper"> </i>
                            </Button>
                          </>
                        )
                      }
                    ]}
                    defaultPageSize={ 10 } />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <CreateMedicine { ...this.props } />
        <UpdateMedicine { ...this.props } />
        { this.props.onLoadMedicines && <LoadingOverlay /> }
      </>
    )
  }
}

export default WithPharmaceuticals( Pharmaceuticals )