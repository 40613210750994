import React from 'react'
import { 
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from 'reactstrap'

const Medical = ({
  selectedPatient,
  onChangePatientData
}) => {
  return (
    <Form onSubmit={ e => e.preventDefault()}>
      <FormGroup>
        <Label>Allergies</Label>
        <Input 
          type="textarea"
          value={ selectedPatient.allergies }
          onChange={ e => {
            onChangePatientData( 'allergies', e.target.value )
          }} />
      </FormGroup>
    </Form>
  )
}

export default Medical