import React, { Component } from 'react'
import _ from 'lodash'

import { Get } from 'utils/axios'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      panels: []
    }

    load = param => this.setState({ loading: param })

    getPanels = () => Get(
      `/panels`,
      this.getPanelsSuccess,
      this.getPanelsError,
      this.load
    )
    getPanelsSuccess = payload => this.setState({ panels: payload })
    getPanelsError = error => {}

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadPanels = { this.state.loading }
          panels = { this.state.panels }
          getPanels={ this.getPanels } />
      )
    }
  }

  return WithHOC
}

export default HOC