import React, { Component } from 'react'
import { 
  Row, 
  Col,
  Card,
  CardBody,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'
import Moment from 'moment'
import { compose } from 'redux'

import PageTitle from 'components/Title'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SearchForm from './SearchForm'
import UpdateInvoice from './Update'
import CreateInvoice from './Create'
import Pagination from 'components/Pagination'
import { priceWithSeparators } from 'utils/priceWithSeparators'

import WithInvoices from './actions'
import WithCompanies from 'actions/dictionary/companies'
import WithBranches from 'actions/dictionary/branches'
import WithTreatments from 'actions/dictionary/treatments'
import WithMedicines from 'actions/dictionary/medicines'
import WithPanels from 'actions/dictionary/panels'
import WithProducts from './actions/Products'
import WithPayments from './actions/Payments'
import WithPatients from './actions/Patients'

class Invoices extends Component {

  componentDidMount = () => {
    let tmp = ''
    this.props.searchParams.map( item => {
      tmp = tmp + `&q[${ item.key }]=${ item.value }`
    })
    this.props.getInvoices( tmp, 1 )
    this.props.getCompanies()
    this.props.getBranches()
    this.props.getTreatments()
    this.props.getMedicines()
    this.props.getProducts()
    this.props.getPatients()
    this.props.getPanels()
  }

  componentDidUpdate = pp => {
    if( ( !this.props.showNewPayment && pp.showNewPayment ) || ( !this.props.showUpdatePayment && pp.showUpdatePayment ) ){
      this.props.onChangePaymentHOC( 'selectedPatient', {} )
      this.props.onChangePaymentHOC( 'caPatients', [] )
    }
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading="Invoices"
          subheading="Listings of all the invoices based on selected company."
          icon="pe-7s-note2 icon-gradient bg-happy-itmeo"
          buttons={[
            {  
              color: 'primary',
              onClick: () => {
                this.props.onChangeInvoiceHOC( 'showCreateInvoice', true )
              },
              content: 'Create receipt'
            }
          ]} />
        <div>
          <Row>
            <Col md={ 12 }>
              <Card className="main-card mb-3">
                <CardBody>
                  <SearchForm { ...this.props } />
                  <ReactTable
                    data={ this.props.invoices.data }
                    columns={[
                      {
                        Header: 'Created at',
                        Cell: ( row ) => (
                          <span>{ Moment( row.original.created_at ).format( "DD MMM YYYY" ) }</span>
                        )
                      },
                      {
                        Header: 'Invoice number',
                        accessor: 'invoice_number'
                      },
                      {
                        Header: "Doctor",
                        Cell: row => {
                          return (
                            <span>{ row.original.invoice_user ? row.original.invoice_user.name : '' }</span>
                          )
                        }
                      },
                      {
                        Header: 'Patient name',
                        accessor: 'patient_name'
                      },
                      {
                        Header: 'Patient contact',
                        accessor: 'patient_contact_number'
                      },
                      {
                        Header: 'Payments',
                        width: 300,
                        Cell: ( row ) => {
                          return (
                            <div className="w-100">
                              <ReactTable
                                columns={[
                                  {
                                    Header: 'Customer name',
                                    accessor: 'customer_name'
                                  },
                                  {
                                    Header: 'Current cost',
                                    accessor: 'current_cost',
                                    Cell: ({ value }) => priceWithSeparators( value )
                                  }
                                ]}
                                data={ row.original.payments }
                                defaultPageSize={ row.original.payments.length }
                                showPagination={ false }
                                sortable={ false }
                                NoDataComponent={() => null} />
                            </div>
                          )
                        }
                      },
                      {
                        Header: 'Actions',
                        maxWidth: 80,
                        Cell: ( row ) => (
                          <>
                            <Button
                              className="mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={() => {
                                this.props.getSelectedInvoice( row.original.id )
                                this.props.getPayments( row.original.id )
                              }}>
                              <i className="pe-7s-pen btn-icon-wrapper"> </i>
                            </Button>
                            {/* <Button
                              className="btn-icon btn-icon-only"
                              color="primary"
                              onClick={() => {
                                
                              }}>
                              Download
                            </Button> */}
                          </>
                        )
                      }
                    ]}
                    defaultPageSize={ 10 }
                    showPagination={ false }
                    sortable={ false } />
                  <Pagination 
                    metadata={ this.props.invoices.pagy } 
                    onChangePage={ pg => {
                      let tmp = ''
                      this.props.searchParams.map( item => {
                        tmp = tmp + `&q[${ item.key }]=${ item.value }`
                      })
                      this.props.getInvoices( tmp, pg )
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <CreateInvoice { ...this.props } />
        <UpdateInvoice { ...this.props } />
        { ( this.props.onLoadInvoices || 
          this.props.onLoadCompanies || 
          this.props.onLoadBranches || 
          this.props.onLoadTreatments ||
          this.props.onLoadMedicines ||
          this.props.onLoadProducts ||
          this.props.onLoadPayments ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithInvoices,
  WithCompanies,
  WithBranches,
  WithTreatments,
  WithMedicines,
  WithPanels,
  WithProducts,
  WithPatients,
  WithPayments
)( Invoices )