import React, { Component } from 'react'
import { toast } from 'react-toastify'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newAssignTreatment: {
        medical_record_id: '',
        treatment_id: ''
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeAssignTreatmentHOC = ( key, val ) => this.setState({ [ key ]: val })

    assignTreatmentToMedicalRecord = data => Post(
      `/medical_records/${ data.medical_record_id }/add_treatment?treatment_id=${ data.treatment_id }`,
      {},
      this.assignTreatmentToMedicalRecordSuccess,
      this.assignTreatmentToMedicalRecordError,
      this.load
    )
    assignTreatmentToMedicalRecordSuccess = payload => {
      this.setState({  
        newAssignTreatment: {
          medical_record_id: '',
          treatment_id: ''
        }
      })
      this.requestSuccess( 'Treatment is assigned to medical record successfully.' )
      this.props.getSelectedMedicalRecord( this.props.selectedMedicalRecord.id )
    }
    assignTreatmentToMedicalRecordError = error => this.requestError( error )

    removeTreatmentFromMedicalRecord = data => Delete(
      `/medical_records/${ data.medical_record_id }/remove_treatment?treatment_id=${ data.treatment_id }`,
      this.removeTreatmentFromMedicalRecordSuccess,
      this.removeTreatmentFromMedicalRecordError,
      this.load
    )
    removeTreatmentFromMedicalRecordSuccess = payload => {
      this.requestSuccess( 'Treatment is removed from medical record successfully.' )
      this.props.getSelectedMedicalRecord( this.props.selectedMedicalRecord.id )
    }
    removeTreatmentFromMedicalRecordError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadAssignTreatment={ this.state.loading }
          newAssignTreatment={ this.state.newAssignTreatment }
          assignTreatmentToMedicalRecord={ this.assignTreatmentToMedicalRecord }
          removeTreatmentFromMedicalRecord={ this.removeTreatmentFromMedicalRecord }
          onChangeAssignTreatmentHOC={ this.onChangeAssignTreatmentHOC } />
      )
    }
  }
  return WithHOC
}

export default HOC