import React, { useState } from 'react'
import {
  Form, 
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardFooter,
  Button,
  CustomInput,
  CardHeader,
  Collapse,
  UncontrolledTooltip
} from 'reactstrap'
import { Multiselect } from 'react-widgets'
import _ from 'lodash'
import Moment from 'moment'

const DummyDoctors = [
  {
    id: 1,
    name: `${ 'Dr.' } ${ 'Kenneth Wong' }`
  },
  {
    id: 2,
    name: `${ 'Dr.' } ${ 'Loo Chien Win' }`
  }
]

const ListItem = ({ item }) => (
  <span>
    { `${ item.name }` }
  </span>
)

const SearchForm = ({
  selectedDoctorID,
  selectedBranchID,
  selectedPatientName,
  doctors,
  onChangeAppointmentsHOC,
  getOffDays,
  getAppointments,
  data,
  showCancelledAppointments,
  selectedDate
}) => {
  const [ isCardOpen, updateIsCardOpen ] = useState( false )
  return (
    <Card className="main-card mb-3">
      <CardHeader 
        id='search-bar-toggle'
        className='d-flex justify-content-between' 
        onClick={ () => updateIsCardOpen( !isCardOpen ) } >
        <Label className='m-0' >Search</Label>
        <i 
          className={ isCardOpen ? 'pe-7s-angle-up' : 'pe-7s-angle-down' }
          style={{ fontSize: "3rem", color: "#000" }} />
        <UncontrolledTooltip target='search-bar-toggle' placement="top-end">
          { isCardOpen ? 'Collapse' : 'Expand' }</UncontrolledTooltip>
      </CardHeader>
      <Collapse isOpen={ isCardOpen } >
        <CardBody>
          <Form onSubmit={ e => e.preventDefault()}>
            {
              data.profileReducer.profile.current_user.role.name !== 'Doctor' && 
              <FormGroup>
                <Label>Select doctor</Label>
                <Multiselect
                  placeholder='All doctors'
                  data={
                    _.filter( 
                      doctors, doctor => _.filter( 
                        doctor.branch_ids, branch_id => _.findIndex( data.profileReducer.profile.current_user.branch_ids, item => item === branch_id ) > -1 
                      ).length > 0 
                      && _.findIndex( selectedDoctorID, { id: doctor.id } ) === -1 
                    ) 
                  }
                  textField={ item => item.salutation + '. ' + item.name  }
                  itemComponent={ item => <span>{ item.value.salutation + '. ' + item.value.name }</span> }
                  value={ selectedDoctorID }
                  onChange={ val => {
                    onChangeAppointmentsHOC( 'selectedDoctorID', val )
                  }} />
              </FormGroup>
            }
            <FormGroup>
              <Label>Select branch</Label>
              <Multiselect
                placeholder='All branches'
                data={ data.profileReducer.profile.branches.filter( branch => _.findIndex( selectedBranchID, { id: branch.id } ) === -1 ) }
                textField={ item => item.name  }
                itemComponent={ item => {
                  return (
                    <span>{ `${ item.value.name }` }</span>
                  )
                }}
                value={ selectedBranchID }
                onChange={ val => {
                  onChangeAppointmentsHOC( 'selectedBranchID', val )
                }} />
            </FormGroup>
            <FormGroup>
              <Label>Search with patient name</Label>
              <Input
                type="text"
                value={ selectedPatientName }
                onChange={ e => {
                  onChangeAppointmentsHOC( 'selectedPatientName', e.target.value )
                }} />
            </FormGroup>
          </Form>
        </CardBody>
        <CardFooter className='d-flex justify-content-between'>
          <CustomInput
            id='showCancelledAppointments'
            type='checkbox'
            label='show cancelled appointment(s)'
            checked={ showCancelledAppointments }
            onChange={ e => onChangeAppointmentsHOC( 'showCancelledAppointments', e.target.checked ) } />
          <Button 
            color="primary" 
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              Promise.all([ 
                getOffDays( selectedDoctorID.length > 0 ? JSON.stringify( selectedDoctorID.map( dr => dr.id ) ) : '[ 0 ]' )
              ]).then(() => {
                getAppointments( 
                  selectedDoctorID.length > 0 ? selectedDoctorID.map( dr => dr.id ) : [],
                  selectedBranchID.length > 0 ? selectedBranchID.map( branch => branch.id ) : [],
                  Moment( selectedDate ).clone().startOf('month').format( 'YYYY-MM-DD' ), 
                  Moment( selectedDate ).clone().endOf('month').add( 1, 'days' ).format( 'YYYY-MM-DD' )
                )
              })
            }}>Search</Button>
        </CardFooter>
      </Collapse>
    </Card>
  )
}

export default SearchForm