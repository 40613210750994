import React, { Component } from 'react'
import { 
  Row, 
  Col,
  Card,
  CardBody,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'
import { compose } from 'redux'

import PageTitle from 'components/Title'
import SearchForm from './SearchForm'
import CreateUser from './Create'
import UpdateUser from './Update'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithUsers from './actions'
import WithRoles from 'actions/dictionary/roles'

class Users extends Component {

  componentDidMount = () => {
    this.props.getUsers()
    this.props.getRoles()
  }
  
  render = () => {

    return (
      <>
        <PageTitle
          heading="Users"
          subheading="Listings of all the doctors and frontdesks in the system."
          icon="pe-7s-users icon-gradient bg-happy-itmeo"
          buttons={
            this.props.data.profileReducer.profile.current_user.role.name === 'Super Admin'
              ? [
                  {  
                    color: 'primary',
                    onClick: () => this.props.onChangeUsersHOC( 'showCreateUser', true ),
                    content: 'Add user'
                  }
                ]
              : []
          } />
        <div>
          <Row>
            <Col md={ 12 }>
              <SearchForm { ...this.props }/>
              <Card className="main-card mb-3">
                <CardBody>
                  {
                    this.props.users.length > 0 ? (
                      <ReactTable
                        data={ this.props.users }
                        columns={[
                          {
                            Header: 'Name',
                            accessor: 'name'
                          },
                          {
                            Header: 'IC/Passport',
                            accessor: 'nric_no'
                          },
                          {
                            Header: 'Contact',
                            accessor: 'contact_number'
                          },
                          {
                            Header: 'Role',
                            Cell: ( row ) => (
                              <>
                                <span>{ row.original.role.name }</span>
                              </>
                            )
                          },
                          {
                            Header: 'Actions',
                            maxWidth: 80,
                            Cell: ( row ) => (
                              <>
                                <Button
                                  className="mb-2 mr-2 btn-icon btn-icon-only"
                                  color="primary"
                                  onClick={() => {
                                    this.props.getSelectedUser( row.original.id )
                                  }}>
                                  <i className="pe-7s-pen btn-icon-wrapper"> </i>
                                </Button>
                                {/* <Button
                                  className="mb-2 mr-2 btn-icon btn-icon-only"
                                  color="light"
                                  onClick={() => {

                                  }}>
                                  <i className="pe-7s-trash btn-icon-wrapper"> </i>
                                </Button> */}
                              </>
                            )
                          }
                        ]}
                        defaultPageSize={ this.props.users.length + 10 } />
                    ) : (
                      <p>No data here</p>
                    )
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
          <CreateUser { ...this.props } />
          <UpdateUser { ...this.props } />
        </div>
        { ( this.props.onLoadUsers || this.props.onLoadRoles ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithUsers,
  WithRoles
)( Users )