import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'
import Cookies from 'js-cookie'
import FileSaver from 'file-saver'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post, Delete } from 'utils/axios'
import getDomainURL from 'utils/api'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      newPatient: {
        name: '',
        nric_no: '',
        email: '',
        date_of_birth: '',
        age: '',
        gender: '',
        address: '',
        country: '',
        state: '',
        city: '',
        postcode: '',
        language: '',
        contact_number: '',
        allergies: '',
        status: '',
        photo: '',
        company_ids: [],
        is_vaccinated: false,
        panel_id: 0
      },
      patients: {
        data: [],
        pagy: {
          pages: 1
        }
      },
      selectedPatient: {},
      showCreatePatient: false,
      showUpdatePatient: false,
      showViewPatient: false,
      downloadPatientCompanyId: 0,
      loading: false,
      patientError: {},
      searchParams: '',
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangePatientsHOC = ( key, val ) => this.setState({ [ key ]: val }, () => {
      if( key === 'showUpdatePatient' ){
        if( !_.isEmpty( this.props.match.params ) ){
          window.location.href = window.location.origin + '/#/dashboard/patients'
        }
      }
    })

    getPatients = ( searchParams, page ) => Get(
      `/patients?item_per_page=20&page=${ page }&search=${ searchParams }`,
      this.getPatientsSuccess,
      this.getPatientsError,
      this.load
    )
    getPatientsSuccess = payload => this.setState({ patients: payload })
    getPatientsError = error => this.requestError( error )

    getSelectedPatient = ( id, type ) => Get(
      `/patients/${ id }`,
      payload => this.getSelectedPatientSuccess( payload, type ),
      this.getSelectedPatientError,
      this.load
    )
    getSelectedPatientSuccess = ( payload, type ) => {
      let tmpBirthday = Moment( payload.date_of_birth )
      let tmp = Moment.duration( Moment().diff( tmpBirthday ) )
      this.setState({ 
        selectedPatient: {
          ...payload,
          date_of_birth: new Date( payload.date_of_birth ),
          age: tmp ? tmp.years() : 'N/A',
          appointments: payload.appointments.sort(( a,b ) => {
            let dateA = new Date(a.start_datetime);
            let dateB = new Date(b.start_datetime);
            return dateB - dateA;
          })
        }, 
        showUpdatePatient: type === 'view' ? false : true 
      })
    }
    getSelectedPatientError = error => this.requestError( error )

    createPatient = data => Post(
      `/patients`,
      data,
      this.createPatientSuccess,
      this.createPatientError,
      this.load
    )
    createPatientSuccess = payload => {
      this.requestSuccess( 'Patient is created successfully.' )
      this.setState({ showCreatePatient: false })
      this.getPatients( this.state.searchParams, 1 )
    }
    createPatientError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ patientError: error })
      }
    }

    updatePatient = data => Put(
      `/patients/${ data.id }`,
      data,
      this.updatePatientSuccess,
      this.updatePatientError,
      this.load
    )
    updatePatientSuccess = payload => {
      this.requestSuccess( 'Patient is updated successfully.' )
      this.setState({ showUpdatePatient: false })
      this.getPatients( this.state.searchParams, 1 )
    }
    updatePatientError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ patientError: error })
      }
    }

    uploadPhoto = data => Put(
      `/patients/${ data.id }/upload_photo`,
      data.fromIcReader 
        ? { id: data.id, photo: data.photo }
        : data,
      payload => this.uploadPhotoSuccess( payload, data ),
      this.uploadPhotoError,
      this.load
    )
    uploadPhotoSuccess = ( payload, data ) => {
      let tmpSelectedPatient = _.cloneDeep( this.state.selectedPatient )
      tmpSelectedPatient = {
        ...tmpSelectedPatient,
        ...( data.fromIcReader ? data.selectedPatient : {} ),
        photo_url: payload.photo_url
      }
      this.setState({ selectedPatient: tmpSelectedPatient }, () => {
        this.requestSuccess( 'Patient photo is uploaded successfully.' )
      })
    }
    uploadPhotoError = error => this.requestError( error )

    removePatient = id => Delete(
      `/patients/${ id }`,
      this.removePatientSuccess,
      this.removePatientError,
      this.load
    )
    removePatientSuccess = payload => {
      this.requestSuccess( 'Patient is removed successfully.' )
      this.getPatients( '', 1 )
      this.setState({ showUpdatePatient: false })
    }
    removePatientError = error => this.requestError( error )

    downloadPatientsPdf = ( company_id, company_name ) => {
      let headers = new Headers()
      headers.append( 'Authorization', `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }` )
      this.load( true )
      fetch(
        `${ getDomainURL() }/patients/download?company_id=${ company_id }`,
        {
          headers: headers
        }
      ).then( res => res.blob()).then( blobby => {
        this.load( false )
        this.setState({ downloadPatientCompanyId: 0 })
        return FileSaver.saveAs( blobby, `${ company_name.replaceAll( ' ', '_' ) }_patient_listing.pdf` )
      }).catch( error => {
        this.load( false )
        this.requestError( 'Failed to save patient listing. Please try again.' )
        this.setState({ downloadPatientCompanyId: 0 })
      })
    }

    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          newPatient={ this.state.newPatient }
          patients={ this.state.patients }
          selectedPatient={ this.state.selectedPatient }
          showCreatePatient={ this.state.showCreatePatient }
          showUpdatePatient={ this.state.showUpdatePatient }
          showViewPatient={ this.state.showViewPatient }
          onLoadPatients={ this.state.loading }
          searchParams={ this.state.searchParams }
          downloadPatientCompanyId={ this.state.downloadPatientCompanyId }
          onChangePatientsHOC={ this.onChangePatientsHOC }
          getPatients={ this.getPatients }
          getSelectedPatient={ this.getSelectedPatient }
          createPatient={ this.createPatient }
          updatePatient={ this.updatePatient }
          removePatient={ this.removePatient }
          uploadPhoto={ this.uploadPhoto }
          downloadPatientsPdf={ this.downloadPatientsPdf } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC