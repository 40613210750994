import React, { Component } from 'react'
import { toast } from 'react-toastify'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Put, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      newBranch: {
        name: '',
        address: '',
        postcode: '',
        country: '',
        city: '',
        state: '',
        company_id: 0
      },
      selectedBranch: {},
      showCreateBranch: false,
      showUpdateBranch: false,
      loading: false,
      branchError: {}
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeBranchesHOC = ( key, val ) => this.setState({ [ key ]: val })

    getSelectedBranch = ( id ) => Get(
      `/branches/${ id }`,
      this.getSelectedBranchSuccess,
      this.getSelectedBranchError,
      this.load
    )
    getSelectedBranchSuccess = payload => this.setState({ selectedBranch: payload, showUpdateBranch: true })
    getSelectedBranchError = error => this.requestError( error )

    createBranch = data => Post(
      `/branches`,
      data,
      this.createBranchSuccess,
      this.createBranchError,
      this.load
    )
    createBranchSuccess = payload => {
      this.requestSuccess( 'Branch is created successfully.' )
      this.props.getSelectedCompany( this.props.selectedCompany.id )
      this.setState({
        newBranch: {
          name: '',
          address: '',
          postcode: '',
          country: '',
          city: '',
          state: '',
          company_id: 0
        },
        showCreateBranch: false
      })
    }
    createBranchError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ branchError: error })
      }
    }

    updateBranch = data => Put(
      `/branches/${ data.id }`,
      data,
      this.updateBranchSuccess,
      this.updateBranchError,
      this.load
    )
    updateBranchSuccess = payload => {
      this.requestSuccess( 'Branch is updated successfully.' )
      this.props.getSelectedCompany( this.props.selectedCompany.id )
      this.setState({ selectedBranch: {}, showUpdateBranch: false })
    }
    updateBranchError = error => {
      if( typeof( error ) === 'string' ) {
        this.requestError( error )
      } else {
        this.setState({ branchError: error })
      }
    }

    removeBranch = id => Delete(
      `/branches/${ id }`,
      this.removeBranchSuccess,
      this.removeBranchError,
      this.load
    )
    removeBranchSuccess = payload => {
      this.requestSuccess( 'Branch is removed successfully.' )
      this.props.getSelectedCompany( this.props.selectedCompany.id )
      this.setState({ showPromptModal: false })
    }
    removeBranchError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent 
            { ...this.props }
            newBranch={ this.state.newBranch }
            selectedBranch={ this.state.selectedBranch }
            showCreateBranch={ this.state.showCreateBranch }
            showUpdateBranch={ this.state.showUpdateBranch }
            onLoadBranches={ this.state.loading }
            onChangeBranchesHOC={ this.onChangeBranchesHOC }
            getSelectedBranch={ this.getSelectedBranch }
            createBranch={ this.createBranch }
            updateBranch={ this.updateBranch } />
          <PromptModal
            showPromptModal={ this.state.showPromptModal }
            onClickYes={() => this.removeBranch( this.state.toRemoveID )}
            onClickNo={() => this.setState({ showPromptModal: false })}
            content={ 'Are you sure you want to delete the record?' } />
        </>
      )
    }
  }
  return WithHOC
}

export default HOC