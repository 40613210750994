import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'

import { Post, Get, Put, Delete } from 'utils/axios'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      panels: [],
      newPanel: {},
      selectedPanel: {},
      showCreatePanel: false,
      showUpdatePanel: false,
      selectedPanelToDelete: 0,
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( ({ closeToast }) => ReactHTMLParser( error.message ) )

    onChangePanelsHOC = ( key, val ) => this.setState({ [ key ]: val })

    createPanel = data => Post(
      `/panels`,
      data,
      this.createPanelSuccess,
      this.createPanelError,
      this.load
    )
    createPanelSuccess = () => {
      this.requestSuccess( 'Panel is successfully created' )
      this.getPanels()
      this.reloadNewPanel()
    }
    createPanelError = error => this.requestError( error )

    getPanels = () => Get(
      `/panels`,
      this.getPanelsSuccess,
      this.getPanelsError,
      this.load
    )
    getPanelsSuccess = payload => this.setState({ panels: payload })
    getPanelsError = error => this.requestError( error )

    getSelectedPanel = panel_id => Get(
      `/panels/${ panel_id }`,
      this.getSelectedPanelSuccess,
      this.getSelectedPanelError,
      this.load
    )
    getSelectedPanelSuccess = payload => this.setState({ selectedPanel: payload, showUpdatePanel: true })
    getSelectedPanelError = error => this.requestError( error )

    updatePanel = data => Put(
      `/panels/${ data.id }`,
      data,
      this.updatePanelSuccess,
      this.updatePanelError,
      this.load
    )
    updatePanelSuccess = () => {
      this.requestSuccess( 'Panel is successfully updated' )
      this.getPanels()
      this.reloadSelectedPanel()
    }
    updatePanelError = error => this.requestError( error )

    removePanel = panel_id => Delete(
      `/panels/${ panel_id }`,
      this.removePanelSuccess,
      this.removePanelError,
      this.load
    )
    removePanelSuccess = () => {
      this.requestSuccess( 'Panel is successfully removed' )
      this.getPanels()
      this.setState({ selectedPanelToDelete: 0 })
    }

    reloadNewPanel = () => this.setState({
      showCreatePanel: false,
      newPanel: {
        code: '',
        panel_name: '',
        address: '',
        contact_number: '',
        email: '',
        person_in_charge: '',
        person_in_charge_contact_number: '',
        company_id: 0
      }
    })

    reloadSelectedPanel = () => this.setState({
      showDownloadPanel: false,
      showUpdatePanel: false,
      selectedPanel: {}
    })

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadPanelsHOC = { this.state.loading }
          panels = { this.state.panels }
          newPanel = { this.state.newPanel }
          selectedPanel = { this.state.selectedPanel }
          showCreatePanel = { this.state.showCreatePanel }
          showUpdatePanel = { this.state.showUpdatePanel }
          selectedPanelToDelete = { this.state.selectedPanelToDelete }
          onChangePanelsHOC={ this.onChangePanelsHOC }
          createPanel={ this.createPanel }
          getPanels={ this.getPanels }
          getSelectedPanel={ this.getSelectedPanel }
          updatePanel={ this.updatePanel }
          removePanel={ this.removePanel }
          reloadNewPanel={ this.reloadNewPanel }
          reloadSelectedPanel={ this.reloadSelectedPanel } />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC