import React, { useEffect } from 'react'
import {
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody
} from 'reactstrap'
import { Multiselect } from 'react-widgets'

import WithCompanies from 'actions/dictionary/companies'

const DummyCompanies = [
  {
    id: 1,
    name: 'Wong and Sim'
  },
  {
    id: 2,
    name: 'Prime Care'
  }
]

const ListItem = ({ item }) => (
  <span>
    { `${ item.name }` }
  </span>
)

const CompanyTab = ({
  onChangeUserData,
  onLoadCompanies,
  companies,
  getCompanies
}) => {

  useEffect(() => {
    getCompanies()
  }, [])

  return (
    <Form onSubmit={ e => e.preventDefault()}>
      <FormGroup>
        <Label>Assign companies</Label>
        <Multiselect
          data={ companies }
          textField={ 'name' }
          itemComponent={ ListItem }
          onChange={ val => {
            let tmp = []
            val.map( item => {
              tmp.push( item.id )
            })
            onChangeUserData( 'company_ids', tmp )
          }} />
      </FormGroup>
    </Form>
  )
}

export default WithCompanies( CompanyTab )