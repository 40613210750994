import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newProduct: {
        product_id: 0,
        quantity: 0,
        price_per_unit: 0
      },
      products: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeProductsHOC = ( key, val ) => this.setState({ [key]: val })

    getProducts = () => Get(
      `/products`,
      this.getProductsSuccess,
      this.getProductsError,
      this.load
    )
    getProductsSuccess = payload => this.setState({ products: payload })
    getProductsError = error => this.requestError( error )

    addInvoiceProduct = ( data, totalPrice ) => Post(
      `/invoices/${ this.props.selectedInvoice.id }/add_product`,
      data,
      ( payload ) => this.addInvoiceProductSuccess( payload, data, totalPrice ),
      this.addInvoiceProductError,
      this.load
    )
    addInvoiceProductSuccess = ( payload, data, totalPrice ) => {
      this.requestSuccess( 'Product is added successfully.' )
      payload.stock_warning && this.requestError( 'This product is going to run out of stock, with stock count less or equal to 10' )
      this.props.getSelectedInvoice( this.props.selectedInvoice.id )
      this.setState({
        newProduct: {
          product_id: 0,
          quantity: 0
        }
      })
    }
    addInvoiceProductError = error => this.requestError( error.message )

    removeInvoiceProduct = ( invoice_id, product_id, totalPrice ) => Delete(
      `/invoices/${ invoice_id }/remove_product?product_id=${ product_id }`,
      payload => this.removeInvoiceProductSuccess( payload, totalPrice ),
      this.removeInvoiceProductError,
      this.load
    )
    removeInvoiceProductSuccess = ( payload, totalPrice ) => {
      this.requestSuccess( 'Product is removed successfully.' )
      this.props.getSelectedInvoice( this.props.selectedInvoice.id )
    }
    removeInvoiceProductError = error => this.requestError( 'Failed to remove product.' )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadProducts={ this.state.loading }
          newProduct={ this.state.newProduct }
          products={ this.state.products }
          onChangeProductsHOC={ this.onChangeProductsHOC }
          getProducts={ this.getProducts }
          addInvoiceProduct={ this.addInvoiceProduct }
          removeInvoiceProduct={ this.removeInvoiceProduct } />
      )
    }
  }
  return WithHOC
}

export default HOC