import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Collapse,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'

const SearchForm = ({
  searchParams,
  onChangeTreatmentsHOC,
  getTreatments
}) => {
  const [ isCardOpen, updateIsCardOpen ] = useState( false )
  return (
    <Card className="main-card mb-3">
      <CardHeader 
        id='search-bar-toggle'
        className='d-flex justify-content-between' 
        onClick={ () => updateIsCardOpen( !isCardOpen ) } >
        <Label className='m-0' >Search</Label>
        <i 
          className={ isCardOpen ? 'pe-7s-angle-up' : 'pe-7s-angle-down' }
          style={{ fontSize: "3rem", color: "#000" }} />
        <UncontrolledTooltip target='search-bar-toggle' placement="top-end">
          { isCardOpen ? 'Collapse' : 'Expand' }</UncontrolledTooltip>
      </CardHeader>
      <Collapse isOpen={ isCardOpen } >
        <CardBody>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <Label>{ 'Name' }</Label>
              <Input
                type={ 'text' }
                value={ searchParams }
                onChange={ e => {
                  onChangeTreatmentsHOC( 'searchParams', e.target.value )
                }} />
            </FormGroup>
            <div className="d-flex">
              <Button 
                color="primary"
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  getTreatments()
                }}>{ 'Search' }</Button>
              <Button 
                color="danger"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  Promise.all([
                    onChangeTreatmentsHOC( 'searchParams', '' )
                  ]).then(() => {
                    getTreatments()
                  })
                }}>{ 'Reset' }</Button>
            </div>
          </Form>
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default SearchForm