import Axios from 'axios'
import Cookies from 'js-cookie'

import getDomainURL from 'utils/api'

export const Get = ( url, response, error, load ) => {
  load( true )
  Axios.defaults.headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : '*',
    'Authorization': `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }`
  }
  return Axios.get( `${ getDomainURL() }${ url }` ).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response ) {
      if( err.response.status === 500 ) {
        error( 'Server encountered issues. Please contact your system admin for assistance.' )
      } else {
        error( err.response.data )
      }
    } else if( err.response ) {
      error( err.response.data )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use the Primeview. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const Post = ( url, data, response, error, load ) => {
  load( true )
  Axios.defaults.headers = {
    'Access-Control-Allow-Origin' : '*',
    'Authorization': `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }`
  }
  return Axios.post( `${ getDomainURL() }${ url }`, data ).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    console.dir( err )
    if( err && err.response ) {
      error( err.response.data )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Primeview. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const Put = ( url, data, response, error, load ) => {
  load( true )
  Axios.defaults.headers = {
    'Access-Control-Allow-Origin' : '*',
    'Authorization': `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }`
  }
  return Axios.put( `${ getDomainURL() }${ url }`, data ).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      if( err.response.status === 500 ) {
        error( 'Server encountered issues. Please contact your system admin for assistance.' )
      } else {
        error( err.response.data )
      }
    } else if( err && err.response ) {
      error( err.response.data )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Primeview. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const Delete = ( url, response, error, load ) => {
  load( true )
  Axios.defaults.headers = {
    'Access-Control-Allow-Origin' : '*',
    'Authorization': `Bearer ${ Cookies.get( 'PRIMEVIEW_WEB_TOKEN' ) }`
  }
  return Axios.delete( `${ getDomainURL() }${ url }` ).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      if( err.response.status === 500 ) {
        error( 'Server encountered issues. Please contact your system admin for assistance.' )
      } else {
        error( err.response.data )
      }
    } else if( err ) {
      error( err.response.data )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Primeview. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const PostNotification = ( data, response, error, load ) => {
  load( true )
  Axios.defaults.headers = {
    'Content-Type': 'application/json',
    'Authorization': `key=AAAAPWZL-uk:APA91bEhMnGrNS3Er2ggfXzn1YtaRKVwi1mByT0gXFLtY9maNn_GIUmPRRsi1WdFXdwzeO-Lks5XHptodsBbc86tZdc4Pa-s4Su0Efy0Orn6hDVjJsuYbrpTy0Pl9A7FaBR41owPB9Hi`
  }
  return Axios.post( `https://fcm.googleapis.com/fcm/send`, data ).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    console.dir( err )
    if( err && err.response ) {
      error( err.response.data )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Primeview. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}