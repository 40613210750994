import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Put, Post, Get } from 'utils/axios'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      selectedCompanyOutstandingInvoices: []
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getCompanyOutstandingInvoices = company_id => Get(
      `/dashboard/outstanding_invoices?company_id=${ company_id }`,
      this.getCompanyOutstandingInvoicesSuccess,
      this.getCompanyOutstandingInvoicesError,
      this.load
    )
    getCompanyOutstandingInvoicesSuccess = payload => this.setState({ selectedCompanyOutstandingInvoices: payload })
    getCompanyOutstandingInvoicesError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadOutstandingInvoices={ this.state.loading }
          selectedCompanyOutstandingInvoices={ this.state.selectedCompanyOutstandingInvoices }
          getCompanyOutstandingInvoices={ this.getCompanyOutstandingInvoices } />
      )
    }
  }

  return WithHOC
}

export default HOC