import React from 'react'
import {
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  Row,
  Col
} from 'reactstrap'
import _ from 'lodash'
import { Multiselect } from 'react-widgets'

import SmileDatepicker from 'components/Datepicker'

const TimeSlipForm = ({
  currentTimeSlip,
  timeSlipType,
  onChangeTimeSlipsHOC,
  doctors,
  patients,
  getPatients,
  onChangePatientsHOC,
  onLoadPatientsHOC,
  patientSearchParam,
  branches,
  companies
}) => {
  const updateCurrentTimeSlip = ( key, value ) => {
    let tmp = _.cloneDeep( currentTimeSlip )
    tmp[ key ] = value

    switch( key ){
      case 'company_id':
        tmp.branch_id = 0
        tmp.user_id = 0  
        break
      case 'branch_id': 
        tmp.user_id = 0
    }

    onChangeTimeSlipsHOC( timeSlipType, tmp )
  }

  const ListItem = ({ item }) => (
    <span>
      { `${ item.name } - ${ item.nric_no } - ${ item.contact_number }` }
    </span>
  )

  return(
    <Form>
      <FormGroup>
        <Label>Select company</Label>
        <Input 
          type="select"
          value={ currentTimeSlip.company_id }
          onChange={ e => updateCurrentTimeSlip( 'company_id', parseInt( e.target.value ) ) }>
          <option key={ 0 } value={ 0 } ></option>
          { companies.map( item => <option key={ item.id } value={ item.id }>{ item.name }</option> ) }
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Select branch</Label>
        <Input 
          type="select"
          value={ currentTimeSlip.branch_id }
          onChange={ e => updateCurrentTimeSlip( 'branch_id', parseInt( e.target.value ) ) }>
          <option key={ 0 } value={ 0 } ></option>
          { 
            _.filter( 
              branches, branch => parseInt( branch.company.id ) === parseInt( currentTimeSlip.company_id )
            ).map( item => <option value={ item.id } >{ item.name }</option> ) 
          }
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Select doctor</Label>
        <Input 
          type="select"
          value={ currentTimeSlip.user_id }
          onChange={ e => updateCurrentTimeSlip( 'user_id', parseInt( e.target.value ) ) }>
          <option key={ 0 } value={ 0 } ></option>
          { 
            _.filter( 
              doctors, doctor => _.findIndex( doctor.branch_ids, branch => parseInt( branch ) === parseInt( currentTimeSlip.branch_id ) ) > -1
            ).map( item => <option value={ item.id } >{ `${ item.salutation } ${ item.name }` }</option> ) 
          }
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Patient</Label>
        <div className="d-flex mb-2">
          <Input 
            type="text" 
            value={ patientSearchParam }
            className='mr-2'
            onChange={ e => onChangePatientsHOC( 'patientSearchParam', e.target.value ) }
            placeholder="Fill in patient NRIC or name here and click search button" />
          <Button 
            color="primary" 
            onClick={() => {
              getPatients( patientSearchParam )
              updateCurrentTimeSlip( 'patient_id', 0 ) }}>
            Search</Button>
        </div>
        {
          onLoadPatientsHOC 
            ? (
              <p>Loading patients......</p>
            ) : <>
              <Multiselect
                data={ patients }
                textField={ 'name' }
                itemComponent={ ListItem }
                value={ currentTimeSlip.patient_id > 0 
                  ? _.find( patients, { id: parseInt( currentTimeSlip.patient_id ) } ) 
                    ? [ _.find( patients, { id: parseInt( currentTimeSlip.patient_id ) } ) ] 
                    : [ {} ]
                  : [] }
                onChange={ val => val.length === 1 
                  ? updateCurrentTimeSlip( 'patient_id', val[ 0 ].id )
                  : updateCurrentTimeSlip( 'patient_id', 0 )
                } />
            </>
        }
      </FormGroup>
      <FormGroup>
        <Label>Date</Label>
        <SmileDatepicker
          showTimeSelect={ false }
          selectedDate={ currentTimeSlip.date }
          onChange={ val => updateCurrentTimeSlip( 'date', val ) }
          dateFormat="MMMM d, yyyy"
          disabled={ false } 
        />
      </FormGroup>
      <FormGroup>
        <Label>Time</Label>
        <Row className='d-flex align-items-center'>
          <Col md={ 12 } className='d-flex flex-row'>
            <Input
              type='time'
              className='width-max-content'
              value={ currentTimeSlip.start_time }
              onChange={ e => updateCurrentTimeSlip( 'start_time', e.target.value ) } />
            <Label className='m-0 p-2'>-</Label>
            <Input
              type='time'
              className='width-max-content'
              value={ currentTimeSlip.end_time }
              onChange={ e => updateCurrentTimeSlip( 'end_time', e.target.value ) } />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Label>remark</Label>
        <Input
          type='text'
          value={ currentTimeSlip.remark }
          onChange={ e => updateCurrentTimeSlip( 'remark', e.target.value ) } />
      </FormGroup>
    </Form>
  )
}

export default TimeSlipForm