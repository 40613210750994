import React, { useState } from 'react'
import {
  Card, CardHeader, CardBody,
  Form, FormGroup,
  Label, Button, Collapse, UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'

import SmileDatepicker from 'components/Datepicker'

const SearchForm = ({
  searchParams,
  onChangeCollectionsHOC,
  getCompaniesCollections,
  exportCompaniesCollectionsReport
}) => {
  const [ isCardOpen, updateIsCardOpen ] = useState( false )
  return (
    <Card className="main-card mb-3">
      <CardHeader 
        id='search-bar-toggle'
        className='d-flex justify-content-between' 
        onClick={ () => updateIsCardOpen( !isCardOpen ) } >
        <Label className='m-0' >Search</Label>
        <i 
          className={ isCardOpen ? 'pe-7s-angle-up' : 'pe-7s-angle-down' }
          style={{ fontSize: "3rem", color: "#000" }} />
        <UncontrolledTooltip target='search-bar-toggle' placement="top-end">
          { isCardOpen ? 'Collapse' : 'Expand' }</UncontrolledTooltip>
      </CardHeader>
      <Collapse isOpen={ isCardOpen } >
        <CardBody>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <Label>Start date</Label>
              <SmileDatepicker
                showTimeSelect={ false }
                selectedDate={ searchParams[ 0 ][ 'value' ] }
                onChange={ val => {
                  let tmp = _.cloneDeep( searchParams )
                  tmp[ 0 ][ 'value' ] = val
                  onChangeCollectionsHOC( 'searchParams', tmp )
                }}
                dateFormat="MMMM d, yyyy"
              />
            </FormGroup>
            <FormGroup>
              <Label>End date</Label>
              <SmileDatepicker
                showTimeSelect={ false }
                selectedDate={ searchParams[ 1 ][ 'value' ] }
                onChange={ val => {
                  let tmp = _.cloneDeep( searchParams )
                  tmp[ 1 ][ 'value' ] = val
                  onChangeCollectionsHOC( 'searchParams', tmp )
                }}
                dateFormat="MMMM d, yyyy"
              />
            </FormGroup>
            <div className="mt-4">
              <Button 
                color="primary"
                style={{ marginRight: '10px', display: 'inline-block' }}
                onClick={ () => getCompaniesCollections(
                  Moment( searchParams[ 0 ][ 'value' ] ).format( 'YYYY-MM-DD' ), 
                  Moment( searchParams[ 1 ][ 'value' ] ).format( 'YYYY-MM-DD' )
                )}>
                { 'Search' }</Button>
              <Button 
                color="danger"
                style={{ marginRight: '10px', display: 'inline-block' }}
                onClick={ () => {
                  getCompaniesCollections(
                    Moment( new Date() ).format( 'YYYY-MM-DD' ),
                    Moment( new Date() ).format( 'YYYY-MM-DD' )
                  )
                  onChangeCollectionsHOC( 'searchParams', [
                    {
                      key: 'start_date',
                      value: new Date()
                    },
                    {
                      key: 'end_date',
                      value: new Date()
                    }
                  ])
                }}>
                { 'Reset' }</Button>
              <Button
                color="primary"
                style={{ marginRight: '10px', display: 'inline-block' }}
                onClick={ () => exportCompaniesCollectionsReport({
                  start_date: Moment( searchParams[ 0 ][ 'value' ] ).format( 'YYYY-MM-DD' ),
                  end_date: Moment( searchParams[ 1 ][ 'value' ] ).format( 'YYYY-MM-DD' )
                })}>
                Export report
              </Button>
            </div>
          </Form>
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default SearchForm