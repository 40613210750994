import React, { Component } from 'react'
import { 
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  UncontrolledTooltip
} from 'reactstrap'
import IosNotificationsOutline from 'react-ionicons/lib/IosNotificationsOutline'
import ReactHTMLParser from 'react-html-parser'
import Moment from 'moment'
import _ from 'lodash'

import PromptModal from 'components/Indicator/Prompt'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import getDomainURL from 'utils/api'
import firebase from '../../firebase'

class Dots extends Component {
  state = {
    onLoadNotifications: false,
    notifications: [],
    showRemoveAllNotifications: false,
    notificationToRemoveIndex: -1
  }

  componentDidMount = () => {
    this.getNotifications()
    navigator.serviceWorker.addEventListener( 'message', message => console.log( message ) )
  }

  componentDidUpdate = pp => {
    if( pp.user.id !== this.props.user.id ){
      this.getNotifications()
    }
  }

  getNotifications = () => (
    this.setState({ onLoadNotifications: true }, () => {
      let userRef = getDomainURL().indexOf( 'staging' ) > -1
        ? firebase.database().ref( `stagingUsers/${ this.props.user.id }/notifications` ) 
        : getDomainURL().indexOf( 'idr' ) > -1
          ? firebase.database().ref( `productionUsersIdr/${ this.props.user.id }/notifications` ) 
          : firebase.database().ref( `productionUsers/${ this.props.user.id }/notifications` ) 

      userRef.on( 'value', snapshot => {
        if( snapshot.exists() ){
          let tmp = snapshot.val().filter( notification => (
            'created_at' in notification &&
            Moment( notification.created_at ).isAfter( Moment().subtract( 2, 'days' ) )
          ))
          let tmpList = []
          for( let id in tmp ){
            tmpList.push({ 
              ...tmp[ id ], 
              message: ReactHTMLParser( 
                window.location.host.indexOf( 'localhost' ) > -1 ? tmp[ id ].message.replace( 
                  'https://app-staging.primeview.site', 'http://localhost:5001'
                ) : tmp[ id ].message 
              ) 
            })
          }
          this.setState({ notifications: tmpList }, () => {
            userRef.set( tmp ).then( () => this.setState({ onLoadNotifications: false }) )
          })
        } else {
          this.setState({ onLoadNotifications: false })
        }
      })
    })
  )

  removeNotifications = () => (
    this.setState({ onLoadNotifications: true }, () => {
      const userRef = getDomainURL().indexOf( 'staging' ) > -1
        ? firebase.database().ref( `stagingUsers/${ this.props.user.id }` ) 
        : getDomainURL().indexOf( 'idr' ) > -1
          ? firebase.database().ref( `productionUsersIdr/${ this.props.user.id }` ) 
          : firebase.database().ref( `productionUsers/${ this.props.user.id }` ) 

      userRef.once( 'value', snapshot => {
        if( snapshot.exists() ){
          let tmpUser = {}
          if( snapshot.hasChild( 'token' ) ){
            tmpUser.token = snapshot.val().token
          }
          if( snapshot.hasChild( 'notifications' ) ){
            if( this.state.notificationToRemoveIndex > -1 ){
              tmpUser.notifications = _.cloneDeep( snapshot.val().notifications )
              tmpUser.notifications.splice( this.state.notificationToRemoveIndex, 1 )
            } else {
              tmpUser.notifications = []
            }
          }
          userRef.set( tmpUser ).then( () => {
            this.setState({ 
              showRemoveAllNotifications: false, 
              notificationToRemoveIndex: -1,
              notifications: tmpUser.notifications ? (
                tmpUser.notifications.map( notification => ({
                  ...notification,
                  message: ReactHTMLParser( 
                    window.location.host.indexOf( 'localhost' ) > -1 ? notification.message.replace( 
                      'https://app-staging.primeview.site', 'http://localhost:5001'
                    ) : notification.message 
                  ) 
                }))
              ) : ( [] ),
              onLoadNotifications: false
            })
          })
        } else {
          this.setState({ onLoadNotifications: false })
        }
      })
    })
  )

  render = () => {
    return (
      <div className="header-dots">
        <UncontrolledDropdown>
          <DropdownToggle className="p-0 mr-2" color="link">
            <div className="icon-wrapper icon-wrapper-alt rounded-circle">
              <div className="icon-wrapper-bg bg-danger"/>
              <IosNotificationsOutline 
                beat={ true } 
                color="#d92550" 
                fontSize="23px" />
              <div className="badge badge-dot badge-dot-sm badge-danger">Notifications</div>
            </div>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-xl rm-pointers">
            <div className="dropdown-menu-header mb-0">
              <div className="dropdown-menu-header-inner bg-deep-blue">
                <div className="menu-header-content text-dark">
                  <h6 className="fw-300">Notifications</h6>
                </div>
              </div>
            </div>
            <div className="pl-3 pr-3 pt-3 pb-0">
              {
                this.state.notifications.map( ( notification, index ) => <Button 
                  id={ `notification-${ index }` }
                  className="w-100 mb-2"
                  color="light">
                  <p 
                    className='w-100 text-align-left' 
                    onClick={ () => {
                      if( notification.message[ 0 ].props.children[ 1 ].props.href === window.location.href ){
                        window.location.reload()
                      }
                    }}>
                    { notification.message }</p>
                  <div className='d-flex flex-row justify-content-between align-items-end' >
                    <i 
                      className='pe-7s-trash' 
                      id={ `btn-delete-notifications-${ index }` }
                      color='danger'
                      style={{ background: "none", border: "none", color: "#000", fontSize: "20px" }}
                      onClick={ () => this.setState({ notificationToRemoveIndex: index }) }/>
                    <UncontrolledTooltip
                      target={ `btn-delete-notifications-${ index }` }
                      placement='top' >
                      Remove notification</UncontrolledTooltip>
                    <span className='mt-3 mb-0'>{ Moment( notification.created_at ).format( 'DD/MM/YYYY h:mm a' ) }</span>
                  </div>
                </Button> )
              }
              <div className="w-100 d-flex justify-content-end" >
                <Button  
                  id='btn-delete-notifications'
                  color='danger'
                  onClick={ () => this.setState({ showRemoveAllNotifications: true }) }>
                  <i className='pe-7s-trash' /></Button>
                <UncontrolledTooltip
                  target='btn-delete-notifications'
                  placement='top' >
                  Remove notifications</UncontrolledTooltip>
              </div>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
        <PromptModal
          showPromptModal={ this.state.showRemoveAllNotifications }
          onClickYes={ () => this.removeNotifications() }
          onClickNo={ () => this.setState({ showRemoveAllNotifications: false }) }
          content='Are you sure you want to remove all notifications?' />
        <PromptModal
          showPromptModal={ this.state.notificationToRemoveIndex > -1 }
          onClickYes={ () => this.removeNotifications() }
          onClickNo={ () => this.setState({ notificationToRemoveIndex: -1 }) }
          content='Are you sure you want to remove the selected notifications?' />
        { this.state.onLoadNotifications && <LoadingOverlay/> }
      </div>
    )
  }
}

export default Dots